import { useMemo } from "react"
import { useCustom, useTranslate } from "@pankod/refine-core"
import { IRoles } from "interfaces"
import { SyLabel, SySelect, SySelectOption } from "components/new/shared"
import { getRoleDisplayName } from "utilities/role"

export const RoleDropdown = ({
  roleId,
  onRoleIdChange
}: {
  roleId: string | undefined
  onRoleIdChange: (roleId: string | undefined) => void
}) => {
  const translate = useTranslate()

  const { data: roleData } = useCustom<[IRoles]>({
    url: "roles",
    method: "get"
  })

  const roleOptions: readonly SySelectOption<string, string>[] = useMemo(() => {
    const roles: readonly IRoles[] = roleData?.data ?? []
    return roles.map((role) => ({
      value: role.id.toFixed(),
      data: getRoleDisplayName(role.name, translate)
    }))
  }, [roleData, translate])

  return (
    <div>
      <SyLabel required={true}>{translate("pages.users.role")}</SyLabel>
      <SySelect
        id="roleId"
        placeholder={translate("pages.users.selectRole")}
        options={roleOptions}
        value={roleId}
        onValueChange={onRoleIdChange}
      />
    </div>
  )
}
