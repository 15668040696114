import { useUpdate } from "@pankod/refine-core"
import { VisitorDataModel } from "modules/Visit/VisitorDataModel"
import { useCallback } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { useTranslate } from "@pankod/refine-core"

export const useUpdateVisitor = () => {
  const queryClient = useQueryClient()
  const { mutate: updateVisitorDataMutation } = useUpdate<VisitorDataModel>()
  const translate = useTranslate()
  const handleVisitorUpdate = useCallback(
    (visitId: number, visitorId: number, visitor: VisitorDataModel) => {
      updateVisitorDataMutation(
        {
          id: "",
          resource: `visits/${visitId}/visitors/${visitorId}`,
          values: visitor
        },
        {
          onSuccess: (_data, _variables, _context) => {
            customToast.success(translate(`notifications.success`))
          },
          onError: (_error, _variables, _context) => {
            void Logger().error(LOG.EDIT_USER_PROFILE, `${_error.stack}`)
          },
          onSettled() {
            setTimeout(() => void queryClient.invalidateQueries(), 100)
          }
        }
      )
    },
    [updateVisitorDataMutation, queryClient, translate]
  )

  return { handleVisitorUpdate }
}
