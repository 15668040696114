import { useSelector, useDispatch } from "react-redux"
import { setSelectedLanguage } from "reduxStore/reducers/languageReducer"
import { AppDispatch, RootState } from "reduxStore/store"

export const useLanguage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const selectedLanguage = useSelector(
    (state: RootState) => state.language.selectedLanguage
  )

  const saveSelectedLanguage = (language: string) => {
    dispatch(setSelectedLanguage(language))
  }

  return { selectedLanguage, saveSelectedLanguage }
}
