import { useTranslate } from "@pankod/refine-core"
import { useMutation } from "@tanstack/react-query"
import MessagingApiService from "services/MessagingApiService"
import { VisitUpdateRequest } from "types"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"

export interface INotificationMessageUpdateRequest {
  visitId: number
  data: VisitUpdateRequest
  userId?: string
}

const useSendNotificationUpdate = () => {
  const translate = useTranslate()
  return useMutation(
    ({ request }: { request: INotificationMessageUpdateRequest }) =>
      MessagingApiService.sendNotificationUpdate(request),
    {
      onSuccess: () => {
        customToast.success(
          translate("table.visits.editVisitModal.updateVisitSendNotificationUpdateSuccess")
        )
      },
      onError: (error: Error) => {
        customToast.error(
          translate("table.visits.editVisitModal.updateVisitSendNotificationUpdateError")
        )
        void Logger().error(LOG.EDIT_VISIT, `${error.stack}`)
      }
    }
  )
}

export default useSendNotificationUpdate
