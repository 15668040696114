import { useMutation } from "@tanstack/react-query"
import MessagingApiService from "services/MessagingApiService"
import { IEmailNotificationMessageRequest } from "./useSendEmail"

const useNotifyHost = () => {
  return useMutation(
    ({ request }: { request: IEmailNotificationMessageRequest }) =>
      MessagingApiService.notifyHost(request)
  )
}

export default useNotifyHost
