import { ISettingEntity } from "interfaces"
import { SettingBoolean } from "./builder-components/SettingBoolean"
import { SettingDropdown } from "./builder-components/SettingDropdown"
import { SettingSingleLineText } from "./builder-components/SettingSingleLineText"
import { SettingTextArea } from "./builder-components/SettingTextArea"
import { SettingPhoneNumber } from "./SettingPhoneNumber"
import { SettingPictureUpload } from "./builder-components/SettingPictureUpload"
import { SettingPrintingBoolean } from "./builder-components/SettingPrintingBoolean"
import { SettingPreArrivalEmailBody } from "./builder-components/SettingPreArrivalEmailBody"
import { SettingTime } from "./builder-components/SettingTime"

/**
 *  SettingBuilder returns react component based on settings data_type
 */
export const SettingBuilder = (settingsData: ISettingEntity, index: number) => {
  switch (settingsData.setting_metadata.data_type) {
    case "PreArrivalEmailBody":
      return (
        <SettingPreArrivalEmailBody key={index} settingsData={settingsData} />
      )
    case "PrintingBoolean":
      return <SettingPrintingBoolean key={index} settingsData={settingsData} />
    case "Phone":
      return <SettingPhoneNumber key={index} settingsData={settingsData} />
    case "Boolean":
      return <SettingBoolean key={index} settingsData={settingsData} />
    case "Picture":
      return <SettingPictureUpload key={index} settingsData={settingsData} />
    case "Email":
    case "Text":
      return <SettingSingleLineText key={index} settingsData={settingsData} />
    case "TextArea":
      return <SettingTextArea key={index} settingsData={settingsData} />
    case "Dropdown":
      return <SettingDropdown key={index} settingsData={settingsData} />
    case "Time":
      return <SettingTime key={index} settingsData={settingsData} />
    default:
      break
  }
}
