import { useTranslate } from "@pankod/refine-core"
import { HostSearchDropdown } from "components/dropdown/HostSearchDropdown"
import { Button, ButtonStyle } from "components/globals/Button"
import { Modal } from "components/Modal"
import { useEffect, useState } from "react"
import { LOG, Logger } from "utilities/logger"
import { IHost, IHost_from_list } from "interfaces"
import useNotifyHost from "hooks/useNotifyHost"
import { Textarea } from "components/globals/Textarea"
import { HostTag } from "modules/Visit/HostTag"

export type HostNotify = {
  organizationId?: number
  hostId?: number
  host?: IHost | null
}

export const QuickNotificationModal = ({
  title,
  open,
  notifyHost,
  onClose
}: {
  title: string
  open: boolean
  notifyHost?: HostNotify
  onClose: () => void
}) => {
  const translate = useTranslate()
  const { mutateAsync: notifyHostAsync } = useNotifyHost()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(
    translate("notifications.visitorAtLobby")
  )
  const [hostId, setHostId] = useState<number>()
  const [organizationId, setOrganizationId] = useState<number>()
  const [visitHost, setVisitHost] = useState<IHost_from_list>()
  const [hostToNotify] = useState(notifyHost)
  const [hostError, setHostError] = useState(false)

  useEffect(() => {
    if (hostToNotify !== undefined && hostToNotify !== null) {
      setHostId(hostToNotify.hostId)
      setOrganizationId(hostToNotify.organizationId)
    }
    if (visitHost !== undefined && visitHost !== null) {
      setHostId(visitHost.id)
      setOrganizationId(visitHost.person.organization_id)
    }
  }, [hostToNotify, visitHost, setVisitHost])

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      setLoading(true)

      await notifyHostAsync({
        request: {
          hostId: hostId,
          organizationId: organizationId,
          hostNotifyContent: message
        }
      })

      onClose()
    } catch (error: any) {
      void Logger().error(LOG.CREATE_VISIT_ERROR, `${error.stack}`)
      onClose()
    } finally {
      setLoading(false)
    }
  }

  const isHostSelected = (): boolean => {
    return hostId !== null && hostId !== undefined && hostId > 0
  }

  return (
    <Modal title={translate(title)} isOpen={open} onClose={onClose}>
      <form
        onSubmit={(e) => void onSubmit(e)}
        className="space-y-6 w-full h-full"
      >
        {hostToNotify === undefined && (
          <div className="flex flex-col p-3 w-full">
            <HostSearchDropdown
              setOrganizationId={setOrganizationId}
              setVisitHost={setVisitHost}
              visitHost={visitHost}
              error={hostError}
              setError={setHostError}
              isNotification={true}
            />
            {visitHost && (
              <HostTag visitHost={visitHost} setVisitHost={setVisitHost} />
            )}
          </div>
        )}
        <div className="w-full h-full">
          <div className="mb-4 font-medium">
            <Textarea
              id="quickNotificationMessage"
              placeholder={message}
              rows={4}
              onChange={(e) => setMessage(e.target.value as string)}
              value={message}
            />
          </div>
        </div>
        <div className="flex justify-between w-full">
          <Button
            style={ButtonStyle.TransparentBorder}
            onClick={() => {
              onClose()
            }}
            name={translate("buttons.cancel")}
            className="bg-gray-200 text-gray-700 rounded-lg px-4 py-2"
          />
          <Button
            type="submit"
            name="Notify host"
            loading={loading}
            disabled={!isHostSelected()}
            className="bg-blue-500 text-white rounded-lg px-4 py-2"
          />
        </div>
      </form>
    </Modal>
  )
}
