import React from "react"
import { Dialog } from "@headlessui/react"
import classNames from "classnames"

export type SyModalSize =
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "full"

export interface SyModalProps {
  readonly open: boolean
  readonly onClose: () => void
  readonly title?: React.ReactNode
  readonly children: React.ReactNode
  readonly size?: SyModalSize
}

export function SyModal({
  open,
  onClose,
  title,
  children,
  size
}: SyModalProps): React.ReactElement {
  const finalDialogPanelClasses = classNames(
    "relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left w-full sm:my-8 sm:p-6",
    getMaxSizeClasses(size)
  )

  return (
    <Dialog as="div" className="relative z-10" open={open} onClose={onClose}>
      <div className="fixed inset-0 bg-black bg-opacity-70" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-6 text-center sm:items-center">
          <Dialog.Panel className={finalDialogPanelClasses}>
            <div>
              {title && (
                <Dialog.Title className="text-xl font-medium text-sy-gray-800 mb-2">
                  {title}
                </Dialog.Title>
              )}
            </div>
            <div>{children}</div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}

function getMaxSizeClasses(size: SyModalSize | undefined): string | undefined {
  if (!size) {
    return undefined
  }

  if (size === "full") {
    return "sm:max-w-full"
  }

  switch (size) {
    case "xs":
      return "sm:max-w-xs"
    case "sm":
      return "sm:max-w-sm"
    case "md":
      return "sm:max-w-md"
    case "lg":
      return "sm:max-w-lg"
    case "xl":
      return "sm:max-w-xl"
    case "2xl":
      return "sm:max-w-xl md:max-w-2xl"
    case "3xl":
      return "sm:max-w-xl md:max-w-2xl lg:max-w-3xl"
    case "4xl":
      return "sm:max-w-xl md:max-w-2xl lg:max-w-4xl"
    case "5xl":
      return "sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl"
    case "6xl":
      return "sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl"
    case "7xl":
      return "sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl"
    default:
      return undefined
  }
}
