import { AccessControlProvider, CanParams } from "@pankod/refine-core"
import { WorkspaceSetting } from "interfaces"
import { SCOPE_ID } from "utilities/constants"
import { axiosInstance } from "utilities/dataProvider"
import { LOG, Logger } from "utilities/logger"

export const accessControlProvider = (): AccessControlProvider => ({
  can: async ({ params }: CanParams) => {
    const modules = params?.resource?.options?.modules as string[]
    const requiresVisitModule = modules?.includes("visit")
    const requiresRoomsModule = modules?.includes("rooms")
    const scopeId = localStorage.getItem(SCOPE_ID)

    if (!scopeId) {
      return {
        can: false,
        reason: "Unauthorized - missing scopeId"
      }
    }

    const fetchModuleData = async (module: string): Promise<boolean> => {
      try {
        const response = await axiosInstance.get<WorkspaceSetting>(
          `organizations/${scopeId}/settings/${module}`
        )
        return response.data?.value === "true"
      } catch (error) {
        void Logger().error(
          LOG.ACCESS_CONTROL_PROVIDER,
          `Error fetching ${module} data: ${error}`
        )
        throw new Error(`Error fetching ${module} data: ${error}`)
      }
    }

    let hasVisitModule: boolean, hasRoomsModule: boolean
    try {
      [hasVisitModule, hasRoomsModule] = await Promise.all([
        fetchModuleData("HasVisitModule"),
        fetchModuleData("HasRoomsModule")
      ])
    } catch (error) {
      return {
        can: false,
        reason: "Error while fetching modules"
      }
    }

    const canAccessResource =
      (!requiresVisitModule || (requiresVisitModule && hasVisitModule)) &&
      (!requiresRoomsModule || (requiresRoomsModule && hasRoomsModule))

    if (!canAccessResource) {
      return {
        can: false,
        reason: "Unauthorized"
      }
    }

    return { can: true }
  }
})
