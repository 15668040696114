import React, { useCallback, useEffect, useMemo, useState } from "react"
import { SyModal } from "components/new/shared"
import { useCustom, useOne, useTranslate } from "@pankod/refine-core"
import { OkCancelButtonGroup } from "components/new/shared/modal"
import {
  VisitDateTimeData,
  VisitDateTimeInput
} from "modules/Visit/VisitDateTimeInput"
import { HostSearchDropdown } from "components/dropdown/HostSearchDropdown"
import { HostTag } from "../HostTag"
import { IHost_from_list, IVisit, IVisitVisitor, IWorkspace } from "interfaces"
import { VisitGetVisitorsResponse } from "types"
import { emptyFn } from "utilities/generic"
import customToast from "utilities/toastHelper"
import { SelectVisitors } from "../SelectVisitors"
import { useAppSelector } from "reduxStore/store"
import { VisitorState } from "../Notification/visitNotificationMessageType"
import { Disclosure } from "@headlessui/react"

export interface EditVisitModalProps {
  readonly open: boolean
  readonly onClose: () => void
  readonly visit: IVisit
  readonly onSaveVisitClick: (visitId: number, data: any) => void
}

export interface VisitHostUpdate {
  readonly host_id: number
  readonly organizer: boolean
  readonly state: "added" | "removed"
}

export function EditVisitModal({
  open,
  onClose,
  visit,
  onSaveVisitClick
}: EditVisitModalProps): React.ReactElement {
  const translate = useTranslate()

  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  const [visitHost, setVisitHost] = useState<IHost_from_list>()
  const [originalHost, setOriginalHost] = useState<IHost_from_list>()
  const [visitDateTime, setVisitDateTime] = useState<VisitDateTimeData>(
    visitDatesToVisitDateTimeData(visit)
  )
  const visitors = useAppSelector((state) => state.adHocVisitor)
  const [originalVisitors, setOriginalVisitors] = useState<IVisitVisitor[]>()
  const [visitName, setVisitName] = useState<string>(visit.name ?? "")
  const [visitInfo, setVisitInfo] = useState<string>(visit.info ?? "")

  const [visitorError, setVisitorError] = useState(false)
  const [hostError, setHostError] = useState(false)

  const [isDisabled, setIsDisabled] = useState(false)

  const {
    data: workspaceResponse,
    isLoading: isWorkspaceLoading,
    isError: isWorkspaceLoadingError
  } = useCustom<IWorkspace>({
    url: `workspaces/${selectedWorkspaceId}`,
    method: "get",
    queryOptions: {
      onError: () => {
        customToast.error(
          translate("table.visits.editVisitModal.workspaceLoadingError")
        )
        onClose()
      }
    }
  })

  const {
    data: visitorsResponse,
    isLoading: isVisitorsLoading,
    isError: isVisitorsLoadingError
  } = useOne<VisitGetVisitorsResponse>({
    resource: `visits/${visit.id}/visitors`,
    id: "",
    queryOptions: {
      enabled: !!visit.host?.host_id,
      cacheTime: 500,
      onError: () => {
        customToast.error(
          translate("table.visits.editVisitModal.visitorsLoadingError")
        )
        onClose()
      }
    }
  })

  const initialVisitors = useMemo(() => {
    const signedIn = visitorsResponse?.data.items.find((visitor) => visitor?.state.name === VisitorState.SIGNED_IN)
    if (signedIn) {
      setIsDisabled(true)
    }

    return (visitorsResponse?.data.items ?? []).filter(
      (visitor) => visitor.state.name !== VisitorState.REMOVED
    )
  }, [visitorsResponse])

  const {
    data: hostResponse,
    isLoading: isHostLoading,
    isError: isHostLoadingError
  } = useOne<IHost_from_list>({
    resource: "hosts",
    id: visit.host?.host_id ?? 0,
    queryOptions: {
      enabled: !!visit.host?.host_id,
      onError: () => {
        customToast.error(
          translate("table.visits.editVisitModal.hostLoadingError")
        )
        onClose()
      }
    }
  })

  useEffect(() => {
    const host = hostResponse?.data
    if (hostResponse?.data) {
      setVisitHost(host)

      // Set original host to be removed if host is updated
      if (originalHost === undefined) {
        setOriginalHost(host)
      }
    }
  }, [hostResponse, setVisitHost])

  useEffect(() => {
    if (visitors && visitors.length > 0 && originalVisitors === undefined) {
      setOriginalVisitors(visitors)
    }
  }, [visitors])

  const handleSaveVisit = useCallback(() => {
    // Set errors
    if (visitHost === undefined) {
      setHostError(true)
    }
    if (visitors.length === 0) {
      setVisitorError(true)
    }
    if (originalVisitors && visitors.length > 0 && visitHost) {
      const newVisitDates = visitDateTimeDataToVisitDates(visitDateTime)

      const hostArray: VisitHostUpdate[] = []

      visitHost && hostArray.push({
        host_id: visitHost.id,
        organizer: true,
        state: "added"
      })

      originalHost && originalHost !== visitHost && hostArray.push({
        host_id: originalHost.id,
        organizer: false,
        state: "removed"
      })

      // Map visitors, filter removed visitors and add new visitors without visitorId
      const _visitors = originalVisitors.map((originalVisitor) => {
        const visitor = visitors.find((visitor) => visitor.visitorId === originalVisitor.visitorId)
        if (visitor) {
          return {
            visitor_id: visitor.visitorId,
            state: VisitorState.EXPECTED
          }
        } else {
          return {
            visitor_id: originalVisitor.visitorId,
            state: VisitorState.REMOVED
          }
        }
      }).concat(visitors.filter((visitor) => {
        return !originalVisitors.some((originalVisitor) => {
          return originalVisitor.visitorId === visitor.visitorId
        })
      }).map((visitor) => {
        if (visitor.visitorId !== undefined) {
          return {
            visitor_id: visitor.visitorId,
            state: VisitorState.EXPECTED
          }
        } else {
          return {
            visitor_id: undefined,
            visitor_data: {
              first_name: visitor.first_name ?? "",
              last_name: visitor.last_name ?? "",
              email: visitor.email ? [
                { value: visitor.email }
              ] : [],
              phone: visitor.phone ? [
                { value: visitor.phone }
              ] : [],
              company: visitor.company ?? "",
            },
            state: VisitorState.EXPECTED
          }
        }
      }))

      // VisitUpdateRequest
      const data = {
        lobby_id: visit.lobby_id,
        start_time: newVisitDates.start_time,
        name: visitName,
        info: visitInfo,
        visitors: _visitors,
        hosts: hostArray
      }
      onSaveVisitClick(visit.id, data)
    }
  }, [
    onSaveVisitClick,
    visit,
    visitDateTime,
    visitors,
    visitHost,
    visitName,
    visitInfo
  ])

  if (
    isWorkspaceLoading ||
    isWorkspaceLoadingError ||
    isVisitorsLoading ||
    isVisitorsLoadingError ||
    isHostLoading ||
    isHostLoadingError
  ) {
    return <></>
  }

  return (
    <SyModal
      open={open}
      onClose={onClose}
      size="xl"
      title={translate("table.visits.editVisitModal.title")}
    >
      <div className="pt-4">
        <VisitDateTimeInput
          dateTime={visitDateTime}
          onDateTimeChange={setVisitDateTime}
          isDisabled={isDisabled}
        />
        <SelectVisitors
          workspace={workspaceResponse.data}
          initialVisitors={initialVisitors}
          visitorError={visitorError}
          setVisitorError={setVisitorError}
        />
        <div className="px-4">
          <HostSearchDropdown
            setOrganizationId={emptyFn}
            setVisitHost={setVisitHost}
            visitHost={visitHost}
            error={hostError}
            setError={setHostError}
          />
          {visitHost && (
            <HostTag visitHost={visitHost} setVisitHost={setVisitHost} />
          )}
        </div>
        <Disclosure as="div" className="mt-4 mb-4 bg-gray-50">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between items-center rounded-md px-4 py-2 text-left text-base font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
                <span>{translate("form.extraInformation")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="currentColor"
                  className={`${open ? "rotate-180 transform" : ""
                    } h-4 w-4 text-systam-dark`}
                >
                  <path d="M24 12L16 22 8 12z"></path>
                </svg>
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 bg-white">
                <label className="mb-1 text-base sm:text-base tracking-wide text-gray-600">{`${translate(
                  "form.visitName"
                )} ${translate("form.optional")}`}</label>
                <input
                  className="text-base sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-2 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                  value={visitName}
                  placeholder={translate("form.visitNamePlaceholder")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setVisitName(e.target.value)
                  }
                />
                <label className="mb-1 text-base sm:text-base tracking-wide text-gray-600">{`${translate(
                  "form.info"
                )} ${translate("form.optional")}`}</label>
                <input
                  className="text-base sm:text-base placeholder-gray-500 pl-4 pr-4 rounded-md border border-gray-300 w-full py-2 ring-0 ring-inset ring-gray-300 focus:outline-none focus:border-systam-blue focus:ring-1 focus:ring-systam-blue"
                  value={visitInfo}
                  placeholder={translate("form.visitInfoPlaceholder")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setVisitInfo(e.target.value)
                  }
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="mt-24">
          <OkCancelButtonGroup
            okLabel={translate("table.visits.editVisitModal.saveVisit")}
            onOkClick={handleSaveVisit}
            cancelLabel={translate("buttons.cancel")}
            onCancelClick={onClose}
          />
        </div>
      </div>
    </SyModal>
  )
}

function visitDatesToVisitDateTimeData(visit: IVisit): VisitDateTimeData {
  const startDateParts = visit.start_time?.split("T") ?? []

  const startDate = startDateParts[0] ?? ""
  const startTime = startDateParts[1] ?? ""

  return {
    startDate,
    startTime
  }
}

type VisitDates = Partial<Pick<IVisit, "start_time">>

function visitDateTimeDataToVisitDates(
  dateTime: VisitDateTimeData
): VisitDates {
  const { startDate, startTime } = dateTime

  const start_time =
    startDate && startTime ? `${startDate}T${startTime}` : undefined

  return {
    start_time
  }
}
