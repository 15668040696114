import { Modal } from "components/Modal"
import { ChangePassword } from "./ChangePassword"
import { Dropdown } from "./Dropdown"
import { useCallback, useEffect, useState } from "react"
import { UserProfile } from "./UserProfile"
import {
  useCan,
  useOne,
  usePermissions,
  useRouterContext,
  useTranslate
} from "@pankod/refine-core"
import { IUserInfo } from "interfaces"
import { Feedback } from "./Feedback"
import { visitPermissionEnabled } from "utilities/permissionSelector"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { QuickNotificationModal } from "./modal/QuickNotificationModal"
import { Menu } from "@headlessui/react"
import { setIsDrawerOpen, setMode } from "reduxStore/reducers/drawerReducer"
import { useAppDispatch } from "reduxStore/store"
import { InputSearch } from "./globals/InputSearch"
import { DRAWER_MODES } from "./drawer/DrawerWrapper"
import { MegaphoneIcon, PlusIcon, UserIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid"
import posthog from "posthog-js"

export const Nav = () => {
  const { data: userInfo } = useOne<IUserInfo>({
    resource: "users/me",
    id: ""
  })
  const [isWorkspaceDropdownOpen, setWorkspaceDropdown] = useState(false)
  const [showDropdown, setDropdown] = useState<boolean>(false)
  const [showChangePassword, setChangePassword] = useState<boolean>(false)
  const [showEditUserProfile, setEditUserProfile] = useState<boolean>(false)
  const [showGiveFeedback, setGiveFeedback] = useState<boolean>(false)
  const [showQuickNotificationModal, setShowQuickNotificationModal] =
    useState<boolean>(false)
  const [search, setSearch] = useState<string>("")

  const translate = useTranslate()
  const { data: permissionsData } = usePermissions<string>()
  const dispatch = useAppDispatch()

  const showDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(true))
  }, [dispatch])

  const setDrawerMode = useCallback(
    (mode: string) => {
      dispatch(setMode(mode))
    },
    [dispatch]
  )

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  // Remove this boolean when functionality for search bar/other feature previews are done
  const [showFeaturePreview] = useState<boolean>(false)

  const { data: canAccessVisitModule } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  useEffect(() => {
    const orgId = userInfo?.data.person.organization_id
    if (orgId) {
      // Update organization id for posthog user
      posthog.capture("set_organizaton_id", {
        // $set_once would only set the value if it is not already set
        // It can't be used here, because organization_id is not permanent
        // as distinct user can use different users with different organization id's
        $set: { organization_id: orgId }
      })
    }
  }, [userInfo])

  return (
    <div className="bg-white border-b border-b-one-gray-100">
      {showQuickNotificationModal && (
        <QuickNotificationModal
          title="Host Notification"
          open={showQuickNotificationModal}
          onClose={() => setShowQuickNotificationModal(false)}
        />
      )}
      <Modal
        title={translate("dropdown.title.changepassword")}
        isOpen={showChangePassword}
        onClose={() => setChangePassword(false)}
      >
        <div className="flex justify-center items-center">
          <ChangePassword onClose={() => setChangePassword(false)} />
        </div>
      </Modal>

      <Modal
        title={translate("dropdown.title.editprofile")}
        isOpen={showEditUserProfile}
        onClose={() => setEditUserProfile(false)}
      >
        <UserProfile onClose={() => setEditUserProfile(false)} />
      </Modal>

      <Modal
        title={translate("dropdown.title.giveFeedback")}
        isOpen={showGiveFeedback}
        onClose={() => setGiveFeedback(false)}
      >
        <Feedback onClose={() => setGiveFeedback(false)} />
      </Modal>

      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center">
            {/* Remove this boolean check when functionality for search bar is done */}
            {showFeaturePreview && (
              <InputSearch
                search={search}
                setSearch={setSearch}
                placeholder="Search for visits, people, devices..."
              />
            )}
          </div>

          <div className="flex items-center space-x-2">
            {/* Info page button */}
            {showFeaturePreview && (
              <button
                className="flex bg-systam-lighter-blue transition-transform ease-out duration-200 hover:transform hover:scale-125 py-1 px-3 rounded-full items-center m-2 w-10 h-10"
                onClick={() => {
                  push(`/info`)
                }}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="text-black"
                />
              </button>
            )}

            {visitPermissionEnabled(permissionsData) &&
              canAccessVisitModule?.can && (
                <button
                  onClick={() => {
                    setShowQuickNotificationModal(!showQuickNotificationModal)
                  }}
                  className="flex justify-center items-center bg-one-gray-100 hover:bg-one-gray-200/50 rounded-full h-12 w-12"
                >
                  <div className="flex justify-center items-center bg-white shadow-sm h-10 w-10 rounded-full">
                    <MegaphoneIcon className="h-5 w-5" />
                  </div>
                </button>
              )}

            {visitPermissionEnabled(permissionsData) &&
              canAccessVisitModule?.can && (
                <Menu as="div" className="relative">
                  <Menu.Button className="flex justify-center items-center bg-one-gray-100 hover:bg-one-gray-200/50 rounded-full h-12 w-12">
                    <div className="flex justify-center items-center bg-white shadow-sm h-10 w-10 rounded-full">
                      <PlusIcon className="h-5 w-5" />
                    </div>
                  </Menu.Button>
                  <Menu.Items
                    style={{ animation: "scaleIn 0.125s" }}
                    className="mt-2 absolute border border-one-gray-200/50 right-0 origin-top-right bg-white z-50 shadow-lg rounded-lg divide-y divide-one-gray-100"
                  >
                    <div className="py-2">
                      <Menu.Item>
                        <button
                          onClick={() => {
                            setDrawerMode(DRAWER_MODES.NEW_VISIT)
                            showDrawer()
                          }}
                          className="flex items-center w-full px-4 py-2 text-one-gray-600 hover:bg-one-gray-100 whitespace-nowrap"
                        >
                          {translate("buttons.newVisit")}
                        </button>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          onClick={() => {
                            setDrawerMode(DRAWER_MODES.EXPECTED_VISIT)
                            showDrawer()
                          }}
                          className="flex items-center w-full px-4 py-2 text-one-gray-600 hover:bg-one-gray-100 whitespace-nowrap"
                        >
                          {translate("buttons.expectedVisit")}
                        </button>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Menu>
              )}

            <div className="relative">
              <button
                data-testid="navigation-bar-dropdown"
                onClick={() => {
                  setDropdown(!showDropdown)
                }}
                className="px-1 flex cursor-pointer flex-row items-center bg-one-gray-100 hover:bg-one-gray-200/50 rounded-full h-12 space-x-2"
              >
                <div className="relative flex justify-center items-center bg-white shadow-sm w-10 h-10 rounded-full">
                  {userInfo?.data?.picture ? (
                    <img
                      key={userInfo?.data?.picture}
                      src={`${userInfo?.data?.picture}?timestamp=${Date.now()}`}
                      className="absolute inset-0 object-cover h-full w-full rounded-full"
                      alt="user info"
                    />
                  ) : (
                    <UserIcon className="text-one-rose-500 h-5 w-5" />
                  )}
                </div>
                <div className="">
                  {userInfo?.data?.person.first_name ?? ""}
                </div>
                {showDropdown ? (
                  <ChevronUpIcon className="h-5 w-5 text-one-gray-600" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-one-gray-600" />
                )}
              </button>

              {showDropdown && (
                <Dropdown
                  setDropdown={setDropdown}
                  setChangePassword={setChangePassword}
                  setEditProfile={setEditUserProfile}
                  setGiveFeedback={setGiveFeedback}
                />
              )}
            </div>
          </div>
        </div>

        {(isWorkspaceDropdownOpen || showDropdown) && (
          <div
            className="absolute w-screen h-screen z-10 top-0 left-0"
            onClick={() => {
              setWorkspaceDropdown(false)
              setDropdown(false)
            }}
          />
        )}
      </div>
    </div>
  )
}
