import { useTranslate } from "@pankod/refine-core"
import { contactDetailType } from "modules/ContactDetails/contactDetailType"

export const ContactDetailNotificationEnablement = ({
  notificationEnabled,
  contactDetailType: contactDetailNotificationType
}: {
  notificationEnabled: boolean
  contactDetailType: contactDetailType
}): JSX.Element => {
  const translate = useTranslate()

  const contactDetailTypeMap = new Map([
    [contactDetailType.phone, translate("notifications.sms")],
    [contactDetailType.email, translate("notifications.email")]
  ])

  const enabled = () => {
    return (
      <div className="mr-2 px-2 py-0.5 flex items-center font-medium text-sm text-gray-900 bg-emerald-50 rounded-full">
        <div className="mr-1 flex-shrink-0 w-2 h-2 rounded-full bg-emerald-600"></div>
        {contactDetailTypeMap.get(contactDetailNotificationType)}
      </div>
    )
  }

  const disabled = () => {
    return (
      <div className="mr-2 px-2 py-0.5 flex items-center font-medium text-sm text-gray-600 bg-gray-100 rounded-full">
        <div className="mr-1 flex-shrink-0 w-2 h-2 rounded-full bg-gray-600"></div>
        {contactDetailTypeMap.get(contactDetailNotificationType)}
      </div>
    )
  }

  return (
    <div className="flex items-center py-0.5">
      {notificationEnabled ? enabled() : disabled()}
    </div>
  )
}
