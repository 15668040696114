import { useCreate, useQueryClient, useTranslate } from "@pankod/refine-core"
import { useNavigate } from "@pankod/refine-react-router-v6"
import { CalendarProviderDropdown } from "components/dropdown/CalendarProviderDropdown"
import { Input } from "components/globals/Input"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react"
import { setIsDrawerOpen } from "reduxStore/reducers/drawerReducer"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import customToast from "utilities/toastHelper"

type CreateSpaceProps = {
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
}

export const CreateSpace = (props: CreateSpaceProps) => {
  const translate = useTranslate()
  const { mutate } = useCreate()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const navigate = useNavigate()

  const [spaceName, setSpaceName] = useState<string>("")
  const [calendarAccountId, setCalendarAccountId] = useState(0)
  const [calendarAddress, setCalendarAddress] = useState("")

  const [errors, setErrors] = useState<Record<string, string | undefined>>({})

  const hideDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(false))
  }, [dispatch])

  useEffect(() => {
    const isChanges =
      spaceName !== "" || calendarAccountId > 0 || calendarAddress !== ""
    props.setUnsavedChanges(isChanges)
  }, [spaceName, calendarAccountId, calendarAddress])

  function validate() {
    const errors: Record<string, string | undefined> = {}
    errors["spaceName"] =
      spaceName.length < 1 ? translate("form.requiredField") : undefined
    errors["calendarAccountId"] =
      calendarAccountId === 0 ? translate("form.requiredField") : undefined

    if (calendarAddress.length < 1) {
      errors["calendarAddress"] = translate("form.requiredField")
    } else {
      errors["calendarAddress"] = undefined
    }

    return errors
  }

  const onSubmit = () => {
    const errors = validate()
    setErrors(errors)
    const hasError = Object.values(errors).some((e) => e !== undefined)
    if (hasError) {
      return
    }

    mutate(
      {
        resource: `rooms/`,
        values: {
          workspace_id: selectedWorkspaceId && parseInt(selectedWorkspaceId),
          name: spaceName,
          resource_account_id: calendarAccountId > 0 ? calendarAccountId : null,
          resource_address:
            calendarAccountId > 0 && calendarAddress !== ""
              ? calendarAddress
              : null
        }
      },
      {
        onError: (error) => {
          customToast.error(
            translate("notifications.createError", {
              resource: translate("resources.space")
            })
          )
        },
        onSuccess: () => {
          customToast.success(
            translate("notifications.createSuccess", {
              resource: translate("resources.space")
            })
          )
        },
        onSettled: (data, error) => {
          if (data) {
            const spaceId = data?.data.id
            void queryClient.resetQueries({
              queryKey: ["space"]
            })
            hideDrawer()
            if (spaceId) {
              navigate(`/spaces/${spaceId}`)
            }
          }
        }
      }
    )
  }

  return (
    <div className="flex flex-col justify-between h-max">
      <div>
        <Input
          error={errors["spaceName"] !== undefined}
          label={translate("form.name")}
          id="input_name"
          value={spaceName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSpaceName(e.target.value)
          }
        />
        {errors["spaceName"] && (
          <p className="text-red-500 text-sm mt-1">{errors["spaceName"]}</p>
        )}
        <CalendarProviderDropdown
          value={calendarAccountId}
          setState={setCalendarAccountId}
          error={errors["calendarAccountId"] !== undefined}
        />
        {errors["calendarAccountId"] && (
          <p className="text-red-500 text-sm mt-1">
            {errors["calendarAccountId"]}
          </p>
        )}
        <div className="mt-4 flex flex-col">
          <Input
            error={errors["calendarAddress"] !== undefined}
            label={translate("pages.devices.settings.calendarResource")}
            id="input_address"
            value={calendarAddress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCalendarAddress(e.target.value)
            }
          />
          {errors["calendarAddress"] ? (
            <p className="text-red-500 text-sm mt-1">
              {errors["calendarAddress"]}
            </p>
          ) : (
            <p className="text-sm text-one-gray-500 mt-1">
              {translate("form.resourceAddressDescription")}
            </p>
          )}
        </div>
      </div>
      <div className="mb-4 flex flex-row gap-4 fixed bottom-4 w-128">
        <button
          className="w-full mt-6 py-2 px-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 font-medium"
          onClick={props.onClose}
        >
          {translate("buttons.cancel")}
        </button>
        <button
          className="w-full mt-6 px-4 py-2 rounded-lg bg-one-gray-950 text-white"
          onClick={() => {
            onSubmit()
          }}
        >
          {translate("buttons.save")}
        </button>
      </div>
    </div>
  )
}
