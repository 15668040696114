import { createSlice } from "@reduxjs/toolkit"
import { SELECTED_WORKSPACE } from "utilities/constants"

export const workspaceSlice = createSlice({
  name: "workspaceId",
  initialState: window.localStorage.getItem(SELECTED_WORKSPACE),
  reducers: {
    setSelectedWorkspace: (state, { payload }) => {
      localStorage.setItem(SELECTED_WORKSPACE, payload as string)
      return payload
    },
    resetWorkspace: () => null
  }
})

export const { setSelectedWorkspace, resetWorkspace } = workspaceSlice.actions

export default workspaceSlice.reducer
