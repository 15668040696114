import { useTranslate } from "@pankod/refine-core"
import { Modal } from "../../../components/Modal"
import customToast from "utilities/toastHelper"
import { IDevice } from "interfaces"
import { useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utilities/dataProvider"
import { HTTP_STATUS_OK } from "utilities/constants"

export const ResetPincodeModal: React.FC<any> = ({
  device,
  open,
  onClose
}: {
  device: IDevice
  open: boolean
  onClose: () => void
}) => {
  const translate = useTranslate()
  const queryClient = useQueryClient()

  const deviceId = device?.id

  const resetPincode = async (e: React.SyntheticEvent) => {
    const res = await axiosInstance.post(`/devices/${deviceId}/reset`)
    if (res.status === HTTP_STATUS_OK) {
      customToast.success(
        translate("notifications.resetSuccess", {
          resource: translate("pages.devices.pinCode")
        })
      )
      onClose()
      void queryClient.resetQueries({
        queryKey: [`device`]
      })
    } else {
      customToast.error(
        translate("notifications.resetError", {
          resource: translate("pages.devices.pinCode")
        })
      )
    }
  }

  return (
    <Modal
      title={translate("pages.devices.resetPinCode")}
      isOpen={open}
      onClose={onClose}
    >
      <div className="text-center text-l pt-6 px-6 ">
        {translate("pages.devices.areYouSureResetPin")}
      </div>
      <div className="flex flex-col">
        <div className="mt-6 ">
          <div className="flex justify-around">
            <button
              onClick={() => {
                onClose()
              }}
            >
              {translate("buttons.cancel")}
            </button>

            <button
              className="py-3 px-6 font-medium text-white bg-systam-blue hover:bg-systam-dark rounded-lg"
              onClick={(event) => void resetPincode(event)}>
              {translate("buttons.ok")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
