import React from "react"

export function Logo({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 114 19"
      className={className}
    >
      <path
        d="M113.974 9.351h-9.596c.024.799.249 1.578.652 2.265.358.607.878 1.1 1.5 1.422.63.322 1.328.486 2.035.48a4.084 4.084 0 0 0 2.425-.704 3.322 3.322 0 0 0 1.305-1.902h1.568a4.945 4.945 0 0 1-1.813 2.788 5.483 5.483 0 0 1-3.482 1.08 5.798 5.798 0 0 1-2.935-.738 5.146 5.146 0 0 1-2.021-2.094 6.584 6.584 0 0 1-.731-3.176 6.704 6.704 0 0 1 .721-3.183 5.023 5.023 0 0 1 2.008-2.097 5.916 5.916 0 0 1 2.935-.727 5.602 5.602 0 0 1 2.889.727 5.011 5.011 0 0 1 1.907 1.955c.444.84.668 1.778.652 2.73.014.51.007.902-.019 1.174Zm-1.983-3.443a3.507 3.507 0 0 0-1.457-1.399 4.46 4.46 0 0 0-2.035-.47 4.011 4.011 0 0 0-2.814 1.07c-.776.71-1.209 1.718-1.304 3.011h8.132a4.133 4.133 0 0 0-.522-2.212Z"
        fill="#6E2662"
      />
      <path
        d="M40.145 2.894H37.55V5.32h2.595V2.894ZM34.606 2.894h-1.38V5.32h1.38V2.894Z"
        fill="#FF739F"
      />
      <path
        d="M9.003 9.384A3.69 3.69 0 0 0 7.59 8.338a15.635 15.635 0 0 0-2.07-.659 9.071 9.071 0 0 1-1.82-.625.957.957 0 0 1-.587-.899.949.949 0 0 1 .43-.823c.37-.22.795-.323 1.223-.296.49-.025.973.12 1.37.412a1.514 1.514 0 0 1 .573 1.109h2.804a3.91 3.91 0 0 0-.388-1.55 3.876 3.876 0 0 0-.974-1.261C7.33 3.05 6.229 2.702 4.849 2.702a5.924 5.924 0 0 0-2.44.464A3.717 3.717 0 0 0 .823 4.434a3.055 3.055 0 0 0-.555 1.774 2.786 2.786 0 0 0 .597 1.876c.384.455.87.81 1.418 1.034.692.268 1.401.488 2.123.658.627.152 1.24.36 1.83.622a.898.898 0 0 1 .573.853 1.028 1.028 0 0 1-.47.866c-.39.243-.846.358-1.304.329A2.267 2.267 0 0 1 3.603 12a1.604 1.604 0 0 1-.652-1.119H0a3.55 3.55 0 0 0 .72 1.975 4.476 4.476 0 0 0 1.787 1.402 6.054 6.054 0 0 0 2.553.52 5.95 5.95 0 0 0 2.413-.454 3.64 3.64 0 0 0 1.592-1.257 3.121 3.121 0 0 0 .554-1.817 2.875 2.875 0 0 0-.616-1.867ZM31.6 9.384a3.69 3.69 0 0 0-1.413-1.046 15.327 15.327 0 0 0-2.07-.659 9.231 9.231 0 0 1-1.82-.625.958.958 0 0 1-.583-.899.952.952 0 0 1 .427-.823 2.588 2.588 0 0 1 2.608.116 1.537 1.537 0 0 1 .574 1.109h2.801A3.898 3.898 0 0 0 31.742 5a3.862 3.862 0 0 0-.974-1.265c-.824-.696-1.926-1.044-3.307-1.044a5.918 5.918 0 0 0-2.435.465 3.697 3.697 0 0 0-1.588 1.267 3.032 3.032 0 0 0-.555 1.774 2.803 2.803 0 0 0 .594 1.876c.38.457.865.815 1.412 1.044.69.269 1.398.489 2.12.658.627.151 1.24.36 1.832.622a.905.905 0 0 1 .573.853 1.027 1.027 0 0 1-.47.866c-.39.243-.845.358-1.303.329A2.268 2.268 0 0 1 26.209 12a1.605 1.605 0 0 1-.652-1.119h-2.934c.038.717.29 1.405.72 1.975a4.49 4.49 0 0 0 1.764 1.402 6.038 6.038 0 0 0 2.55.52 5.966 5.966 0 0 0 2.416-.454 3.66 3.66 0 0 0 1.591-1.257 3.122 3.122 0 0 0 .555-1.817 2.895 2.895 0 0 0-.62-1.867ZM40.147 12.097v2.492h-1.842c-2.47 0-3.703-1.207-3.701-3.62v-5.65h2.935v5.66a1.127 1.127 0 0 0 .283.855c.275.202.614.296.952.263h1.373ZM37.552 0h-2.948v2.893h2.948V0Z"
        fill="#6E2662"
      />
      <path d="M37.552 2.894h-2.948V5.32h2.948V2.894Z" fill="#FF3364" />
      <path
        d="M50.215 2.893v1.665a4.64 4.64 0 0 0-1.559-1.316 4.685 4.685 0 0 0-2.282-.527 5.044 5.044 0 0 0-2.687.737 5.17 5.17 0 0 0-1.904 2.114 6.828 6.828 0 0 0-.701 3.143 6.968 6.968 0 0 0 .7 3.18 5.298 5.298 0 0 0 1.905 2.143c.797.506 1.722.77 2.664.76a4.74 4.74 0 0 0 2.282-.54 4.381 4.381 0 0 0 1.559-1.362V14.6h2.934V2.893h-2.911Zm-.42 7.692a2.95 2.95 0 0 1-1.129 1.185 3 3 0 0 1-1.526.412 2.836 2.836 0 0 1-1.49-.431 3.079 3.079 0 0 1-1.128-1.212 3.825 3.825 0 0 1-.43-1.85 3.683 3.683 0 0 1 .43-1.823 2.95 2.95 0 0 1 1.087-1.154c.46-.278.986-.423 1.521-.42a3 3 0 0 1 1.526.412c.479.279.87.688 1.128 1.182a4.26 4.26 0 0 1 0 3.693l.01.006Z"
        fill="#6E2662"
      />
      <path
        d="M16.076 17.98 15.652 19H12.54l1.998-4.802.043.095v.004l.74 1.82.756 1.863ZM22.36 2.894 17.74 13.99l-.8-1.962-.567-1.392-.173-.425v-.003l3.046-7.314h3.114Z"
        fill="#FF739F"
      />
      <path
        d="m17.738 13.99-1.663 3.989-.757-1.863-.74-1.82v-.004l-.039-.099 1.5-3.604.16-.379.172.425.568 1.393.799 1.961Z"
        fill="#FF3364"
      />
      <path
        d="M16.2 10.208v.003l-.16.379-1.5 3.604-4.454-10.932-.15-.368h3.283l.013.032 2.967 7.282ZM62.847 3.137a5.5 5.5 0 0 0-2.491-.55 5.645 5.645 0 0 0-2.527.56 4.31 4.31 0 0 0-1.803 1.646 5.097 5.097 0 0 0-.672 2.696v7.1h2.87V7.472a2.318 2.318 0 0 1 .538-1.646 2.028 2.028 0 0 1 1.545-.576 1.94 1.94 0 0 1 1.526.576c.373.463.56 1.05.522 1.646a5.103 5.103 0 0 1 .671-2.68c.22-.365.49-.698.802-.987a4 4 0 0 0-.981-.668Z"
        fill="#6E2662"
      />
      <path
        d="M71.586 4.8a4.092 4.092 0 0 0-1.734-1.646 5.522 5.522 0 0 0-2.495-.55 5.622 5.622 0 0 0-2.524.56 4.358 4.358 0 0 0-1.004.677c.298.29.553.624.757.988.453.826.678 1.759.652 2.702v7.1-7.159a2.318 2.318 0 0 1 .534-1.646 2.03 2.03 0 0 1 1.546-.576 1.94 1.94 0 0 1 1.526.576c.374.462.56 1.05.522 1.646v7.117h2.869V7.502a5.307 5.307 0 0 0-.649-2.703Z"
        fill="#FF739F"
      />
      <path
        d="M65.21 7.502v7.1h-2.869v-7.13a5.103 5.103 0 0 1 .672-2.679c.22-.366.49-.698.802-.987.299.29.553.623.757.987.45.829.67 1.765.639 2.71Z"
        fill="#FF3364"
      />
      <path
        d="M82.145 14.056a5.307 5.307 0 0 1-2.054-2.094 7.05 7.05 0 0 1 0-6.35 5.34 5.34 0 0 1 2.077-2.09c.898-.499 1.91-.754 2.935-.74a5.954 5.954 0 0 1 2.957.74 5.268 5.268 0 0 1 2.074 2.094 6.995 6.995 0 0 1 0 6.337 5.294 5.294 0 0 1-2.097 2.103c-.91.496-1.93.75-2.964.737a5.867 5.867 0 0 1-2.928-.737Zm5.051-1.09a3.964 3.964 0 0 0 1.578-1.583 5.145 5.145 0 0 0 .603-2.597 5.233 5.233 0 0 0-.593-2.597 3.906 3.906 0 0 0-1.565-1.58 4.55 4.55 0 0 0-4.239 0 3.83 3.83 0 0 0-1.558 1.58 6.097 6.097 0 0 0 0 5.194c.35.67.888 1.22 1.545 1.583a4.53 4.53 0 0 0 4.239 0h-.01ZM100.406 3.759a5.394 5.394 0 0 0-3.354-1.172A5.393 5.393 0 0 0 93.7 3.76c-.864.777-1.305 1.945-1.305 3.473v7.482h1.562V7.215c0-1.116.31-1.945.842-2.459a3.08 3.08 0 0 1 2.23-.773 3.121 3.121 0 0 1 2.246.783c.542.52.851 1.343.851 2.45v7.498h1.569V7.242c.006-1.538-.427-2.706-1.288-3.483Z"
        fill="#6E2662"
      />
    </svg>
  )
}
