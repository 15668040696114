import {
  useQueryClient,
  useTranslate,
  useUpdate,
  useCreate,
  useCustom
} from "@pankod/refine-core"
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from "react"
import { setIsDrawerOpen } from "reduxStore/reducers/drawerReducer"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import customToast from "utilities/toastHelper"
import { LOG, Logger } from "utilities/logger"
import { AvailableRoomsDevicesDropdown } from "../components/RoomsDevicesDropdown"
import { IDeviceType } from "interfaces"

type CreateLinkProps = {
  selectedRoomId: number
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>
  onClose?: () => void
}

export const CreateLink = (props: CreateLinkProps) => {
  const translate = useTranslate()
  const { mutate } = useUpdate()
  const { mutate: mutateCreate } = useCreate()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const [selectedDeviceId, setSelectedDeviceId] = useState("")
  const [addNewMode, setAddNewMode] = useState(false)
  const [newDeviceName, setNewDeviceName] = useState("")
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const [roomsDisplayTypeId, setRoomsDisplayTypeId] = useState<number | null>(
    null
  )

  const hideDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(false))
  }, [dispatch])

  useEffect(() => {
    const isChanges = newDeviceName !== "" || parseInt(selectedDeviceId) > 0
    props.setUnsavedChanges(isChanges)
  }, [newDeviceName, selectedDeviceId])

  const { data: deviceTypeData } = useCustom<[IDeviceType]>({
    url: `/device-types`,
    method: "get"
  })

  useEffect(() => {
    // Get the device type id for room display
    const roomDeviceType = deviceTypeData?.data.find(
      (deviceType) => deviceType.name === "Room Display"
    )
    if (roomDeviceType) {
      setRoomsDisplayTypeId(roomDeviceType.id)
    }
  }, [deviceTypeData])

  const onSubmit = () => {
    if (addNewMode && newDeviceName.length > 0) {
      mutateCreate(
        {
          resource: `devices/`,
          values: [
            {
              device_model_id: roomsDisplayTypeId,
              name: `${newDeviceName}`,
              room_id: props.selectedRoomId,
              workspace_id: selectedWorkspaceId
            }
          ]
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.createError", {
                resource: translate("resources.device")
              })
            )
            void Logger().error(LOG.CREATE_DEVICE, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            customToast.success(
              translate("notifications.createSuccess", {
                resource: translate("resources.device")
              })
            )
          },
          onSettled(data) {
            if (data) {
              void queryClient.resetQueries({
                queryKey: ["space"]
              })
              hideDrawer()
            }
          }
        }
      )
    } else {
      mutate(
        {
          id: selectedDeviceId,
          resource: `devices/`,
          values: {
            room_id: props.selectedRoomId
          }
        },
        {
          onError: (error) => {
            customToast.error(translate("notifications.linkError"))
            void Logger().error(LOG.LINK_DEVICE, `${error.stack}`)
          },
          onSuccess: () => {
            customToast.success(translate("notifications.linkSuccess"))
            void Logger().log(
              LOG.LINK_DEVICE,
              `Room Id ${props.selectedRoomId} linked to device Id ${selectedDeviceId}`
            )
          },
          onSettled: (data, error) => {
            if (data) {
              void queryClient.resetQueries({
                queryKey: ["space"]
              })
              hideDrawer()
            }
          }
        }
      )
    }
  }

  return (
    <div className="flex flex-col justify-between h-max">
      <div className="flex flex-col">
        <p className="text-sm text-one-gray-500 pb-6">
          {translate("pages.spaces.linkDisplayDescription")}
        </p>
        <AvailableRoomsDevicesDropdown
          setSelectedDeviceId={setSelectedDeviceId}
          addNewMode={addNewMode}
          setAddNewMode={setAddNewMode}
          newDeviceName={newDeviceName}
          setNewDeviceName={setNewDeviceName}
        />
      </div>
      <div className="mb-4 flex flex-row gap-4 fixed bottom-4 w-128">
        <button
          className="w-full mt-6 py-2 px-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 font-medium"
          onClick={props.onClose}
        >
          {translate("buttons.cancel")}
        </button>
        <button
          className="w-full mt-6 px-4 py-2 rounded-lg bg-one-gray-950 text-white"
          onClick={() => {
            onSubmit()
          }}
        >
          {translate("buttons.save")}
        </button>
      </div>
    </div>
  )
}
