import { useDelete, useTranslate } from "@pankod/refine-core"
import { Modal } from "../../components/Modal"
import customToast from "utilities/toastHelper"
import { IDevice } from "interfaces"
import { useQueryClient } from "@tanstack/react-query"

export const DeleteDevice: React.FC<any> = ({
  device,
  open,
  onClose
}: {
  device: IDevice
  open: boolean
  onClose: () => void
}) => {
  const translate = useTranslate()
  const { mutate } = useDelete()
  const queryClient = useQueryClient()

  const deviceId = device?.id

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    mutate(
      {
        id: deviceId,
        resource: `/devices`
      },
      {
        onError: (error, variables, context) => {
          customToast.error(
            translate("notifications.deleteError", {
              resource: translate("resources.device")
            })
          )
        },
        onSuccess: (data, variables, context) => {
          customToast.success(
            translate("notifications.deleteSuccess", {
              resource: translate("resources.device")
            })
          )
          onClose()
        },
        onSettled() {
          void queryClient.resetQueries({
            queryKey: ["devicelist"]
          })
        }
      }
    )
  }

  return (
    <Modal
      title={translate("pages.devices.deleteDevice")}
      isOpen={open}
      onClose={onClose}
    >
      <div className="text-center text-l pt-6 px-6 ">
        {translate("pages.devices.areYouSure")}
      </div>
      <div className="flex flex-col">
        <form className="mt-6 ">
          <div className="flex justify-around">
            <button
              onClick={() => {
                onClose()
              }}
            >
              {translate("buttons.cancel")}
            </button>
            <button
              className="py-3 px-6 font-medium text-white bg-systam-blue hover:bg-systam-dark rounded-lg"
              onClick={(event) => void onSubmit(event)}>
              {translate("buttons.delete")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
