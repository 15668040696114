import { useQuery, useRouterContext, useTranslate } from "@pankod/refine-core"
import { Button } from "components/globals/Button"
import { Select } from "components/globals/Select"
import { ICaledarIntegration } from "interfaces"
import { ChangeEvent, Dispatch, SetStateAction } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { axiosInstance } from "utilities/dataProvider"
import { QUERY_KEYS } from "utilities/types"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

// TODO: Remove this component when show-spaces feature flag is removed
export const CalendarProviderDropdownOld = ({
  value,
  setState,
  initialValue
}: {
  value: any
  setState: Dispatch<SetStateAction<any>>
  initialValue?: any
}) => {
  const { data: calendarIntegrations, isLoading } = useQuery<
    [ICaledarIntegration]
  >([QUERY_KEYS.CALENDAR_INTEGRATION], async () => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/calendars`
    )
    res.data = [
      {
        name: null,
        id: 0
      },
      ...res.data
    ]
    return res.data
  })

  const { useHistory } = useRouterContext()
  const { push }: { push: (url: string) => void } = useHistory()

  const translate = useTranslate()

  const label = translate("pages.devices.settings.calendarAccount")

  if (isLoading) {
    return (
      <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
        <Skeleton className="mt-6" count={1} height={40} />
      </SkeletonTheme>
    )
  }

  const noIntegrations =
    !calendarIntegrations || calendarIntegrations.length <= 1
  if (noIntegrations) {
    return (
      <NoIntegrationsDisplay
        label={label}
        text={translate("pages.devices.settings.noCalendarIntegrationsText")}
        addIntegrationLabel={translate(
          "pages.devices.settings.addCalendarIntegration"
        )}
        onAddIntegrationClick={() => {
          push("/calendar-integrations")
        }}
      />
    )
  }

  return (
    <Select
      label={label}
      placeholder={"add.localoca"}
      id="calendar-dropdown"
      optionData={calendarIntegrations}
      value={value}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        setState(parseInt(e?.target?.value))
      }}
    />
  )
}

interface NoIntegrationsDisplayProps {
  label: string
  text: string
  addIntegrationLabel: string
  onAddIntegrationClick: () => void
}

const NoIntegrationsDisplay: React.FC<NoIntegrationsDisplayProps> = ({
  label,
  text,
  addIntegrationLabel,
  onAddIntegrationClick
}) => {
  return (
    <div className="w-full">
      <label className="text-s pt-1 text-neutral-800">{label}</label>
      <div className="mt-2">
        <div>{text}</div>
        <Button
          className=""
          type="submit"
          name={addIntegrationLabel}
          icon={faPlus}
          onClick={onAddIntegrationClick}
        />
      </div>
    </div>
  )
}
