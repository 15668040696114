import { useCreate, useQueryClient, useTranslate } from "@pankod/refine-core"
import { WorkspaceDropdown } from "components/dropdown/WorkspaceDropdown"
import ErrorText from "components/formMessage"
import { Button, ButtonStyle } from "components/globals/Button"
import { Input } from "components/globals/Input"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { setIsDrawerOpen } from "reduxStore/reducers/drawerReducer"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhone
} from "utilities/formValidator"
import { LOG, Logger } from "utilities/logger"
import { capitalizeFirstLetter } from "utilities/string"
import customToast from "utilities/toastHelper"

type CreateVisitorProps = {
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}

export const CreateVisitor = (props: CreateVisitorProps) => {
  const { mutate } = useCreate()
  const translate = useTranslate()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [workspaceId, setWorkspaceId] = useState(0)

  const [errors, setErrors] = useState<string[]>([])

  // Set selected workspace
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  useEffect(() => {
    if (selectedWorkspaceId && parseInt(selectedWorkspaceId)) {
      const id = parseInt(selectedWorkspaceId)
      setWorkspaceId(id)
    }
  }, [selectedWorkspaceId])

  const handleInputChange = (e) => {
    const { id, value } = e.target
    if (id === "firstName") {
      setFirstName(value as string)
    } else if (id === "lastName") {
      setLastName(value as string)
    } else if (id === "company") {
      setCompany(value as string)
    } else if (id === "email") {
      setEmail(value as string)
    } else if (id === "phone") {
      setPhone(value as string)
    } else if (id === "workspaceId") {
      setWorkspaceId(value as number)
    }
  }

  /**
   * Validate fields
   * @returns True if no errors
   */
  function validate(): boolean {
    const validationErrors = []
    validationErrors["firstName"] = validateFirstName(firstName)
    validationErrors["lastName"] = validateLastName(lastName)
    if (email) {
      validationErrors["email"] = validateEmail(email)
    }
    if (phone) {
      validationErrors["phone"] = validatePhone(phone)
    }

    setErrors(validationErrors)
    return validationErrors["firstName"] ||
      validationErrors["lastName"] ||
      validationErrors["email"] ||
      validationErrors["phone"]
      ? false
      : true
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    void Logger().log(LOG.CREATE_VISITOR)

    if (validate()) {
      mutate(
        {
          resource: `visitors`,
          values: {
            email: email
              ? [
                  {
                    value: email
                  }
                ]
              : [],
            phone: phone
              ? [
                  {
                    value: phone
                  }
                ]
              : [],
            first_name: capitalizeFirstLetter(firstName),
            last_name: capitalizeFirstLetter(lastName),
            company: capitalizeFirstLetter(company),
            workspace_id: workspaceId
          }
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.createError", {
                resource: translate("resources.visitor")
              })
            )
            void Logger().error(LOG.CREATE_VISITOR, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            customToast.success(
              translate("notifications.createSuccess", {
                resource: translate("resources.visitor")
              })
            )
          },
          onSettled(data, error, variables, context) {
            if (!error) {
              setTimeout(() => void queryClient.invalidateQueries(), 100)
              dispatch(setIsDrawerOpen(false))
            }
          }
        }
      )
    }
  }

  return (
    <form className="form pt-10" onSubmit={onSubmit} autoComplete="off">
      <div className="flex flex-col gap-2 px-6">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-1">
            <Input
              label={translate("form.firstName")}
              placeholder={translate("form.firstName")}
              id="firstName"
              type="text"
              value={firstName}
              required={true}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="flex flex-1">
            <Input
              label={translate("form.lastName")}
              placeholder={translate("form.lastName")}
              id="lastName"
              type="text"
              value={lastName}
              required={true}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between gap-4 -mt-1">
          <div className="flex flex-1">
            {errors["firstName"] && ErrorText(errors["firstName"])}
          </div>
          <div className="flex flex-1">
            {errors["lastName"] && ErrorText(errors["lastName"])}
          </div>
        </div>
        <div>
          <Input
            label={translate("form.company")}
            placeholder={translate("form.company")}
            id="company"
            type="text"
            value={company}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div>
          <Input
            label={translate("form.email")}
            placeholder={translate("form.email")}
            id="email"
            type="text"
            value={email}
            onChange={(e) => handleInputChange(e)}
          />
          <div className="pt-1">
            {errors["email"] && ErrorText(errors["email"])}
          </div>
        </div>
        <div>
          <Input
            label={translate("form.phone")}
            placeholder={translate("form.phone")}
            id="phone"
            type="text"
            value={phone}
            onChange={(e) => handleInputChange(e)}
          />
          <div className="pt-1">
            {errors["phone"] && ErrorText(errors["phone"])}
          </div>
        </div>
        <WorkspaceDropdown value={workspaceId} setState={setWorkspaceId} />
        <div className="py-6 flex justify-around">
          <Button
            type="submit"
            style={ButtonStyle.BluePrimary}
            name={translate("buttons.save")}
          />
        </div>
      </div>
    </form>
  )
}
