import { useList, QueryFunctionContext, useQuery, HttpError } from "@pankod/refine-core"
import { IDevice, ILinkedDevice } from "interfaces"
import { axiosInstance } from "utilities/dataProvider"

export type DevicesFromQueryContext = {
  data: IDevice[]
  total: number
}

export const useLinkedPrinters = () => {
  const {
    data: deviceData,
    isLoading: isDeviceDataLoading
  } = useList<IDevice, HttpError>({
    resource: `devices/systam-visit`,
    queryOptions: {
      select(data) {
        const filteredDevices = (data.data || []).filter((device) => device.model.type === "Kiosk")
        return {
          data: filteredDevices,
          total: filteredDevices.length
        }
      }
    }
  })

  const devices = deviceData?.data || []

  const fetchLinkedDevices = async (context: QueryFunctionContext): Promise<ILinkedDevice[]> => {
    const deviceContextData = context.queryKey[1] as DevicesFromQueryContext
    const deviceIds = deviceContextData.data.map(device => device.id)

    // Fetch all linked devices for each device ID
    const linkedDevicesPromises = deviceIds.map(async (deviceId) => {
      const response = await axiosInstance.get<ILinkedDevice[]>(`devices/${deviceId}/links`)
      return response.data
    })

    // Wait for all promises to resolve and concatenate the results into a single array
    return (await Promise.all(linkedDevicesPromises)).flat()
  }

  const { data: linkedPrintersData = [], isLoading: isLinkedPrintersDataLoading, isError: isLinkedPrintersError } = useQuery(['linkedDevices', deviceData], fetchLinkedDevices, {
    enabled: !!devices.length,
    select(data) {
      return data.filter((linkedDevice) => linkedDevice.type_name === "Printer")
    }
  })

  return { linkedPrintersData, isDeviceDataLoading, isLinkedPrintersDataLoading, isLinkedPrintersError }
}
