import { IHost_from_list } from "interfaces"
import { Dispatch, SetStateAction } from "react"
import { Tag } from "./Tag"

export const HostTag = ({
  visitHost,
  setVisitHost
}: {
  visitHost: IHost_from_list
  setVisitHost: Dispatch<SetStateAction<IHost_from_list | undefined>>
}) => {
  return (
    <Tag
      css="bg-emerald-100 border-emerald-200"
      setVisitHost={setVisitHost}
      host={visitHost}
    />
  )
}
