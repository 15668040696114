import { IEmailNotificationMessageRequest } from "hooks/useSendEmail"
import { INotificationMessageUpdateRequest } from "hooks/useSendNotificationUpdate"
import { ISmsNotificationMessageRequest } from "hooks/useSendSms"
import { axiosNoUrl } from "utilities/dataProvider"

export enum MESSAGING_API_POST_FIX_URL {
  EMAIL = "/messaging/email",
  SMS = "/messaging/sms",
  NOTIFY_HOST = "/messaging/from-lobby",
  NOTIFICATION_UPDATE = "/messaging/notification-update"
}

const sendEmail = async (email: IEmailNotificationMessageRequest) => {
  const { data } = await axiosNoUrl.post(
    `${process.env.REACT_APP_MESSAGING_API_URL}${MESSAGING_API_POST_FIX_URL.EMAIL}`,
    email
  )
  return data
}

const sendSms = async (sms: ISmsNotificationMessageRequest) => {
  const { data } = await axiosNoUrl.post(
    `${process.env.REACT_APP_MESSAGING_API_URL}${MESSAGING_API_POST_FIX_URL.SMS}`,
    sms
  )
  return data
}

const notifyHost = async (request: IEmailNotificationMessageRequest) => {
  const { data } = await axiosNoUrl.post(
    `${process.env.REACT_APP_MESSAGING_API_URL}${MESSAGING_API_POST_FIX_URL.NOTIFY_HOST}`,
    request
  )
  return data
}

const sendNotificationUpdate = async (
  request: INotificationMessageUpdateRequest
) => {
  const { data } = await axiosNoUrl.post(
    `${process.env.REACT_APP_MESSAGING_API_URL}${MESSAGING_API_POST_FIX_URL.NOTIFICATION_UPDATE}`,
    request
  )
  return data
}

const MessagingApiService = {
  sendEmail,
  sendSms,
  notifyHost,
  sendNotificationUpdate
}

export default MessagingApiService
