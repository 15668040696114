import { IVisitor } from "interfaces"
import { DateTime } from "luxon"
import { isEmptyString, leadingZero } from "utilities/string"

type SignedOutDateTimeProps = { visitEndTime: string }

const SignedOutDateTimeCell = (props: SignedOutDateTimeProps) => {
  const signedOut = DateTime.fromISO(props.visitEndTime + "Z")
  return (
    <div>
      <p className="font-medium truncate">
        {signedOut.toLocaleString(DateTime.TIME_24_SIMPLE)}
      </p>
      <p className="text-gray-500">{`${leadingZero(
        signedOut.day
      )}.${leadingZero(signedOut.month)}.${leadingZero(signedOut.year)}`}</p>
    </div>
  )
}

export const SignedOutCell = (visitor: IVisitor | null) => {
  const endTime: string =
    visitor?.end_time !== undefined ? visitor?.end_time : ""
  const hasEndTime = !isEmptyString(endTime)

  return hasEndTime ? SignedOutDateTimeCell({ visitEndTime: endTime }) : <></>
}
