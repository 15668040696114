import {
  faEllipsisVertical,
  faEnvelope,
  faPhone,
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import {
  IResourceComponentsProps,
  usePermissions,
  useTranslate
} from "@pankod/refine-core"
import {
  ColumnDef,
  Row,
  getCoreRowModel,
  useReactTable
} from "@pankod/refine-react-table"
import { DeleteIcon, EditIcon } from "components/icons"
import { useLocalStorage } from "hooks/useLocalStorage"
import { usePagination } from "hooks/usePagination"
import React, { useCallback } from "react"
import { useState } from "react"
import { LOG, Logger } from "utilities/logger"
import { INF_SCROLL_QUERY_KEY_TYPES, LOCAL_STORAGE_KEYS } from "utilities/types"
import { useModalForm } from "@pankod/refine-react-hook-form"
import { Button } from "components/globals/Button"
import { Table } from "components/table/table"
import { IVisitorInfo } from "interfaces"
import { generateIcon } from "resources"
import { InputSearch } from "components/globals/InputSearch"
import { capitalizeFirstLetter, isEmptyString } from "utilities/string"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import {
  setIsDrawerOpen,
  setMode,
  setSelectedResourceId
} from "reduxStore/reducers/drawerReducer"
import { DeleteVisitor } from "./delete"
import { DRAWER_MODES } from "components/drawer/DrawerWrapper"
import { UserIcon } from "@heroicons/react/24/outline"

export const VisitorsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate()
  const [selectedVisitorId, setSelectedVisitorId] = useState(0)
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  const [columnVisibility, setLocalStorage]: [any, (arg0: any) => void] =
    useLocalStorage(LOCAL_STORAGE_KEYS.VISITORS, {})

  const emailIcon = generateIcon(faEnvelope)
  const phoneIcon = generateIcon(faPhone)

  const { data: permissionsData } = usePermissions<string>()
  const columnArray: ColumnDef<IVisitorInfo>[] = [
    {
      id: "visitor",
      header: "Name",
      accessorKey: "picture",
      cell: ({ row }) => {
        const { picture, first_name, last_name } = row.original
        let notFound = false

        if (
          first_name === undefined ||
          isEmptyString(first_name) ||
          last_name === undefined ||
          isEmptyString(last_name)
        )
          notFound = true

        return (
          <div className="flex items-center">
            {picture ? (
              <img
                alt="visitorpic"
                width="40"
                height="40"
                className="rounded-full object-cover aspect-square"
                src={picture}
              />
            ) : (
              <UserIcon className="h-8 w-8 text-systam-blue" />
            )}
            <div className="pl-2">
              <p className={notFound ? "text-gray-500" : ""}>
                {notFound
                  ? translate("pages.visitors.visitorNameNotFound")
                  : `${first_name} ${last_name}`}
                {row.original.company && (
                  <p className="text-sm text-gray-500">
                    {capitalizeFirstLetter(row.original.company)}
                  </p>
                )}
              </p>
            </div>
          </div>
        )
      }
    },
    {
      id: "company_name",
      header: "Host company",
      accessorKey: "workspace.name"
    },
    {
      id: "contact_details",
      header: "Contact Details",
      accessorKey: "contact_details",
      cell: ({ row }) => {
        if (
          row.original.contact_details &&
          row.original.contact_details.length > 0
        ) {
          return (
            <div>
              {row.original.contact_details.map((contact, index) => {
                let icon
                if (contact?.type === "Email") {
                  icon = emailIcon
                } else if (contact?.type === "Phone") {
                  icon = phoneIcon
                }

                return (
                  <p>
                    {icon} {contact?.value}
                  </p>
                )
              })}
            </div>
          )
        } else {
          return <></>
        }
      }
    }
  ]

  const isEditCreateButton =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("All.ReadWrite") ||
    permissionsData?.includes("Visitor.ReadWrite") ||
    permissionsData?.includes("Visitor.All")
  const isDeleteButton =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Visitor.All")

  function getActionButtons(id: number, row: Row<IVisitorInfo>) {
    return (
      <div>
        <Menu
          as="div"
          className="relative inline-flex text-left"
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <Menu.Button
            className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <FontAwesomeIcon
              icon={faEllipsisVertical}
              className="align-center self-center"
            />
          </Menu.Button>
          <Menu.Items className="absolute z-50 right-0 mt-11 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              {isEditCreateButton && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        void Logger().log(LOG.PRESS_EDIT_VISITOR_MODAL)
                        setSelectedVisitorId(id)
                        setDrawerVisitorId(id)
                        setDrawerMode(DRAWER_MODES.EDIT_VISITOR)
                        showDrawer()
                      }}
                      className={`${
                        active
                          ? "font-medium bg-systam-blue text-white"
                          : "bg-white text-gray-600"
                      }  flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                    >
                      {EditIcon}
                      <p className="ml-2">
                        {translate("pages.visitors.editVisitor")}
                      </p>
                    </button>
                  )}
                </Menu.Item>
              )}
              {isDeleteButton && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        void Logger().log(LOG.PRESS_DEL_VISITOR_MODAL)
                        setSelectedVisitorId(id)
                        showDeleteModal(id)
                      }}
                      className={`${
                        active
                          ? "font-medium bg-systam-blue text-white"
                          : "bg-white text-gray-600"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                    >
                      {DeleteIcon}
                      <p className="ml-2">
                        {translate("pages.visitors.deleteVisitor")}
                      </p>
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </Menu.Items>
        </Menu>
      </div>
    )
  }

  if (isEditCreateButton || isDeleteButton) {
    columnArray.push({
      id: "action",
      header: "Action",
      accessorKey: "id",
      cell: function render({ getValue, row }) {
        const id = getValue() as number
        if (isEditCreateButton || isDeleteButton) {
          return getActionButtons(id, row)
        }
      }
    })
  }

  const columns = React.useMemo<ColumnDef<IVisitorInfo | any>[]>(
    () => columnArray,
    [permissionsData]
  )

  const [search, setSearch] = useState("")

  const [visitors, page, isLoading, fetchNextPage, hasNextPage, isFetching] =
    usePagination<IVisitorInfo>(
      `/visitors`,
      search,
      `workspace_id=${selectedWorkspaceId}`,
      INF_SCROLL_QUERY_KEY_TYPES.VISITORS
    )

  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    onColumnVisibilityChange: (state) => {
      setLocalStorage(state)
    },
    initialState: {},
    state: {
      columnVisibility
    },
    data: visitors,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true
  })

  const dispatch = useAppDispatch()

  const showDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(true))
  }, [dispatch])

  const setDrawerMode = useCallback(
    (mode: string) => {
      dispatch(setMode(mode))
    },
    [dispatch]
  )

  const setDrawerVisitorId = useCallback(
    (visitorId: number) => {
      dispatch(setSelectedResourceId(visitorId))
    },
    [dispatch]
  )

  const deleteModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `/visitors`
    }
  })

  const {
    modal: { show: showDeleteModal }
  } = deleteModalFormReturnValues

  const CreateVisitorButton = () => {
    return (
      <div className="flex justify-end items-center">
        {isEditCreateButton && (
          <Button
            icon={faPlus}
            name={translate("pages.visitors.createVisitor")}
            onClick={() => {
              setDrawerMode(DRAWER_MODES.CREATE_VISITOR)
              showDrawer()
            }}
          />
        )}
      </div>
    )
  }

  return (
    <>
      <div className="container mx-auto pb-4">
        <DeleteVisitor
          {...deleteModalFormReturnValues}
          selectedId={selectedVisitorId}
        />
        <div>
          <Table
            header="Visitors"
            page={page}
            getAllColumns={getAllColumns}
            getHeaderGroups={getHeaderGroups}
            getRowModel={getRowModel}
            leftButtons={[<CreateVisitorButton />]}
            rightButtons={[
              <InputSearch
                search={search}
                setSearch={setSearch}
                placeholder={translate("pages.visitors.search")}
              />
            ]}
            isLoading={isLoading}
            isFetching={isFetching}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
          />
        </div>
      </div>
    </>
  )
}
