import { useTranslate } from "@pankod/refine-core"
import { DATE_TIME_INPUT_TYPES } from "utilities/types"
import "./datetimeinput.css"

export const DateTimeInput = ({
  title,
  type,
  value,
  onChange,
  min,
  isDisabled
}: {
  title: string | null | undefined
  type: DATE_TIME_INPUT_TYPES
  value: any
  min?: any
  onChange: (arg0: any) => void
  isDisabled?: boolean
}) => {
  const translate = useTranslate()

  return (
    <div className="flex flex-col w-full">
      {title && <label className="pb-1">{translate(title)}</label>}
      <div className="flex flex-row">
        <input
          className="bg-gray-100 rounded-lg p-2 text-center"
          type={type}
          value={value}
          min={min}
          onChange={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        />
      </div>
    </div>
  )
}
