import { useCustomMutation, useTranslate } from "@pankod/refine-core"
import { Button } from "components/globals/Button"
import { Toggler } from "components/globals/Toggler"
import { IDeviceSetting } from "interfaces"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { axiosNoUrl, axiosInstance } from "utilities/dataProvider"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { SETTING_DATA_TYPES } from "utilities/types"

export const DeviceSettingInput = ({
  deviceId,
  deviceSettingsData,
  settingName,
  dataType,
  description
}: {
  deviceId: number
  deviceSettingsData: IDeviceSetting[]
  settingName: string
  // Setting data type can have fixed value or try to use metadata value from setting
  dataType?: SETTING_DATA_TYPES | null
  // Set string to display custom descrption. Null to disable. Otherwise will show description from database.
  description?: string | null
}) => {
  const [settingValue, setSettingValue] = useState("")
  const [settingDataType, setSettingDataType] = useState("")
  const [checked, setChecked] = useState(false)
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  )
  const [imgPreview, setImgPreview] = useState("")

  const translate = useTranslate()
  const { mutate: customMutation } = useCustomMutation()

  const usePrevious = (value: string) => {
    const ref = useRef<string>()
    useEffect(() => {
      ref.current = value
    }, [value]) // Only re-run if value changes

    return ref.current
  }

  const selectedSetting = deviceSettingsData?.filter(
    (setting) => setting.setting_metadata.key === settingName
  )[0]

  useEffect(() => {
    if (selectedSetting !== undefined) {
      setSettingValue(selectedSetting.value)
      setSettingDataType(
        dataType ?? selectedSetting.setting_metadata.data_type.name
      )
      if (selectedSetting.setting_metadata.data_type.name === "Boolean") {
        setChecked(selectedSetting.value === "true" ? true : false)
      }
    } else {
      if (dataType !== undefined && dataType !== null)
        setSettingDataType(dataType)
    }
  }, [selectedSetting])

  const prevSettingValue = usePrevious(settingValue)

  /// Checks if setting has dedicated localization for changing value. Otherwise will use generic localization.
  const checkSettingUpdateLocalization = (settingName: string, localizationType: string) => {
    const translated = translate(`notifications.${settingName}.${localizationType}`)
    if (translated === `notifications.${settingName}.${localizationType}`) {
      return translate(`notifications.changeSetting${localizationType}`, {
        resource: translate(`pages.devices.settings.${settingName}`)
      })
    }
    else {
      return translated
    }
  }

  const updateSetting = (value: string) => {
    if (settingDataType !== "Picture" && value === prevSettingValue) {
      return
    }

    const successText = checkSettingUpdateLocalization(settingName, "Success")
    const errorText = checkSettingUpdateLocalization(settingName, "Error")

    customMutation(
      {
        url: `devices/${deviceId}/settings/${settingName}`,
        values: { value },
        method: "put"
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(successText)
        },
        onError: (_error, _variables, _context) => {
          customToast.error(errorText)
          void Logger().error(LOG.EDIT_SETTING, `${_error.stack}`)
        }
      }
    )
  }

  const resetButtonStyle = "px-1 text-soft-blue"

  let inputStyle

  switch (settingDataType) {
    case "Color":
      inputStyle =
        "border border-gray-300 text-gray-900 rounded-md focus:ring-blue-500 focus:border-blue-500 focus:border-2 w-8 h-8"
      break
    default:
      inputStyle =
        "bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:border-2 block p-2.5 w-full"
      break
  }

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const file = target.files?.[0]
    setSelectedImage(file)
  }

  useEffect(() => {
    const uploadPhoto = async () => {
      let publicUrl = ""

      const getSignedUrl = async () => {
        if (selectedImage !== undefined) {
          try {
            const res = await axiosInstance.post(
              `/devices/${deviceId}/settings/signed-url`,
              {
                blob: selectedImage?.name
              }
            )
            const signedUrl: string = res.data.signed_url
            publicUrl = res.data.public_url

            void axiosNoUrl.put(signedUrl, selectedImage, {
              headers: {
                "Content-type": selectedImage.type
              }
            })
          } catch (error) {
            customToast.error(translate("notifications.fileUploadError"))
            void Logger().error(LOG.SIGNED_URL, `${error}`)
          }
        }
      }
      await getSignedUrl()
      setSettingValue(publicUrl)
      updateSetting(publicUrl)
    }

    if (selectedImage !== undefined) {
      const objectUrl = URL.createObjectURL(selectedImage)
      setImgPreview(objectUrl)
      void uploadPhoto()

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [selectedImage])

  return (
    <div>
      {settingDataType === "Boolean" && (
        <Toggler
          header={`pages.devices.settings.${settingName}`}
          description={
            description !== undefined
              ? description
              : selectedSetting?.setting_metadata.description
          }
          checked={checked}
          onChange={() => {
            updateSetting(checked ? "false" : "true")
            setSettingValue(checked ? "false" : "true")
            setChecked(!checked)
          }}
          className="mb-8"
        />
      )}
      {settingDataType === "Picture" && (
        <div>
          <div className="flex flex-col flex-1 mb-2">
            <div className="flex flex-col justify-between mb-1">
              <p className="font-medium self-start mb-2">
                {translate(`pages.devices.settings.${settingName}`)}
              </p>
              <input
                type="file"
                className={inputStyle}
                onChange={(e) => onChangeFile(e)}
              ></input>
            </div>
            <p className="text-gray-500">
              {description && description}
              {description === undefined &&
                selectedSetting?.setting_metadata.description}
            </p>
          </div>
          {settingValue !== "" ? (
            <div className="flex flex-col pt-2 mb-2 items-start">
              <img
                id="preview"
                className="inset-0 object-scale-down w-32"
                alt="Preview"
                src={imgPreview !== "" ? imgPreview : settingValue}
              />
              <Button
                name="Reset"
                style={`${resetButtonStyle}`}
                onClick={() => {
                  setSettingValue("")
                  updateSetting("")
                }}
              />
            </div>
          ) : (
            <div className="flex flex-col pt-2">
              <p>{translate("pages.devices.noImage")}</p>
            </div>
          )}
        </div>
      )}
      {settingDataType === "Color" && (
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 mb-8">
            <div className="flex justify-between mb-3">
              <p className="font-medium self-center">
                {translate(`pages.devices.settings.${settingName}`)}
              </p>
            </div>
            <div className="flex flex-row gap-4 justify-start items-center">
              <input
                type={settingDataType}
                className={inputStyle}
                value={
                  settingDataType === "Color"
                    ? settingValue === ""
                      ? "#548bd3" // Default color on kiosk
                      : settingValue
                    : settingValue
                }
                onChange={(event) => setSettingValue(event.target.value)}
                onBlur={() => updateSetting(settingValue)}
              ></input>
              {settingDataType === "Color" && (
                <Button
                  name="Reset"
                  style={`${resetButtonStyle}`}
                  onClick={() => {
                    setSettingValue("")
                    updateSetting("")
                  }}
                />
              )}
            </div>
            <p className="text-gray-500 mt-2">
              {description && description}
              {description === undefined &&
                selectedSetting?.setting_metadata.description}
            </p>
          </div>
        </div>
      )}
      {settingDataType !== "Boolean" &&
        settingDataType !== "Picture" &&
        settingDataType !== "Color" && (
          <div className="flex flex-col flex-1 mb-8">
            <div className="flex justify-between mb-3">
              <p className="font-medium self-center">
                {translate(`pages.devices.settings.${settingName}`)}
              </p>
            </div>
            <input
              type={settingDataType}
              className={inputStyle}
              value={settingValue}
              onChange={(event) => setSettingValue(event.target.value)}
              onBlur={() => updateSetting(settingValue)}
            ></input>
            <p className="text-gray-500 mt-2">
              {description && description}
              {description === undefined &&
                selectedSetting?.setting_metadata.description}
            </p>
          </div>
        )}
    </div>
  )
}
