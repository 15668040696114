import React from "react"
import { Dispatch, SetStateAction, useRef } from "react"
import { Close, MagnifyGlass } from "icons/icons"
import { useTranslate } from "@pankod/refine-core"

export const InputSearch = ({
  search,
  setSearch,
  placeholder,
  onEnter,
  setFocused,
  error,
  onBlur,
  css
}: {
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  placeholder?: string
  onEnter?: () => void
  setFocused?: Dispatch<SetStateAction<boolean>>
  error?: boolean
  onBlur?: () => void
  css?: string
}) => {
  const translate = useTranslate()
  const inputRef = useRef<HTMLInputElement>(null)
  const focusInput = () => inputRef?.current?.focus()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value)

  const clearState = () => setSearch("")

  React.useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        onEnter && onEnter()
        inputRef?.current?.blur()
      }
    }
    inputRef.current?.addEventListener("keypress", callback)

    return () => inputRef.current?.removeEventListener("keypress", callback)
  }, [onEnter])

  return (
    <div
      className={["flex items-center rounded-lg relative", css && css].join(
        " "
      )}
    >
      <div onClick={focusInput} className="absolute left-3">
        <MagnifyGlass />
      </div>
      <input
        autoFocus={false}
        onFocus={() => setFocused && setFocused(true)}
        onBlur={() => {
          onBlur && onBlur()
          // TODO: Handle this differently
          // Selecting child elements of a dropdown is not triggered before onBlur event, thus the timeout
          setTimeout(() => setFocused && setFocused(false), 300)
        }
        }
        ref={inputRef}
        value={search}
        onChange={handleChange}
        type="text"
        placeholder={placeholder ?? translate("pages.hosts.search")}
        className={`flex transition-shadow delay-100 mr-2 py-2 bg-white ring-1 ${error ? 'ring-red-600' : 'ring-gray-200'} ring-inset rounded-md pl-10 pr-8 focus:ring-2 focus:ring-systam-blue focus:outline-none ${css ? css : ''}`}
      />
      <button onClick={clearState} className="absolute right-5">
        <Close />
      </button>
    </div>
  )
}
