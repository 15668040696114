import { useTranslate } from "@pankod/refine-core"

export const VisitorState = ({
  state: visitorState
}: {
  state: string
}): JSX.Element => {
  const translate = useTranslate()
  const statusStyle =
    "px-4 py-2 flex justify-center font-medium text-sm center rounded-full text-gray-900 min-w-26"

  const signedIn = (): JSX.Element => {
    return (
      <div className={`${statusStyle} bg-emerald-100`}>
        <h3>{translate("visitor_state.signed_in")}</h3>
      </div>
    )
  }

  const signedOut = (): JSX.Element => {
    return (
      <div className={`${statusStyle} bg-red-100`}>
        <h3>{translate("visitor_state.signed_out")}</h3>
      </div>
    )
  }

  const expected = (): JSX.Element => {
    return (
      <div className={`${statusStyle} bg-blue-100`}>
        <h3>{translate("visitor_state.expected")}</h3>
      </div>
    )
  }

  const notFound = (): JSX.Element => {
    return (
      <div className={`${statusStyle} bg-gray-100`}>
        <h3>{translate("not_found")}</h3>
      </div>
    )
  }

  const removed = (): JSX.Element => {
    return (
      <div className={`${statusStyle} bg-gray-100`}>
        <h3>{translate("removed")}</h3>
      </div>
    )
  }

  const visitorStateRender = (visitorState: string) => {
    switch (visitorState) {
      case "signed_in":
        return signedIn()
      case "signed_out":
        return signedOut()
      case "expected":
        return expected()
      case "removed":
        return removed()
      default:
        return notFound()
    }
  }

  return (
    <div className="flex items-center">{visitorStateRender(visitorState)}</div>
  )
}
