import { RegisterForm } from "./Login/RegisterForm"
import niceToSeeYou from "assets/images/systam_nice_to_see_you.svg"

export const RegisterPage = () => {
  // Hide help-widget
  if (window) {
    window.FreshworksWidget("hide")
  }

  return (
    <div className="flex flex-col lg:flex-row w-full min-h-screen overflow-hidden">
      <div className="relative w-full h-full lg:w-1/2 flex flex-col items-center justify-start p-8 lg:pl-52 lg:pr-16 lg:py-16">
        <div className="w-full lg:w-144">
          <RegisterForm />
        </div>
      </div>
      <div className="relative w-full lg:w-1/2 h-64 lg:h-full">
        <img
          alt="Systam"
          className="absolute inset-0 object-cover object-center aspect-auto"
          src={niceToSeeYou}
        />
      </div>
    </div>
  )
}
