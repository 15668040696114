import { Refine } from "@pankod/refine-core"
import routerProvider from "@pankod/refine-react-router-v6"
import "index.css"
import "react-loading-skeleton/dist/skeleton.css"
import { Layout } from "components/Layout"
import { Sider } from "components/Sider"
import { Login } from "components/Login"
import { dataProvider } from "utilities/dataProvider"
import authProvider from "utilities/authProvider"
import { resources } from "resources"
import { useTranslation } from "react-i18next"
import { UpdatePasswordPage } from "pages/UpdatePasswordPage"
import ErrorBoundary from "components/ErrorBoundary"
import { DeviceShow } from "pages/devices"
import { Dashboard } from "pages/Dashboard"
import { DisplayCreate, DisplayShow } from "pages/displays"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { PassInfo } from "pages/PassInfo"
import { FallbackUpselling } from "components/FallbackUpselling"
import { accessControlProvider } from "providers/accessControlProvider"
import { RegisterPage } from "components/Register"
import { SpaceShow } from "pages/spaces/show"
import { PrivacyPolicy } from "pages/PrivacyPolicy"
import { TermsOfService } from "pages/TermsOfService"

export const App: React.FC = () => {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  }

  return (
    <ErrorBoundary>
      <Refine
        accessControlProvider={accessControlProvider()}
        options={{ disableTelemetry: true }}
        LoginPage={Login}
        i18nProvider={i18nProvider}
        DashboardPage={Dashboard}
        authProvider={authProvider(process.env.REACT_APP_API_URL as string)}
        routerProvider={{
          ...routerProvider,
          routes: [
            { path: "/terms-of-service", element: <TermsOfService /> },
            { path: "/privacy-policy", element: <PrivacyPolicy /> },
            { path: "/update-password", element: <UpdatePasswordPage /> },
            {
              element: <DeviceShow />,
              path: "/devices/:deviceId",
              layout: true
            },
            {
              element: <DisplayCreate />,
              path: "/displays/create",
              layout: true
            },
            {
              element: <DisplayShow />,
              path: "/displays/:deviceId",
              layout: true
            },
            {
              element: <SpaceShow />,
              path: "/spaces/:spaceId",
              layout: true
            },
            {
              element: <PassInfo />,
              path: "/inductions",
              layout: true
            },
            {
              element: <RegisterPage />,
              path: "/register",
              layout: false
            }
          ]
        }}
        Sider={Sider}
        dataProvider={dataProvider(process.env.REACT_APP_API_URL as string)}
        Layout={Layout}
        resources={resources}
        catchAll={<FallbackUpselling />}
      >
        <ToastContainer theme="colored" />
      </Refine>
    </ErrorBoundary>
  )
}
