import { useTranslate } from "@pankod/refine-core"
import Switch from "react-switch"

export const Toggler = ({
  header,
  description,
  checked,
  onChange,
  className,
  icon
}: {
  header: string
  description?: string | null
  checked: boolean
  onChange: () => void
  className?: string
  icon?: JSX.Element
}) => {
  const translate = useTranslate()
  return (
    <div className={["flex flex-col flex-1", className && className].join(" ")}>
      <div className="flex justify-between mb-1 items-center">
        <div className="flex">
          {icon && <div className="flex items-center pr-3">{icon}</div>}
          <p className="font-medium self-center">{translate(header)}</p>
        </div>

        <Switch
          onChange={onChange}
          checked={checked}
          offColor="#D9D9D9"
          onColor="#34D399"
          onHandleColor="#fff"
          handleDiameter={27}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(29, 29, 29, 0.2)"
          activeBoxShadow="0px 0px 1px 10px rgba(29, 29, 29, 0.2)"
          height={33}
          width={60}
        />
      </div>
      {description && <p className="text-gray-500 pr-16">{translate(description)}</p>}
    </div>
  )
}
