export enum VisitorState {
  SIGNED_IN = "signed_in",
  EXPECTED = "expected",
  SIGNED_OUT = "signed_out",
  REMOVED = "removed"
}

export enum NotificationMessageType {
  EXPECTED_VISIT = "NotificationForVisitorExpectedVisit",
  ARRIVED_VISITOR_GROUP = "NotificationForHostArrivedVisitorGroup",
  ARRIVED_VISITOR = "NotificationForHostArrivedVisitor"
}

export const getNotificationMessageTemplateTypeName = (
  visitorState: VisitorState,
  visitorsCount: number
): NotificationMessageType => {
  if (visitorState === VisitorState.EXPECTED) {
    return NotificationMessageType.EXPECTED_VISIT
  } else {
    return visitorsCount > 1
      ? NotificationMessageType.ARRIVED_VISITOR_GROUP
      : NotificationMessageType.ARRIVED_VISITOR
  }
}
