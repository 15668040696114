import React from "react"

/**
 * Renders the Privacy Policy page.
 * Redirects the user to the Systam privacy policy page.
 */
export const PrivacyPolicy = () => {
  React.useEffect(() => {
    window.location.href = "https://systam.io/privacy"
  }, [])

  return null
}
