import { AxiosError, AxiosInstance } from "axios"
import { CustomJwtPayload } from "interfaces"
import { createBrowserHistory } from "history"
import jwt_decode from "jwt-decode"
import {
  ACCESS_TOKEN_KEY,
  HTTP_STATUS_FORBIDDEN,
  HTTP_STATUS_UNAUTHORIZED,
  REFRESH_TOKEN_KEY
} from "./constants"
import { RefreshTokenRequest } from "./types"

export const refreshTokenRoute = `${process.env.REACT_APP_API_URL}/token/refresh`

export const isTokenExpired = (token: CustomJwtPayload): boolean => {
  // Lifetime in seconds of the access/refresh token, after which the token
  // SHALL NOT be accepted by the resource server

  return Date.now() >= token.exp * 1000
}

export const shouldSignOutUserInterceptError = (error: AxiosError) => {
  let refreshToken
  try {
    refreshToken = jwt_decode<CustomJwtPayload>(
      localStorage.getItem(REFRESH_TOKEN_KEY) ?? ""
    )
  } catch (e) {
    console.log("error", e)
  }

  const shouldRefreshHttpStatusCodes = new Set([
    HTTP_STATUS_UNAUTHORIZED,
    HTTP_STATUS_FORBIDDEN
  ])

  if (error !== undefined && error.response !== undefined) {
    if (
      shouldRefreshHttpStatusCodes.has(error?.response?.status) &&
      refreshToken &&
      isTokenExpired(refreshToken as CustomJwtPayload)
    ) {
      localStorage.clear()
      createBrowserHistory().replace("/login")
      window.location.reload()
    }
  }
}

export const shouldRequestRefreshError = (
  error: AxiosError,
  isRefreshTokenRequestInFlight: boolean,
  axiosInstance: AxiosInstance,
  callback: (req: RefreshTokenRequest) => AxiosInstance
) => {
  // When an access token is expired, the client can request a new access
  // token by presenting a valid refresh token.
  const shouldRefreshHttpStatusCodes = new Set([
    HTTP_STATUS_UNAUTHORIZED,
    HTTP_STATUS_FORBIDDEN
  ])
  const accessToken = jwt_decode<CustomJwtPayload>(
    localStorage.getItem(ACCESS_TOKEN_KEY) ?? ""
  )
  if (error !== undefined && error.response !== undefined) {
    if (
      shouldRefreshHttpStatusCodes.has(error?.response?.status) &&
      accessToken &&
      isTokenExpired(accessToken) &&
      !isRefreshTokenRequestInFlight
    ) {
      const refreshTokenRequest: RefreshTokenRequest = {
        instance: axiosInstance,
        shouldRetry: isRefreshTokenRequestInFlight
      }

      return callback(refreshTokenRequest)
    }
  }
}
