import { ISettingEntity } from "interfaces"
import { SettingBuilder } from "./SettingBuilder"
import { useTranslate } from "@pankod/refine-core"

/**
 * SettingCard is just a wrapper that wraps settings components to a single card on UI.
 * You can determine how many settingEntities are inside a card by modifying DefaultWorkspaceSettings.ts file.
 */
export const SettingCard = ({
  settingsPerCard,
  index,
  subHeader,
  cardDescription
}: {
  settingsPerCard: ISettingEntity[][]
  index: number
  subHeader: string
  cardDescription: string
}) => {
  const translate = useTranslate()

  return (
    <div
      key={index}
      className="bg-white rounded-xl px-8 p-5 m-4 border border-gray-300"
    >
      {subHeader && (
        <>
          <h1 className="font-kanit text-xl -mt-4 pt-8 pb-2">
            {translate(subHeader)}
          </h1>
          <div className="px-5 -mx-8 pb-4">
            {cardDescription && (
              <p className="text-gray-600 pl-3 pb-2 text-sm">
                {translate(cardDescription)}
              </p>
            )}
          </div>
          <hr className="text-gray-300 my-2 border border-gray" />
        </>
      )}
      <div className="pt-4">
        {settingsPerCard.map((settingsData: ISettingEntity[], index) => (
          <div key={settingsData[index]?.value} className="py-1">
            <div className="lg:flex pb-2">
              {settingsData.map((adjacentSetting, settingChildEntityIndex) =>
                SettingBuilder(adjacentSetting, settingChildEntityIndex)
              )}
            </div>
            {index < settingsPerCard.length - 1 && (
              <hr className="text-gray-300 py-1 my-2" />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
