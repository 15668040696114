import { throttle } from "lodash"
import { useEffect } from "react"
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult
} from "@tanstack/react-query"

export const useScrollTrigger = (
  hasNextPage: boolean | undefined,
  ref: React.RefObject<HTMLDivElement>,
  fetchNextPage?: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>
) => {
  useEffect(() => {
    const element = ref.current
    const fullThrottle = throttle((e) => {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      const TRIGGER_HEIGHT = 200
      const bottomScrollTrigger = scrollHeight - clientHeight - TRIGGER_HEIGHT

      if (scrollTop >= bottomScrollTrigger && bottomScrollTrigger > 0) {
        if (hasNextPage) fetchNextPage && void fetchNextPage()
      }
    }, 200) // Throttle delay in ms

    element?.addEventListener("scroll", fullThrottle)
    return () => element?.removeEventListener("scroll", fullThrottle)
  }, [hasNextPage])
}
