import React, { useCallback, useMemo } from "react"
import { Listbox } from "@headlessui/react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

export interface SySelectOption<TKey extends string | number, TData> {
  readonly value: TKey
  readonly data: TData
}

export interface SySelectProps<TKey extends string | number, TData> {
  readonly id?: string
  readonly placeholder?: string
  readonly options: readonly SySelectOption<TKey, TData>[]
  readonly value: TKey | undefined
  readonly onValueChange: (value: TKey | undefined) => void
  readonly optionRenderer?: (
    option: SySelectOption<TKey, TData> | undefined
  ) => React.ReactElement
}

export function SySelect<
  TKey extends string | number = string,
  TData = string
>({
  id,
  placeholder,
  options,
  value,
  onValueChange,
  optionRenderer
}: SySelectProps<TKey, TData>): React.ReactElement {
  const finalOptionsRenderer = useCallback(
    (option: SySelectOption<TKey, TData> | undefined) =>
      optionRenderer ? optionRenderer(option) : defaultOptionRenderer(option),
    [optionRenderer]
  )

  const finalSelectionRenderer = useCallback(
    (option: SySelectOption<TKey, TData> | undefined) => {
      if (!option && placeholder) {
        return <span className="text-sy-gray-300">{placeholder}</span>
      }

      return finalOptionsRenderer(option)
    },
    [finalOptionsRenderer]
  )

  const optionsMap: ReadonlyMap<
    TKey,
    SySelectOption<TKey, TData>
  > = useMemo(() => {
    return new Map<TKey, SySelectOption<TKey, TData>>(
      options.map(
        (option) =>
          [option.value, option] as [TKey, SySelectOption<TKey, TData>]
      )
    )
  }, [options])

  const selectedOption = useMemo(
    () => (value ? optionsMap.get(value) : undefined),
    [optionsMap, value]
  )

  return (
    <Listbox value={value} onChange={onValueChange}>
      {({ open }) => (
        <div className="relative text-lg">
          <Listbox.Button
            id={id}
            className="relative w-full min-h-12 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-black ring-1 ring-inset ring-sy-gray-100 focus:outline-none focus:ring-2 focus:ring-sy-denim-800 sm:leading-6"
          >
            <span className="block truncate">
              {finalSelectionRenderer(selectedOption)}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <FontAwesomeIcon
                icon={faChevronDown}
                size="lg"
                className="text-black"
              />
            </span>
          </Listbox.Button>

          {open && (
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {options.map((option) => (
                <Listbox.Option
                  key={option.value}
                  className={({ active }) =>
                    classNames(
                      active ? "bg-sy-denim-800 text-white" : "text-black",
                      "relative cursor-default select-none py-1 pl-3 pr-9"
                    )
                  }
                  value={option.value}
                >
                  {({ selected, active: _active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {finalOptionsRenderer(option)}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          )}
        </div>
      )}
    </Listbox>
  )
}

function defaultOptionRenderer<TKey extends string | number, TData>(
  option: SySelectOption<TKey, TData> | undefined
): React.ReactNode {
  if (!option) {
    return ""
  }

  const { data } = option
  return typeof data === "string" ? data : <>{data}</>
}
