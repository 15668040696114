import { IVisitor } from "interfaces"
import { UserIcon } from "@heroicons/react/24/outline"

interface VisitorAvatarProps {
  visitor: IVisitor
}

export const VisitorAvatar = (props: VisitorAvatarProps) => {
  return (
    <div className="flex-shrink-0 h-16 w-16">
      {props.visitor?.details?.picture ? (
        <img
          className="shadow-lg h-16 w-16 rounded-full object-cover aspect-square"
          src={props.visitor.details.picture}
          alt="Visitor looks"
        />
      ) : (
        <UserIcon className="h-14 w-14 text-systam-blue" />
      )}
    </div>
  )
}
