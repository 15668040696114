import {
  useTranslate,
  useCustomMutation,
  usePermissions
} from "@pankod/refine-core"
import { Select } from "components/globals/Select"
import { ISettingEntity, ISettingOption } from "interfaces"
import { useEffect, useState } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"

export const SettingDropdown = ({
  settingsData: {
    value,
    endpoint,
    setting_metadata: { description, key, data_type }
  }
}: {
  settingsData: ISettingEntity
}) => {
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const [currentOption, setOption] = useState<ISettingOption>()
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")

  const settingOptions: ISettingOption[] = [
    { id: "1", name: "Optional" },
    { id: "2", name: "Hidden" },
    { id: "3", name: "Mandatory" }
  ]

  useEffect(() => {
    for (const option of settingOptions) {
      if (value === option.name) {
        return setOption(option)
      }
    }
  }, [value])

  const handleChange = (e) => {
    let selectedOption: ISettingOption | undefined = undefined

    for (const option of settingOptions) {
      if (e.target.value === option.id) {
        selectedOption = option
      }
    }

    const workspace = localStorage.getItem(SELECTED_WORKSPACE) || ""

    updateMutation(
      {
        url: endpoint.replace("{id}", workspace),
        method: "put",
        values: { value: selectedOption?.name }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          setOption(selectedOption)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }

  return (
    <div className="flex flex-row flex-1 mt-2 mb-4 mr-2 justify-between items-center">
      <div className="flex flex-col gap-2 justify-between mb-1">
        <h1 className="font-medium text-md">{translate(`pages.settings.${key}`)}</h1>
        <p className="text-gray-500 text-sm whitespace-pre-wrap">{translate(description)}</p>
      </div>
      <div>
        <Select
          disabled={!canEdit}
          label={""}
          placeholder={"placeholderi"}
          id="settingOption"
          value={`${currentOption?.id}`}
          onChange={(e) => void handleChange(e)}
          optionData={settingOptions}
        />
      </div>
    </div>
  )
}
