import { useCustom, useTranslate } from "@pankod/refine-core"
import { Modal } from "../../../components/Modal"
import { useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utilities/dataProvider"
import { HTTP_STATUS_OK } from "utilities/constants"
import { IDevice } from "interfaces"
import { Select } from "components/globals/Select"
import { Dispatch, SetStateAction, useState } from "react"
import customToast from "utilities/toastHelper"

export const AddDeviceLink: React.FC<any> = ({
  deviceId,
  open,
  onClose
}: {
  deviceId: number
  open: boolean
  onClose: () => void
}) => {
  const translate = useTranslate()
  const queryClient = useQueryClient()

  const [selectedDeviceId, setSelectedDeviceId] = useState("")
  const [selectedConnectionType, setSelectedConnectionType] = useState("1")

  const postDeviceLink = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const res = await axiosInstance.post(`/devices/${deviceId}/links`, {
      connection_type_id: selectedConnectionType,
      link_device_id: selectedDeviceId
    })
    if (res.status === HTTP_STATUS_OK) {
      customToast.success(
        translate("notifications.createSuccess", {
          resource: translate("resources.deviceLink")
        })
      )
      onClose()
      void queryClient.resetQueries({
        queryKey: [`deviceLinks`]
      })
    } else {
      customToast.error(translate("notifications.createError"))
    }
  }

  return (
    <Modal
      title={translate("pages.devices.addDeviceLink")}
      isOpen={open}
      onClose={onClose}
    >
      <div className="text-l px-6 ">
        <p className="mb-4">
          {translate("pages.devices.addDeviceLinkDescription")}
        </p>
        <DevicesDropdown
          selectedDeviceId={selectedDeviceId}
          setSelectedDeviceId={setSelectedDeviceId}
        />
        <label className="mt-2">{translate("pages.devices.selectType")}</label>
        <select
          className={
            "flex w-full py-2 bg-gray-100 outline-none rounded-md pl-2"
          }
          id="selectConnType"
          value={selectedConnectionType}
          onChange={(e) => setSelectedConnectionType(e.target.value)}
        >
          <option value="1">USB</option>
          <option value="2">Wifi</option>
        </select>
      </div>
      <div className="flex flex-col">
        <div className="mt-6 ">
          <div className="flex justify-around">
            <button
              onClick={() => {
                onClose()
              }}
            >
              {translate("buttons.cancel")}
            </button>
            <button
              className="py-3 px-6 font-medium text-white bg-systam-blue hover:bg-systam-dark rounded-lg"
              onClick={(event) => void postDeviceLink(event)}>
              {translate("buttons.save")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const DevicesDropdown = ({
  selectedDeviceId,
  setSelectedDeviceId
}: {
  selectedDeviceId: string
  setSelectedDeviceId: Dispatch<SetStateAction<any>>
}) => {
  const translate = useTranslate()

  const { data: deviceData } = useCustom<[IDevice]>({
    url: `devices/`,
    method: "get"
  })

  return (
    <>
      {deviceData?.data !== undefined && deviceData?.data.length > 1 && (
        <Select
          label={translate("pages.devices.selectDevice")}
          placeholder=""
          id="deviceTypeDropdown"
          optionData={deviceData?.data}
          allowShowAll={false}
          value={selectedDeviceId}
          onChange={(e) => {
            setSelectedDeviceId(e.target.value)
          }}
        />
      )}
    </>
  )
}
