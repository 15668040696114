import { AuthProvider } from "@pankod/refine-core"
import axios, { AxiosError, AxiosResponse } from "axios"
import { CustomJwtPayload } from "interfaces"
import jwt_decode from "jwt-decode"
import {
  ACCESS_TOKEN_KEY,
  EXPIRES_KEY,
  PERMISSION_KEY,
  REFRESH_TOKEN_KEY,
  ROLE_KEY,
  SCOPE_ID,
  USER_ID_KEY,
  USERNAME_KEY,
  USER_TYPE_KEY
} from "./constants"
import { LOG, Logger } from "utilities/logger"
import {
  AccessTokenResponse,
  ForgotPasswordResponse,
  ForgotPasswordVariables,
  LoginVariables,
  RegisterVariables,
  UpdatePasswordResponse,
  UpdatePasswordVariables
} from "./types"
import {
  ClientsRegisterRequest,
  ClientsRegisterResponse
} from "./../types/pretty-api-types"
import { extractTokenFromUrlSearchParams, isEmptyString } from "./string"

const authProvider = (apiUrl: string): AuthProvider => {
  const axiosInstance = axios.create()
  axiosInstance.defaults.baseURL = `${apiUrl}/users`
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error: AxiosError) => {
      return Promise.reject(error)
    }
  )

  return {
    // eslint-disable-next-line @typescript-eslint/require-await
    register: async (props: RegisterVariables) => {
      const { email, company, firstName, lastName, password } = props

      const { data } = await axiosInstance.post<
        ClientsRegisterResponse,
        AxiosResponse<ClientsRegisterResponse>,
        ClientsRegisterRequest
      >(
        "/clients/register",
        {
          organization: company,
          first_name: firstName,
          last_name: lastName,
          email,
          password,
          verify_password: password,
          modules: {
            visit: false,
            rooms: true
          }
        },
        {
          baseURL: apiUrl
        }
      )
      if (data) {
        // TODO: handle register response
      }
    },
    login: async (props: LoginVariables) => {
      const params = new URLSearchParams()
      params.append("username", props.username)
      params.append("password", props.password)

      try {
        const { data } = await axiosInstance.post<AccessTokenResponse>(
          "/login",
          params
        )
        if (data) {
          const decoded = jwt_decode<CustomJwtPayload>(data.access_token)
          localStorage.setItem(SCOPE_ID, data.user.scope_id.toString())
          localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token)
          localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token)
          localStorage.setItem(USERNAME_KEY, props.username)
          localStorage.setItem(
            EXPIRES_KEY,
            decoded.exp?.toString() !== undefined ? decoded.exp.toString() : ""
          )
          localStorage.setItem(
            USER_TYPE_KEY,
            data.user.type !== undefined ? data.user.type : ""
          )
          localStorage.setItem(
            USER_ID_KEY,
            data.user.id.toString() !== undefined ? data.user.id.toString() : ""
          )
          localStorage.setItem(
            PERMISSION_KEY,
            JSON.stringify(data.user.role.permissions)
          )
          localStorage.setItem(ROLE_KEY, JSON.stringify(data.user.role.name))
          void Logger().log(LOG.LOGIN)
          return Promise.resolve()
        }
      } catch (err) {
        void Logger().error(LOG.AUTH_PROVIDER, `${err}`)
      }

      return Promise.reject()
    },
    logout: () => {
      window.sessionStorage.clear()
      localStorage.clear()
      return Promise.resolve()
    },
    checkAuth: () => {
      return localStorage.getItem(ACCESS_TOKEN_KEY)
        ? Promise.resolve()
        : Promise.reject()
    },
    checkError: () => Promise.resolve(),
    getPermissions: () => {
      const type = localStorage.getItem(PERMISSION_KEY)
      return Promise.resolve(type)
    },
    getUserIdentity: () => {
      const userIdentity = localStorage.getItem(USERNAME_KEY)
      if (userIdentity) {
        return Promise.resolve(userIdentity)
      }

      return Promise.reject()
    },
    forgotPassword: async (params: ForgotPasswordVariables) => {
      if (params.username) {
        try {
          const { data } = await axiosInstance.post<ForgotPasswordResponse>(
            "/forgot-password",
            { username: params.username }
          )
          if (data && data.success) {
            return Promise.resolve("/login")
          }
        } catch (err) {
          void Logger().error(LOG.AUTH_PROVIDER, `${err}`)
        }
      }
      return Promise.reject()
    },
    updatePassword: async (params: UpdatePasswordVariables) => {
      if (window.location.search && !isEmptyString(window.location.search)) {
        const token = extractTokenFromUrlSearchParams(window.location.search)
        if (params.confirmPassword && params.password && token) {
          try {
            const { data } = await axiosInstance.post<UpdatePasswordResponse>(
              "/update-password",
              {
                token: token,
                password: params.password,
                confirm_password: params.confirmPassword
              }
            )
            if (data?.success) {
              return Promise.resolve()
            }
          } catch (err) {
            void Logger().error(LOG.AUTH_PROVIDER, `${err}`)
          }
        }
      }
      return Promise.reject()
    }
  }
}

export default authProvider
