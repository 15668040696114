import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { SyTypography } from "../display"

export interface SyLabelProps {
  readonly htmlFor?: string
  readonly required?: boolean
  readonly children: string
}

export function SyLabel({
  htmlFor,
  required,
  children
}: SyLabelProps): React.ReactElement {
  return (
    <label htmlFor={htmlFor}>
      <SyTypography family="garnett">{children}</SyTypography>
      {required && (
        <FontAwesomeIcon
          icon={faCircle}
          size="2xs"
          className="ml-1 text-sy-mandy-500 scale-75"
        />
      )}
    </label>
  )
}
