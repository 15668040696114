export const addDotstoEnd = (word?: string | null, maxLen = 20): string =>
  word == null
    ? ""
    : word.length > maxLen
    ? word.slice(0, maxLen) + "..."
    : word

export const leadingZero = (num: number): string =>
  num.toString().length === 1 ? `0${num}` : num.toString()

/**
 * Checks if a string is empty or not (e.g. " " is empty) or null and returns true if it is empty, otherwise false (e.g. " " is not empty)
 * @param data The string to check if it is empty or not
 * @returns True if the string is empty, otherwise false
 */
export const isEmptyString = (data: string): boolean =>
  (typeof data === "string" && data.trim().length === 0) || data === null

export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

/**
 * Extracts the token from a search string (e.g. "?token=1234") or undefined if the token is not found in the search string
 * @param search - The search string to extract the token from
 * @returns The token or undefined if the token is not found in the search string
 */
export const extractTokenFromUrlSearchParams = (
  search = ""
): string | undefined => {
  if (!search) {
    return undefined
  }

  const urlParams = new URLSearchParams(search)
  const token = urlParams.get("token")
  if (typeof token === "string") {
    return token.split("?")[0] || search
  }

  return undefined
}

/**
 * Converts a string to a number or undefined if the string is null or not a number (NaN)
 * @param input - The string to convert to a number
 * @returns The number or undefined if the string is null or not a number (NaN)
 */
export const convertToNumber = (input: string | null): number | undefined =>
  input === null ? undefined : parseFloat(input) || undefined
