import { SetStateAction, useState } from "react"
import { useApiUrl, useCustomMutation, useTranslate } from "@pankod/refine-core"
import customToast from "utilities/toastHelper"
import { LOG, Logger } from "utilities/logger"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { Button, ButtonStyle } from "./globals/Button"
import { Input } from "./globals/Input"
import { MIN_PASSWORD_LENGTH } from "utilities/passwordValidator"

type ModalPropsType = {
  onClose: () => void
}

type ChangePasswordType = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export const ChangePassword = ({ onClose }: ModalPropsType) => {
  const [currentPassword, setCurrentPassword] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const translate = useTranslate()
  const { mutate: customMutation } = useCustomMutation()
  const apiUrl = useApiUrl()

  const [showPassword, setShowPassword] = useState({
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false
  })

  const toggle = (passwordField: string) => {
    switch (passwordField) {
      case "oldPassword":
        setShowPassword({
          ...showPassword,
          showCurrentPassword: !showPassword.showCurrentPassword
        })
        break
      case "newPassword":
        setShowPassword({
          ...showPassword,
          showNewPassword: !showPassword.showNewPassword
        })
        break
      case "confirmPassword":
        setShowPassword({
          ...showPassword,
          showConfirmPassword: !showPassword.showConfirmPassword
        })
        break
    }
  }

  const performValidate = () => {
    const conditions = [
      newPassword === null,
      newPassword.length < MIN_PASSWORD_LENGTH,
      confirmPassword === null,
      confirmPassword !== newPassword
    ]

    return conditions.some((condition) => condition)
  }

  const changePassword = (value: ChangePasswordType) => {
    customMutation(
      {
        url: `${apiUrl}/users/change-password`,
        method: "patch",
        values: {
          current_password: value.currentPassword,
          new_password: value.newPassword,
          verify_new_password: value.confirmPassword
        }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(translate(`notifications.passwordChanged`))
          void Logger().log(LOG.CHANGE_PASSWORD_SUCCESS)
        },
        onError: (_error, _variables, _context) => {
          if (_error && _error.response) {
            void Logger().error(LOG.LOGIN, `${_error.stack}`)
            switch (_error.response.status) {
              case 422:
                customToast.error(
                  translate(`notifications.passwordsNotMatching`)
                )
                break
              case 401:
                customToast.error(translate(`notifications.passwordNotMatch`))
                break
            }
          }
        }
      }
    )
  }

  return (
    <div className="w-4/5">
      <div className="flex flex-col justify-between gap-1">
        <div className="flex flex-1">
          <Input
            label={translate("pages.changePassword.currentPasswordPlaceholder")}
            type={showPassword.showCurrentPassword ? "text" : "password"}
            id="oldPassword"
            placeholder={translate(
              "pages.changePassword.currentPasswordPlaceholder"
            )}
            onChange={(event: { target: { value: SetStateAction<string> } }) =>
              setCurrentPassword(event.target.value)
            }
            autoComplete="off"
          />
          <div className="flex items-center mt-4 ml-2">
            {!showPassword.showCurrentPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => toggle("oldPassword")}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={() => toggle("oldPassword")}
              />
            )}
          </div>
        </div>
        <div className="flex flex-1">
          <Input
            label={translate("pages.changePassword.newPassword")}
            type={showPassword.showNewPassword ? "text" : "password"}
            id="newPassword"
            placeholder={translate(
              "pages.changePassword.newPasswordPlaceholder"
            )}
            autoComplete="off"
            onChange={(event: { target: { value: SetStateAction<string> } }) =>
              setNewPassword(event.target.value)
            }
            onBlur={() => performValidate()}
          />
          <div className="flex items-center mt-4 ml-2">
            {!showPassword.showNewPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => toggle("newPassword")}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={() => toggle("newPassword")}
              />
            )}
          </div>
        </div>
        <div className="flex flex-1">
          <Input
            label={translate("pages.changePassword.confirmPassword")}
            type={showPassword.showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            placeholder={translate(
              "pages.changePassword.confirmPasswordPlaceholder"
            )}
            autoComplete="off"
            onChange={(event: { target: { value: SetStateAction<string> } }) =>
              setConfirmPassword(event.target.value)
            }
            onBlur={() => performValidate()}
          />
          <div className="flex items-center mt-4 ml-2">
            {!showPassword.showConfirmPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => toggle("confirmPassword")}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={() => toggle("confirmPassword")}
              />
            )}
          </div>
        </div>
        <div className="py-1 flex justify-around">
          <Button
            style={ButtonStyle.TransparentBorder}
            onClick={() => {
              onClose()
            }}
            name={translate("buttons.cancel")}
          />
          <Button
            name={translate("buttons.save")}
            disabled={performValidate()}
            onClick={() => {
              changePassword({
                currentPassword: currentPassword,
                newPassword,
                confirmPassword
              })
            }}
          />
        </div>
        <div className="flex justify-end text-sm">
          <a
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            href="/forgot-password"
          >
            {translate("pages.forgotPassword.send")}
          </a>
        </div>
      </div>
    </div>
  )
}
