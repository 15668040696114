import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCustom } from "@pankod/refine-core"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useAppSelector } from "reduxStore/store"
import { RoomsResponse } from "types/pretty-api-types"

export const SpacesDropdown = ({
  selectedRoomId,
  setSelectedRoomId
}: {
  selectedRoomId: number | undefined
  setSelectedRoomId: Dispatch<SetStateAction<any>>
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRoomName, setSelectedRoomName] = useState("")

  const { data: spacesData } = useCustom<RoomsResponse>({
    url: `rooms`,
    method: "get"
  })

  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  useEffect(() => {
    if (selectedRoomName === "") {
      const name = spacesData?.data.items?.find(
        (room) => room.id === selectedRoomId
      )?.name

      setSelectedRoomName(name ?? "")
    }
  }, [spacesData, selectedRoomId])

  const ref = useRef<HTMLDivElement>(null)

  // Event handler to close the dropdown if clicked outside
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div className="flex flex-col relative" ref={ref}>
      <div className="w-full ring-1 ring-one-gray-300 rounded-md flex justify-between items-center h-10">
        <button
          id="button_devices_dropdown"
          className="w-full flex py-2 px-4"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {selectedRoomName ? (
            <p className="w-full text-start">{selectedRoomName}</p>
          ) : (
            <div className="w-full"></div>
          )}
          <FontAwesomeIcon
            className="text-one-gray-500"
            icon={isOpen ? faCaretUp : faCaretDown}
            size="lg"
          />
        </button>
      </div>
      {spacesData?.data.items && isOpen && (
        <div className="flex flex-col overflow-auto max-h-64 ring-1 ring-one-gray-300 rounded-md shadow-md absolute mt-12 w-full">
          {
            // Filter rooms by workspace_id
            spacesData?.data.items
              ?.filter((room) => room.workspace_id === selectedWorkspaceId)
              .map((room) => (
                <button
                  key={room.id}
                  className="w-full py-2 px-4 flex justify-between items-center bg-white hover:bg-one-gray-100"
                  onClick={() => {
                    setSelectedRoomName(room.name)
                    setSelectedRoomId(room.id)
                    setIsOpen(false)
                  }}
                >
                  <div>{room.name}</div>
                </button>
              ))
          }
        </div>
      )}
    </div>
  )
}
