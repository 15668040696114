import {
  faCaretDown,
  faCaretUp,
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCustom, useTranslate } from "@pankod/refine-core"
import { Close } from "icons/icons"
import { IDevice } from "interfaces"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { useAppSelector } from "reduxStore/store"

export const AvailableRoomsDevicesDropdown = ({
  setSelectedDeviceId,
  addNewMode,
  setAddNewMode,
  newDeviceName,
  setNewDeviceName
}: {
  setSelectedDeviceId: Dispatch<SetStateAction<any>>
  addNewMode: boolean
  setAddNewMode: Dispatch<SetStateAction<boolean>>
  newDeviceName: string
  setNewDeviceName: Dispatch<SetStateAction<string>>
}) => {
  const translate = useTranslate()
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedDeviceName, setSelectedDeviceName] = useState("")

  const { data: deviceData } = useCustom<[IDevice]>({
    url: `devices/systam-workplace`,
    method: "get"
  })

  const { data: workspaceData } = useCustom({
    url: `workspaces/${selectedWorkspaceId}`,
    method: "get"
  })

  const filteredDevices = deviceData?.data.filter(
    (device) => device.rooms.length === 0
  )
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (addNewMode) {
      inputRef.current?.focus()
    }
  }, [addNewMode])

  return (
    <div className="flex flex-col">
      <label htmlFor="button_devices_dropdown" className="font-medium mb-2">
        {addNewMode
          ? translate("pages.spaces.newDisplayName")
          : translate("pages.spaces.selectDisplay")}
      </label>
      <div className="w-full ring-1 ring-one-gray-800 rounded-md flex justify-between items-center h-10">
        {addNewMode ? (
          <>
            <input
              ref={inputRef}
              className="h-6 w-full border-0 focus:border-0 focus:ring-0 focus:ring-offset-0 px-4 py-4 m-0"
              type="text"
              value={newDeviceName}
              onChange={(e) => setNewDeviceName(e.target.value)}
            ></input>
            <button
              className="p-4"
              onClick={() => {
                setAddNewMode(false)
                setNewDeviceName("")
              }}
            >
              <Close />
            </button>
          </>
        ) : (
          <button
            id="button_devices_dropdown"
            className="w-full flex py-2 px-4"
            onClick={() => {
              if (!addNewMode) setIsOpen(!isOpen)
            }}
          >
            {selectedDeviceName ? (
              <p className="w-full text-start">{selectedDeviceName}</p>
            ) : (
              <div className="w-full"></div>
            )}
            <FontAwesomeIcon
              className="text-one-gray-500"
              icon={isOpen ? faCaretUp : faCaretDown}
              size="lg"
            />
          </button>
        )}
      </div>
      {isOpen && (
        <div className="flex flex-col mt-2 overflow-auto max-h-64 ring-1 ring-one-gray-300 rounded-md shadow-md">
          <button
            className="w-full py-2 px-4 flex justify-center items-center border-b border-one-gray-300 hover:bg-one-gray-100"
            onClick={() => {
              setAddNewMode(true)
              setSelectedDeviceId(null)
              setSelectedDeviceName("")
              setIsOpen(false)
            }}
          >
            <FontAwesomeIcon
              className="text-one-gray-500 mr-4"
              icon={faPlus}
              size="lg"
            />
            {translate("pages.spaces.addNewDisplay")}
          </button>
          {filteredDevices?.map((device) => (
            <button
              key={device.id}
              className="w-full py-2 px-4 flex justify-between items-center hover:bg-one-gray-100"
              onClick={() => {
                setSelectedDeviceName(device.name)
                setSelectedDeviceId(device.id)
                setIsOpen(false)
              }}
            >
              <div>{device.name}</div>
            </button>
          ))}
        </div>
      )}
      {addNewMode && (
        <p className="text-one-gray-500 mt-2">
          {translate("pages.spaces.newDisplayWorkspaceDescription1")}
          <span className="text-one-gray-950 font-medium">
            {workspaceData?.data?.name}
          </span>
          {translate("pages.spaces.newDisplayWorkspaceDescription2")}
        </p>
      )}
    </div>
  )
}
