import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  useOne,
  useQueryClient,
  useTranslate,
  useUpdate
} from "@pankod/refine-core"
import { ColumnDef, Row, useTable } from "@pankod/refine-react-table"
import { Table } from "components/table/table"
import { useLocalStorage } from "hooks/useLocalStorage"
import { IHost, IVisit, IVisitor } from "interfaces"
import { useAppSelector } from "reduxStore/hooks"
import { ExportVisitsModal } from "components/modal/VisitsExportModal"
import { VisitTableDateFilterModal } from "components/modal/VisitTableDateFilterModal"
import { TableButton } from "components/table/TableButton"
import { usePagination } from "hooks/usePagination"
import {
  INF_SCROLL_QUERY_KEY_TYPES,
  LOCAL_STORAGE_KEYS,
  VisitState
} from "utilities/types"
import { faCalendarDays, faFileExport } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  HostNotify,
  QuickNotificationModal
} from "components/modal/QuickNotificationModal"
import { PersonPictureModal } from "modules/Person/PersonPictureModal"
import { VisitorStateCell } from "modules/Visit/Table/Cells/VisitorStateCell"
import { VisitPrintBadgeModal } from "components/modal/VisitPrintBadgeModal"
import { setIsPrintVisitBadgeOpen } from "reduxStore/reducers/visitPrintBadgeReducer"
import {
  VisitorStateFilterMultiple,
  VisitorStateFilters,
  findVisitorStateFiltersByIds
} from "modules/Visit/VisitorStateFilters"
import { VisitDayDropdown } from "components/dropdown/VisitDayDropdown"
import dayjs from "dayjs"
import {
  formatVisitDateTime,
  isOverdueVisit
} from "modules/Visit/visitDateTimeFormatter"
import { REFETCH_INTERVAL_IN_MS, USER_ID_KEY } from "utilities/constants"
import { VisitDeleteModal } from "modules/Visit/VisitDeleteModal"
import { VisitorCell } from "modules/Visit/Table/Cells/VisitorCell"
import { SignedInCell } from "modules/Visit/Table/Cells/VisitorSignedInCell"
import { SignedOutCell } from "modules/Visit/Table/Cells/VisitorSignedOutCell"
import { HostCell } from "modules/Visit/Table/Cells/HostCell"
import { ActionsCell } from "modules/Visit/Table/Cells/ActionsCell"
import { getTimeSpanText } from "modules/Visit/visitDateTime"
import { EditVisitModal } from "modules/Visit/editVisit/EditVisitModal"
import customToast from "utilities/toastHelper"
import { LOG, Logger } from "utilities/logger"
import { VisitUpdateRequest } from "types"
import { useHasPermission } from "utilities/permissionSelector"
import { IS_PREVIEW_MODE } from "utilities/development"
import { handleAxiosError } from "modules/Visit/addVisitErrorHandler"
import useSendNotificationUpdate from "hooks/useSendNotificationUpdate"

export const Visits: React.FC = () => {
  const translate = useTranslate()

  const queryClient = useQueryClient()

  const [columnVisibility, setLocalStorage]: [any, (arg0: any) => void] =
    useLocalStorage(LOCAL_STORAGE_KEYS.VISITS, {})

  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const showPrint = useAppSelector((state) => state.showPrint)
  const {
    today,
    tomorrow,
    customDate,
    customStartDatetime,
    customEndDatetime,
    yesterday
  } = useAppSelector((state) => state.visitTableFilters)

  const [filterDateModal, setFilterDatesModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedVisitId, setSelectedVisitId] = useState<number>()
  const [selectedVisitorId, setSelectedVisitorId] = useState<number>()
  const [showQuickNotificationModal, setShowQuickNotificationModal] =
    useState<boolean>(false)
  const [visitHost, setVisitHost] = useState<HostNotify>()
  const [showPictureModal, setShowPictureModal] = useState(false)
  const [selectedPicture, setSelectedPicture] = useState<string>()
  const [showExportVisitsModal, setShowExportVisitsModal] = useState(false)
  const [selectedVisitorStateFilter, setSelectedVisitorStateFilter] = useState(
    findVisitorStateFiltersByIds([
      VisitState.signedIn,
      VisitState.signedOut,
      VisitState.expected
    ])
  )

  useEffect(() => {
    // Remove visits query if selected workspace is changed
    queryClient.removeQueries([INF_SCROLL_QUERY_KEY_TYPES.VISITS])
  }, [selectedWorkspaceId])

  const {
    data: visitorData,
    isLoading: isLoadingVisitor,
    isError
  } = useOne<IVisitor>({
    resource: `visits/${selectedVisitId}/visitors/${selectedVisitorId}`,
    id: "",
    queryOptions: {
      enabled: !!(selectedVisitId && selectedVisitorId && visitHost)
    }
  })

  const columns = React.useMemo<ColumnDef<IVisit>[]>(
    () => [
      {
        id: "state",
        header: "State",
        accessorKey: "visitor",
        cell: ({ getValue }) => {
          return VisitorStateCell(getValue<IVisitor | null>())
        }
      },
      {
        id: "visitor",
        header: "Visitor",
        accessorKey: "visitor",
        cell: ({ getValue, row }) => {
          return VisitorCell(
            getValue<IVisitor | null>(),
            row,
            setSelectedPicture,
            setShowPictureModal
          )
        }
      },
      {
        id: "host",
        header: "Host",
        accessorKey: "host",
        cell: ({ getValue }) => {
          return HostCell(
            getValue<IHost>(),
            setSelectedPicture,
            setShowPictureModal
          )
        }
      },
      {
        id: "ETA",
        header: "ETA",
        accessorKey: "visitor",
        cell: ({
          row: {
            original: { start_time, visitor }
          }
        }) => {
          if (visitor?.state?.name === "expected") {
            return (
              <div>
                <p className="font-medium truncate">
                  {formatVisitDateTime(start_time)}
                </p>
                <p className="text-gray-500">
                  {dayjs(start_time).format("DD.MM.YYYY HH:mm")}
                </p>
              </div>
            )
          } else {
            return <></>
          }
        }
      },
      {
        id: "signed_in",
        header: "Signed In",
        accessorKey: "start_time",
        cell: ({
          row: {
            original: { visitor, visitors_count }
          }
        }) => {
          if (visitor?.state?.name === "expected" || visitors_count > 1) {
            return <></>
          }

          return SignedInCell(visitor?.sign_in?.time as string)
        }
      },
      {
        id: "signed_out",
        header: "Signed Out",
        accessorKey: "visitor",
        cell: ({
          getValue,
          row: {
            original: { visitors_count }
          }
        }) => {
          const visitor = getValue<IVisitor | null>()
          if (visitor?.state?.name === "expected" || visitors_count > 1) {
            return <></>
          }

          return SignedOutCell(visitor)
        }
      },
      {
        id: "Action",
        enableResizing: true,
        enableGrouping: true,
        enableHiding: true,
        header: "Actions",
        accessorKey: "action",
        cell: ({ row }) => {
          return ActionsCell(
            row,
            handleOpenEditVisitModal,
            setShowDeleteModal,
            setSelectedVisitId,
            setSelectedVisitorId,
            showQuickNotificationModal,
            setShowQuickNotificationModal,
            setVisitHost
          )
        }
      }
    ],
    []
  )

  const [visits, page, isLoading, fetchNextPage, hasNextPage, isFetching] =
    usePagination<IVisit>(
      `workspaces/${selectedWorkspaceId}/visits`,
      "",
      `${
        selectedVisitorStateFilter && selectedVisitorStateFilter.length > 0
          ? `visitor__state_id__in=${selectedVisitorStateFilter
              ?.map(
                (visitorStateFilter, _visitorStateFilterIndex) =>
                  visitorStateFilter?.id
              )
              .join(",")}`
          : ""
      }${today ? `&${today}` : ""}${tomorrow ? `&${tomorrow}` : ""}${
        customDate ? `&${customDate}` : ""
      }${yesterday ? `&${yesterday}` : ""}`,
      INF_SCROLL_QUERY_KEY_TYPES.VISITS,
      REFETCH_INTERVAL_IN_MS,
      true
    )

  const isExpectedVisit = (visit: IVisit) =>
    visit?.visitor?.state.name === "expected"
  const isOverdue = (visit: IVisit) => isOverdueVisit(visit.start_time)

  const filteredVisits = useMemo(() => {
    return visits?.filter((visit) => {
      return !(isExpectedVisit(visit) && isOverdue(visit))
    })
  }, [visits])

  const { getHeaderGroups, getRowModel, getAllColumns } = useTable<any>({
    columns,
    data: filteredVisits,
    onColumnVisibilityChange: (state) => {
      setLocalStorage(state)
    },
    state: {
      columnVisibility
    }
  })

  const selectedVisit = useMemo(
    () => filteredVisits?.find((v) => v.id === selectedVisitId),
    [filteredVisits, selectedVisitId]
  )

  const [showEditVisitModal, setShowEditVisitModal] = useState(false)

  const { mutateAsync: updateVisit } = useUpdate<IVisit>()

  const { mutateAsync: sendNotificationUpdateAsync } =
    useSendNotificationUpdate()

  const hasEditVisitPermissions = useHasPermission("Visit", "ReadWrite")

  const handleOpenEditVisitModal = useCallback(
    (row: Row<any>) => {
      if (!IS_PREVIEW_MODE || !hasEditVisitPermissions) {
        return
      }

      setSelectedVisitId((row.original as IVisit).id)
      setShowEditVisitModal(true)
    },
    [hasEditVisitPermissions, setSelectedVisitId, setShowEditVisitModal]
  )

  const handleSaveVisit = useCallback(
    async (visitId: number, data: VisitUpdateRequest) => {
      try {
        await updateVisit(
          {
            resource: "visits",
            id: visitId,
            values: data
          },
          {
            onSuccess: () => {
              customToast.success(
                translate("table.visits.editVisitModal.updateVisitSuccess")
              )
              setShowEditVisitModal(false)
              void queryClient.invalidateQueries([
                INF_SCROLL_QUERY_KEY_TYPES.VISITS
              ])
            },
            onError: (error) => {
              customToast.error(
                translate("table.visits.editVisitModal.updateVisitError")
              )
              void Logger().error(LOG.EDIT_VISIT, `${error.stack}`)
            }
          }
        )

        await sendNotificationUpdateAsync({
          request: {
            visitId: visitId,
            data: data,
            userId: localStorage.getItem(USER_ID_KEY) || ""
          }
        })
      } catch (error) {
        handleAxiosError(error, selectedWorkspaceId)
      }
    },
    [translate, updateVisit, setShowEditVisitModal]
  )

  return (
    <>
      <VisitDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        visitId={selectedVisitId}
      />
      <VisitPrintBadgeModal
        open={!isLoadingVisitor && showPrint && !isError}
        setOpen={setIsPrintVisitBadgeOpen}
        visitId={selectedVisitId}
        visitorId={selectedVisitorId}
        visitorData={visitorData?.data}
        visitHost={visitHost}
      />
      <PersonPictureModal
        showPictureModal={showPictureModal}
        setShowPictureModal={setShowPictureModal}
        selectedPicture={selectedPicture}
      />
      {showQuickNotificationModal && (
        <QuickNotificationModal
          title="Host Notification"
          notifyHost={visitHost}
          open={showQuickNotificationModal}
          onClose={() => setShowQuickNotificationModal(false)}
        />
      )}
      <ExportVisitsModal
        showExportVisitsModal={showExportVisitsModal}
        setShowExportVisitsModal={setShowExportVisitsModal}
      />
      {IS_PREVIEW_MODE &&
        hasEditVisitPermissions &&
        showEditVisitModal &&
        selectedVisit && (
          <EditVisitModal
            open={showEditVisitModal}
            onClose={() => {
              setShowEditVisitModal(false)
            }}
            visit={selectedVisit}
            onSaveVisitClick={(visitId, data: VisitUpdateRequest) => {
              void handleSaveVisit(visitId, data)
            }}
          />
        )}
      <Table
        page={page}
        header={translate("pages.flow.flow")}
        getAllColumns={getAllColumns}
        getHeaderGroups={getHeaderGroups}
        getRowModel={getRowModel}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        leftButtons={[
          <VisitDayDropdown />,
          <div className="relative mr-2" key="div_custom_date">
            {filterDateModal && (
              <VisitTableDateFilterModal showModal={setFilterDatesModal} />
            )}
            <TableButton
              active={customDate}
              onClick={() => setFilterDatesModal(!filterDateModal)}
            >
              <>
                <p className="pr-2">
                  {customDate
                    ? getTimeSpanText(customStartDatetime, customEndDatetime)
                    : translate("buttons.chooseDates")}
                </p>
                <FontAwesomeIcon icon={faCalendarDays} />
              </>
            </TableButton>
          </div>,
          <VisitorStateFilters
            key={"visitor_state_filters "}
            selectedVisitorStateFilter={
              selectedVisitorStateFilter as VisitorStateFilterMultiple
            }
            setSelectedVisitorStateFilter={setSelectedVisitorStateFilter}
          />
        ]}
        rightButtons={[
          <TableButton
            key="export_visits_button"
            onClick={() => {
              setShowExportVisitsModal(true)
            }}
          >
            <div>
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              <span>Export</span>
            </div>
          </TableButton>
        ]}
        isLoading={isLoading}
        isFetching={isFetching}
        hasDetails={true}
        onEditEntry={handleOpenEditVisitModal}
      />
    </>
  )
}
