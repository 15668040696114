import {
  useUpdatePassword,
  useNavigation,
  useTranslate
} from "@pankod/refine-core"
import { useState, useCallback } from "react"
import customToast from "utilities/toastHelper"
import { LOG, Logger } from "utilities/logger"
import ErrorText, { SuccessText } from "components/formMessage"
import greetMeetRepeat from "assets/images/systam_greet_meet_repeat.svg"
import { Logo } from "components/Logo"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import {
  FooterContentItem,
  FooterContentItemSeparator
} from "components/footer/Footer"
import { useFeatureFlagEnabled } from "posthog-js/react"
import { MIN_PASSWORD_LENGTH } from "utilities/passwordValidator"

export const UpdatePasswordPage = () => {
  const translate = useTranslate()
  const { mutate: updatePassword } = useUpdatePassword()
  const { replace } = useNavigation()

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [updateError, setUpdateError] = useState<string | undefined>()
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const showTermsOfServiceLink =
    useFeatureFlagEnabled("show-terms-of-service-link") ?? false

  const validateField = (field: string, value: string): string | undefined => {
    if (!value) {
      return `Please fill ${field} field.`
    }
    if (value.length < MIN_PASSWORD_LENGTH) {
      return `${field} must be at least 8 characters long.`
    }
    return undefined
  }

  const validate = () => {
    const errors: Record<string, string | undefined> = {}
    errors["newPassword"] = validateField("newPassword", newPassword)
    errors["confirmPassword"] = validateField(
      "confirmPassword",
      confirmPassword
    )

    if (newPassword !== confirmPassword) {
      errors["confirmPassword"] = translate(
        "notifications.passwordDoesNotMatch"
      )
    }

    return errors
  }

  const handleUpdatePassword = () => {
    const errors = validate()
    const hasError = Object.values(errors).some((e) => e !== undefined)
    setUpdateError(
      hasError ? errors["confirmPassword"] ?? errors["newPassword"] : undefined
    )

    if (hasError) {
      customToast.error(
        updateError ?? translate("notifications.passwordTokenExpired")
      )
      return
    }

    updatePassword(
      {
        password: newPassword,
        confirmPassword: confirmPassword
      },
      {
        onSuccess: () => {
          setUpdateError("")
          setUpdateSuccess(true)
          customToast.success(translate("notifications.passwordChanged"))
          void Logger().log(LOG.CHANGE_PASSWORD_SUCCESS)
        },
        onError: () => {
          setUpdateSuccess(false)
          setUpdateError(translate("notifications.passwordTokenExpired"))
          customToast.error(translate("notifications.passwordTokenExpired"))
        }
      }
    )
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && newPassword && confirmPassword) {
      handleUpdatePassword()
    }
  }

  const handleKeyPressCallback = useCallback(handleKeyPress, [
    newPassword,
    confirmPassword
  ])

  return (
    <div className="flex flex-col lg:flex-row w-full min-h-full">
      <div className="my-36 flex flex-col w-full lg:w-1/2 p-8 lg:p-16">
        <div className="flex justify-between items-center h-20">
          {/* LanguageDropdown can be added here if needed */}
        </div>
        <div className="flex flex-grow flex-col items-center justify-start">
          <div className="w-full lg:w-full max-w-md">
            <Logo className="h-10" />
            <h1 className="font-dmsans font-medium text-3xl mb-6 mt-8">
              {translate("pages.updatePassword.send")}
            </h1>
            <div className="space-y-6">
              <div>
                <label htmlFor="newPassword" className="font-medium">
                  {translate("pages.updatePassword.newPassword")}
                </label>
                <div className="relative">
                  <input
                    id="newPassword"
                    type={passwordVisible ? "text" : "password"}
                    name="newPassword"
                    placeholder={translate(
                      "pages.updatePassword.newPasswordPlaceholder"
                    )}
                    className="mt-2 block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyDown={handleKeyPressCallback}
                    tabIndex={1}
                    data-testid="newPassword"
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setPasswordVisible(!passwordVisible)
                    }}
                    className="absolute top-1/2 right-3 transform -translate-y-1/2"
                    tabIndex={1}
                    data-testid="toggleNewPasswordVisibility"
                  >
                    {passwordVisible ? (
                      <EyeSlashIcon className="h-6 w-6 hover:text-one-rose-400" />
                    ) : (
                      <EyeIcon className="h-6 w-6 hover:text-one-rose-400" />
                    )}
                  </button>
                </div>
              </div>
              <div>
                <label htmlFor="confirmPassword" className="font-medium">
                  {translate("pages.updatePassword.confirmNewPassword")}
                </label>
                <div className="relative">
                  <input
                    id="confirmPassword"
                    type={confirmPasswordVisible ? "text" : "password"}
                    name="confirmPassword"
                    placeholder={translate(
                      "pages.updatePassword.confirmNewPasswordPlaceholder"
                    )}
                    className="mt-2 block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={handleKeyPressCallback}
                    tabIndex={2}
                    data-testid="confirmPassword"
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      setConfirmPasswordVisible(!confirmPasswordVisible)
                    }}
                    className="absolute top-1/2 right-3 transform -translate-y-1/2"
                    tabIndex={2}
                    data-testid="toggleConfirmPasswordVisibility"
                  >
                    {confirmPasswordVisible ? (
                      <EyeSlashIcon className="h-6 w-6 hover:text-one-rose-400" />
                    ) : (
                      <EyeIcon className="h-6 w-6 hover:text-one-rose-400" />
                    )}
                  </button>
                </div>
              </div>
              <div className="items-center justify-between">
                <button
                  onClick={handleUpdatePassword}
                  className="flex w-full justify-center rounded-lg bg-one-rose-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-one-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-400"
                  tabIndex={3}
                  data-testid="submitButton"
                >
                  {translate("pages.updatePassword.send")}
                </button>
              </div>
              <div className="my-4">
                {updateSuccess &&
                  SuccessText(translate("notifications.passwordChanged"))}
                {updateError && ErrorText(updateError)}
              </div>
              <div className="flex items-center py-2">
                <p>{translate("pages.login.backTo")}</p>
                <button
                  className="ml-2 font-medium text-one-rose-400 hover:text-one-rose-500"
                  onClick={() => replace("/login")}
                  tabIndex={4}
                  data-testid="backToLogin"
                >
                  {translate("pages.login.login")}
                </button>
              </div>
            </div>
            <div className="mt-44 flex justify-start items-center space-x-4">
              <FooterContentItem href="https://systam.io">
                {translate("footer.about")}
              </FooterContentItem>
              <FooterContentItemSeparator />
              {showTermsOfServiceLink && (
                <FooterContentItem href="https://app.systam.io/terms-of-service">
                  {translate("footer.termsOfService")}
                </FooterContentItem>
              )}
              <FooterContentItemSeparator />
              <FooterContentItem href="https://systam.io/service-privacy-policy/">
                {translate("footer.privacyPolicy")}
              </FooterContentItem>
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full lg:w-1/2 h-64 lg:h-full hidden lg:block">
        <div className="h-full w-full">
          <img
            alt="Systam"
            className="inset-0 h-full w-full object-cover"
            src={greetMeetRepeat}
            data-testid="greetMeetRepeatImage"
          />
        </div>
      </div>
    </div>
  )
}
