import React from "react"
import { Visits } from "./visits/list"
import { CanAccess, useCan } from "@pankod/refine-core"
import { FallbackUpselling } from "components/FallbackUpselling"
import { DeviceList } from "./devices"

export const Dashboard: React.FC = () => {
  const { data: canAccessVisitModule } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  return (
    <CanAccess
      resource={canAccessVisitModule?.can ? "visits" : "displays"}
      action="list"
      params={{
        resource: {
          name: "",
          options: {
            name: "",
            modules: canAccessVisitModule?.can ? ["visit"] : ["rooms"]
          }
        }
      }}
      fallback={<FallbackUpselling />}
    >
      {canAccessVisitModule?.can ? <Visits /> : <DeviceList isRooms={true} />}
    </CanAccess>
  )
}
