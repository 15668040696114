import { useTranslate } from "@pankod/refine-core"
import { useCallback, useEffect, useState } from "react"
import { HTTP_STATUS_NOT_FOUND, MAX_PAGE } from "utilities/constants"
import { IContactDetail, IVisits, IVisit } from "interfaces"
import { axiosInstance } from "utilities/dataProvider"
import { ClipLoader } from "react-spinners"
import customToast from "utilities/toastHelper"
import axios from "axios"
import { splitDateTime } from "utilities/date"
import { Logger, LOG } from "utilities/logger"
import { Dialog } from "@headlessui/react"

export const ExportVisitsModal = ({
  showExportVisitsModal,
  setShowExportVisitsModal
}: {
  showExportVisitsModal: boolean
  setShowExportVisitsModal: (showExportVisitsModal: boolean) => void
}) => {
  const translate = useTranslate()
  const date = new Date()
  const dateNow = date.toISOString().split("T")[0]
  const [startDate, setStartDate] = useState(dateNow)
  const [endDate, setEndDate] = useState(dateNow)
  const [startTime, setStartTime] = useState("00:00")
  const [endTime, setEndTime] = useState("23:59")
  const [startFetch, setStartFetch] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [visits, setVisits] = useState<IVisits>({
    items: [],
    total: 100,
    page: 1,
    size: MAX_PAGE
  })

  /**
   * NOTE: This is a recursive useEffect, that loops untill all pages has been fetched
   */
  useEffect(() => {
    const fetchVisits = async () => {
      if (!startFetch) return
      try {
        setLoading(true)
        const url = `/visits?page=${visits.page}&size=${MAX_PAGE}&start_time__gte=${startDate}T${startTime}&start_time__lt=${endDate}T${endTime}`

        const { data }: { data: IVisits } = await axiosInstance.get(url)

        if (visits.items.length >= visits.total) {
          setVisits({
            page: 1,
            total: 100,
            items: [],
            size: MAX_PAGE
          })
          setStartFetch(false)
          exportCSV(visits.items)
          setLoading(false)
          return
        }

        setVisits({
          ...visits,
          page: visits.page + 1,
          total: data.total,
          items: [...visits.items, ...data.items]
        })
      } catch (e) {
        void Logger().error(LOG.EXPORT_VISITS, `${e}`)
        if (
          axios.isAxiosError(e) &&
          e.response &&
          e.response.status === HTTP_STATUS_NOT_FOUND
        ) {
          customToast.error(translate("notifications.visitsNotFound"))
        } else {
          customToast.error(translate("pages.error.something_wrong"))
        }

        setStartFetch(false)
        setLoading(false)
      }
    }
    void fetchVisits()
  }, [startFetch, visits])

  const exportCSV = (data: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    let rows: string[][] = data.map((obj: IVisit) => {
      const { start_time, end_time, visitor, host } = obj

      const [startDateSplit, startTimeSplit] = splitDateTime(start_time)

      const [endDateSplit, endTimeSplit] = splitDateTime(end_time)

      const { first_name: visitorName, last_name: visitorLastname } =
        visitor?.details || {}

      const {
        first_name: hostName,
        last_name: hostLastname,
        contact_details
      } = host?.details || {}

      const hostPhone = contact_details?.find(
        (contactDetail: IContactDetail) => contactDetail.type === "Phone"
      )?.value

      const hostEmail = contact_details?.find(
        (contactDetail: IContactDetail) => contactDetail.type === "Email"
      )?.value

      return [
        startDateSplit,
        startTimeSplit,
        endDateSplit,
        endTimeSplit,
        visitorName,
        visitorLastname,
        hostName,
        hostLastname,
        hostPhone,
        hostEmail
      ]
    })

    const CSV_HEADERS = [
      "Start Date",
      "Start Time",
      "End Date",
      "End Time",
      "Visitor Firstname",
      "Visitor Familyname",
      "Host Firstname",
      "Host Familyname",
      "Host Phone",
      "Host Email"
    ]

    rows = [CSV_HEADERS, ...rows]

    const csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n")

    const encodedLUL = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedLUL)
    link.setAttribute("download", "visits_data.csv")
    document.body.appendChild(link)

    link.click()
  }

  const handleCancelCallback = useCallback(() => {
    setShowExportVisitsModal(false)
  }, [setShowExportVisitsModal])

  return (
    <Dialog
      as="div"
      className="relative z-50"
      open={showExportVisitsModal}
      onClose={() => setShowExportVisitsModal(false)}
    >
      <div
        className="fixed inset-0 bg-systam-dark opacity-70"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-52">
        <Dialog.Panel className="w-full max-w-md transform bg-white overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
          <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
            {translate("modal.exportVisit")}
          </div>
          <div className="mt-10">
            <div className="flex flex-col mb-6">
              <label className="mb-1 text-sm sm:text-sm tracking-wide text-gray-600">
                {translate("startDate")}
              </label>
              <div className="relative">
                <input
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-systam-blue"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col mb-6">
              <label className="mb-1 text-sm sm:text-sm tracking-wide text-gray-600">
                {translate("endDate")}
              </label>
              <input
                className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-systam-blue"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="flex w-full justify-between mb-16">
            <div className="flex flex-col">
              <label className="mb-1 text-sm sm:text-sm tracking-wide text-gray-600">
                {translate("starts")}
              </label>
              <input
                className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-systam-blue"
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1 text-sm sm:text-sm tracking-wide text-gray-600">
                {translate("ends")}
              </label>
              <input
                className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-systam-blue"
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="flex justify-between mt-6">
            <button
              onClick={() => {
                handleCancelCallback()
              }}
              className="items-center justify-center focus:outline-none text-sm sm:text-base font-medium bg-gray-200 hover:bg-gray-300 rounded py-2 w-1/5 transition duration-150 ease-in border border-transparent text-gray-600 px-5 mr-2"
            >
              <span className="mr-2">{translate("buttons.cancel")}</span>
            </button>
            <button
              onClick={() => {
                setStartFetch(true)
              }}
              className="items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-systam-blue hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
            >
              {isLoading ? (
                <ClipLoader
                  color="#fff"
                  loading={true}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <>
                  <span className="mr-2 uppercase">
                    {translate("buttons.download")}
                  </span>
                </>
              )}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
