import { axiosInstance } from "utilities/dataProvider"
import { INTEGRATION_TYPES } from "utilities/types"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

export type ActivateCalendarRequest = {
  integration_type: INTEGRATION_TYPES
  username?: string
  id_token?: string
  code?: string
}

type ValidationError = {
  loc: string[]
  msg: string
  type: string
}

type ValidationErrorResponse = {
  detail: ValidationError[]
}

const activateCalendar = async ({
  code,
  integration_type
}: ActivateCalendarRequest): Promise<string> => {
  try {
    const response = await axiosInstance.post<
      ActivateCalendarRequest,
      AxiosResponse<string>
    >("/calendars/activate", {
      code,
      integration_type
    })
    return response.data
  } catch (error) {
    const axiosError = error as AxiosError<ValidationErrorResponse>
    if (axiosError?.response?.status === 422) {
      const validationErrors = axiosError.response.data.detail
      console.error("Validation errors:", validationErrors)
    }
    throw new Error(axiosError.message)
  }
}

const useActivateCalendar = (): UseMutationResult<
  string,
  AxiosError<ValidationError>,
  ActivateCalendarRequest,
  unknown
> => {
  return useMutation(activateCalendar)
}

export default useActivateCalendar
