import { useCreate, useTranslate } from "@pankod/refine-core"
import { UseModalFormReturnType } from "@pankod/refine-react-hook-form"
import { Modal } from "../../components/Modal"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import ErrorText from "components/formMessage"
import validators from "utilities/formValidator"
import { axiosInstance, axiosNoUrl } from "utilities/dataProvider"
import { isEmptyString } from "utilities/string"
import { useQueryClient } from "@tanstack/react-query"
import { IMandatoryFields } from "interfaces"
import { Button, ButtonStyle } from "components/globals/Button"
import { Input } from "components/globals/Input"
import { useAppSelector } from "reduxStore/store"
import Switch from "react-switch"
import { UserIcon } from "@heroicons/react/24/outline"

export const CreateHost: React.FC<UseModalFormReturnType> = ({
  refineCore: { formLoading },
  modal: { visible, close }
}) => {
  const translate = useTranslate()
  const { mutate } = useCreate()

  // TODO: Set these values somewhere more global
  const mandatoryFields: IMandatoryFields = {
    firstName: true,
    lastName: true,
    phone: false,
    email: true
  }

  const workspaceId = useAppSelector((state) => state.workspace)

  const resetFormData = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setPicture("")
    setLanguageId("")
    setErrors([])
    setBucketImage("")
    setPreview("")
    setSendEmail(false)
    setSendSms(false)
  }

  const [errors, setErrors] = useState<string[]>([])
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [picture, setPicture] = useState("")
  const [languageId, setLanguageId] = useState("")
  const [preview, setPreview] = useState("")
  const [bucketImage, setBucketImage] = useState("")
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  )
  const [sendEmail, setSendEmail] = useState(true)
  const [sendSms, setSendSms] = useState(false)

  const inputFile = useRef<HTMLInputElement | null>(null)
  const queryClient = useQueryClient()
  const openFileBrowser = () => {
    // `current` points to the mounted file input element
    inputFile.current?.click()
  }

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    const target = event.target
    const file = target.files?.[0]

    setSelectedImage(file)
  }

  const cancelPreview = () => {
    setPreview("")
  }

  const deletePhoto = () => {
    setPicture("")
    setBucketImage("")
    setPreview("")
    setSelectedImage(undefined)
  }

  useEffect(() => {
    const uploadPhoto = async () => {
      let publicUrl = ""

      const getSignedUrl = async () => {
        if (selectedImage !== undefined) {
          try {
            const res = await axiosInstance.post(
              `workspaces/${workspaceId}/hosts/signed-url`,
              {
                blob: selectedImage?.name
              }
            )
            const signedUrl: string = res.data.signed_url
            publicUrl = res.data.public_url

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            void axiosNoUrl.put(signedUrl, selectedImage, {
              headers: {
                "Content-type": selectedImage.type
              }
            })
          } catch (error) {
            void Logger().error(LOG.SIGNED_URL, `${error}`)
            customToast.error(translate("notifications.fileUploadError"))
          }
        }
      }

      await getSignedUrl()

      setBucketImage(publicUrl)
    }

    if (!selectedImage) {
      setPreview("")
    } else if (selectedImage !== undefined) {
      const objectUrl = URL.createObjectURL(selectedImage)
      setPreview(objectUrl)
      void uploadPhoto()

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [selectedImage])

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    void Logger().log(LOG.CREATE_HOST)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const pic = !isEmptyString(bucketImage)
      ? bucketImage
      : e.target["picture"].value

    if (validate()) {
      mutate(
        {
          resource: `hosts`,
          values: {
            workspace_id: workspaceId,
            first_name: e.target["firstName"].value,
            last_name: e.target["lastName"].value,
            email: [
              {
                primary: true,
                notifications: sendEmail,
                value: e.target["email"].value
              }
            ],
            phone: [
              {
                primary: true,
                notifications: sendSms,
                value: e.target["phone"].value
              }
            ],
            picture: pic,
            language_id: e.target["languageId"].value
          }
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.createError", {
                resource: translate("resources.host")
              })
            )
            void Logger().error(LOG.CREATE_HOST, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            customToast.success(
              translate("notifications.createSuccess", {
                resource: translate("resources.host")
              })
            )
            resetFormData()
            close()
          },
          onSettled(data, error, variables, context) {
            setTimeout(() => void queryClient.invalidateQueries(), 200)
          }
        }
      )
    }
  }

  /**
   * Validate fields
   * @returns True if no errors
   */
  function validate(): boolean {
    const validationErrors = validators.handleValidation(
      firstName,
      lastName,
      phone,
      email,
      mandatoryFields
    )
    setErrors(validationErrors)
    return validationErrors["firstName"] ||
      validationErrors["lastName"] ||
      validationErrors["email"] ||
      validationErrors["phone"]
      ? false
      : true
  }

  const handleInputChange = (e) => {
    const { id, value } = e.target
    if (id === "firstName") {
      setFirstName(value as string)
    }
    if (id === "lastName") {
      setLastName(value as string)
    }
    if (id === "email") {
      setEmail(value as string)
    }
    if (id === "phone") {
      setPhone(value as string)
    }
    if (id === "picture") {
      setPicture(value as string)
    }
    if (id === "languageId") {
      setLanguageId(value as string)
    }
  }

  return (
    <Modal
      title={translate("pages.hosts.createHost")}
      isOpen={visible}
      onClose={close}
    >
      <form className="form" onSubmit={onSubmit}>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={(e) => onChangeFile(e)}
        />
        <div className="flex flex-col gap-2 px-6">
          <div>
            <div className="flex flex-col gap-4 pb-6">
              <div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-1 items-center justify-center">
                    {preview || picture ? (
                      <img
                        alt="hostpic"
                        width="96"
                        height="96"
                        className="rounded-full object-cover aspect-square"
                        src={preview ? preview : picture}
                      ></img>
                    ) : (
                      <div>
                        <UserIcon className="h-14 w-14 text-systam-blue" />
                      </div>
                    )}
                  </div>
                  <div className="flex flex-1 items-center justify-start">
                    <div className="flex-col">
                      <div>
                        <Button
                          type="button"
                          onClick={openFileBrowser}
                          style={ButtonStyle.BluePrimary}
                          name={
                            !preview && !picture
                              ? translate("form.addPhoto")
                              : translate("form.changePhoto")
                          }
                        />
                      </div>
                      {picture && !preview && (
                        <div>
                          <Button
                            type="button"
                            onClick={deletePhoto}
                            style={ButtonStyle.TransparentNoBorder}
                            name={translate("form.deletePhoto")}
                          />
                        </div>
                      )}
                      {preview && (
                        <>
                          <div>
                            <Button
                              type="button"
                              style={ButtonStyle.TransparentBorder}
                              onClick={cancelPreview}
                              name={translate("buttons.cancel")}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between gap-4">
            <div className="flex flex-1">
              <Input
                label={translate("form.firstName")}
                placeholder={translate("form.firstName")}
                id="firstName"
                type="text"
                value={firstName}
                required={mandatoryFields.firstName}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div className="flex flex-1">
              <Input
                label={translate("form.lastName")}
                placeholder={translate("form.lastName")}
                id="lastName"
                type="text"
                value={lastName}
                required={mandatoryFields.lastName}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between gap-4 -mt-1">
            <div className="flex flex-1">
              {errors["firstName"] && ErrorText(errors["firstName"])}
            </div>
            <div className="flex flex-1">
              {errors["lastName"] && ErrorText(errors["lastName"])}
            </div>
          </div>
          <div>
            <Input
              label={translate("form.email")}
              placeholder={translate("form.email")}
              id="email"
              type="text"
              value={email}
              required={mandatoryFields.email}
              onChange={(e) => handleInputChange(e)}
            />
            <div className="pt-1">
              {errors["email"] && ErrorText(errors["email"])}
            </div>
          </div>
          <div>
            <Input
              label={translate("form.phone")}
              placeholder={translate("form.phone")}
              id="phone"
              type="text"
              value={phone}
              required={mandatoryFields.phone}
              onChange={(e) => handleInputChange(e)}
            />
            <div className="pt-1">
              {errors["phone"] && ErrorText(errors["phone"])}
            </div>
          </div>
          <div>
            <input
              type="text"
              value={picture}
              onChange={(e) => handleInputChange(e)}
              id="picture"
              placeholder={translate("form.pictureUrl")}
              style={{ display: "none" }}
            />
          </div>
          <div>
            <label className="text-s pt-1 !text-neutral-800">
              {translate("form.selectedLanguage")}
            </label>
            <select
              className="ring-1 ring-gray-300 ring-inset rounded-md focus:ring-2 focus:ring-systam-blue focus:outline-none"
              id="languageId"
              value={languageId}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="" disabled selected hidden>
                {translate("form.selectedLanguage")}
              </option>
              <option value="1">{translate("languages.finnish")}</option>
              <option value="2">{translate("languages.english")}</option>
            </select>
          </div>
          <div className="flex justify-between mt-4">
            <label className="text-s pt-1 !text-neutral-800">
              {translate("pages.hosts.enableSmsNotification")}
            </label>
            <Switch
              onChange={() => setSendSms(!sendSms)}
              checked={sendSms}
              offColor="#D9D9D9"
              onColor="#34D399"
              onHandleColor="#fff"
              handleDiameter={27}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(29, 29, 29, 0.2)"
              activeBoxShadow="0px 0px 1px 10px rgba(29, 29, 29, 0.2)"
              height={33}
              width={60}
            />
          </div>
          <p className="text-gray-500 lg">
            {translate("pages.hosts.enableSmsNotificationDescription")}
          </p>
          <div className="flex justify-between mt-4">
            <label className="text-s pt-1 !text-neutral-800">
              {translate("pages.hosts.enableEmailNotification")}
            </label>
            <Switch
              onChange={() => setSendEmail(!sendEmail)}
              checked={sendEmail}
              offColor="#D9D9D9"
              onColor="#34D399"
              onHandleColor="#fff"
              handleDiameter={27}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(29, 29, 29, 0.2)"
              activeBoxShadow="0px 0px 1px 10px rgba(29, 29, 29, 0.2)"
              height={33}
              width={60}
            />
          </div>
          <p className="text-gray-500 lg">
            {translate("pages.hosts.enableEmailNotificationDescription")}
          </p>
          <div className="py-6 flex justify-around">
            <Button
              style={ButtonStyle.TransparentBorder}
              onClick={() => {
                void Logger().log(LOG.PRESS_CANCEL_CREATE_HOST_MODAL)
                close()
              }}
              name={translate("buttons.cancel")}
            />
            <Button
              type="submit"
              style={ButtonStyle.BluePrimary}
              name={
                formLoading ? translate("loading") : translate("buttons.save")
              }
            />
          </div>
        </div>
      </form>
    </Modal>
  )
}
