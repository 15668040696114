import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSignIn,
  faSignOut,
  faUserClock
} from "@fortawesome/free-solid-svg-icons"
import { convertTime } from "./visitDateTime"

interface VisualIndicatorProps {
  startTime: string
  endTime: string
  expectedVisitors: number
  signedInVisitors: number
  signedOutVisitors: number
  visitorsCount: number
}

export const VisitGroupIndicator: React.FC<VisualIndicatorProps> = ({
  startTime,
  endTime,
  expectedVisitors,
  signedInVisitors,
  signedOutVisitors,
  visitorsCount
}) => {
  return (
    <div className="border border-gray-100 rounded-b-md p-4 rounded-md flex flex-col items-start space-y-4 mb-4">
      <div className="text-base font-medium border-t border-b border-gray-100 w-full py-2">
        {convertTime(startTime, endTime)}
      </div>
      <div className="w-full flex items-center">
        <div className="bg-blue-100 px-2 py-1 rounded-md flex items-center space-x-2 text-base font-medium border-r border-gray-100">
          <FontAwesomeIcon icon={faUserClock} size="sm" />
          <span>{expectedVisitors} expected</span>
        </div>
        <div className="bg-emerald-100 px-2 py-1 rounded-md flex items-center space-x-2 text-base font-medium border-r border-gray-100 mx-2">
          <FontAwesomeIcon icon={faSignIn} size="sm" />
          <span>{signedInVisitors} signed in</span>
        </div>
        <div className="bg-red-100 px-2 py-1 rounded-md flex items-center space-x-2 text-base font-medium">
          <FontAwesomeIcon icon={faSignOut} size="sm" />
          <span>{signedOutVisitors} signed out</span>
        </div>
      </div>
    </div>
  )
}
