import { faCircle } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { generateIcon } from "resources"
import error_icon from "assets/images/error_icon.svg"

type InputProps = {
  label: string
  inputStyle?: string
  labelStyle?: string
  placeholder?: string
  id: string
  value?: string
  /** Input type */
  type?: string
  /** Error state */
  error?: boolean
  required?: boolean
  disabled?: boolean
  autoComplete?: "on" | "off"
  onChange?: (e) => void
  onBlur?: () => void
}

export const Input: React.FC<InputProps> = ({
  label,
  inputStyle,
  labelStyle,
  placeholder,
  id,
  value,
  type = "text",
  error,
  required,
  disabled,
  autoComplete = "off",
  onChange,
  onBlur
}) => {
  const requiredIcon = generateIcon(faCircle, "2xs", "orangered")
  return (
    <div className="w-full">
      <label
        className={[
          "text-s pt-1",
          error ? "text-red" : "text-neutral-800",
          labelStyle
        ].join(" ")}
      >
        {label}
      </label>
      {required && (
        <div className="inline-block pl-1 scale-75">{requiredIcon}</div>
      )}
      <div className="flex flex-row items-center">
        <input
          className={[
            "flex w-full py-3 ring-1 ring-gray-300 ring-inset rounded-md pl-3 focus:ring-2 focus:ring-systam-blue focus:outline-none",
            error
              ? "bg-white !ring-red-500 !border-none"
              : disabled
              ? "bg-gray-200 !border-none"
              : "bg-white !border-none",
            inputStyle
          ].join(" ")}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          id={id}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
        />
        {error && (
          <img
            src={error_icon}
            className="absolute right-10 w-5 h-5"
            alt="error icon"
          />
        )}
      </div>
    </div>
  )
}
