import { toast, TypeOptions } from "react-toastify"
import i18n from "./i18n"

const Msg = (message: string, title: string) => (
  <div>
    <h2 className="text-xl">{title}</h2>
    <p className="text-sm">{message}</p>
  </div>
)

const customToast = {
  error(message: string) {
    Toast(message, "error")
  },
  info(message: string) {
    Toast(message, "info")
  },
  success(message: string) {
    Toast(message, "success")
  },
  warning(message: string) {
    Toast(message, "warning")
  }
}

function Toast(message: string, type: TypeOptions) {
  let msg: JSX.Element
  switch (type) {
    case "info":
      msg = Msg(message, i18n.t("notifications.titleInfo") + "!")
      toast.info(msg, { delay: 200 })
      break
    case "warning":
      msg = Msg(message, i18n.t("notifications.titleWarning") + "!")
      toast.warning(msg, { delay: 200 })
      break
    case "error":
      msg = Msg(message, i18n.t("notifications.titleError") + "!")
      toast.error(msg, { delay: 200 })
      break
    case "success":
      msg = Msg(message, i18n.t("notifications.titleSuccess") + "!")
      toast.success(msg, { delay: 200 })
      break
  }
}

export default customToast
