import { useAppDispatch, useAppSelector } from "reduxStore/store"
import { Drawer } from "./Drawer"
import { useTranslate } from "@pankod/refine-core"
import { setIsDrawerOpen } from "reduxStore/reducers/drawerReducer"
import { CreateVisit } from "pages/visits/create"
import { CreateVisitor } from "pages/visitors/create"
import { EditVisitor } from "pages/visitors/edit"
import { useCallback, useEffect, useState } from "react"
import { CreateSpace } from "pages/spaces/create"
import { SyModal } from "components/new/shared"
import { CreateLink } from "pages/spaces/linked-devices/create"

export const DRAWER_MODES = {
  CREATE_VISITOR: "createVisitor",
  EDIT_VISITOR: "editVisitor",
  NEW_VISIT: "newVisit",
  EXPECTED_VISIT: "expectedVisit",
  CREATE_SPACE: "createSpace",
  ADD_LINKED_DEVICE: "addLinkedDevice"
}

export const DrawerWrapper = () => {
  const toggleDrawer = useAppSelector((state) => state.drawer.isDrawerOpen)
  const translate = useTranslate()
  const [title, setTitle] = useState<string>("")
  const drawerMode = useAppSelector((state) => state.drawer.mode)
  const selectedResourceId = useAppSelector((state) => state.drawer.selectedId)

  const [unsavedChanges, setUnsavedChanges] = useState(false)

  useEffect(() => {
    switch (drawerMode) {
      case DRAWER_MODES.CREATE_VISITOR:
        setTitle(translate("pages.visitors.createVisitor"))
        break
      case DRAWER_MODES.EDIT_VISITOR:
        setTitle(translate("pages.visitors.editVisitor"))
        break
      case DRAWER_MODES.NEW_VISIT:
        setTitle(translate("buttons.newVisit"))
        break
      case DRAWER_MODES.EXPECTED_VISIT:
        setTitle(translate("buttons.expectedVisit"))
        break
      case DRAWER_MODES.CREATE_SPACE:
        setTitle(translate("pages.spaces.addSpace"))
        break
      case DRAWER_MODES.ADD_LINKED_DEVICE:
        setTitle(translate("pages.spaces.linkDisplay"))
        break
    }
  }, [drawerMode])

  const dispatch = useAppDispatch()
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [unsaved, setUnsaved] = useState(false)

  useEffect(() => {
    setUnsaved(unsavedChanges ?? false)
  }, [unsavedChanges])

  function handleHideDrawer() {
    if (unsaved) {
      setCancelModalOpen(true)
    } else {
      hideDrawer()
    }
  }

  const hideDrawer = useCallback(() => {
    dispatch(setIsDrawerOpen(false))
  }, [dispatch])

  return (
    <Drawer
      open={toggleDrawer}
      setOpen={setIsDrawerOpen}
      title={title}
      onClose={handleHideDrawer}
    >
      {drawerMode === DRAWER_MODES.CREATE_VISITOR && (
        <div className="flex flex-col">
          <CreateVisitor setIsDrawerOpen={setIsDrawerOpen} />
        </div>
      )}
      {drawerMode === DRAWER_MODES.EDIT_VISITOR && (
        <div className="flex flex-col">
          <EditVisitor
            setIsDrawerOpen={setIsDrawerOpen}
            selectedVisitorId={selectedResourceId ?? 0}
          />
        </div>
      )}
      {drawerMode === DRAWER_MODES.NEW_VISIT && (
        <div className="flex flex-col">
          <CreateVisit setIsDrawerOpen={setIsDrawerOpen} />
        </div>
      )}
      {drawerMode === DRAWER_MODES.EXPECTED_VISIT && (
        <div className="flex flex-col">
          <CreateVisit setIsDrawerOpen={setIsDrawerOpen} />
        </div>
      )}
      {drawerMode === DRAWER_MODES.CREATE_SPACE && (
        <CreateSpace
          setUnsavedChanges={setUnsavedChanges}
          onClose={handleHideDrawer}
        />
      )}
      {drawerMode === DRAWER_MODES.ADD_LINKED_DEVICE && (
        <CreateLink
          selectedRoomId={selectedResourceId ?? 0}
          setUnsavedChanges={setUnsavedChanges}
          onClose={handleHideDrawer}
        />
      )}
      <SyModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        title={translate("modal.cancelConfirmation")}
        size="lg"
      >
        <div className="flex flex-row justify-between gap-4">
          <button
            className="w-full mt-4 ring-1 ring-one-gray-300 border rounded-lg border-one-gray-400 text-one-gray-900 px-4 py-2 font-medium"
            onClick={() => setCancelModalOpen(false)}
          >
            {translate("buttons.no")}
          </button>
          <button
            onClick={() => {
              setCancelModalOpen(false)
              setUnsaved(false)
              hideDrawer()
            }}
            className="w-full mt-4 border rounded-lg bg-one-gray-950 text-white px-4 py-2 font-medium"
          >
            {translate("buttons.yes")}
          </button>
        </div>
      </SyModal>
    </Drawer>
  )
}
