import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface LanguageState {
  selectedLanguage: string
}

const savedLanguage = localStorage.getItem("selectedLanguage")

const initialState: LanguageState = {
  selectedLanguage: savedLanguage || "en"
}

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload
      localStorage.setItem("selectedLanguage", action.payload)
    }
  }
})

export const { setSelectedLanguage } = languageSlice.actions
export default languageSlice.reducer
