import { useState } from "react"
import { useTranslate } from "@pankod/refine-core"
import { SyLabel } from "components/new/shared"
import { CaretDown } from "icons/icons"
import { IWorkspace } from "interfaces"

export interface SelectWorkspaceProps {
  readonly workspaces: readonly IWorkspace[]
  readonly selectedWorkspace?: IWorkspace
  readonly setSelectedWorkspace: (workspace: IWorkspace | undefined) => void
}

export const SelectWorkspace = ({
  workspaces,
  selectedWorkspace,
  setSelectedWorkspace
}: SelectWorkspaceProps) => {
  const translate = useTranslate()
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <div className="relative">
      <SyLabel required={true}>{translate("workspace")}</SyLabel>
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="flex justify-between items-center w-full bg-gray-200 rounded-md p-2"
      >
        <h1>{selectedWorkspace?.name}</h1>
        <CaretDown />
      </button>
      {showDropdown && (
        <div className="absolute bg-white shadow-xl w-full rounded-md overflow-y-scroll max-h-40">
          {workspaces.map((workspace, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setSelectedWorkspace(workspace)
                  setShowDropdown(false)
                }}
                className="hover:bg-gray-100 w-full text-left px-3 py-2"
              >
                {workspace.name}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}
