import React, { useMemo, useRef } from "react"
import {
  Column,
  flexRender,
  HeaderGroup,
  Row,
  RowModel
} from "@pankod/refine-react-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/free-solid-svg-icons"
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult
} from "@tanstack/react-query"
import { IPage } from "interfaces"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { useModal, useTranslate } from "@pankod/refine-core"
import { useScrollTrigger } from "hooks/useScrollTrigger"
import { v4 as uuidv4 } from "uuid"
import { VisitExpandableRow } from "modules/Visit/VisitExpandableRow"
import { TableButton } from "./TableButton"
import { ClipLoader } from "react-spinners"
import { ColumnIcon } from "icons/icons"
import classNames from "classnames"

export const Table = ({
  header,
  page,
  getAllColumns,
  getHeaderGroups,
  getRowModel,
  leftButtons,
  rightButtons,
  isLoading,
  isFetching,
  hasNextPage,
  fetchNextPage,
  hasDetails,
  onEditEntry,
  hideColumnsButton,
  disableShowNoEntries
}: {
  header?: string
  page: IPage | undefined
  getAllColumns: () => Column<any>[]
  getHeaderGroups: () => HeaderGroup<any>[]
  getRowModel: () => RowModel<any>
  leftButtons?: JSX.Element[]
  rightButtons?: JSX.Element[]
  isLoading?: boolean
  isFetching?: boolean
  errorMessage?: string
  hasNextPage?: boolean
  fetchNextPage?: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>
  hasDetails?: boolean
  onEditEntry?: (row: Row<any>) => void
  hideColumnsButton?: boolean
  disableShowNoEntries?: boolean
}) => (
  <ActualTable
    header={header}
    page={page}
    getAllColumns={getAllColumns}
    getHeaderGroups={getHeaderGroups}
    getRowModel={getRowModel}
    leftButtons={leftButtons}
    rightButtons={rightButtons}
    isLoading={isLoading}
    isFetching={isFetching}
    hasNextPage={hasNextPage}
    fetchNextPage={fetchNextPage}
    hasDetails={hasDetails}
    onEditEntry={onEditEntry}
    hideColumnsButton={hideColumnsButton}
    disableShowNoEntries={disableShowNoEntries}
  />
)

const ActualTable = ({
  header,
  page,
  getAllColumns,
  getHeaderGroups,
  getRowModel,
  leftButtons,
  rightButtons,
  isLoading,
  isFetching,
  hasNextPage,
  fetchNextPage,
  hasDetails,
  onEditEntry,
  hideColumnsButton,
  disableShowNoEntries
}: {
  header?: string
  page: IPage | undefined
  getAllColumns: () => Column<any>[]
  getHeaderGroups: () => HeaderGroup<any>[]
  getRowModel: () => RowModel<any>
  leftButtons?: JSX.Element[]
  rightButtons?: JSX.Element[]
  isLoading?: boolean
  isFetching?: boolean
  hasNextPage?: boolean
  fetchNextPage?: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>
  onAddNewEntry?: () => void
  hasDetails?: boolean
  onEditEntry?: (row: Row<any>) => void
  hideColumnsButton?: boolean
  disableShowNoEntries?: boolean
}) => {
  const { visible: columnFilterVisible, show, close } = useModal()
  const ref = useRef<HTMLDivElement>(null)

  useScrollTrigger(hasNextPage, ref, fetchNextPage)

  const renderRowSubComponent = useMemo(
    () =>
      ({ row }: { row: Row<any> }) =>
        (
          <VisitExpandableRow
            row={row}
            colSpan={row.getVisibleCells().length}
            onEditClick={onEditEntry}
          />
        ),
    [onEditEntry]
  )

  const translate = useTranslate()
  const numRows = getRowModel().rows.length
  const showTable = !isLoading && numRows > 0
  const showNoEntries = !isLoading && numRows === 0

  return (
    <>
      {columnFilterVisible && (
        <div
          onClick={() => close()}
          className="absolute w-screen h-screen left-0 top-0 z-10"
        />
      )}
      <div style={{ animation: "fadeIn 0.2s" }}>
        {header && (
          <h1 className="flex justify-between items-center font-kanit font-medium text-3xl h-20">
            {header}
          </h1>
        )}

        <div className="flex justify-between items-center my-2">
          <div className="flex">
            {leftButtons?.map((FilterComponent, i) =>
              React.cloneElement(FilterComponent, { key: i })
            )}
          </div>

          <div className="relative flex items-center [&>*]:ml-1">
            {rightButtons?.map((FilterComponent, i) =>
              React.cloneElement(FilterComponent, { key: i })
            )}
            {!hideColumnsButton && (
              <TableButton onClick={() => show()}>
                <>
                  <ColumnIcon />
                  <p>{translate("buttons.columns")}</p>
                </>
              </TableButton>
            )}
            {columnFilterVisible && (
              <div
                style={{ animation: "scaleIn 0.2s" }}
                className="absolute flex flex-col p-3 w-40 rounded-md bg-white z-10 mt-12 top-0 right-0 shadow-md"
              >
                <div className="flex flex-col">
                  {getAllColumns().map(
                    ({
                      id,
                      columnDef,
                      getIsVisible,
                      getToggleVisibilityHandler
                    }) => {
                      const columnVisible: boolean = getIsVisible()
                      return (
                        <div
                          onClick={(e) => {
                            getToggleVisibilityHandler()(e)
                          }}
                          key={id}
                          className="flex cursor-pointer my-1 justify-between items-center"
                        >
                          <div className="flex items-center">
                            <input
                              checked={columnVisible}
                              className="w-4 h-4"
                              type="checkbox"
                            />
                            <p className="pl-2">
                              {columnDef.header?.toString()}
                            </p>
                          </div>

                          <FontAwesomeIcon
                            color="#efefef"
                            icon={faGripVertical}
                          />
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          ref={ref}
          className="container max-h-160 mx-auto overflow-x-auto rounded-lg"
        >
          <table className="table-auto min-w-full border-separate">
            <thead className="bg-white">
              {getHeaderGroups().map(({ id, headers }) => (
                <tr key={id}>
                  {headers.map(({ id, colSpan, column, getContext }) => (
                    <th
                      key={id}
                      colSpan={colSpan}
                      className={`py-3 px-6 font-normal text-gray-500 first:rounded-tl-lg last:rounded-tr-lg ${
                        id.startsWith("centered_") ? "text-center" : "text-left"
                      }`}
                    >
                      {flexRender(column.columnDef.header, getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {isLoading && (
              <tbody>
                {getHeaderGroups().map(({ id, headers }) => (
                  <tr key={id}>
                    {headers.map(({ id, colSpan }) => (
                      <th
                        key={id}
                        colSpan={colSpan}
                        className="cursor-pointer py-3 px-6 text-left font-normal text-gray-500 first:rounded-tl-lg last:rounded-tr-lg"
                      >
                        <SkeletonTheme
                          baseColor="#f4f4f4"
                          highlightColor="#fcfcfc"
                        >
                          <Skeleton count={10} height={65} />
                        </SkeletonTheme>
                      </th>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
            {showTable && (
              <tbody className="bg-white pb-8">
                {getRowModel().rows.map((row) => {
                  const classes = classNames(
                    "transition hover:bg-gray-100 bg-white",
                    { "cursor-pointer": hasDetails }
                  )

                  return (
                    <React.Fragment key={row.id}>
                      <tr
                        key={row.id}
                        className={classes}
                        onClick={() => {
                          row.toggleExpanded()
                        }}
                      >
                        {row
                          .getVisibleCells()
                          .map(({ id, column, getContext }) => {
                            return (
                              <td
                                key={id}
                                className="whitespace-nowrap py-2 px-6 text-gray-700"
                              >
                                {flexRender(
                                  column.columnDef.cell,
                                  getContext()
                                )}
                              </td>
                            )
                          })}
                      </tr>
                      {hasDetails && row.getIsExpanded() ? (
                        <tr key={uuidv4()}>
                          <td
                            key={uuidv4()}
                            colSpan={row.getVisibleCells().length}
                          >
                            {renderRowSubComponent({
                              row
                            })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  )
                })}
                {/*
                Temporary filler space at the bottom of rows.
                Row's option dropdown menu does appends the whole table instead of showing the menu in front of the table.
                Menu dropdown should be refactored.
                */}
                {!isFetching && <tr className="h-16"></tr>}
                {isFetching && (
                  <tr
                    key="row-loading"
                    className="transition hover:bg-gray-100 border relative border-gray-50 rounded-md bg-white"
                  >
                    <td
                      key="row-loading"
                      colSpan={6}
                      className="whitespace-nowrap py-2 px-6 text-gray-700"
                    >
                      <div className="flex justify-center">
                        <ClipLoader
                          loading
                          color="#0C46CA"
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        {page && showTable && (
          <div className="flex flex-row pt-4">
            <p className="text-gray-600">{translate("show_results")}&nbsp;</p>
            <p className="text-gray-600 font-medium">
              {getRowModel().rows.length}&nbsp;
            </p>
            <p className="text-gray-600">{translate("of_total")}&nbsp;</p>
            <p className="text-gray-600 font-medium">{page?.total}&nbsp;</p>
            <p className="text-gray-600">{translate("results")}</p>
          </div>
        )}
        {!disableShowNoEntries && showNoEntries && (
          <div className="flex flex-col gap-2 items-center mt-6">
            <span>{translate("table.general.noEntries")}</span>
          </div>
        )}
      </div>
    </>
  )
}
