import { faCircle } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { generateIcon } from "resources"

type TextareaProps = {
  label?: string
  placeholder: string
  id: string
  value?: string
  /** Input type */
  type?: string
  /** Error state */
  error?: boolean
  required?: boolean
  rows?: number
  autoComplete?: "on" | "off"
  onChange?: (e) => void
  onBlur?: () => void
}

export const Textarea: React.FC<TextareaProps> = ({
  label,
  placeholder,
  id,
  value,
  error,
  required,
  rows,
  autoComplete = "off",
  onChange,
  onBlur
}) => {
  const requiredIcon = generateIcon(faCircle, "2xs", "orangered")
  return (
    <div className="w-full">
      <label
        className={[
          "text-s pt-1",
          error ? "text-red" : "text-neutral-800"
        ].join(" ")}
      >
        {label}
      </label>
      {required && (
        <div className="inline-block pl-1 scale-75">{requiredIcon}</div>
      )}
      <textarea
        className={[
          "flex w-full py-3 ring-1 ring-gray-300 ring-inset rounded-md pl-3 max-h-96 focus:ring-2 focus:ring-systam-blue focus:outline-none",
          error ? "!border !border-red !border-solid" : "!border-none"
        ].join(" ")}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        required={required}
        rows={rows}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    </div>
  )
}
