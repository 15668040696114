import React from "react"
import { SyButton } from ".."

export interface OkCancelButtonGroupProps {
  readonly okLabel: string
  readonly onOkClick: () => void
  readonly cancelLabel: string
  readonly onCancelClick: () => void
}

export function OkCancelButtonGroup({
  okLabel,
  onOkClick,
  cancelLabel,
  onCancelClick
}: OkCancelButtonGroupProps): React.ReactElement {
  return (
    <div className="flex justify-between gap-6">
      <SyButton
        className="w-40"
        color="secondary"
        label={cancelLabel}
        onClick={onCancelClick}
      />
      <SyButton className="w-40" label={okLabel} onClick={onOkClick} />
    </div>
  )
}
