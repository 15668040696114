import { useCreate, useTranslate } from "@pankod/refine-core"
import { UseModalFormReturnType } from "@pankod/refine-react-hook-form"
import { DeviceModelDropdown } from "components/dropdown/DeviceModelDropdown"
import { Button, ButtonStyle } from "components/globals/Button"
import { Input } from "components/globals/Input"
import { Modal } from "components/Modal"
import { useState } from "react"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { useQueryClient } from "@tanstack/react-query"
import { SELECTED_WORKSPACE } from "utilities/constants"

export const CreateDevice: React.FC<UseModalFormReturnType> = ({
  refineCore: { formLoading },
  modal: { visible, close }
}) => {
  const translate = useTranslate()
  const { mutate } = useCreate()
  const [deviceName, setDeviceName] = useState("")
  const [deviceModelId, setDeviceModelId] = useState("")
  const queryClient = useQueryClient()

  function validate(): boolean {
    if (
      deviceName.length > 0 &&
      deviceModelId.length > 0
    ) {
      return true
    }
    return false
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) || ""

    if (validate()) {
      mutate(
        {
          resource: `devices/`,
          values: [
            {
              device_model_id: `${deviceModelId}`,
              workspace_id: selectedWorkspaceId,
              name: `${deviceName}`,
            }
          ]
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.createError", {
                resource: translate("resources.device")
              })
            )
            void Logger().error(LOG.CREATE_DEVICE, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            customToast.success(
              translate("notifications.createSuccess", {
                resource: translate("resources.device")
              })
            )
            close()
          },
          onSettled() {
            setTimeout(
              () =>
                void queryClient.resetQueries({
                  queryKey: ["devicelist"]
                }),
              200
            )
          }
        }
      )
    }
  }

  return (
    <Modal
      title={translate("pages.devices.addDevice")}
      isOpen={visible}
      onClose={close}
    >
      <form className="form" onSubmit={onSubmit} autoComplete="off">
        <DeviceModelDropdown
          value={deviceModelId}
          setState={setDeviceModelId}
        />
        <Input
          label="Device name"
          id="test"
          value={deviceName}
          onChange={(e) => setDeviceName(e.target.value as string)}
        />
        <div className="flex justify-between mt-4">
          <Button
            style={ButtonStyle.TransparentBorder}
            onClick={() => {
              close()
            }}
            name={translate("buttons.cancel")}
          />
          <Button
            type="submit"
            name={translate("buttons.save")}
            loading={formLoading}
          />
        </div>
      </form>
    </Modal>
  )
}
