import { isEmptyString } from "utilities/string"
import { useTranslate } from "@pankod/refine-core"
import { Button, ButtonStyle } from "./globals/Button"
import { useState } from "react"
import { Textarea } from "./globals/Textarea"
import { LabeledCheckbox } from "./globals/LabeledCheckbox"
import { axiosInstance } from "utilities/dataProvider"
import customToast from "utilities/toastHelper"
import { LOG, Logger } from "utilities/logger"
import { HTTP_STATUS_OK } from "utilities/constants"

type ModalPropsType = {
  onClose: () => void
}

export const Feedback = ({ onClose }: ModalPropsType) => {
  const supportEmailAddress = "support@systam.io"
  const feedbackCharLimit = 1000

  const translate = useTranslate()
  const [feedback, setFeedback] = useState("")
  const [isAnonymous, setAnonymous] = useState(false)
  const handleInputChange = (e) => {
    let str: string = e.target.value
    if (str.length > feedbackCharLimit) {
      str = str.substring(0, feedbackCharLimit)
    }
    setFeedback(str)
  }

  const submitFeedback = async () => {
    if (!isEmptyString(feedback)) {
      try {
        const response = await axiosInstance.post("feedback/", {
          comment: feedback,
          anonymous: isAnonymous
        })

        if (response.status === HTTP_STATUS_OK) {
          onClose()
          customToast.success(translate("notifications.feedbackSuccess"))
        }
      } catch (error) {
        customToast.error(translate("notifications.feedbackError"))
        void Logger().error(LOG.PRESS_SUBMIT_FEEDBACK, `${error}`)
      }
    }
  }

  return (
    <div>
      <div className="px-4 whitespace-pre-line">
        <p className="text-center">
          {translate("feedback.feedbackDescription")}
        </p>
        <div className="py-4">
          <Textarea
            id="feedbackText"
            placeholder={translate("feedback.feedbackPlaceholder")}
            rows={8}
            onChange={(e) => handleInputChange(e)}
            value={feedback}
          />
          <p className="text-sm">
            {feedback.length}/{feedbackCharLimit}
          </p>
        </div>
        <p className="text-center text-sm">
          {translate("feedback.feedbackDirectContact")}
          <a className="text-blue-600" href={"mailto:" + supportEmailAddress}>
            {supportEmailAddress}
          </a>
        </p>
        <div className="py-6">
          <LabeledCheckbox
            id="anonymous"
            label={translate("feedback.sendAnonymously")}
            checked={isAnonymous}
            onChange={() => setAnonymous(!isAnonymous)}
          ></LabeledCheckbox>
        </div>
        <div className="flex justify-between mt-2">
          <Button
            type="button"
            style={ButtonStyle.TransparentBorder}
            onClick={() => {
              onClose()
            }}
            name={translate("buttons.cancel")}
          />
          <Button
            disabled={isEmptyString(feedback)}
            type="button"
            style={ButtonStyle.BluePrimary}
            onClick={() => {
              void submitFeedback()
            }}
            name={translate("buttons.submit")}
          />
        </div>
      </div>
    </div>
  )
}
