import { Dialog } from "@headlessui/react"

interface PersonPictureModalProps {
  showPictureModal: boolean
  setShowPictureModal: (showPictureModal: boolean) => void
  selectedPicture?: string
}

export const PersonPictureModal = (props: PersonPictureModalProps) => {
  return (
    <Dialog
      as="div"
      className="relative z-50"
      open={props.showPictureModal}
      onClose={() => props.setShowPictureModal(false)}
    >
      <div
        className="fixed inset-0 bg-systam-dark opacity-70"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-52">
        <Dialog.Panel className="w-full max-w-md transform bg-white overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
          <img
            alt="host person"
            className="rounded-none object-cover aspect-square hover:scale-150 transition duration-300 ease-in-out"
            src={props.selectedPicture}
          />
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
