import { useLogout, useOne, useTranslate } from "@pankod/refine-core"
import React, { Dispatch, SetStateAction } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { ButtonStyle } from "./globals/Button"
import { IUserInfo } from "interfaces"
import { useQueryClient } from "@tanstack/react-query"
import { getRoleDisplayName } from "utilities/role"
import { UserIcon } from "@heroicons/react/24/outline"

type DropdownProps = {
  name: string
  onClick: () => void
  style: ButtonStyle | string
}

export const Dropdown = ({
  setDropdown,
  setChangePassword,
  setEditProfile: setEditUserProfile,
  setGiveFeedback
}: {
  setDropdown: Dispatch<SetStateAction<boolean>>
  setChangePassword: Dispatch<SetStateAction<boolean>>
  setEditProfile: Dispatch<SetStateAction<boolean>>
  setGiveFeedback: Dispatch<SetStateAction<boolean>>
}) => {
  const translate = useTranslate()
  const { mutate: logout } = useLogout()
  const queryClient = useQueryClient()

  const { isLoading, data: userInfo } = useOne<IUserInfo>({
    resource: "users/me",
    id: ""
  })

  const dropDownItems = new Set<DropdownProps>([
    {
      name: `${translate("dropdown.title.editprofile")}`,
      onClick: () => {
        setEditUserProfile(true)
        setDropdown(false)
      },
      style: ButtonStyle.BluePrimary
    },
    {
      name: `${translate("dropdown.title.changepassword")}`,
      onClick: () => {
        setChangePassword(true)
        setDropdown(false)
      },
      style: ButtonStyle.BlueSecondary
    },
    {
      name: `${translate("dropdown.title.giveFeedback")}`,
      onClick: () => {
        setGiveFeedback(true)
        setDropdown(false)
      },
      style: "px-1 pb-1 pt-4 my-1 text-soft-blue min-w-32 disabled:opacity-75"
    }
  ])

  return (
    <>
      {isLoading ? (
        <div
          role={"menu"}
          style={{ animation: "scaleIn 0.2s" }}
          className="cursor-pointer absolute right-2 bg-white shadow-md rounded-md p-2 mr-1 z-20 w-50 py-1"
        >
          <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
            <Skeleton
              count={1}
              height={230}
              width={233}
              circle={false}
              enableAnimation={true}
            />
          </SkeletonTheme>
        </div>
      ) : (
        <div
          role={"menu"}
          style={{ animation: "scaleIn 0.125s" }}
          className="mt-2 absolute border border-one-gray-200/50 right-0 origin-top-right bg-white z-50 shadow-lg rounded-lg divide-y divide-one-gray-100">
          <div className="py-2">
            <div className="px-4 py-2 flex space-x-2">
              <div className="flex justify-center items-center h-12 w-12 bg-one-gray-100 rounded-full">
                <div className="relative flex justify-center items-center h-10 w-10 rounded-full bg-white shadow-sm">
                  {userInfo?.data?.picture ? (
                    <img
                      src={userInfo?.data?.picture}
                      className="absolute inset-0 object-cover h-10 w-10 rounded-full"
                      alt="user info"
                    />
                  ) : (
                    <UserIcon className="h-6 w-6 text-systam-blue" />
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-center">
                <div className="font-medium">
                  {userInfo?.data?.person?.first_name} {userInfo?.data?.person?.last_name}
                </div>
                <div className="text-sm text-one-gray-600 truncate">
                  {getRoleDisplayName(
                    userInfo?.data?.role?.name || "",
                    translate
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            {Array.from(dropDownItems).map(
              ({ name, onClick, style }, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => onClick()}
                    className="flex items-center w-full px-4 py-2 text-one-gray-600 hover:bg-one-gray-100">
                    {name}
                  </button>

                )
              }
            )}
          </div>
          <div className="py-2">
            <button
              onClick={() => {
                queryClient.clear()
                logout()
              }}
              className="flex items-center w-full px-4 py-2 text-one-rose-500 hover:bg-one-gray-100">
              {translate("dropdown.title.logout")}
            </button>
          </div>
        </div>
      )}
    </>
  )
}
