import { useMutation } from "@tanstack/react-query"
import MessagingApiService from "services/MessagingApiService"

type IVisitor = {
  state: string
  first_name: string
  last_name: string
  email: string
  phone: string
  company: string
  visitorId?: number
}

export interface IEmailNotificationMessageRequest {
  userId?: string
  visitors?: IVisitor[]
  visitId?: number
  visitorName?: string
  visitorCompanyName?: string
  visitorPhone?: string
  visitorEmail?: string
  organizationId?: number
  hostName?: string
  hostId?: number
  hostEmail?: string
  template?: string
  hostNotifyContent?: string
}

const useSendEmail = () => {
  return useMutation(
    ({ request }: { request: IEmailNotificationMessageRequest }) =>
      MessagingApiService.sendEmail(request)
  )
}

export default useSendEmail
