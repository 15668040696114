import { CodeResponse, useGoogleLogin } from "@react-oauth/google"
import customToast from "utilities/toastHelper"
import { INTEGRATION_TYPES, QUERY_KEYS } from "utilities/types"
import useActivateCalendar from "./useActivateCalendar"
import { useQueryClient, useTranslate } from "@pankod/refine-core"
import { googleOAuth } from "config/googleOAuth"

export const useGoogleCalendar = () => {
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const activateCalendarMutation = useActivateCalendar()

  const login = useGoogleLogin({
    scope: googleOAuth.scope,
    include_granted_scopes: true,
    flow: "auth-code",
    redirect_uri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/calendar-integrations/"
        : `https://app.systam.io/calendar-integrations/`,
    onSuccess: (codeReponse: CodeResponse) => {
      if (codeReponse?.code) {
        activateCalendarMutation.mutate(
          {
            code: codeReponse.code,
            integration_type: INTEGRATION_TYPES.GOOGLE
          },
          {
            onSuccess: () => {
              customToast.success(
                translate("notifications.createSuccess", {
                  resource: translate("resources.CalendarIntegration")
                })
              )
            },
            onError: () => {
              customToast.error(
                translate("notifications.createError", {
                  resource: translate("resources.CalendarIntegration")
                })
              )
            },
            onSettled: () => {
              setTimeout(
                () =>
                  void queryClient.resetQueries({
                    queryKey: [QUERY_KEYS.CALENDAR_INTEGRATION]
                  }),
                200
              )
            }
          }
        )
      }
    },
    onError: (error) => {
      console.log("Login Failed:", error)
      customToast.error(translate("notifications.loginError"))
    }
  })

  return login
}
