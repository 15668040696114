import { Configuration, LogLevel } from "@azure/msal-browser"
import { LOG, Logger } from "utilities/logger"

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

export const msalConfig: Configuration = {
  auth: {
    clientId: "9e594b80-090b-45c6-b1e6-e8f63e27eed1",
    authority: "https://login.microsoftonline.com/organizations/",
    redirectUri:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/calendar-integrations/"
        : `https://app.systam.io/calendar-integrations/`
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            void Logger().error(LOG.MSAL, `Error: ${message}`)
            return
          case LogLevel.Info:
            void Logger().error(LOG.MSAL, `Info: ${message}`)
            return
          case LogLevel.Verbose:
            void Logger().error(LOG.MSAL, `Verbose: ${message}`)
            return
          case LogLevel.Warning:
            void Logger().error(LOG.MSAL, `Warning: ${message}`)
            return
          default:
            return
        }
      }
    }
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [
    "User.Read",
    "User.Readbasic.All",
    "Calendars.Read",
    "Calendars.Readwrite",
    "Calendars.Read.Shared",
    "Calendars.Readwrite.Shared"
  ]
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}
