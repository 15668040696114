import React, { useEffect, useRef, useState } from "react"
import { axiosInstance, axiosNoUrl } from "utilities/dataProvider"
import {
  IResourceComponentsProps,
  useTranslate,
  useApiUrl,
  useRouterContext
} from "@pankod/refine-core"
import { IDevice, VersionInfo } from "interfaces"
import { useQuery } from "@tanstack/react-query"
import ql700 from "assets/images/ql700.png"
import { DeviceTypeDropdown } from "components/dropdown/DeviceTypeDropdown"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { InputSearch } from "components/globals/InputSearch"
import { Button } from "components/globals/Button"
import { useModalForm } from "@pankod/refine-react-hook-form"
import { CreateDevice } from "./create"
import { useOutsideClick } from "hooks/useOutsideClick"
import { setDeviceSearch } from "reduxStore/reducers/deviceSearchReducer"
import { useAppSelector } from "reduxStore/store"
import { useDispatch } from "react-redux"
import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons"
import { LOG, Logger } from "utilities/logger"
import { USER_ID_KEY } from "utilities/constants"
import roomsDisplayImg from "assets/images/rooms_display.png"

interface Props {
  isRooms: boolean
}

/// Lists all devices for Visit
export const VisitDeviceList: React.FC<IResourceComponentsProps> = () => {
  return <DeviceList isRooms={false} />
}

/// Used to list devices.
/// isRooms defines whether to show Rooms or Visit devices
export const DeviceList: React.FC<Props> = ({ isRooms }) => {
  const translate = useTranslate()

  const [selectedType, setSelectedType] = useState<string>("")
  const [search, setSearch] = useState<string>("")
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  const deviceSearch = useAppSelector<string>(
    (state) => state.deviceSearch.deviceSearchInput
  )
  const dispatch = useDispatch()

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const apiUrl = useApiUrl()

  // Initially set search to deviceSearch from redux
  useEffect(() => {
    setSearch(deviceSearch)
  }, [])

  // Update redux deviceSearch on search change
  useEffect(() => {
    dispatch(setDeviceSearch(search))
  }, [search])

  const { data: deviceData, isLoading } = useQuery<[IDevice]>(
    ["devicelist"],
    async () => {
      const endpoint = isRooms
        ? "devices/systam-workplace"
        : "devices/systam-visit"
      const res = await axiosInstance.get(`${apiUrl}/${endpoint}`)
      return res.data
    }
  )

  function filterWorkspace(device: IDevice) {
    return (
      selectedWorkspaceId &&
      device.workspace_id === parseInt(selectedWorkspaceId)
    )
  }

  function filterSelection(device: IDevice) {
    if (selectedType === "Show all") {
      return device
    } else if (selectedType !== undefined && selectedType.length > 0) {
      return device.model.type === selectedType
    } else {
      return device
    }
  }

  function filterSearch(device: IDevice) {
    if (deviceSearch.length > 0) {
      return device.name.toLowerCase().includes(deviceSearch.toLowerCase())
    } else {
      return device
    }
  }

  function getFilteredDevices() {
    if (deviceData !== undefined) {
      const deviceArray = deviceData
        .filter(filterWorkspace)
        .filter(filterSelection)
        .filter(filterSearch)
        .map((device: IDevice) => {
          return (
            <DeviceContainer
              key={device.id.toString()}
              id={device.id}
              device={device}
            />
          )
        })

      if (deviceArray != null && deviceArray.length > 0) {
        return deviceArray
      }
    }
    return <p>{translate("pages.devices.noDevices")}</p>
  }

  const createModalFormReturnValues = useModalForm({
    refineCoreProps: {
      action: "create",
      resource: `/devices`
    }
  })

  const {
    modal: { show: showCreateModal }
  } = createModalFormReturnValues

  return (
    <>
      <CreateDevice {...createModalFormReturnValues} />
      <h1 className="flex justify-between items-center font-kanit font-medium text-3xl h-20">
        {isRooms
          ? translate("pages.devices.displays")
          : translate("pages.devices.devices")}
      </h1>
      <div className="flex row items-end">
        {!isRooms && (
          <div className="max-w-3xl mr-4">
            <DeviceTypeDropdown
              value={selectedType}
              setState={setSelectedType}
            />
          </div>
        )}
        <div className="">
          <InputSearch
            search={search}
            setSearch={setSearch}
            placeholder={translate("buttons.filter")}
          />
        </div>
        <div className="ml-auto">
          <div className="flex flex-row gap-2">
            {isRooms && (
              <Button
                icon={faDownload}
                name={translate("buttons.downloadApk")}
                onClick={() => {
                  async function downloadApk() {
                    const getVersionInfo = async (): Promise<
                      VersionInfo | undefined
                    > => {
                      try {
                        const response = await axiosNoUrl.get<VersionInfo>(
                          "https://storage.googleapis.com/rooms-production/version-info.json"
                        )
                        const versionInfo = response.data
                        void Logger().log(
                          LOG.PRESS_DOWNLOAD_APK,
                          `UserId: ${
                            localStorage.getItem(USER_ID_KEY) || ""
                          } downloaded APK with Version: ${
                            versionInfo.version
                          }, Build: ${versionInfo.releaseDate}, Notes: ${
                            versionInfo.notes
                          }`
                        )
                        return versionInfo
                      } catch (error) {
                        console.error("Error fetching version info:", error)
                        return undefined
                      }
                    }

                    const version = await getVersionInfo()

                    return await axiosNoUrl
                      .get(
                        `https://storage.googleapis.com/rooms-production/app-release.apk`,
                        {
                          responseType: "blob"
                        }
                      )
                      .then((response) => {
                        const url = window.URL.createObjectURL(
                          new Blob([response.data])
                        )
                        const link = document.createElement("a")
                        link.href = url
                        link.setAttribute(
                          "download",
                          `${
                            version?.version
                              ? `rooms-${version?.version}`
                              : "systam-rooms"
                          }.apk`
                        )
                        document.body.appendChild(link)
                        link.click()
                      })
                  }
                  downloadApk().catch((error) => {
                    console.error("Error downloading APK:", error)
                  })
                }}
              />
            )}
            <Button
              icon={faPlus}
              name={translate("pages.devices.addDevice")}
              onClick={() => {
                isRooms ? push(`/displays/create`) : showCreateModal()
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="mt-6 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {isLoading && (
            <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
              <Skeleton count={2} height={324} width={270} />
              <Skeleton count={2} height={324} width={270} />
              <Skeleton count={2} height={324} width={270} />
              <Skeleton count={2} height={324} width={270} />
            </SkeletonTheme>
          )}
          {deviceData && getFilteredDevices()}
        </div>
      </div>
    </>
  )
}

const DeviceStatusIndicator = ({ status }: { status: string }) => {
  let outerStyle: string
  let innerColor: string
  let text: string
  const translate = useTranslate()

  switch (status) {
    case "active":
      outerStyle = "bg-emerald-50 ring-2 ring-emerald-100"
      innerColor = "text-emerald-600"
      text = translate("pages.devices.active")
      break
    case "inactive":
      outerStyle = "bg-amber-50 ring-2 ring-amber-100"
      innerColor = "text-amber-400"
      text = translate("pages.devices.inactive")
      break
    default:
      outerStyle = "bg-red-50 ring-2 ring-red-100"
      innerColor = "text-red-600"
      text = translate("pages.devices.notWorking")
  }

  return (
    <div className="flex-shrink-0">
      <div
        className={
          `px-2 flex items-center justify-between h-8 rounded-full ` +
          outerStyle
        }
      >
        <span className="mr-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            fill="currentColor"
            className={`w-4 h-4 ` + innerColor}
          >
            <circle cx="16" cy="16" r="8"></circle>
          </svg>
        </span>
        <span className="font-medium">{text}</span>
      </div>
    </div>
  )
}

export const DeviceContainer = ({
  id,
  device,
  isDeviceShow
}: {
  id: number
  device: IDevice
  isDeviceShow?: boolean
}) => {
  const translate = useTranslate()
  const dropdownMenuRef = useRef<HTMLDivElement>(null)
  const dropdownButtonRef = useRef<HTMLButtonElement>(null)

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const [dropdown, setDropdown] = useOutsideClick(
    dropdownMenuRef,
    dropdownButtonRef
  )

  const handlePropagationClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    callback: () => void
  ) => {
    e.stopPropagation()
    callback()
  }

  return (
    <div>
      <div
        key={id.toString()}
        className={`w-64 h-60 px-4 py-2 relative flex flex-col items-center justify-between border-2 rounded-xl bg-white shadow-sm border-gray-100 ${
          !isDeviceShow && "hover:border-blue-600 cursor-pointer"
        }`}
        onClick={() => {
          push(`/devices/${device.id}`)
        }}
      >
        <div className="flex flex-col space-y-4 w-full">
          <div className="flex-shrink-0 w-full">
            <div className="absolute">
              <DeviceStatusIndicator
                status={device.pin_code === null ? "active" : "inactive"}
              />
            </div>
            <div className="pt-4 px-8">
              <div className="h-24">
                <DeviceImage type={device.model.type} />
              </div>
            </div>
          </div>
          <div className="text-left max-w-56">
            <h3 className="font-medium text-ellipsis  overflow-hidden">
              {device.name}
            </h3>
            <p className="text-one-gray-500 truncate">{device.model.type}</p>
            <p className="text-one-gray-500 truncate">{device.model.name}</p>
          </div>
        </div>
        {!isDeviceShow && (
          <div className="absolute top-4 right-4">
            <button
              ref={dropdownButtonRef}
              className="flex items-center justify-center w-10 h-10 z-40 rounded-md bg-gray-50 hover:bg-gray-100"
              onClick={(e) =>
                handlePropagationClick(e, () => setDropdown(!dropdown))
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="currentColor"
                className="w-6 h-6"
              >
                <circle cx="16" cy="8" r="2"></circle>
                <circle cx="16" cy="16" r="2"></circle>
                <circle cx="16" cy="24" r="2"></circle>
              </svg>
            </button>
            {dropdown && (
              <div
                ref={dropdownMenuRef}
                className="flex flex-col mt-1 absolute border border-gray-100 bg-white rounded-md shadow-sm z-10"
              >
                <button
                  className="flex grow px-4 py-2 text-gray-600 whitespace-nowrap hover:bg-gray-100"
                  onClick={(e) =>
                    handlePropagationClick(e, () =>
                      push(`/devices/${device.id}`)
                    )
                  }
                >
                  {translate("buttons.viewEdit")}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const DeviceImage = ({ type }: { type: string }) => {
  let imgSrc: string

  switch (type) {
    case "Kiosk":
      imgSrc =
        // TODO: Get image from project instead of elotouch.com
        "https://www.elotouch.com/media/catalog/product/cache/d17bfcab5607f3037da2022c76e4ac56/0/7/0702l_front_001_nologo_hero_gallery_update_1400x800.png"
      break
    case "Room Display":
      imgSrc = roomsDisplayImg
      break
    case "Printer":
      imgSrc = ql700
      break
    default:
      imgSrc = roomsDisplayImg
      break
  }

  return (
    <img
      src={imgSrc}
      alt="deviceModel"
      className="flex items-center justify-center max-h-24 object-scale-down"
    />
  )
}
