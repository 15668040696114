import { useCustom, useTranslate } from "@pankod/refine-core"
import { Select } from "components/globals/Select"
import { IWorkspace } from "interfaces"
import { Dispatch, SetStateAction, useEffect } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export const WorkspaceDropdown = ({
  value,
  setState
}: {
  value: any
  setState: Dispatch<SetStateAction<any>>
}) => {
  const translate = useTranslate()
  const { data, isLoading } = useCustom<[IWorkspace]>({
    url: "workspaces/",
    method: "get"
  })

  useEffect(() => {
    if (!isLoading) {
      setState(data?.data[0])
    }
  }, [])

  return (
    <>
      {isLoading && (
        <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
          <Skeleton count={1} height={40} />
        </SkeletonTheme>
      )}
      {data?.data !== undefined && data?.data.length > 1 && (
        <Select
          label={translate("workspace")}
          placeholder={translate("selectWorkspace")}
          id={"workspaceDropdown"}
          optionData={data?.data}
          value={value}
          onChange={(e) => setState(e.target.value)}
        />
      )}
    </>
  )
}
