import { useCustom, useTranslate } from "@pankod/refine-core"
import { DefaultLanguageDropdown } from "components/dropdown/DefaultLanguageDropdown"
import { IDevice, IDeviceSettings } from "interfaces"
import { DeviceSettingInput } from "./DeviceSettingInput"

export const CustomizationTab = ({ device }: { device: IDevice }) => {
  const { data: deviceSettingData } = useCustom<IDeviceSettings>({
    url: `devices/${device.id}/settings`,
    method: "get"
  })

  const translate = useTranslate()

  return (
    <div className="border shadow-md rounded-lg p-4">
      <h2 className="text-xl font-medium leading-6 text-gray-900">
        {translate("pages.devices.tab.customization")}
      </h2>
      <p className="text-gray-500">
        {translate("pages.devices.tab.customizationDescription")}
      </p>
      <p className="mb-2 mt-1 text-gray-500"></p>
      {deviceSettingData && device.model.type === "Kiosk" && (
        <div className="divide-y-2 divide-gray-200 xl:w-2/3">
          <div className="border border-gray-200 px-4 py-2 my-2 rounded-lg">
            <h1 className="text-xl font-medium mb-1">Logo</h1>
            <DeviceSettingInput
              deviceId={device.id}
              deviceSettingsData={deviceSettingData?.data.settings}
              settingName="Logo"
              dataType={"Picture"}
              description={null}
            />
          </div>
          <div className="border border-gray-200 px-4 py-2 my-2 rounded-lg">
            <h1 className="text-xl font-medium">
              {translate("pages.devices.settings.title")}
            </h1>
            <p className="text-gray-500 mb-4">
              {translate("pages.devices.settings.titleDescription")}
            </p>
            <DeviceSettingInput
              deviceId={device.id}
              deviceSettingsData={deviceSettingData?.data.settings}
              settingName="IdleTitleVisible"
              dataType={"Boolean"}
              description={null}
            />
            <DeviceSettingInput
              deviceId={device.id}
              deviceSettingsData={deviceSettingData?.data.settings}
              settingName="IdleTitle"
              dataType={"Text"}
              description={translate(
                "pages.devices.settings.idleTitleDescription"
              )}
            />
            <DeviceSettingInput
              deviceId={device.id}
              deviceSettingsData={deviceSettingData?.data.settings}
              settingName="IdleTitleFontColor"
              dataType={"Color"}
              description={translate("pages.devices.settings.fontColor", {
                resource: translate(
                  "pages.devices.settings.title"
                ).toLowerCase()
              })}
            />
          </div>
          <div className="border border-gray-200 px-4 py-2 my-2 rounded-lg">
            <h1 className="text-xl font-medium">
              {translate("pages.devices.settings.subtitle")}
            </h1>
            <p className="text-gray-500 mb-4">
              {translate("pages.devices.settings.subtitleDescription")}
            </p>
            <DeviceSettingInput
              deviceId={device.id}
              deviceSettingsData={deviceSettingData?.data.settings}
              settingName="IdleSubtitleVisible"
              dataType={"Boolean"}
              description={null}
            />
            <DeviceSettingInput
              deviceId={device.id}
              deviceSettingsData={deviceSettingData?.data.settings}
              settingName="IdleSubtitle"
              dataType={"Text"}
              description={null}
            />
            <DeviceSettingInput
              deviceId={device.id}
              deviceSettingsData={deviceSettingData?.data.settings}
              settingName="IdleSubtitleFontColor"
              dataType={"Color"}
              description={translate("pages.devices.settings.fontColor", {
                resource: translate(
                  "pages.devices.settings.subtitle"
                ).toLowerCase()
              })}
            />
          </div>
        </div>
      )}
      {deviceSettingData && device.model.type === "Room Display" && (
        <div>
          <br />
          <DefaultLanguageDropdown
            deviceId={device.id}
            deviceSettingsData={deviceSettingData?.data.settings}
          />
          <DeviceSettingInput
            deviceId={device.id}
            deviceSettingsData={deviceSettingData?.data.settings}
            settingName={"AdHocReservation"}
            dataType={"Boolean"}
            description={translate(
              "pages.devices.settings.EnableAdHocReservationDescription"
            )}
          />
          <DeviceSettingInput
            deviceId={device.id}
            deviceSettingsData={deviceSettingData?.data.settings}
            settingName={"EventNameVisible"}
            dataType={"Boolean"}
            description={translate(
              "pages.devices.settings.ShowEventNameDescription"
            )}
          />
          <DeviceSettingInput
            deviceId={device.id}
            deviceSettingsData={deviceSettingData?.data.settings}
            settingName={"EventOrganizerVisible"}
            dataType={"Boolean"}
            description={translate(
              "pages.devices.settings.ShowEventOrganizerDescription"
            )}
          />
          <DeviceSettingInput
            deviceId={device.id}
            deviceSettingsData={deviceSettingData?.data.settings}
            settingName={"ConfirmBooking"}
            dataType={"Boolean"}
            description={translate(
              "pages.devices.settings.ConfirmBookingDescription"
            )}
          />
        </div>
      )}
    </div>
  )
}
