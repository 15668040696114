import { useDelete, useQueryClient, useTranslate } from "@pankod/refine-core"
import { UseModalFormReturnType } from "@pankod/refine-react-hook-form"
import { Modal } from "components/Modal"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { QUERY_KEYS } from "utilities/types"

export const DeleteIntegrationModal = ({
  modal: { visible, close },
  refineCore: { id: calendarIntegrationId }
}: UseModalFormReturnType) => {
  const translate = useTranslate()
  const { mutate } = useDelete()
  const queryClient = useQueryClient()

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    mutate(
      {
        id: calendarIntegrationId || 0,
        resource: "calendars"
      },
      {
        onError: (error) => {
          customToast.error(
            translate("notifications.deleteError", {
              resource: translate("resources.CalendarIntegration")
            })
          )
          void Logger().error(LOG.DEL_CALENDAR_INTEGRATION, `${error.stack}`)
        },
        onSuccess: () => {
          void Logger().log(
            LOG.DEL_CALENDAR_INTEGRATION,
            `deleted ${calendarIntegrationId}`
          )

          customToast.success(
            translate("notifications.deleteSuccess", {
              resource: translate("resources.CalendarIntegration")
            })
          )
          close()
        },
        onSettled() {
          void queryClient.resetQueries({
            queryKey: [QUERY_KEYS.CALENDAR_INTEGRATION]
          })
        }
      }
    )
  }

  return (
    <>
      <Modal
        title={translate("pages.calendarIntegrations.delete")}
        isOpen={visible}
        onClose={close}
      >
        <p className="text-center text-l pt-6 px-6 ">
          {translate("pages.calendarIntegrations.areYouSure")}
        </p>

        <form className="mt-6 ">
          <div className="flex justify-around">
            <button
              onClick={() => {
                void Logger().log(LOG.PRESS_CANCEL_DEL_INTEGRATION_MODAL)
                close()
              }}
            >
              {translate("buttons.cancel")}
            </button>

            <button
              className="py-3 px-6 font-medium text-white bg-systam-blue hover:bg-systam-dark rounded-lg"
              onClick={(event) => void onSubmit(event)}>
              {translate("buttons.delete")}
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}
