import {
  faEllipsisVertical,
  faSignInAlt,
  faSignOutAlt,
  faEdit,
  faCancel,
  faPrint,
  faBullhorn,
  faCaretUp,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import { usePermissions, useTranslate } from "@pankod/refine-core"
import { Row } from "@pankod/refine-react-table"
import { useUpdateVisitor } from "hooks/useUpdateVisitorData"
import { useUpdateVisitorGroup } from "hooks/useUpdateVisitorGroupData"
import { IHost, IVisit } from "interfaces"
import { setIsPrintVisitBadgeOpen } from "reduxStore/reducers/visitPrintBadgeReducer"
import { useAppDispatch } from "reduxStore/store"
import { IS_PREVIEW_MODE } from "utilities/development"
import { LOG, Logger } from "utilities/logger"
import {
  useHasPermission,
  visitPermissionEnabled
} from "utilities/permissionSelector"

export const ActionsCell = (
  row: Row<IVisit>,
  onEdit: (row: Row<IVisit>) => void,
  setShowDeleteModal: (value: boolean) => void,
  setSelectedVisitId: (value: number) => void,
  setSelectedVisitorId: (value: number | undefined) => void,
  showQuickNotificationModal: boolean,
  setShowQuickNotificationModal: (value: boolean) => void,
  setVisitHost: (value: {
    organizationId: number | undefined
    hostId: number | undefined
    host: IHost | null
  }) => void
) => {
  const { id, host, workspace, visitor } = row.original

  const translate = useTranslate()
  const dispatch = useAppDispatch()
  const { handleVisitorUpdate } = useUpdateVisitor()
  const { handleVisitorGroupUpdate } = useUpdateVisitorGroup()
  const { data: permissionsData } = usePermissions<string>()
  const canRemoveVisit = visitPermissionEnabled(permissionsData)

  const hasEditVisitPermissions = useHasPermission("Visit", "ReadWrite")

  return (
    <div className="flex gap-4">
      <Menu
        as="div"
        className="relative inline-flex text-left"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <Menu.Button
          className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white"
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className="align-center self-center"
          />
        </Menu.Button>
        <Menu.Items className="absolute z-50 right-0 mt-11 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() =>
                    row.original.visitors_count > 1
                      ? handleVisitorGroupUpdate(id, {
                          state: "signed_in"
                        })
                      : handleVisitorUpdate(id, visitor?.visitor_id as number, {
                          state: "signed_in"
                        })
                  }
                  className={`${
                    active
                      ? "font-medium bg-systam-blue text-white"
                      : "bg-white text-gray-600"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                >
                  {active ? (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faSignInAlt}
                        className="mr-4 text-white"
                      />
                      <span>{translate("table.visits.signIn")}</span>
                    </div>
                  ) : (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faSignInAlt}
                        className="mr-4 text-systam-blue hover:text-white"
                      />
                      <span>{translate("table.visits.signIn")}</span>
                    </div>
                  )}
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    row.original.visitors_count > 1
                      ? handleVisitorGroupUpdate(id, {
                          state: "signed_out"
                        })
                      : handleVisitorUpdate(id, visitor?.visitor_id as number, {
                          state: "signed_out"
                        })
                  }}
                  className={`${
                    active
                      ? "font-medium bg-systam-blue text-white"
                      : "bg-white text-gray-600"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                >
                  {active ? (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="mr-4 text-white"
                      />
                      <span>{translate("table.visits.signOut")}</span>
                    </div>
                  ) : (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faSignOutAlt}
                        className="mr-4 text-systam-blue hover:text-white"
                      />
                      <span>{translate("table.visits.signOut")}</span>
                    </div>
                  )}
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            {IS_PREVIEW_MODE && hasEditVisitPermissions && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                    onClick={() => {
                      onEdit(row)
                    }}
                  >
                    {active ? (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="mr-4 text-white"
                        />
                        <span>{translate("table.visits.editVisit")}</span>
                      </div>
                    ) : (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="mr-4 text-systam-blue hover:text-white"
                        />
                        <span>{translate("table.visits.editVisit")}</span>
                      </div>
                    )}
                  </button>
                )}
              </Menu.Item>
            )}
            {canRemoveVisit && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setSelectedVisitId(id)
                      setShowDeleteModal(true)
                    }}
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {active ? (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faCancel}
                          className="mr-4 text-white"
                        />
                        <span>{translate("table.visits.deleteVisit")}</span>
                      </div>
                    ) : (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faCancel}
                          className="mr-4 text-systam-blue hover:text-white"
                        />
                        <span>{translate("table.visits.deleteVisit")}</span>
                      </div>
                    )}
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => {
                    setSelectedVisitId(id)
                    setSelectedVisitorId(visitor?.visitor_id)
                    setVisitHost({
                      hostId: host?.host_id,
                      host: host,
                      organizationId: workspace?.organization?.id
                    })
                    dispatch(setIsPrintVisitBadgeOpen(true))
                  }}
                  className={`${
                    active
                      ? "font-medium bg-systam-blue text-white"
                      : "bg-white text-gray-600"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                >
                  {active ? (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faPrint}
                        className="mr-4 text-white"
                      />
                      <span>{translate("table.visits.printBadge")}</span>
                    </div>
                  ) : (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faPrint}
                        className="mr-4 text-systam-blue hover:text-white"
                      />
                      <span>{translate("table.visits.printBadge")}</span>
                    </div>
                  )}
                </button>
              )}
            </Menu.Item>
            <Menu.Item disabled>
              {({ active }) => (
                <button
                  className={`${
                    active
                      ? "font-medium bg-systam-blue text-white"
                      : "bg-white text-gray-600"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                >
                  {active ? (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faPrint}
                        className="mr-4 text-white"
                      />
                      <span>{translate("table.visits.prePrintBadges")}</span>
                    </div>
                  ) : (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faPrint}
                        className="mr-4 text-systam-blue hover:text-white"
                      />
                      <span>{translate("table.visits.prePrintBadges")}</span>
                    </div>
                  )}
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active
                      ? "font-medium bg-systam-blue text-white"
                      : "bg-white text-gray-600"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  onClick={() => {
                    void Logger().log(LOG.PRESS_HOST_NOTIFY)
                    setVisitHost({
                      hostId: host?.host_id,
                      host: host,
                      organizationId: workspace?.organization?.id
                    })
                    setShowQuickNotificationModal(!showQuickNotificationModal)
                  }}
                >
                  {active ? (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faBullhorn}
                        className="mr-4 text-white"
                      />
                      <span>{translate("table.visits.notifyHost")}</span>
                    </div>
                  ) : (
                    <div className="text-base">
                      <FontAwesomeIcon
                        icon={faBullhorn}
                        className="mr-4 text-systam-blue hover:text-white"
                      />
                      <span>{translate("table.visits.notifyHost")}</span>
                    </div>
                  )}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
      <button
        className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium leading-tight transition duration-150 ease-in-out bg-blue-100 hover:bg-blue-300 hover:text-white cursor-pointer items-center"
        onClick={(event) => {
          event.stopPropagation()
          row.toggleExpanded()
        }}
      >
        {row.getIsExpanded() ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </button>
    </div>
  )
}
