import { UseModalFormReturnType } from "@pankod/refine-react-hook-form"
import { useDelete, useTranslate } from "@pankod/refine-core"
import { Modal } from "../../components/Modal"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { useQueryClient } from "@tanstack/react-query"
import { INF_SCROLL_QUERY_KEY_TYPES } from "utilities/types"

export const DeleteVisitor: React.FC<UseModalFormReturnType | any> = ({
  modal: { visible, close },
  selectedId
}: {
  modal: { visible: boolean; close: () => void }
  selectedId: number
}) => {
  const translate = useTranslate()
  const { mutate } = useDelete()

  const queryClient = useQueryClient()
  const visitorId = selectedId

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    void Logger().log(LOG.DEL_VISITOR, `deleted ${visitorId}`)
    mutate(
      {
        id: visitorId,
        resource: `/visitors`
      },
      {
        onError: (error, variables, context) => {
          customToast.error(
            translate("notifications.deleteError", {
              resource: translate("resources.visitor")
            })
          )
          void Logger().error(LOG.DEL_VISITOR, `${error.stack}`)
        },
        onSuccess: (data, variables, context) => {
          customToast.success(
            translate("notifications.deleteSuccess", {
              resource: translate("resources.visitor")
            })
          )
          close()
        },
        onSettled() {
          setTimeout(
            () =>
              void queryClient.resetQueries({
                queryKey: [INF_SCROLL_QUERY_KEY_TYPES.VISITORS]
              }),
            200
          )
        }
      }
    )
  }

  return (
    <Modal
      title={translate("pages.visitors.deleteVisitor")}
      isOpen={visible}
      onClose={close}
    >
      <div className="text-center text-l pt-6 px-6 ">
        {translate("pages.visitors.areYouSure")}
      </div>
      <div className="flex flex-col">
        <form className="mt-6 ">
          <div className="flex justify-around">
            <button
              onClick={() => {
                void Logger().log(LOG.PRESS_CANCEL_DEL_VISITOR_MODAL)
                close()
              }}
            >
              {translate("buttons.cancel")}
            </button>
            <button
              className="py-3 px-6 font-medium text-white bg-systam-blue hover:bg-systam-dark rounded-lg"
              onClick={(event) => void onSubmit(event)}>
              {translate("buttons.delete")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
