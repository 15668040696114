import React from "react"

/**
 * Renders the Terms of Service page.
 * Redirects the user to the Systam agreement attachments page.
 */
export const TermsOfService = () => {
  React.useEffect(() => {
    window.location.href = "https://systam.fi/en/systam-agreement-attachments"
  }, [])

  return null
}
