import { createSlice } from "@reduxjs/toolkit"
import { IVisitVisitor } from "interfaces"

export const adHocVisitorSlice = createSlice({
  name: "adHocVisitor",
  initialState: Array<IVisitVisitor>,
  reducers: {
    addAdHocVisitor: (state, action: { payload: IVisitVisitor }) => {
      state.push(action.payload)
    },
    removeAdHocVisitor: (state, { payload }) => {
      state.splice(payload.index as number, 1)
    },
    clearAdHocVisitors: (state) => {
      state.splice(0, state.length)
    }
  }
})

export const { addAdHocVisitor, removeAdHocVisitor, clearAdHocVisitors } =
  adHocVisitorSlice.actions

export default adHocVisitorSlice.reducer
