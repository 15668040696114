import { generateIcon } from "resources"
import {
  IconDefinition,
  faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons"

function ErrorText(error: any, center = false): JSX.Element {
  return (
    <div
      className={`flex flex-grow border-1 rounded border-red-400 bg-red-100 px-2 py-2 ${
        center ? "justify-center" : ""
      }`}
    >
      <span className=" text-red-600 text-xs ">
        {generateIcon(faTriangleExclamation)} {error}
      </span>
    </div>
  )
}

export function SuccessText(
  text: any,
  icon?: IconDefinition,
  center = false
): JSX.Element {
  return (
    <div
      className={`flex flex-grow border-1 rounded border-emerald-400 bg-emerald-50 px-2 py-2 ${
        center ? "justify-center" : ""
      }`}
    >
      <span className=" text-emerald-500 text-xs ">
        {icon && generateIcon(faTriangleExclamation)} {text}
      </span>
    </div>
  )
}

export default ErrorText
