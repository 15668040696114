import { useUpdate } from "@pankod/refine-core"
import { VisitorDataModel } from "modules/Visit/VisitorDataModel"
import { useCallback, useState, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { useTranslate } from "@pankod/refine-core"
import { IVisitor } from "interfaces"
import { axiosInstance } from "utilities/dataProvider"

export const useUpdateVisitorGroup = () => {
  const [visitId, setVisitId] = useState<number | null>(null)
  const [visitorData, setVisitorData] = useState<VisitorDataModel | undefined>()
  const queryClient = useQueryClient()
  const { mutate: updateVisitorGroupMutation } = useUpdate<VisitorDataModel>()
  const translate = useTranslate()

  const [visitorsData, setVisitorsData] = useState<IVisitor[]>([])

  const handleVisitorGroupUpdate = useCallback(
    (visitId: number, visitorData: VisitorDataModel) => {
      setVisitId(visitId)
      setVisitorData(visitorData)
    },
    []
  )

  useEffect(() => {
    if (visitId !== null) {
      axiosInstance
        .get(`visits/${visitId}/visitors`)
        .then((response) => {
          if (Array.isArray(response.data.items)) {
            setVisitorsData(response.data.items as IVisitor[])
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [visitId])

  useEffect(() => {
    if (visitId !== null && visitorsData && visitorData) {
      visitorsData.forEach((visitor) => {
        updateVisitorGroupMutation(
          {
            id: "",
            resource: `visits/${visitId}/visitors/${visitor.visitor_id}`,
            values: visitorData
          },
          {
            onSuccess: (_data, _variables, _context) => {
              customToast.success(translate(`notifications.success`))
            },
            onError: (_error, _variables, _context) => {
              void Logger().error(LOG.EDIT_USER_PROFILE, `${_error.stack}`)
            },
            onSettled() {
              setTimeout(() => void queryClient.invalidateQueries(), 100)
            }
          }
        )
      })
    }
  }, [
    visitId,
    visitorsData,
    visitorData,
    updateVisitorGroupMutation,
    queryClient,
    translate
  ])

  return { handleVisitorGroupUpdate }
}
