/**
 * Below are the actual setting components, only Boolean and Text are created for now.
 * If settings data_type does not have corresbonding component yet,
 * then you can add it below.
 */

import {
  useCustomMutation,
  usePermissions,
  useTranslate
} from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useState, useEffect } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import customToast from "utilities/toastHelper"
import { Logger, LOG } from "utilities/logger"
import Switch from "react-switch"

export const SettingBoolean = ({
  settingsData: {
    value,
    endpoint,
    setting_metadata: { description, key }
  }
}: {
  settingsData: ISettingEntity
}) => {
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const [checked, setChecked] = useState(value === "true" ? true : false)
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")

  useEffect(() => {
    setChecked(value === "true" ? true : false)
  }, [value])

  const handleChange = () => {
    const workspace = localStorage.getItem(SELECTED_WORKSPACE) || ""

    updateMutation(
      {
        url: endpoint.replace("{id}", workspace),
        method: "put",
        values: { value: !checked ? "true" : "false" }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          setChecked(!checked)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }

  return (
    <div className="flex flex-row flex-1 mt-2 mb-4 mr-2 justify-between items-center">
      <div className="flex flex-col gap-2 justify-between mb-1">
        <div className="flex text-md font-medium">
          <label htmlFor={`switch-${key}`}>
            {translate(`pages.settings.${key}`)}
          </label>
        </div>
        {description && (
          <p className="text-gray-600 text-sm">{translate(description)}</p>
        )}
      </div>
      <Switch
        id={`switch-${key}`}
        data-testid={`switch-${key}`}
        disabled={!canEdit}
        onChange={() => void handleChange()}
        checked={checked}
        offColor="#D9D9D9"
        onColor="#34D399"
        onHandleColor="#fff"
        handleDiameter={24}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(29, 29, 29, 0.2)"
        activeBoxShadow="0px 0px 1px 10px rgba(29, 29, 29, 0.2)"
        height={32}
        width={56}
      />
    </div>
  )
}
