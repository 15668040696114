import {
  faEdit,
  faEllipsisVertical,
  faPrint,
  faSignInAlt,
  faSignOutAlt,
  faUser
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Menu } from "@headlessui/react"
import { useTranslate } from "@pankod/refine-core"
import { VisitorState } from "components/VisitorState"
import { useUpdateVisitor } from "hooks/useUpdateVisitorData"
import { IVisitor } from "interfaces"

export const AdHocVisitorAvatar = () => {
  return (
    <div className="border-2 rounded-full text-white ring-1 ring-systam-dashboard-500 bg-systam-blue w-10 h-10 flex justify-center items-center">
      <span className="text-sm">
        <FontAwesomeIcon icon={faUser} />
      </span>
    </div>
  )
}

interface AdHocVisitorInitialsAvatarProps {
  visitId: number
  initials?: string
  fullName: (string | JSX.Element)[]
  visitor?: IVisitor
}

export const AdHocVisitorInitialsAvatar = (
  props: AdHocVisitorInitialsAvatarProps
) => {
  const translate = useTranslate()
  const { handleVisitorUpdate } = useUpdateVisitor()

  return (
    <div className="border border-gray-100 rounded-md p-2 flex items-center space-x-2 w-2/3">
      <div className="border-2 rounded-full text-white ring-1 ring-systam-dashboard-500 bg-systam-blue w-10 h-10 flex justify-center items-center">
        <span className="text-sm">{props.initials?.charAt(0)}</span>
      </div>
      <div className="flex-1 text-sm sm:text-base text-gray-600">
        {props.fullName}
      </div>
      <div className="flex-1">
        <VisitorState state={props.visitor ? props.visitor.state.name : ""} />
      </div>
      <div className="flex-1">
        <Menu as="div" className="relative inline-flex text-left">
          <Menu.Button className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white">
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </Menu.Button>
          <Menu.Items className="absolute z-50 right-0 mt-11 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() =>
                      handleVisitorUpdate(
                        props.visitId,
                        props.visitor?.visitor_id as number,
                        {
                          state: "signed_in"
                        }
                      )
                    }
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {active ? (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faSignInAlt}
                          className="mr-4 text-white"
                        />
                        <span>{translate("table.visits.signIn")}</span>
                      </div>
                    ) : (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faSignInAlt}
                          className="mr-4 text-systam-blue hover:text-white"
                        />
                        <span>{translate("table.visits.signIn")}</span>
                      </div>
                    )}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      handleVisitorUpdate(
                        props.visitId,
                        props.visitor?.visitor_id as number,
                        {
                          state: "signed_out"
                        }
                      )
                    }}
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {active ? (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faSignOutAlt}
                          className="mr-4 text-white"
                        />
                        <span>{translate("table.visits.signOut")}</span>
                      </div>
                    ) : (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faSignOutAlt}
                          className="mr-4 text-systam-blue hover:text-white"
                        />
                        <span>{translate("table.visits.signOut")}</span>
                      </div>
                    )}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item disabled>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {active ? (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="mr-4 text-white"
                        />
                        <span>{translate("pages.visitors.editVisitor")}</span>
                      </div>
                    ) : (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="mr-4 text-systam-blue hover:text-white"
                        />
                        <span>{translate("pages.visitors.editVisitor")}</span>
                      </div>
                    )}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item disabled>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {active ? (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faPrint}
                          className="mr-4 text-white"
                        />
                        <span>{translate("table.visits.printBadge")}</span>
                      </div>
                    ) : (
                      <div className="text-base">
                        <FontAwesomeIcon
                          icon={faPrint}
                          className="mr-4 text-systam-blue hover:text-white"
                        />
                        <span>{translate("table.visits.printBadge")}</span>
                      </div>
                    )}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  )
}
