import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { App } from "./App"
import "./utilities/i18n"
import { Provider } from "react-redux"
import { store } from "reduxStore/store"
import { PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "config/msal"
import { MsalProvider } from "@azure/msal-react"
import { PostHogProvider } from "posthog-js/react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { googleOAuth } from "config/googleOAuth"

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
}

const pca = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={googleOAuth.auth.clientId}>
        <MsalProvider instance={pca}>
          <React.Suspense fallback="loading">
            <PostHogProvider
              apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
              options={options}
            >
              <App />
            </PostHogProvider>
          </React.Suspense>
        </MsalProvider>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
)
