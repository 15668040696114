import { IContactDetail } from "interfaces"

/**
 * Should send notification to host for a given contact detail type that is primary
 * @param contactDetails Host contact details
 * @param contactDetailType Contact detail type to check
 * @returns True if given contact detail type is primary and notifications are enabled, otherwise false
 */
export const shouldSendNotification = ({
  contactDetails,
  contactDetailType
}: {
  contactDetails: IContactDetail[]
  contactDetailType: string
}): boolean => {
  const sendPrimaryNotification = contactDetails.find(
    (contactDetail: { type: string; primary: boolean }) =>
      contactDetail.type === contactDetailType && contactDetail.primary === true
  )

  return (
    sendPrimaryNotification !== undefined &&
    sendPrimaryNotification.notifications
  )
}

export const contactDetailPrimaryByType = ({
  contactDetails,
  contactDetailType
}: {
  contactDetails: IContactDetail[]
  contactDetailType: string
}): string => {
  if (contactDetails === undefined) {
    return ""
  }

  const contactDetailPrimaryByTypeResult = contactDetails.find(
    (contactDetail: { type: string; primary: boolean }) =>
      contactDetail.type === contactDetailType && contactDetail.primary === true
  )

  return contactDetailPrimaryByTypeResult?.value ?? ""
}
