import classNames from "classnames"
import React from "react"

export type SyTypographyFontFamily = "garnett" | "dmSans"

export type SyTypographyFontWeight = "normal" | "medium" | "semibold" | "bold"

export type SyTypographyFontSize =
  | "xs"
  | "sm"
  | "base"
  | "lg"
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "9xl"

export interface SyTypographyProps {
  readonly family?: SyTypographyFontFamily
  readonly weight?: SyTypographyFontWeight
  readonly size?: SyTypographyFontSize
  readonly children: string
}

export function SyTypography({
  family,
  weight,
  size,
  children
}: SyTypographyProps): React.ReactElement {
  // TODO: using important prefix '!' is bad form, and should be removed
  //  only using it here because there is an existing 'important' modifier defined globally in 'index.css'
  //  after that is remove, remove the '!' prefix here as well
  const cssFontFamily = `!font-${family ?? "dmSans"}`
  const cssFontWeight = `font-${weight ?? "normal"}`
  const cssFontSize = `text-${size ?? "base"}`

  const classes = classNames([cssFontFamily, cssFontWeight, cssFontSize])

  return <span className={classes}>{children}</span>
}
