import { useCustom, useTranslate } from "@pankod/refine-core"
import { Select } from "components/globals/Select"
import { IDeviceType } from "interfaces"
import { ChangeEvent, Dispatch, SetStateAction } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export const DeviceTypeDropdown = ({
  value,
  setState
}: {
  value: any
  setState: Dispatch<SetStateAction<any>>
}) => {
  const { data, isLoading } = useCustom<[IDeviceType]>({
    url: `/device-types`,
    method: "get"
  })
  const translate = useTranslate()

  return (
    <>
      {isLoading && (
        <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
          <Skeleton className="mt-6" count={1} height={40} />
        </SkeletonTheme>
      )}
      {data?.data !== undefined && data?.data.length > 1 && (
        <Select
          label={translate("pages.devices.deviceType")}
          placeholder=""
          id="deviceTypeDropdown"
          optionData={data?.data}
          allowShowAll={true}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            if (e?.target?.selectedIndex === 0) {
              setState("")
            } else {
              setState(e?.target?.options[e.target.selectedIndex]?.text)
            }
          }}
        />
      )}
    </>
  )
}
