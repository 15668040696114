import { useTranslate, useCustomMutation } from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useState, useEffect } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"

export const SettingPhoneNumber = ({
  settingsData: {
    value,
    endpoint,
    setting_metadata: { description, key, data_type }
  }
}: {
  settingsData: ISettingEntity
}) => {
  const selectedWorkspaceId = localStorage.getItem(SELECTED_WORKSPACE) || ""
  const [savedValue, setSavedValue] = useState(value)
  const [settingValue, setSettingValue] = useState("")
  const [settingDataType, setSettingDataType] = useState("")
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()

  useEffect(() => {
    setSavedValue(value)
  }, [value])

  useEffect(() => {
    setSettingValue(value)
    setSettingDataType(data_type.toLowerCase())
  }, [value, data_type])

  const updateSetting = (value: string) => {
    if (savedValue === settingValue || selectedWorkspaceId === "") {
      return
    }

    // TODO: validation logic here

    updateMutation(
      {
        url: endpoint.replace("{id}", selectedWorkspaceId),
        method: "put",
        values: { value }
      },
      {
        onSuccess: (data, _variables, _context) => {
          setSavedValue(data.data?.value as string)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().log(LOG.EDIT_SETTING, key)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }

  return (
    <div className="flex flex-col flex-1 mb-8">
      <div className="flex justify-between mb-3 lg:w-1/2">
        <p className="font-medium self-center text-md">
          {translate(`pages.settings.${key}`)}
        </p>
        <input
          type={settingDataType}
          className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:border-2 block p-2.5 w-1/2"
          value={settingValue}
          onChange={(event) => setSettingValue(event.target.value)}
          onBlur={() => updateSetting(settingValue)}
        ></input>
      </div>
      <p className="text-gray-500 lg:w-1/3 text-sm">{description}</p>
      {settingDataType === "picture" && (
        <div className="flex flex-col pt-2">
          <img
            id="preview"
            className="inset-0 w-1/3 h-32"
            alt="Preview"
            src={settingValue}
          />
        </div>
      )}
    </div>
  )
}
