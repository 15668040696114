import { DataProvider, HttpError } from "@pankod/refine-core"
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios"
import {
  ACCESS_TOKEN_KEY,
  HTTP_STATUS_OK,
  HTTP_STATUS_UNAUTHORIZED,
  REFRESH_TOKEN_KEY
} from "./constants"
import { stringify } from "query-string"
import jwt_decode from "jwt-decode"
import { CustomJwtPayload } from "interfaces"
import { createBrowserHistory } from "history"
import {
  isTokenExpired,
  shouldSignOutUserInterceptError
} from "./refreshTokenUtils"
import { AccessTokenResponse } from "./types"
import { Logger, LOG } from "utilities/logger"
import { isEmptyString } from "./string"

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})

const axiosNoUrl = axios.create()

axiosInstance.interceptors.request.use(
  (config) => {
    if (config?.headers) {
      config.headers["Content-Type"] = "application/json"
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`
      }
    }
    return config
  },
  async (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status
    }

    return Promise.reject(customError)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosError) => {
    const originalRequest = error.config
    void Logger().error(LOG.ERROR_AXIOS_INTERCEPTOR, error.message)
    switch (error.response?.status) {
      case HTTP_STATUS_UNAUTHORIZED:
        {
          const accessTokenFromStorage = jwt_decode<CustomJwtPayload>(
            localStorage.getItem(ACCESS_TOKEN_KEY) ?? ""
          )
          // Refresh token has been expired - sign out user and require to login again
          shouldSignOutUserInterceptError(error)
          // When an access token is expired, the client can request a new access
          // token by presenting a valid refresh token.
          if (isTokenExpired(accessTokenFromStorage)) {
            const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
            const refreshTokenAxiosInstance = axios.create()

            try {
              const refreshTokenResponse =
                await refreshTokenAxiosInstance.post<AccessTokenResponse>(
                  `${process.env.REACT_APP_API_URL}/token/refresh`,
                  null,
                  {
                    headers: {
                      Authorization: `Bearer ${refreshToken}`
                    }
                  }
                )

              if (refreshTokenResponse.status === HTTP_STATUS_OK) {
                localStorage.setItem(
                  ACCESS_TOKEN_KEY,
                  refreshTokenResponse.data.access_token
                )
                localStorage.setItem(
                  REFRESH_TOKEN_KEY,
                  refreshTokenResponse.data.refresh_token
                )

                if (originalRequest) {
                  return axiosInstance(originalRequest)
                } else {
                  throw new Error("originalRequest is undefined")
                }
              }
            } catch (e) {
              void Logger().error(LOG.DATA_PROVIDER, `${e}`)
              if (
                axios.isAxiosError(e) &&
                e.response &&
                e.response.status === HTTP_STATUS_UNAUTHORIZED
              ) {
                localStorage.clear()
                createBrowserHistory().replace("/login")
                window.location.reload()
              }
            }
          }
        }
        break
      default:
        break
    }

    return Promise.reject(error)
  }
)

const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
  custom: async ({ url, method, payload, query, headers, filters }) => {
    const requestUrl = `${apiUrl}/${url}`
    let axiosResponse: AxiosResponse<any, any>
    switch (method) {
      case "put":
        axiosResponse = await httpClient[method](url, payload)
        break
      case "post":
      case "patch":
        axiosResponse = await httpClient[method](url, payload)
        break
      case "delete":
        axiosResponse = await httpClient.delete(url)
        break
      default:
        axiosResponse = await httpClient.get(requestUrl)
        break
    }

    const { data } = axiosResponse

    return Promise.resolve({ data })
  },
  getList: async (props) => {
    const url = `${apiUrl}/${props.resource}`
    const { data } = await httpClient.get(url)

    if (data?.length > 0) {
      return { data, total: data.length }
    }

    return {
      data: data.items,
      total: -1
    }
  },
  getMany: async ({ resource, ids }) => {
    const { data } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`
    )

    return {
      data
    }
  },
  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`
    const { data } = await httpClient.post(url, variables)

    return {
      data
    }
  },
  createMany: async ({ resource, variables }) => {
    const response = await Promise.all(
      variables.map(async (param) => {
        const { data } = await httpClient.post(`${apiUrl}/${resource}`, param)
        return data
      })
    )

    return { data: response }
  },
  update: async ({ resource, id, variables }) => {
    const getUpdateUrl = (id: string | number): string =>
      isEmptyString(id as string)
        ? `${apiUrl}/${resource}`
        : `${apiUrl}/${resource}/${id}`

    const url = getUpdateUrl(id)
    const { data } = await httpClient.patch(url, variables)

    return {
      data
    }
  },

  updateMany: async ({ resource, ids, variables }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.patch(
          `${apiUrl}/${resource}/${id}`,
          variables
        )
        return data
      })
    )

    return { data: response }
  },

  getOne: async ({ resource, id }) => {
    const geOneUrl = (id: string | number): string =>
      isEmptyString(id as string)
        ? `${apiUrl}/${resource}`
        : `${apiUrl}/${resource}/${id}`

    const url = geOneUrl(id)
    const { data } = await httpClient.get(url)

    return {
      data
    }
  },

  deleteOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`

    const { data } = await httpClient.delete(url)

    return {
      data
    }
  },

  deleteMany: async ({ resource, ids }) => {
    const response = await Promise.all(
      ids.map(async (id) => {
        const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`)
        return data
      })
    )
    return { data: response }
  },
  getApiUrl: () => {
    return apiUrl
  }
})

export { dataProvider, axiosInstance, axiosNoUrl }
