import {
  Authenticated,
  usePermissions,
  useQuery,
  useQueryClient,
  useRouterContext,
  useTranslate
} from "@pankod/refine-core"
import { useParams } from "@pankod/refine-react-router-v6"
import { IDevice } from "interfaces"
import { useEffect, useState } from "react"
import { DeleteDevice } from "./delete"
import { DeviceContainer } from "./list"
import { TabButton } from "components/globals/TabButton"
import { DeviceSettingsTab } from "./components/DeviceSettingsTab"
import { axiosInstance } from "utilities/dataProvider"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export const DeviceShow: React.FC = () => {
  const { deviceId } = useParams<"deviceId">()

  const { data: permissionsData } = usePermissions<string>()

  const showLinkedDevicesTab =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Device.All")

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const translate = useTranslate()
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const queryClient = useQueryClient()

  useEffect(() => {
    void queryClient.resetQueries({
      queryKey: [`device`]
    })
  }, [])

  const { data: deviceData, isLoading } = useQuery<IDevice>(
    ["device"],
    async () => {
      const res = await axiosInstance.get(`devices/${deviceId}`)
      return res.data
    }
  )

  const [activeTab, setActiveTab] = useState("general")

  return (
    <Authenticated>
      <div className="container mx-auto">
        {showDeleteModal && (
          <DeleteDevice
            device={deviceData}
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
        <div className="mb-6 flex row h-12 items-center justify-between">
          <button
            onClick={() => {
              deviceData?.model.type === "Room Display"
                ? push("../displays")
                : push("../devices")
            }}
          >
            <BackButton />
          </button>
          <h1 className="text-3xl px-4 font-medium break-all truncate">
            {deviceData?.name}
          </h1>
          <div />
        </div>
        <div className="my-6 border-b border-gray-200">
          <div className="-mb-px flex space-x-8">
            <TabButton
              activeTab={activeTab}
              tabId="general"
              tabTitle={translate("pages.devices.tab.generalSettings")}
              setActiveTab={setActiveTab}
            />
            {deviceData?.model.type !== "Printer" && (
              <TabButton
                activeTab={activeTab}
                tabId="settings"
                tabTitle={translate("pages.devices.tab.customization")}
                setActiveTab={setActiveTab}
              />
            )}
            {showLinkedDevicesTab &&
              deviceData?.model.type !== "Room Display" && (
                <TabButton
                  activeTab={activeTab}
                  tabId="links"
                  tabTitle={translate("pages.devices.tab.linkedDevices")}
                  setActiveTab={setActiveTab}
                />
              )}
          </div>
        </div>
        {isLoading && (
          <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
            <div className="flex flex-row">
              <Skeleton
                className="mt-2 mr-2"
                count={1}
                width={260}
                height={400}
              />
              <Skeleton className="mt-2" count={1} width={750} height={400} />
            </div>
          </SkeletonTheme>
        )}
        {deviceData && (
          <div className="flex row gap-2">
            <div className="w-80 flex-col">
              <DeviceContainer
                id={deviceData?.id}
                device={deviceData}
                isDeviceShow={true}
              />
            </div>
            <div
              className={`w-full px-4 relative flex flex-col justify-between`}
            >
              <DeviceSettingsTab
                device={deviceData}
                activeTab={activeTab}
                deviceId={deviceData?.id}
              />
            </div>
          </div>
        )}
      </div>
    </Authenticated>
  )
}

export const BackButton = () => {
  return (
    <svg
      id="Icons"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      fill="black"
      className="w-8 h-8"
    >
      <path d="M13,26a1,1,0,0,1-.71-.29l-9-9a1,1,0,0,1,0-1.42l9-9a1,1,0,1,1,1.42,1.42L5.41,16l8.3,8.29a1,1,0,0,1,0,1.42A1,1,0,0,1,13,26Z" />
      <path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
    </svg>
  )
}
