import { Dispatch, SetStateAction } from "react"

export const TabButton = ({
  activeTab,
  tabId,
  tabTitle,
  setActiveTab,
  itemCount
}: {
  activeTab: string
  tabId: string
  tabTitle: string
  setActiveTab: Dispatch<SetStateAction<string>>
  itemCount?: number
}) => {
  const active = activeTab === tabId

  return (
    <button
      className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium
        ${
          active
            ? "border-blue-600 text-blue-600"
            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
        }`}
      onClick={() => {
        setActiveTab(tabId)
      }}
    >
      {tabTitle}
      {itemCount !== undefined && (
        <span className="text-md border rounded-full ml-1 px-2 text-black">
          {itemCount}
        </span>
      )}
    </button>
  )
}
