import { IconDefinition } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { ClipLoader } from "react-spinners"

export enum ButtonStyle {
  BlackPrimary,
  BluePrimary,
  BlueSecondary,
  PinkPrimary,
  RedPrimary,
  RedSecondary,
  TransparentBorder,
  TransparentNoBorder
}

type ButtonProps = {
  /** Button text */
  name: string
  /** Use ButtonStyle for button preset style or string for custom css. */
  style?: ButtonStyle | string
  /** OnClick function */
  onClick?: () => void
  disabled?: boolean
  /** Button type */
  type?: "button" | "submit" | "reset"
  loading?: boolean
  icon?: IconDefinition
  /** Custom class name */
  className?: string
}

const blackPrimary =
  "!bg-one-gray-900 px-3 text-white min-w-16 font-medium focus:outline-none"
const bluePrimary =
  "!bg-systam-blue px-3 text-white min-w-32 font-medium hover:bg-blue-900 focus:outline-none"
const blueSecondary = "px-1 text-soft-blue min-w-32"
const pinkPrimary = "!bg-one-rose-200 px-3 text-one-gray-950 min-w-32"
const redPrimary = "!bg-soft-red px-3 text-white min-w-32"
const redSecondary = "px-1 text-soft-red min-w-32"
const transparentBorder =
  "px-1 min-w-32 border border-transparent !bg-gray-200 text-gray-600 rounded-md hover:bg-gray-600 font-medium focus:outline-none"
const transparentNoBorder = "px-1 min-w-32 text-gray-500"

export const Button: React.FC<ButtonProps> = ({
  name,
  style = ButtonStyle.BluePrimary,
  type = "button",
  disabled = false,
  loading = false,
  onClick,
  icon,
  className
}) => {
  let css: string | undefined

  if (typeof style === "string") {
    css = style
  } else {
    switch (style) {
      case ButtonStyle.BlackPrimary:
        css = blackPrimary
        break
      case ButtonStyle.BluePrimary:
        css = bluePrimary
        break
      case ButtonStyle.BlueSecondary:
        css = blueSecondary
        break
      case ButtonStyle.PinkPrimary:
        css = pinkPrimary
        break
      case ButtonStyle.RedPrimary:
        css = redPrimary
        break
      case ButtonStyle.RedSecondary:
        css = redSecondary
        break
      case ButtonStyle.TransparentBorder:
        css = transparentBorder
        break
      case ButtonStyle.TransparentNoBorder:
        css = transparentNoBorder
        break
      default:
        css = bluePrimary
        break
    }
  }

  if (disabled) {
    css += " !cursor-not-allowed focus:outline-none"
  }

  return (
    <button
      className={[
        "flex justify-center items-center py-1 my-1 rounded-md disabled:opacity-75",
        css,
        className
      ].join(" ")}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {loading ? (
        <ClipLoader
          loading
          color="#fff"
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        name
      )}
      {icon ? <FontAwesomeIcon icon={icon} className="ml-2" /> : null}
    </button>
  )
}
