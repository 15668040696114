import React, { useCallback, useMemo, useState } from "react"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslate } from "@pankod/refine-core"
import { SyTypography } from "../display"
import classNames from "classnames"

export interface SyFileUploadProps {
  readonly id?: string
  readonly accept?: readonly string[]
  readonly multiple?: boolean
  readonly files?: readonly File[] | undefined
  readonly onFileUploadChange: (files: readonly File[] | undefined) => void
}

export function SyFileUpload({
  id,
  accept,
  multiple,
  files,
  onFileUploadChange
}: SyFileUploadProps): React.ReactElement {
  const t = useTranslate()

  const [higlight, setHighlight] = useState(false)

  const finalAccept = useMemo(
    () => (accept ? accept.join(",") : undefined),
    [accept]
  )

  const acceptText = useMemo(
    () => getAcceptText(accept, t("general.or")),
    [accept, t]
  )

  const filesTexts = useMemo(() => getFileTexts(files), [files])

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFileUploadChange(toFileArray(event.target.files))
    },
    []
  )

  const handleDragOver = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault()
      setHighlight(true)
    },
    [setHighlight]
  )

  const handleDragLeave = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault()
      setHighlight(false)
    },
    [setHighlight]
  )

  const handleDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault()
      setHighlight(false)
      const files = toFileArray(event.dataTransfer.files) ?? []
      const acceptedFiles = filterAcceptableFiles(files, accept ?? [])
      if (acceptedFiles.length === 0 || !acceptedFiles[0]) {
        return
      }
      const finalFiles = multiple ? acceptedFiles : [acceptedFiles[0]]
      onFileUploadChange(finalFiles)
    },
    [onFileUploadChange, accept, multiple]
  )

  const labelClasses = classNames(
    "flex flex-col items-center justify-center w-full h-[200px] border-2 border-sy-gray-300 border-dashed rounded-lg cursor-pointer  hover:bg-sy-gray-100",
    {
      "bg-sy-gray-50": !higlight
    },
    {
      "bg-sy-gray-100": higlight
    }
  )

  return (
    <div
      className="flex items-center justify-center w-full"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <label htmlFor={id} className={labelClasses}>
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <FontAwesomeIcon
            icon={faUpload}
            size="xl"
            className="text-sy-gray-400 mb-2"
          />
          <p className="text-sy-gray-500">
            <SyTypography weight="semibold" size="sm">
              {t("components.fileUpload.clickToUpload")}
            </SyTypography>{" "}
            <SyTypography size="sm">
              {t("components.fileUpload.dragAndDrop")}
            </SyTypography>
          </p>
          {acceptText && (
            <p className="text-sy-gray-500">
              <SyTypography size="xs">{acceptText}</SyTypography>
            </p>
          )}
          {filesTexts && (
            <div className="flex flex-col items-center text-sy-gray-500 mt-2">
              {filesTexts.map((fileText, index) => (
                <SyTypography key={index} size="xs">
                  {fileText}
                </SyTypography>
              ))}
            </div>
          )}
        </div>
        <input
          id={id}
          type="file"
          className="hidden"
          accept={finalAccept}
          multiple={multiple}
          onChange={handleFileChange}
        />
      </label>
    </div>
  )
}

function getAcceptText(
  accept: readonly string[] | undefined,
  orSeparator: string
): string | undefined {
  if (!accept || accept.length === 0) {
    return undefined
  }

  if (accept.length === 1) {
    return accept[0]
  }

  return `${accept.slice(0, -1).join(", ")} ${orSeparator} ${
    accept[accept.length - 1]
  }`
}

const SHOW_FILE_COUNT = 2

function getFileTexts(
  files: readonly File[] | undefined
): readonly string[] | undefined {
  if (!files || files.length === 0) {
    return undefined
  }

  const shownFilesName = files
    .slice(0, SHOW_FILE_COUNT)
    .map((file) => file.name)
  const remainingFilesCount = files.length - SHOW_FILE_COUNT
  const fileItems = [
    ...shownFilesName,
    ...(remainingFilesCount > 0 ? [`(+${remainingFilesCount})`] : [])
  ]

  return fileItems
}

function toFileArray(files: FileList | null): readonly File[] | undefined {
  return files ? Array.from(files) : undefined
}

function filterAcceptableFiles(
  files: readonly File[],
  accept: readonly string[]
): readonly File[] {
  if (accept.length === 0) {
    return files
  }

  // currently, only implement filtering by extension in
  const acceptByExtension = accept
    .filter((a) => /^\.\w+$/.test(a))
    .map((a) => a.toLocaleLowerCase())

  // file name has to match at least one of the extensions
  return files.filter((file) =>
    acceptByExtension.some((a) => file.name.toLocaleLowerCase().endsWith(a))
  )
}
