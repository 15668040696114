import { Fragment, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"

type DrawerProps = {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  description?: string
  onClose?: () => void
  children: React.ReactNode
}

export const Drawer = (props: DrawerProps) => {
  useEffect(() => {
    // Hide/show help-widget
    if (window) {
      const widgetState = props.open ? "hide" : "show"
      window.FreshworksWidget(widgetState)
    }
  }, [props.open])

  return (
    <Transition.Root appear show={props.open} as={Fragment}>
      <Dialog
        unmount
        as="div"
        className="fixed inset-0"
        open={props.open}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-one-gray-950/50"
            aria-hidden="true"
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transform transition ease-out duration-200"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-out duration-100"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="absolute sm:top-2 sm:right-2 sm:bottom-2 sm:w-144 sm:h-auto h-full w-full bg-white sm:rounded-xl rounded-none shadow">
            <div className="p-6 flex justify-between items-center">
              <h2 className="text-2xl font-kanit">{props.title}</h2>

              <button
                className="flex justify-center items-center h-8 w-8 bg-one-gray-100 hover:bg-one-gray-200 rounded-full"
                onClick={props.onClose}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
            </div>

            {props.description && (
              <div className="px-6">
                <div className="p-4 flex text-one-gray-800 bg-one-gray-100 rounded-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="mt-0.5 mr-2 flex-shrink-0 w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>

                  <div>{props.description}</div>
                </div>
              </div>
            )}

            <div className="px-6">{props.children}</div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}
