import { useState } from "react"
import { Logger, LOG } from "utilities/logger"
import { LOCAL_STORAGE_KEYS } from "utilities/types"

export const useLocalStorage = (
  key: LOCAL_STORAGE_KEYS,
  initialValue
): [any, (arg0: any) => void] => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue
    }
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      void Logger().error(LOG.LOCAL_STORAGE, `${error}`)
      return initialValue
    }
  })

  const setValue = (value: (arg0: any) => void) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      void Logger().error(LOG.LOCAL_STORAGE, `${error}`)
    }
  }
  return [storedValue, setValue]
}
