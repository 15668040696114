import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface DeviceSearchState {
    deviceSearchInput: string
}

const deviceSearch = localStorage.getItem("deviceSearch")

const initialState: DeviceSearchState = {
    deviceSearchInput: deviceSearch || ""
}

export const deviceSearchSlice = createSlice({
    name: "deviceSearch",
    initialState,
    reducers: {
        setDeviceSearch: (state, action: PayloadAction<string>) => {
            state.deviceSearchInput = action.payload
            localStorage.setItem("deviceSearch", action.payload)
        }
    }
})

export const { setDeviceSearch } = deviceSearchSlice.actions
export default deviceSearchSlice.reducer
