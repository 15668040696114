import { useTranslate } from "@pankod/refine-core"
import { DateTime } from "luxon"
import { leadingZero } from "utilities/string"

export const SignedInCell = (start_time: string) => {
  const translate = useTranslate()
  const {
    day,
    month,
    year,
    hour,
    minute,
    invalidReason: startTimeInvalid
  } = DateTime.fromISO(start_time + "Z")

  let startDate: string
  if (startTimeInvalid) {
    startDate = translate("not_found")
  } else {
    startDate = `${leadingZero(hour)}:${leadingZero(minute)}`
  }

  return (
    <div>
      <p className="font-medium truncate">{`${startDate}`}</p>
      <p className="text-gray-500">{`${leadingZero(day)}.${leadingZero(
        month
      )}.${leadingZero(year)}`}</p>
    </div>
  )
}
