import React, { useEffect } from "react"
import workspace from "assets/images/systam_vastaanotto.png"
import visitor from "assets/images/systam_asiakas.png"
import visit from "assets/images/systam_neuvotteluhuone.png"
import building from "assets/images/Systam_kiinteistosi.png"
import hostnotify from "assets/images/Systam_helpota_tyota.png"
import { useCustom, useOne } from "@pankod/refine-core"
import { IUserInfo, IWorkspace } from "interfaces"
import { setSelectedWorkspace } from "reduxStore/reducers/workspaceReducer"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export const Onboarding: React.FC = () => {
  const { data: workspaceData, isLoading } = useCustom<[IWorkspace]>({
    url: "workspaces/",
    method: "get"
  })

  const dispatch = useAppDispatch()
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const { data: userInfo } = useOne<IUserInfo>({
    resource: "users/me",
    id: ""
  })

  useEffect(() => {
    if (!isLoading && !selectedWorkspaceId) {
      const { id } = workspaceData?.data[0] || {}
      dispatch(setSelectedWorkspace(id))
    }
  }, [isLoading, selectedWorkspaceId])

  const currentWorkspace = workspaceData?.data?.at(
    workspaceData?.data.findIndex(
      (obj) => obj.id === parseInt(selectedWorkspaceId || "")
    )
  )

  return (
    <div>
      <>
        {isLoading && (
          <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
            <Skeleton count={1} height={40} />
          </SkeletonTheme>
        )}
      </>
      <div className="max-w-sm w-full lg:max-w-full lg:flex mt-4">
        <div className="rounded-b p-4 flex flex-col justify-between leading-normal">
          <div className="text-gray-900 font-medium text-4xl mb-4">
            Welcome{" "}
            <span className="underline">{`${userInfo?.data?.person?.first_name} ${userInfo?.data?.person?.last_name}`}</span>{" "}
            to Systam Dashboard
            <p className="mt-4 mb-4">
              Your assigned workspace is - {currentWorkspace?.name}
            </p>
            <p className="mb-2">
              Your assigned role is - {userInfo?.data?.role?.name}
            </p>
          </div>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 lg:w-1/3 px-4">
              <div className="p-4 md:px-7 xl:px-10 rounded-[10px] border border-gray-100 bg-white shadow-md mb-8">
                <div className="flex items-center justify-center bg-primary rounded-2xl mb-8">
                  <div className="mt-4 text-gray-700 text-base">
                    <h1 className="font-medium text-xl text-dark mb-3">
                      What is Systam Dashboard?
                    </h1>
                    <p className="text-gray-700 text-lg mb-4">
                      The Systam Dashboard is a place where you can manage your
                      visits, users and hosts. It's our web app for front desk
                      staff, security teams and hosts. Not sure where to start?
                      Here is everything you need to know to get started.
                    </p>
                    <h1 className="font-medium text-xl text-dark mb-3">
                      Getting Started
                    </h1>
                    <p className="text-gray-700 text-lg mb-4">
                      Getting started with Systam Dashboard is easy. You can
                      start by{" "}
                      <button className="text-systam-blue">
                        adding a new visit
                      </button>
                      . You can also{" "}
                      <button className="text-systam-blue">
                        add a new user
                      </button>
                      or{" "}
                      <button className="text-systam-blue">add a host</button>{" "}
                      depending on your role within your organization.
                    </p>
                    <h1 className="font-medium text-xl text-dark mb-3">
                      Updating Your Profile
                    </h1>
                    <p className="text-gray-700 text-lg mb-4">
                      You may want to{" "}
                      <button className="text-systam-blue">
                        update your profile
                      </button>{" "}
                      information. You can do this by clicking on your profile
                      picture in the top right corner of the screen.
                    </p>
                    <p className="text-gray-700 text-lg mb-4">
                      <h1 className="font-medium text-2xl text-dark mb-3">
                        Not sure where to start? Click on any of the buttons
                        below to get you up and running in no time.
                      </h1>
                    </p>
                    <div className="flex flex-row mt-4">
                      <img
                        className="m-4 ring-1 h-28 w-28 bg-systam-blue rounded-full hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-systam-blue focus:ring-4 focus:ring-blue-300 focus:outline-none object-cover aspect-square hover:scale-150 transition duration-300 ease-in-out"
                        src={building}
                        alt="Visitor Management"
                      />
                      <img
                        className="m-4 ring-1 h-28 w-28 bg-systam-blue rounded-full hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-systam-blue focus:ring-4 focus:ring-blue-300 focus:outline-none object-cover aspect-square hover:scale-150 transition duration-300 ease-in-out"
                        src={visitor}
                        alt="Visitor Management"
                      />
                      <img
                        className="m-4 ring-1 h-28 w-28 bg-systam-blue rounded-full hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-systam-blue focus:ring-4 focus:ring-blue-300 focus:outline-none object-cover aspect-square hover:scale-150 transition duration-300 ease-in-out"
                        src={workspace}
                        alt="Visitor Management"
                      />
                      <img
                        className="m-4 ring-1 h-28 w-28 bg-systam-blue rounded-full hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-systam-blue focus:ring-4 focus:ring-blue-300 focus:outline-none object-cover aspect-square hover:scale-150 transition duration-300 ease-in-out"
                        src={hostnotify}
                        alt="Visitor Management"
                      />
                      <img
                        className="m-4 ring-1 h-28 w-28 bg-systam-blue rounded-full hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-systam-blue focus:ring-4 focus:ring-blue-300 focus:outline-none object-cover aspect-square hover:scale-150 transition duration-300 ease-in-out"
                        src={visit}
                        alt="Visitor Management"
                      />
                    </div>
                    <div className="hidden">
                      <h1 className="font-medium text-xl text-dark mb-3">
                        Visitor Types
                      </h1>
                      <h1 className="font-medium text-lg text-dark mb-3">
                        Ad hoc Visitor
                      </h1>
                      <p className="text-gray-700 text-lg">
                        Ad hoc visitor refers to a one-time or spontaneous
                        visitor to a workspace. This type of visitor is not
                        pre-registered or scheduled for a visit, but arrives
                        unannounced or unexpectedly. An ad hoc visitor typically
                        requires a process for checking in, verifying identity,
                        and determining the purpose for the visit - and
                        optionally obtaining a visitor badge. The Systam
                        Dashboard can be used to track the entry (sign in) and
                        exit (sign out) of ad hoc visitors and ensure for
                        example that security protocols for workspace are
                        followed.
                      </p>
                    </div>
                    <div className="mt-4">
                      <a
                        href="/visits/new"
                        className="text-systam-blue text-sm font-medium"
                      >
                        Do not show me again
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
