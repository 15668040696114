import { LayoutProps, useTranslate } from "@pankod/refine-core"
import { Nav } from "./Nav"
import { DrawerWrapper } from "./drawer/DrawerWrapper"

export const Layout: React.FC<LayoutProps> = ({ children, Sider }) => {
  const showSider = !!Sider
  const actingAsRole = localStorage.getItem("actAsRole") === "true"
  const translate = useTranslate()

  return (
    <div className="pt-2 flex w-full bg-one-gray-100">
      {showSider && <Sider />}
      <div className="border-l border-l-one-gray-950/15 border-t border-t-one-gray-950/10 shadow-sm rounded-tl-lg bg-white flex-col flex-grow overflow-hidden">
        {!actingAsRole ? (
          <Nav />
        ) : (
          <div className="flex justify-center items-center bg-yellow-300 py-2">
            <div className="flex items-center font-medium">
              {translate("actAsRole.you_are")}
              <p className="font-bold ml-1">
                {translate("actAsRole.organizationOwner")}
              </p>
              <button
                className="ml-2 text-white bg-one-gray-950 px-2 py-1 rounded-md hover:bg-one-gray-950/80"
                onClick={() => {
                  // Clear all the local/session storage and close the tab
                  window.sessionStorage.clear()
                  localStorage.clear()
                  window.close()
                }}
              >
                {translate("actAsRole.leave")}
              </button>
            </div>
          </div>
        )}
        <DrawerWrapper />
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{children}</div>
      </div>
    </div>
  )
}
