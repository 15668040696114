import { useQuery, useRouterContext, useTranslate } from "@pankod/refine-core"
import { Button } from "components/globals/Button"
import { ICaledarIntegration } from "interfaces"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { axiosInstance } from "utilities/dataProvider"
import { QUERY_KEYS } from "utilities/types"
import {
  faCaretDown,
  faCaretUp,
  faPlus
} from "@fortawesome/free-solid-svg-icons"
import { getResourceTypeIcon } from "utilities/icons"
import { CheckIcon } from "@heroicons/react/20/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import error_icon from "assets/images/error_icon.svg"

export const CalendarProviderDropdown = ({
  value,
  setState,
  error
}: {
  value: any
  setState: Dispatch<SetStateAction<any>>
  error?: boolean
}) => {
  const { data: calendarIntegrations, isLoading } = useQuery<
    [ICaledarIntegration]
  >([QUERY_KEYS.CALENDAR_INTEGRATION], async () => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/calendars`
    )
    res.data = [
      {
        name: null,
        id: 0
      },
      ...res.data
    ]
    return res.data
  })

  const [isOpen, setIsOpen] = useState(false)
  const { useHistory } = useRouterContext()
  const { push }: { push: (url: string) => void } = useHistory()
  const ref = useRef<HTMLDivElement>(null)

  // Event handler to close the dropdown if clicked outside
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const translate = useTranslate()

  const label = translate("pages.devices.settings.calendarAccount")

  if (isLoading) {
    return (
      <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
        <Skeleton className="mt-6" count={1} height={40} />
      </SkeletonTheme>
    )
  }

  const noIntegrations =
    !calendarIntegrations || calendarIntegrations.length <= 1
  if (noIntegrations) {
    return (
      <NoIntegrationsDisplay
        label={label}
        text={translate("pages.devices.settings.noCalendarIntegrationsText")}
        addIntegrationLabel={translate(
          "pages.devices.settings.addCalendarIntegration"
        )}
        onAddIntegrationClick={() => {
          push("/calendar-integrations")
        }}
      />
    )
  }

  const calendars = calendarIntegrations.map((cal) => {
    const name = cal.username
    const icon = generateIcon(cal.integration?.type)
    const isSelected = cal.id === value

    return (
      <button
        key={cal.id}
        id={`calendar_${cal.id}`}
        className="flex flex-row bg-white items-center p-1 hover:bg-one-gray-100 cursor-pointer"
        onClick={() => {
          setState(cal.id)
          setIsOpen(false)
        }}
      >
        {icon}
        <div
          id={cal?.id?.toString()}
          className={`ml-2 ${isSelected && `font-medium`}`}
        >
          {name ?? "None"}
        </div>
        {isSelected && <CheckIcon className="w-4 h-4 ml-auto mr-2" />}
      </button>
    )
  })

  function getSelectedProvider() {
    const selected = calendarIntegrations?.find(
      (calendarIntegration) => calendarIntegration.id === value
    )
    if (selected) {
      const name = selected.username
        ? selected.username
        : "No calendar provider selected"
      const icon = generateIcon(selected.integration?.type)
      return (
        <div className="flex flex-row bg-white items-center justify-between p-1">
          <div className="flex flex-row items-center">
            {icon}
            <div className="ml-2">{name}</div>
          </div>
          <div className="flex flex-row">
            {error && (
              <img src={error_icon} className="w-5 h-5" alt="error icon" />
            )}
            <FontAwesomeIcon
              className={`mx-4 ${error ? "text-red-500" : "text-one-gray-500"}`}
              icon={isOpen ? faCaretUp : faCaretDown}
              size="lg"
            />
          </div>
        </div>
      )
    }
  }

  function generateIcon(type?: string) {
    const imgSrc = getResourceTypeIcon(type)

    return (
      <div className="border rounded-full ml-1">
        <img
          src={imgSrc}
          alt="calendarType"
          className="flex items-center m-2 justify-center w-4 h-4"
        />
      </div>
    )
  }

  return (
    <div className="mt-4">
      <h2>{translate("pages.spaces.calendarProvider")}</h2>
      <div
        ref={ref}
        className={`flex flex-col relative p-1 ring-1 ring-inset rounded-md ${
          error ? " ring-red-500" : " ring-gray-300"
        }`}
      >
        <button className="" onClick={() => setIsOpen(!isOpen)}>
          {getSelectedProvider()}
        </button>
        {isOpen && (
          <div className="absolute top-full left-0 z-10 w-full">
            <div className="flex flex-col border border-gray-300 mx-auto w-full">
              {calendars}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

interface NoIntegrationsDisplayProps {
  label: string
  text: string
  addIntegrationLabel: string
  onAddIntegrationClick: () => void
}

const NoIntegrationsDisplay: React.FC<NoIntegrationsDisplayProps> = ({
  label,
  text,
  addIntegrationLabel,
  onAddIntegrationClick
}) => {
  return (
    <div className="w-full">
      <label className="text-s pt-1 text-neutral-800">{label}</label>
      <div className="mt-2">
        <div>{text}</div>
        <Button
          className=""
          type="submit"
          name={addIntegrationLabel}
          icon={faPlus}
          onClick={onAddIntegrationClick}
        />
      </div>
    </div>
  )
}
