import { useOne, useTranslate, useUpdate } from "@pankod/refine-core"
import customToast from "utilities/toastHelper"
import { useQueryClient } from "@tanstack/react-query"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { isEmptyString } from "utilities/string"
import { Input } from "./globals/Input"
import { axiosInstance, axiosNoUrl } from "utilities/dataProvider"
import { Button, ButtonStyle } from "./globals/Button"
import { IUserInfo } from "interfaces"
import { Logger, LOG } from "utilities/logger"
import { UserIcon } from "@heroicons/react/24/outline"

type ModalPropsType = {
  onClose: () => void
}

interface IUserProfileData {
  phone: string
  first_name: string
  last_name: string
  email: string
  picture: string
}

export const UserProfile = ({ onClose }: ModalPropsType) => {
  const { data: userInfo } = useOne<IUserInfo>({
    resource: "users/me",
    id: ""
  })
  const translate = useTranslate()
  const [userData, setUserData] = useState<IUserProfileData>({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    picture: ""
  })
  const { mutate: updateMutation } = useUpdate<IUserProfileData>()
  const queryClient = useQueryClient()
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const [preview, setPreview] = useState("")

  const handleClick = () => {
    inputRef?.current?.click()
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files && e.target.files[0]
    if (!fileObj) {
      return
    }

    setSelectedImage(fileObj)
  }

  useEffect(() => {
    const uploadPhoto = async () => {
      let publicUrl = ""

      const getSignedUrl = async () => {
        if (selectedImage !== undefined) {
          try {
            const signedUrlResult = await axiosInstance.post(
              "users/me/signed-url",
              {
                blob: selectedImage?.name
              }
            )

            const signedUrl: string = signedUrlResult.data.signed_url
            publicUrl = signedUrlResult.data.public_url

            await axiosNoUrl.put(signedUrl, selectedImage, {
              headers: {
                "Content-type": selectedImage.type
              }
            })
          } catch (error) {
            customToast.error(translate("notifications.fileUploadError"))
            void Logger().error(LOG.SIGNED_URL, `${error}`)
          }
        }
      }
      await getSignedUrl()
      setUserData({ ...userData, picture: publicUrl })
    }

    if (!selectedImage) {
      setPreview("")
    } else if (selectedImage !== undefined) {
      const objectUrl = URL.createObjectURL(selectedImage)
      setPreview(objectUrl)
      void uploadPhoto()

      return () => URL.revokeObjectURL(objectUrl)
    }
  }, [selectedImage])

  useEffect(() => {
    setUserData({
      phone: userInfo?.data?.person.phone ?? "",
      first_name: userInfo?.data?.person.first_name ?? "",
      last_name: userInfo?.data?.person.last_name ?? "",
      email: userInfo?.data?.person.email ?? "",
      picture: userInfo?.data?.picture ?? ""
    })
  }, [userInfo])

  const isValidUserData = (userData: string) => /^.{1,256}$/i.test(userData)

  const isEmail = (email: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)

  const deletePhoto = () => {
    setUserData({ ...userData, picture: "" })
    setPreview("")
  }

  const updateProfile = (values: IUserProfileData) => {
    // First name and last name are required
    if (isEmptyString(values.first_name) || isEmptyString(values.last_name)) {
      return
    }

    // First name and last name must be at least 1 characters long and at most 256 characters long
    if (
      isValidUserData(values.first_name) === false ||
      isValidUserData(values.last_name) === false
    ) {
      return
    }

    if (isEmail(values.email) === false) {
      return
    }

    updateMutation(
      {
        resource: "users/me",
        id: "",
        values: values
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(translate(`notifications.success`))
          onClose()
        },
        onError: (_error, _variables, _context) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate("resources.profile")
            })
          )
          void Logger().error(LOG.EDIT_USER_PROFILE, `${_error.stack}`)
        },
        onSettled(data, error, variables, context) {
          setTimeout(() => void queryClient.invalidateQueries(), 100)
        }
      }
    )
  }

  return (
    <div className="flex flex-col gap-2 px-6">
      <div className="flex flex-col gap-4 pb-6">
        <div className="flex flex-row justify-center">
          <div className="flex flex-col items-center px-12 py-4">
            {preview || userData.picture ? (
              <img
                src={preview ? preview : userData.picture}
                alt="profile"
                className="w-24 h-24 rounded-full object-cover aspect-square"
              />
            ) : (
              <UserIcon className="h-20 w-20 text-systam-blue" />
            )}
          </div>
          <div className="flex flex-col">
            <div className="align-center justify-center overflow-hidden mt-4 cursor-pointer">
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleFileChange}
              />
              <Button
                type="button"
                style={ButtonStyle.BlueSecondary}
                onClick={handleClick}
                name={translate("form.addPhoto")}
              />
            </div>
            <div className="align-center justify-center overflow-hidden mt-0 cursor-pointer">
              <Button
                type="button"
                onClick={deletePhoto}
                style={ButtonStyle.TransparentNoBorder}
                name={translate("form.deletePhoto")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-1">
          <div className="w-full">
            <Input
              id="firstName"
              label={translate("form.firstName")}
              placeholder={translate("form.firstName")}
              value={userData.first_name}
              type="text"
              required={true}
              onChange={(e) =>
                setUserData({ ...userData, first_name: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-1">
          <div className="w-full">
            <Input
              required={true}
              type="text"
              label={translate("form.lastName")}
              value={userData.last_name}
              id="lastName"
              placeholder={translate("form.lastName")}
              onChange={(e) =>
                setUserData({ ...userData, last_name: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-1">
          <div className="w-full">
            <Input
              label={translate("form.email")}
              type="text"
              value={userData.email}
              id="email"
              placeholder={translate("form.email")}
              onChange={(e) =>
                setUserData({ ...userData, email: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-1">
          <div className="w-full">
            <Input
              type="text"
              value={userData.phone}
              id="phone"
              label={translate("form.phone")}
              placeholder={translate("form.phone")}
              onChange={(e) =>
                setUserData({ ...userData, phone: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="py-6 flex justify-evenly mt-14">
        <Button
          type="button"
          style={ButtonStyle.TransparentBorder}
          onClick={() => {
            onClose()
          }}
          name={translate("buttons.cancel")}
        />
        <Button
          type="button"
          style={ButtonStyle.BluePrimary}
          onClick={() => updateProfile(userData)}
          name={translate("buttons.save")}
        />
      </div>
    </div>
  )
}
