import { useCustom, useImport, useTranslate } from "@pankod/refine-core"
import { useCallback, useEffect, useState } from "react"
import { LOG, Logger } from "utilities/logger"
import { IRoles, IWorkspace, UserCsvEntry } from "interfaces"
import ErrorText from "components/formMessage"
import { useAppSelector } from "reduxStore/store"
import customToast from "utilities/toastHelper"
import { useQueryClient } from "@tanstack/react-query"
import { ClipLoader } from "react-spinners"
import { SyFileUpload } from "components/new/shared/input/SyFileUpload"
import { OkCancelButtonGroup } from "components/new/shared/modal"
import { SyLabel } from "components/new/shared"
import { SelectWorkspace } from "components/globals/SelectWorkspace"
import { UserPostRequest } from "types"
import { SyCode } from "components/new/shared/display/SyCode"

export interface ImportUsersCsvProps {
  readonly onSuccess: () => void
  readonly onClose: () => void
}

export const ImportUsersCsv = ({ onSuccess, onClose }: ImportUsersCsvProps) => {
  const translate = useTranslate()
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    IWorkspace | undefined
  >(undefined)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [fileMissing, setFileMissing] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const [itemsProcessed, setItemsProcessed] = useState(1)
  const [total, setTotal] = useState(0)
  const [processing, setProcessing] = useState(false)

  const workspaceId = useAppSelector((state) => state.workspace)

  const { data: workspaces } = useCustom<IWorkspace[]>({
    url: "/workspaces/",
    method: "get"
  })

  const { data: roleData } = useCustom<[IRoles]>({
    url: "roles",
    method: "get"
  })

  const { handleChange } = useImport({
    resourceName: `users`,
    paparseOptions: {
      skipEmptyLines: true
    },
    onProgress: ({ totalAmount, processedAmount }) => {
      setItemsProcessed(processedAmount)
      setTotal(totalAmount)
    },
    onFinish: ({ succeeded, errored }) => {
      if (succeeded.length === 0) {
        customToast.error(translate("users.importCsv.userImportFailed"))
      } else {
        customToast.success(
          translate("users.importCsv.usersImported", {
            amount: succeeded.length
          })
        )
        onSuccess()
        if (errored.length > 0) {
          customToast.error(
            translate("users.importCsv.userImportFailed", {
              amount: errored.length
            })
          )
        }
      }

      void queryClient.resetQueries({
        queryKey: ["infScroll"]
      })
    },
    mapData: (userCsv: UserCsvEntry): UserPostRequest => {
      const { Email, Password, FirstName, LastName, Phone, RoleName } = userCsv

      return {
        role_id: roleNameToId(roleData?.data ?? [], RoleName),
        password: Password,
        email: Email,
        phone: Phone,
        first_name: FirstName,
        last_name: LastName,
        workspaces: [parseInt(workspaceId as string)]
      }
    },
    batchSize: 1
  })

  useEffect(() => {
    const workspaceList = workspaces?.data ?? []
    if (selectedWorkspace || workspaceList.length === 0) {
      return
    }

    if (workspaceList.length === 1) {
      setSelectedWorkspace(workspaceList[0])
      return
    }

    const currentUserWorkspace = workspaceList.find(
      (w) => w.id.toFixed() === workspaceId
    )
    if (currentUserWorkspace) {
      setSelectedWorkspace(currentUserWorkspace)
    }
  }, [workspaceId, workspaces, selectedWorkspace, setSelectedWorkspace])

  const handleImport = useCallback(() => {
    async function handleImportInternal() {
      if (!selectedFile) {
        setFileMissing(true)
        return
      }

      setProcessing(true)
      try {
        await handleChange({
          file: selectedFile as Partial<File>
        })
        setSelectedFile(undefined)
        setFileMissing(false)
      } catch (e) {
        void Logger().error(LOG.IMPORT_CSV, `${e}`)
      } finally {
        setProcessing(false)
      }
    }
    void handleImportInternal()
  }, [selectedFile, setProcessing, setFileMissing, handleChange])

  const handleClose = useCallback(() => {
    setSelectedFile(undefined)
    setFileMissing(false)
    setProcessing(false)
    onClose()
  }, [onClose, setSelectedFile, setFileMissing, setProcessing])

  return (
    <div className="flex flex-col">
      <SelectWorkspace
        workspaces={workspaces?.data ?? []}
        selectedWorkspace={selectedWorkspace}
        setSelectedWorkspace={setSelectedWorkspace}
      />
      <div className="mt-3">
        <SyLabel>{translate("users.importCsv.instructionsLabel")}</SyLabel>
        <p className="text-gray-500">
          {translate("users.importCsv.instructions")}
        </p>
      </div>
      <SyCode lines={EXAMPLE_LINES} />
      <p className="text-gray-500 mt-1">
          {translate("users.importCsv.roleInfo")}
        </p>
      <div className="mt-5 mb-5">
        <SyFileUpload
          accept={[".csv"]}
          files={selectedFile ? [selectedFile] : undefined}
          onFileUploadChange={(files) => {
            if (!files || files.length === 0) {
              setFileMissing(true)
              return
            }
            setSelectedFile(files[0])
          }}
        />
        {fileMissing && ErrorText("Add csv file")}
      </div>

      <OkCancelButtonGroup
        okLabel={translate("buttons.importCsv")}
        onOkClick={handleImport}
        cancelLabel={translate("buttons.cancel")}
        onCancelClick={handleClose}
      />

      {processing && (
        <div className="flex justify-end mt-3">
          <div className="flex justify-center items-center">
            <ClipLoader
              color="#0C46CA"
              loading={true}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p className="text-gray-500 pl-2">
              {translate("users.importCsv.importing") +
                `... ${itemsProcessed} / ${total}`}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

const EXAMPLE_LINES: readonly string[] = [
  "Email;Password;FirstName;LastName;Phone;RoleName",
  "john.doe@mail.com;password;John;Doe;050123124;OrganizationOwner",
  "jane.doe@mail.com;password;Jane;Doe;050123125;WorkspaceAdmin",
]

function roleNameToId(roles: readonly IRoles[], roleName: string): number {
  return roles.find((r) => r.name === roleName)?.id as number
}
