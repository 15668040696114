import { HTTP_STATUS_OK, PAGE_ONE, PAGE_SIZE } from "utilities/constants"
import { axiosInstance } from "utilities/dataProvider"
import {
  useInfiniteQuery,
  InfiniteQueryObserverResult,
  FetchNextPageOptions,
  useQueryClient
} from "@tanstack/react-query"
import { Logger, LOG } from "utilities/logger"
import { useState, useEffect, useRef } from "react"
import { useDebounce } from "./useDebounce"
import { INF_SCROLL_QUERY_KEY_TYPES } from "utilities/types"
import { IPage } from "interfaces"

export const usePagination = <T,>(
  urlPrefix: string,
  search: string,
  /** filters parameter can be arbitrary filter string accepted by the API eg: &start_time__gte=2023-1-13 */
  filters: string,
  queryKey: INF_SCROLL_QUERY_KEY_TYPES,
  refetchInterval?: number,
  refetchIntervalInBackground?: boolean
): [
  T[],
  IPage | undefined,
  boolean,
  (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<any, unknown>>,
  boolean | undefined,
  boolean | undefined
] => {
  const [items, setItems] = useState<T[]>([])
  const [page, setPage] = useState<IPage>()
  const queryClient = useQueryClient()

  const {
    isLoading,
    isRefetching,
    isFetching,
    isPreviousData,
    fetchNextPage,
    hasNextPage,
    data
  } = useInfiniteQuery({
    keepPreviousData: true,
    queryKey: [queryKey],
    refetchIntervalInBackground: refetchIntervalInBackground,
    refetchInterval: refetchInterval,
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const URL = `${urlPrefix}?page=${pageParam}&size=${PAGE_SIZE}${
          search ? `&search=${search}` : ""
        }${filters ? `&${filters}` : ""}`

        const res = await axiosInstance.get(URL)
        if (res.status === HTTP_STATUS_OK) return res.data
      } catch (e) {
        void Logger().error(LOG.INFINITE_SCROLL, `${e}`)
        return {
          items: [],
          total: 0,
          page: PAGE_ONE,
          size: PAGE_SIZE
        }
      }
    },
    getNextPageParam: (lastPage) => {
      if (lastPage === undefined) return undefined // Return undefined to indicate there is no next page available.
      const {
        page,
        size,
        total
      }: { page: number; size: number; total: number } = lastPage

      if (total === items.length) return undefined
      if (page * size >= total) return undefined

      return page + PAGE_ONE
    }
  })

  const isWorking =
    isLoading || isRefetching || (isFetching && isPreviousData)
      ? true
      : false || false

  const debouncedSearch = useDebounce(search)

  // search keyword result is stored to ref so we can access it without causing unwanted renders
  const searchRef = useRef(debouncedSearch)

  // Search Hook
  useEffect(() => {
    const asyncHandler = async () => {
      try {
        // reset query on each search keyword press
        queryClient.removeQueries({ queryKey: [queryKey] })

        // Reset page back to 1 on each keyword press
        await fetchNextPage({ pageParam: 1 })
      } catch (e) {
        void Logger().error(LOG.HOST_SEARCH, `${e}`)
      }
    }

    if (debouncedSearch) {
      void asyncHandler()
    } else {
      // if search keyword is empty, invalidate query
      if (searchRef.current.length > debouncedSearch.length) {
        void asyncHandler()
      }
    }

    searchRef.current = debouncedSearch // set local ref to match search keyword for making comparisons
  }, [debouncedSearch])

  useEffect(() => {
    if (data?.pages[0]) {
      const len = data?.pages.length
      setPage({
        page: data?.pages[len - 1].page,
        size: Math.ceil(data?.pages[len - 1].total / PAGE_SIZE),
        total: data?.pages[len - 1].total
      })
    }

    setItems(getItems<T>(data))
  }, [data])

  // Filter Hook
  useEffect(() => {
    const asyncHandler = async () => {
      try {
        // query needs to be removed here, so the data gets resetted on each search keyword
        queryClient.removeQueries({ queryKey: [queryKey] })

        // on each new search keyword we reset page back to 1
        await fetchNextPage({ pageParam: 1 })
      } catch (e) {
        void Logger().error(LOG.FILTER_PAGINATION_ERROR, `${e}`)
      }
    }
    void asyncHandler()
  }, [filters])

  return [items, page, isWorking, fetchNextPage, hasNextPage, isFetching]
}

/**
 * Copied from React Querys types
 */
interface InfiniteData<TData> {
  pages: TData[]
  pageParams: unknown[]
}

interface IPages {
  items: any[]
  total: number
  page: number
  size: number
  pages: number
}

const getItems = <T,>(data: InfiniteData<any> | undefined): T[] => {
  let arr: T[] = []
  if (data !== undefined) {
    for (let i = 0; i < data.pages.length; i++) {
      const { items } = data.pages[i] as IPages
      for (let j = 0; j < items.length; j++) {
        const element: any = items[j]
        arr = [...arr, element]
      }
    }
  }
  return arr
}
