import { IDevice } from "interfaces"
import { GeneralSettingsTab } from "./GeneralSettingsTab"
import { LinkedDevicesTab } from "./LinkedDevicesTab"
import { CustomizationTab } from "./CustomizationTab"

export const DeviceSettingsTab = ({
  device,
  activeTab,
  deviceId
}: {
  device: IDevice
  activeTab: string
  deviceId: number
}) => {
  return (
    <div>
      {activeTab === "general" && <GeneralSettingsTab device={device} />}
      {activeTab === "settings" && <CustomizationTab device={device} />}
      {activeTab === "links" && <LinkedDevicesTab deviceId={deviceId} />}
    </div>
  )
}
