import { createSlice } from "@reduxjs/toolkit"
import {
  VISIT_TABLE_FILTER_YESTERDAY,
  VISIT_TABLE_FILTER_TODAY,
  VISIT_TABLE_FILTER_TOMORROW,
  VISIT_TABLE_FILTER_CUSTOM_DATE,
  VISIT_TABLE_FILTER_CUSTOM_START,
  VISIT_TABLE_FILTER_CUSTOM_END
} from "utilities/constants"

export const visitTableFilters = createSlice({
  name: "visitTableFilters",
  initialState: {
    yesterday: window.localStorage.getItem(VISIT_TABLE_FILTER_YESTERDAY),
    today: window.localStorage.getItem(VISIT_TABLE_FILTER_TODAY),
    tomorrow: window.localStorage.getItem(VISIT_TABLE_FILTER_TOMORROW),
    customDate: window.localStorage.getItem(VISIT_TABLE_FILTER_CUSTOM_DATE),
    customStartDatetime: window.localStorage.getItem(
      VISIT_TABLE_FILTER_CUSTOM_START
    ),
    customEndDatetime: window.localStorage.getItem(
      VISIT_TABLE_FILTER_CUSTOM_END
    )
  },
  reducers: {
    setFilterYesterday: (state, action: { payload: string; type: string }) => {
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_DATE, "")
      localStorage.setItem(VISIT_TABLE_FILTER_TOMORROW, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_START, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_END, "")
      localStorage.setItem(VISIT_TABLE_FILTER_TODAY, "")
      localStorage.setItem(VISIT_TABLE_FILTER_YESTERDAY, action.payload)

      state.tomorrow = ""
      state.customDate = ""
      state.customEndDatetime = ""
      state.customStartDatetime = ""
      state.today = ""
      state.yesterday = action.payload
    },
    setFilterToday: (state, action: { payload: string; type: string }) => {
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_DATE, "")
      localStorage.setItem(VISIT_TABLE_FILTER_TOMORROW, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_START, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_END, "")
      localStorage.setItem(VISIT_TABLE_FILTER_YESTERDAY, "")
      localStorage.setItem(VISIT_TABLE_FILTER_TODAY, action.payload)

      state.tomorrow = ""
      state.customDate = ""
      state.customEndDatetime = ""
      state.customStartDatetime = ""
      state.yesterday = ""
      state.today = action.payload
    },
    setFilterTomorrow: (state, action: { payload: string; type: string }) => {
      localStorage.setItem(VISIT_TABLE_FILTER_TODAY, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_DATE, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_START, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_END, "")
      localStorage.setItem(VISIT_TABLE_FILTER_YESTERDAY, "")
      localStorage.setItem(VISIT_TABLE_FILTER_TOMORROW, action.payload)

      state.today = ""
      state.customDate = ""
      state.customEndDatetime = ""
      state.customStartDatetime = ""
      state.yesterday = ""
      state.tomorrow = action.payload
    },
    setFilterCustomDate: (state, action: { payload: string; type: string }) => {
      localStorage.setItem(VISIT_TABLE_FILTER_TODAY, "")
      localStorage.setItem(VISIT_TABLE_FILTER_TOMORROW, "")
      localStorage.setItem(VISIT_TABLE_FILTER_YESTERDAY, "")
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_DATE, action.payload)

      state.today = ""
      state.tomorrow = ""
      state.yesterday = ""
      state.customDate = action.payload
    },
    setFilterCustomDateStart: (
      state,
      action: { payload: string; type: string }
    ) => {
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_START, action.payload)
      state.customStartDatetime = action.payload
    },
    setFilterCustomDateEnd: (
      state,
      action: { payload: string; type: string }
    ) => {
      localStorage.setItem(VISIT_TABLE_FILTER_CUSTOM_END, action.payload)
      state.customEndDatetime = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setFilterYesterday,
  setFilterToday,
  setFilterTomorrow,
  setFilterCustomDate,
  setFilterCustomDateStart,
  setFilterCustomDateEnd
} = visitTableFilters.actions

export default visitTableFilters.reducer
