import { useForgotPassword, useTranslate } from "@pankod/refine-core"
import React, { useCallback, useState } from "react"
import ErrorText, { SuccessText } from "components/formMessage"
import customToast from "utilities/toastHelper"
import i18n from "utilities/i18n"
import { ArrowPathIcon } from "@heroicons/react/24/outline"
import { Logo } from "../Logo"

export const ResetPassword = () => {
  const translate = useTranslate()
  const { mutate: forgotPassword } = useForgotPassword()
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleForgotPassword = () => {
    if (email) {
      setLoading(true)

      document
        .querySelector("[data-cy=forgot-password]")
        ?.classList.add("cursor-not-allowed")
      document
        .querySelector("[data-cy=forgot-password]")
        ?.setAttribute("disabled", "disabled")

      forgotPassword(
        { username: email, redirectPath: "/login" },
        {
          onSuccess: () => {
            setLoading(false)
            setSuccess(true)
            customToast.success(i18n.t("pages.forgotPassword.success"))
          },
          onError: () => {
            setLoading(false)
            customToast.error(i18n.t("pages.forgotPassword.failure"))
          }
        }
      )
    } else {
      setErrorMessage(translate("pages.forgotPassword.enterCredentials"))
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && email) {
      handleForgotPassword()
    }
  }

  const handleKeyPressCallback = useCallback(handleKeyPress, [email])

  return (
    <div className="flex justify-center items-center w-full min-h-full">
      <div className="w-full lg:w-96">
        <Logo className="h-10" />
        <div className="mb-4">
          <h1 className="font-dmsans font-medium text-3xl mb-6 mt-8">
            {translate("pages.forgotPassword.reset")}
          </h1>
          <p>{translate("pages.forgotPassword.reason")}</p>
        </div>

        <div className="space-y-6">
          <div>
            {errorMessage && ErrorText(errorMessage, true)}
            {success && SuccessText(translate("pages.forgotPassword.success"))}
          </div>
          <div>
            <label htmlFor="username" className="font-medium">
              {translate("pages.forgotPassword.email")}
            </label>
            <input
              id="username"
              name="username"
              type="text"
              className="mt-2 block w-full rounded-md border-0 py-2 px-4 shadow-sm ring-1 ring-inset ring-one-gray-200 placeholder:text-one-gray-400 focus:ring-2 focus:ring-inset focus:ring-one-rose-400"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              onKeyDown={handleKeyPressCallback}
            />
          </div>
          <div>
            <button
              data-cy="forgot-password"
              onClick={handleForgotPassword}
              className="relative flex w-full justify-center rounded-lg bg-one-rose-400 px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-one-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-400"
            >
              {isLoading && (
                <ArrowPathIcon
                  className="absolute right-2 h-6 w-6 text-rose-900 animate-spin"
                  data-testid="loader"
                />
              )}
              {translate("pages.forgotPassword.sendInstructions")}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
