import { useCustomMutation, usePermissions, useTranslate } from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useState, useEffect } from "react"
import customToast from "utilities/toastHelper"
import { Logger, LOG } from "utilities/logger"
import Switch from "react-switch"
import { useAppSelector } from "reduxStore/store"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { useLinkedPrinters } from "hooks/useLinkedPrinters"

export const SettingPrintingBoolean = ({
  settingsData: { value, endpoint, setting_metadata: { description, key } }
}: { settingsData: ISettingEntity }) => {
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const [checked, setChecked] = useState(value === "true" ? true : false)
  const { data: permissionsData } = usePermissions<string>()
  const canEdit = () => permissionsData?.includes("All.All") || permissionsData?.includes("Workspace.All")
  const { isDeviceDataLoading, linkedPrintersData, isLinkedPrintersError } = useLinkedPrinters()
  const hasPrinterLinked = linkedPrintersData.length > 0 && !isLinkedPrintersError

  useEffect(() => {
    setChecked(value === "true" ? true : false)
  }, [value])

  const handleChange = () => {
    updateMutation(
      {
        url: endpoint.replace("{id}", selectedWorkspaceId || ""),
        method: "put",
        values: { value: !checked ? "true" : "false" }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          setChecked(!checked)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }

  if (isDeviceDataLoading) {
    return (
      <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
        <div className="flex flex-row flex-1 mt-2 mb-4 mr-2 justify-between items-center">
          <div className="flex flex-col gap-2 justify-between mb-1">
            <div className="flex">
              <h1 className="self-center"><Skeleton count={1} height={30} width={150} /></h1>
            </div>
            <p className="text-gray-600"><Skeleton count={1} height={20} width={250} /></p>
          </div>
          <div>
            <Skeleton count={1} height={32} width={56} />
          </div>
        </div>
      </SkeletonTheme>
    )
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row flex-1 mt-2 mb-4 mr-2 justify-between items-center">
        <div className="flex flex-col gap-2 justify-between mb-1">
          <div className="flex">
            <h1 className="self-center text-md font-medium">{translate(`pages.settings.${key}`)}</h1>
          </div>
          {description && (
            <p className="text-gray-600 text-sm whitespace-pre-wrap">{translate(description)}</p>
          )}
        </div>
        <Switch
          disabled={!canEdit() || !hasPrinterLinked} // Disable the switch if the user can't edit OR there isn't a linked printer
          onChange={() => void handleChange()}
          checked={checked}
          offColor="#D9D9D9"
          onColor="#34D399"
          onHandleColor="#fff"
          handleDiameter={24}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(29, 29, 29, 0.2)"
          activeBoxShadow="0px 0px 1px 10px rgba(29, 29, 29, 0.2)"
          height={32}
          width={56}
        />
      </div>
      {!hasPrinterLinked && (
        <div className="flex items-center p-4 rounded-lg bg-gray-100 mt-2">
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="text-rose-500"
          />
          <p className="ml-2 font-medium text-rose-500">
            No linked printers in this workspace. To enable printing, go to Visits ➡️ Devices in the sidebar and click 'Add new device' and follow instructions.
          </p>
        </div>
      )}
    </div>
  )
}
