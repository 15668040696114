import { Row } from "@pankod/refine-react-table"
import { IVisitor, IVisit } from "interfaces"
import { AdHocVisitorAvatar } from "modules/Visit/AdHocVisitorAvatar"
import i18n from "utilities/i18n"
import { isEmptyString, addDotstoEnd } from "utilities/string"
import { v4 as uuidv4 } from "uuid"
import { Dispatch, SetStateAction } from "react"
import { useTranslate } from "@pankod/refine-core"
import { UserIcon } from "@heroicons/react/24/outline"

export const VisitorCell = (
  visitor: IVisitor | null,
  row: Row<IVisit>,
  setSelectedPicture: Dispatch<SetStateAction<string | undefined>>,
  setShowPictureModal: Dispatch<SetStateAction<boolean>>
) => {
  const translate = useTranslate()
  const { visitors_count } = row.original
  let notFound = false

  const {
    first_name,
    last_name,
    company = "",
    picture
  } = visitor?.details || {}

  if (first_name === undefined || last_name === undefined) {
    notFound = true
  }

  return (
    <div className="flex items-center">
      {notFound && (
        <div>
          <UserIcon className="h-8 w-8 text-systam-blue" />
        </div>
      )}
      {!notFound &&
        (isEmptyString(picture as string) ? (
          visitors_count > 1 && visitors_count <= 3 ? (
            <div className="flex -space-x-4">
              {Array.from(Array(visitors_count).keys()).map((_, index) => (
                <AdHocVisitorAvatar key={uuidv4()} />
              ))}
            </div>
          ) : visitors_count > 3 ? (
            <div className="flex -space-x-4">
              {Array.from(Array(4).keys()).map((_, index) => (
                <AdHocVisitorAvatar key={uuidv4()} />
              ))}
              <div className="border-2 rounded-full text-white ring-1 ring-systam-dashboard-500 bg-systam-blue w-10 h-10 flex justify-center items-center">
                {`+${visitors_count - 4}`}
              </div>
            </div>
          ) : (
            <UserIcon className="h-8 w-8 text-systam-blue" />
          )
        ) : (
          <img
            alt="visiting person"
            width="40"
            height="40"
            className="rounded-full object-cover aspect-square"
            src={picture}
            onClick={() => {
              setSelectedPicture(picture as string)
              setShowPictureModal(true)
            }}
          />
        ))}
      <div className="pl-2">
        <h3 className={notFound ? "text-gray-500" : "font-medium truncate"}>
          {notFound && translate("visitor_not_found")}
          {!notFound &&
            (visitors_count > 1
              ? `${i18n.t("table.visits.personGroup", {
                  visitorsCount: visitors_count
                })}`
              : `${first_name} ${last_name}`)}
        </h3>
        {visitor?.state.name === "signed_in" ||
          (visitor?.state.name === "signed_out" && (
            <p className="text-gray-500">{addDotstoEnd(company)}</p>
          ))}
      </div>
    </div>
  )
}
