import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslate } from "@pankod/refine-core"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  setFilterToday,
  setFilterTomorrow,
  setFilterYesterday
} from "reduxStore/reducers/visitTableFilters"
import { useAppSelector } from "reduxStore/store"
import {
  filterVisitsToday,
  filterVisitsTomorrow,
  filterVisitsYesterday
} from "utilities/filters"

export const DAY_FILTERS = [
  { id: 0, name: "today" },
  { id: 1, name: "tomorrow" },
  { id: 2, name: "yesterday" }
]

export const VisitDayDropdown = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()

  const dayFilterLocalizationMap = new Map([
    [0, translate("pages.flow.today")],
    [1, translate("pages.flow.tomorrow")],
    [2, translate("pages.flow.yesterday")]
  ])

  const [selectedValue, setSelectedValue] = useState(
    dayFilterLocalizationMap.get(0) as string
  )

  const { today, tomorrow, customDate, yesterday } = useAppSelector(
    (state) => state.visitTableFilters
  )

  useEffect(() => {
    dispatch(setFilterToday(filterVisitsToday()))

    if (today) setSelectedValue(dayFilterLocalizationMap.get(0) as string)
    else if (tomorrow)
      setSelectedValue(dayFilterLocalizationMap.get(1) as string)
    else if (yesterday)
      setSelectedValue(dayFilterLocalizationMap.get(2) as string)
  }, [])

  useEffect(() => {
    if (customDate) setSelectedValue(translate("buttons.datesSelected"))
  }, [customDate])

  const handleOnChange = (e) => {
    const index = e.target.selectedIndex

    if (index === 1) {
      if (today) dispatch(setFilterToday(""))
      else dispatch(setFilterToday(filterVisitsToday()))
    } else if (index === 2) {
      if (tomorrow) dispatch(setFilterTomorrow(""))
      else dispatch(setFilterTomorrow(filterVisitsTomorrow()))
    } else if (index === 3) {
      if (yesterday) dispatch(setFilterYesterday(""))
      else dispatch(setFilterYesterday(filterVisitsYesterday()))
    } else {
      dispatch(setFilterToday(""))
      dispatch(setFilterTomorrow(""))
      dispatch(setFilterYesterday(""))
    }

    setSelectedValue(e.target.value as string)
  }

  return (
    <div className="flex justify-center items-center space-x-2 mr-2">
      <button
        className="rounded-lg text-md max-w-48 border-gray-200"
        onClick={() => {
          if (selectedValue === dayFilterLocalizationMap.get(1)) {
            dispatch(setFilterToday(filterVisitsToday()))
            setSelectedValue(dayFilterLocalizationMap.get(0) as string)
          } else if (selectedValue === dayFilterLocalizationMap.get(0)) {
            dispatch(setFilterYesterday(filterVisitsYesterday()))
            setSelectedValue(dayFilterLocalizationMap.get(2) as string)
          }
        }}
        disabled={selectedValue === dayFilterLocalizationMap.get(2)}
      >
        <FontAwesomeIcon
          className="rounded-full w-6 h-6 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white"
          icon={faCaretLeft}
          size="2x"
          color={
            selectedValue === dayFilterLocalizationMap.get(2) ? "gray" : "black"
          }
        />
      </button>{" "}
      <select
        className="rounded-lg text-md max-w-48 border-gray-200 p-2"
        value={selectedValue}
        onChange={(e) => handleOnChange(e)}
      >
        <option>{translate("buttons.showAll")}</option>
        {DAY_FILTERS.map((filter) => (
          <option key={`button_${DAY_FILTERS.indexOf(filter)}`}>
            {dayFilterLocalizationMap.get(filter.id)}
          </option>
        ))}
        <option hidden>{translate("buttons.datesSelected")}</option>
      </select>
      <button
        className="rounded-lg text-md max-w-48 border-gray-200"
        onClick={() => {
          if (selectedValue === dayFilterLocalizationMap.get(2)) {
            dispatch(setFilterToday(filterVisitsToday()))
            setSelectedValue(dayFilterLocalizationMap.get(0) as string)
          } else if (selectedValue === dayFilterLocalizationMap.get(0)) {
            dispatch(setFilterTomorrow(filterVisitsTomorrow()))
            setSelectedValue(dayFilterLocalizationMap.get(1) as string)
          }
        }}
        disabled={selectedValue === dayFilterLocalizationMap.get(1)}
      >
        <FontAwesomeIcon
          className="rounded-full w-6 h-6 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white"
          icon={faCaretRight}
          size="2x"
          color={
            selectedValue === dayFilterLocalizationMap.get(1) ? "gray" : "black"
          }
        />
      </button>{" "}
    </div>
  )
}
