import {
  IResourceComponentsProps,
  usePermissions,
  useQueryClient,
  useTranslate
} from "@pankod/refine-core"
import React, { useCallback, useEffect, useState } from "react"
import { IHost_from_list } from "interfaces"
import { useModalForm } from "@pankod/refine-react-hook-form"
import { CreateHost } from "pages/hosts/create"
import { EditHost } from "pages/hosts/edit"
import { DeleteHost } from "pages/hosts/delete"
import { Table } from "components/table/table"
import { useLocalStorage } from "hooks/useLocalStorage"
import { usePagination } from "hooks/usePagination"
import { InputSearch } from "components/globals/InputSearch"
import {
  useReactTable,
  ColumnDef as CDef,
  getCoreRowModel
} from "@tanstack/react-table"
import { LOG, Logger } from "utilities/logger"
import { DeleteIcon, EditIcon } from "components/icons"
import { contactDetailType } from "modules/ContactDetails/contactDetailType"
import { shouldSendNotification } from "modules/ContactDetails/contactDetailPrimaryNotification"
import { ContactDetailNotificationEnablement } from "modules/ContactDetails/ContactDetailNotificationEnablement"
import { INF_SCROLL_QUERY_KEY_TYPES, LOCAL_STORAGE_KEYS } from "utilities/types"
import { Button } from "components/globals/Button"
import {
  faEllipsisVertical,
  faPlus,
  faUpload
} from "@fortawesome/free-solid-svg-icons"
import { Menu } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SyButton } from "components/new/shared"
import { ImportHostsCsvModal } from "components/hosts/ImportHostsCsvModal"
import { UserIcon } from "@heroicons/react/24/outline"
import { useAppSelector } from "reduxStore/store"

export const HostsList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate()
  const [selectedHostId, setSelectedHostId] = useState(0)
  const { data: permissionsData } = usePermissions<string>()
  const queryClient = useQueryClient()
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  useEffect(() => {
    // Remove hosts query if selected workspace is changed
    queryClient.removeQueries({ queryKey: [INF_SCROLL_QUERY_KEY_TYPES.HOSTS] })
  }, [selectedWorkspaceId])

  const columnsMemoized = React.useMemo<CDef<IHost_from_list, any>[]>(() => {
    return [
      {
        id: "host",
        header: "Name",
        accessorKey: "picture",
        cell: ({ row }) => {
          const { picture, first_name, last_name } = row.original
          let notFound = false

          if (first_name === undefined || last_name === undefined)
            notFound = true

          return (
            <div className="flex items-center">
              {picture ? (
                <img
                  alt="hostpic"
                  width="40"
                  height="40"
                  className="rounded-full object-cover aspect-square"
                  src={picture}
                />
              ) : (
                <UserIcon className="h-8 w-8 text-systam-blue" />
              )}
              <div className="pl-2">
                <p className={notFound ? "text-gray-500" : ""}>
                  {notFound
                    ? translate("host_not_found")
                    : `${first_name} ${last_name}`}
                </p>
              </div>
            </div>
          )
        }
      },
      {
        id: "organization_name",
        header: "Organization",
        accessorKey: "person.organization_name"
      },
      {
        id: "host_email",
        header: "Email",
        accessorKey: "person.email"
      },
      {
        id: "host_phone",
        header: "Phone",
        accessorKey: "person.phone"
      },
      {
        id: "host_notifications",
        header: "Notifications",
        accessorKey: "contact_details",
        cell: ({ row }) => {
          const { contact_details } = row.original
          if (contact_details?.length > 0) {
            return (
              <div className="flex flex-wrap flex-col">
                <ContactDetailNotificationEnablement
                  notificationEnabled={shouldSendNotification({
                    contactDetails: contact_details,
                    contactDetailType: contactDetailType.phone
                  })}
                  contactDetailType={contactDetailType.phone}
                />
                <ContactDetailNotificationEnablement
                  notificationEnabled={shouldSendNotification({
                    contactDetails: contact_details,
                    contactDetailType: contactDetailType.email
                  })}
                  contactDetailType={contactDetailType.email}
                />
              </div>
            )
          }
        }
      }
    ]
  }, [permissionsData])

  const isEditCreateButton =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("All.ReadWrite") ||
    permissionsData?.includes("Host.ReadWrite") ||
    permissionsData?.includes("Host.All")
  const isDeleteButton =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Host.All")

  function getActionButtons(id: number) {
    return (
      <Menu as="div" className="relative inline-flex text-left">
        <Menu.Button
          className="rounded-full w-10 h-10 border border-gray-200 p-2 text-lg font-medium transition duration-150 ease-in-out !bg-blue-100 hover:!bg-blue-300 hover:text-white"
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className="align-center self-center"
          />
        </Menu.Button>
        <Menu.Items className="absolute z-50 right-0 mt-11 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            {isEditCreateButton && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      void Logger().log(LOG.PRESS_EDIT_HOST_MODAL)
                      showEditModal(id)
                    }}
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    }  flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {EditIcon}
                    <p className="ml-2">{translate("pages.hosts.editHost")}</p>
                  </button>
                )}
              </Menu.Item>
            )}
            {isDeleteButton && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      void Logger().log(LOG.PRESS_DEL_HOST_MODAL)
                      setSelectedHostId(id)
                      showDeleteModal(id)
                    }}
                    className={`${
                      active
                        ? "font-medium bg-systam-blue text-white"
                        : "bg-white text-gray-600"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm z-auto`}
                  >
                    {DeleteIcon}
                    <p className="ml-2">
                      {translate("pages.hosts.deleteHost")}
                    </p>
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Menu>
    )
  }

  if (isEditCreateButton || isDeleteButton) {
    columnsMemoized.push({
      id: "action",
      header: "Action",
      accessorKey: "id",
      cell: function render({ getValue }) {
        return getActionButtons(getValue() as number)
      }
    })
  }

  const [search, setSearch] = useState("")

  const [hosts, page, isLoading, fetchNextPage, hasNextPage, isFetching] =
    usePagination<IHost_from_list>(
      `/workspaces/${selectedWorkspaceId}/hosts`,
      search,
      "",
      INF_SCROLL_QUERY_KEY_TYPES.HOSTS
    )

  const [columnVisibility, setLocalStorage]: [any, (arg0: any) => void] =
    useLocalStorage(LOCAL_STORAGE_KEYS.HOSTS, {})

  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    onColumnVisibilityChange: (state) => {
      setLocalStorage(state)
    },
    initialState: {},
    state: {
      columnVisibility
    },
    data: hosts,
    columns: columnsMemoized,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true
  })

  const createModalFormReturnValues = useModalForm({
    refineCoreProps: {
      action: "create",
      resource: `/hosts`
    }
  })

  const editModalFormReturnValues = useModalForm({
    refineCoreProps: {
      action: "edit",
      resource: `/hosts`
    }
  })

  const deleteModalFormReturnValues = useModalForm({
    refineCoreProps: {
      resource: `/hosts`
    }
  })

  const {
    modal: { show: showCreateModal }
  } = createModalFormReturnValues

  const {
    modal: { show: showEditModal }
  } = editModalFormReturnValues

  const {
    modal: { show: showDeleteModal }
  } = deleteModalFormReturnValues

  const [importHostCsvOpen, setImportHostCsvOpen] = useState(false)

  const handleCreateHost = useCallback(() => {
    void Logger().log(LOG.PRESS_CREATE_HOST_MODAL)
    showCreateModal()
  }, [showCreateModal])

  const CreateHostButton = () => {
    if (isEditCreateButton) {
      return (
        <div className="flex justify-end items-center">
          <Button
            icon={faPlus}
            name={translate("pages.hosts.createHost")}
            onClick={handleCreateHost}
          />
        </div>
      )
    }

    return null
  }

  return (
    <div className="container mx-auto pb-4">
      <CreateHost {...createModalFormReturnValues} />
      <EditHost {...editModalFormReturnValues} />
      <DeleteHost
        {...deleteModalFormReturnValues}
        selectedId={selectedHostId}
      />
      <div>
        <Table
          header="Hosts"
          page={page}
          getAllColumns={getAllColumns}
          getHeaderGroups={getHeaderGroups}
          getRowModel={getRowModel}
          leftButtons={[<CreateHostButton />]}
          rightButtons={[
            <SyButton
              variant="outlined"
              color="secondary"
              label={translate("buttons.importCsv")}
              rightIcon={faUpload}
              onClick={() => {
                setImportHostCsvOpen(true)
                void Logger().log(LOG.PRESS_IMPORT_HOST_BTN)
              }}
            />,
            <InputSearch search={search} setSearch={setSearch} />
          ]}
          isLoading={isLoading}
          isFetching={isFetching}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </div>
      <ImportHostsCsvModal
        open={importHostCsvOpen}
        onSuccess={() => {
          setImportHostCsvOpen(false)
          void queryClient.invalidateQueries([INF_SCROLL_QUERY_KEY_TYPES.HOSTS])
        }}
        onClose={() => {
          setImportHostCsvOpen(false)
        }}
      />
    </div>
  )
}
