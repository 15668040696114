import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import workspaceReducer from "./reducers/workspaceReducer"
import visitTableFilters from "./reducers/visitTableFilters"
import drawerReducer from "./reducers/drawerReducer"
import adHocVisitorReducer from "./reducers/adHocVisitorReducer"
import adHocVisitorPaginationReducer from "./reducers/adHocVisitorPaginationReducer"
import visitPrintBadgeReducer from "./reducers/visitPrintBadgeReducer"
import notificationsReducer from "./reducers/notificationsReducer"
import languageReducer from "./reducers/languageReducer"
import deviceSearchReducer from "./reducers/deviceSearchReducer"

export const store = configureStore({
  reducer: {
    workspace: workspaceReducer,
    visitTableFilters: visitTableFilters,
    drawer: drawerReducer,
    adHocVisitor: adHocVisitorReducer,
    adHocVisitorPagination: adHocVisitorPaginationReducer,
    showPrint: visitPrintBadgeReducer,
    notifications: notificationsReducer,
    language: languageReducer,
    deviceSearch: deviceSearchReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
