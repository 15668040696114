import { InputSearch } from "components/globals/InputSearch"
import { usePagination } from "hooks/usePagination"
import { Dispatch, SetStateAction, useRef } from "react"
import { useState } from "react"
import { INF_SCROLL_QUERY_KEY_TYPES } from "utilities/types"
import { IHost_from_list } from "interfaces"
import { useScrollTrigger } from "hooks/useScrollTrigger"
import { useTranslate } from "@pankod/refine-core"
import { useAppSelector } from "reduxStore/store"
import { faCircleUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isEmptyString } from "utilities/string"

export const HostSearchDropdown = ({
  setOrganizationId,
  setVisitHost,
  visitHost,
  error,
  setError,
  isNotification
}: {
  setOrganizationId: Dispatch<SetStateAction<number | undefined>>
  setVisitHost: Dispatch<SetStateAction<IHost_from_list | undefined>>
  visitHost?: IHost_from_list
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  isNotification?: boolean
}) => {
  const [search, setSearch] = useState("")
  const [hostDropdown] = useState(true)
  const [focused, setFocused] = useState(false)

  const scrollRef = useRef<HTMLDivElement>(null)
  const translate = useTranslate()

  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  const [hosts, , , fetchNextPage, hasNextPage] =
    usePagination<IHost_from_list>(
      "/hosts",
      search,
      `workspace_id=${selectedWorkspaceId}`,
      INF_SCROLL_QUERY_KEY_TYPES.WORKSPACE_HOSTS
    )

  useScrollTrigger(hasNextPage, scrollRef, fetchNextPage)

  const selectHost = () => {
    if (hosts.length === 1) {
      const aHost = hosts[0] as IHost_from_list
      const workspace = aHost.workspaces[0]

      setOrganizationId(workspace?.workspace?.organization?.id)
      setVisitHost(aHost)
      setSearch("")
    }
  }

  return (
    <div className="py-1">
      <label>
        {translate("addVisit.selectHost")}
      </label>
      <div className="flex rounded-lg py-1">
        <InputSearch
          search={search}
          setSearch={setSearch}
          onEnter={selectHost}
          setFocused={setFocused}
          css="w-full"
          onBlur={() => {
            visitHost === undefined ? setError(true) : setError(false)
          }}
          error={error}
        />
      </div>
      <div className="min-h-fit">
        <div
          ref={scrollRef}
          className="overflow-x-auto bg-white max-h-48 shadow-md rounded-md"
        >
          {hosts.map((host: IHost_from_list, index) => {
            return (
              hostDropdown &&
              (search || focused) && (
                <div key={index} className="pt-1">
                  {host.workspaces.map((workspace, index) => {
                    return (
                      <div
                        key={index}
                        className="cursor-pointer px-2 py-1 flex hover:bg-gray-100 items-center"
                        onClick={() => {
                          setOrganizationId(
                            workspace?.workspace?.organization?.id
                          )
                          visitHost = host
                          setVisitHost(host)
                          setError(false)
                          setSearch("")
                        }}
                      >
                        <div className="text-blue-500">
                          {isEmptyString(host.picture) ? (
                            <FontAwesomeIcon
                              icon={faCircleUser}
                              className="mr-2"
                            />
                          ) : (
                            <img
                              alt="host person"
                              width="20"
                              height="20"
                              className="mr-2 rounded-full object-cover aspect-square"
                              src={host.picture}
                            />
                          )}
                        </div>
                        <p>{host.first_name + " " + host.last_name}</p>
                        <p className="cursor-pointer whitespace-pre text-gray-500 text-xs text-center">
                          {"  " + workspace?.workspace.name}
                        </p>
                      </div>
                    )
                  })}
                </div>
              )
            )
          })}
        </div>
        {error && !focused && (
          <div className="pl-1 py-1 flex items-center text-red-600 text-sm">
            <p>
              {isNotification ? translate("addVisit.errorHostNotification") :
                translate("addVisit.errorHost")
              }
            </p>
          </div>)}
      </div>
    </div>
  )
}
