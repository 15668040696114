export const splitDateTime = (date: string | null | undefined): string[] =>
  date !== null && date !== undefined ? date.split("T") : ["", ""]

export const startAndEndDateCrudFilter = (
  startDate: string,
  endDate: string
): string => {
  return `${startDate ? `start_time__gte=${startDate}` : ""}${
    startDate && endDate ? "&" : ""
  }${endDate ? `start_time__lt=${endDate}` : ""}`
}
