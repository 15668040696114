import React, { useState, useEffect, useRef } from "react"
import { Tooltip } from "react-tooltip"
import {
  useMenu,
  useRouterContext,
  ITreeMenu,
  useCustom,
  usePermissions,
  useCan
} from "@pankod/refine-core"
import { IWorkspace } from "interfaces"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import { setSelectedWorkspace } from "reduxStore/reducers/workspaceReducer"
import { LOG, Logger } from "utilities/logger"
import { getPermittedMenuItems } from "utilities/permissionSelector"
import { Home } from "icons/icons"
import {
  IconDefinition,
  faCertificate,
  faPeopleArrows,
  faPersonShelter
} from "@fortawesome/free-solid-svg-icons"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEYS } from "utilities/types"
import { generateIcon } from "resources"
import { useDispatch } from "react-redux"
import { setDeviceSearch } from "reduxStore/reducers/deviceSearchReducer"
import { Logo } from "./Logo"
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  LockClosedIcon
} from "@heroicons/react/20/solid"
import { usePostHog } from "posthog-js/react"

export const Sider: React.FC = () => {
  const { menuItems } = useMenu()
  const [showDropdown, setDropdown] = useState(false)
  const { data: permissionsData } = usePermissions<string>()

  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const posthog = usePostHog()

  const { data: canAccessVisitModule, isLoading } = useCan({
    resource: "visits",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["visit"]
        }
      }
    }
  })

  const { data: canAccessRoomsModule } = useCan({
    resource: "rooms",
    action: "list",
    params: {
      resource: {
        name: "",
        options: {
          name: "",
          modules: ["rooms"]
        }
      }
    }
  })

  const newMenuItems = [
    {
      name: "/",
      route: "/",
      icon: <Home />,
      children: [],
      label: "Home"
    },
    ...getPermittedMenuItems(permissionsData, menuItems)
  ]

  // Filter and sort menu items
  function getNavItems(menuItems: ITreeMenu[]): JSX.Element {
    let home: JSX.Element = <></>
    const visitItems: JSX.Element[] = []
    const roomsItems: JSX.Element[] = []

    const items = menuItems
      .map((item) => {
        if (item.name === "/") {
          home = <NavItem key={item.name} {...item} />
          return null
        } else if (
          item.name === "visits" ||
          item.name === "devices/" ||
          item.name === "hosts/" ||
          item.name === "visitors/"
        ) {
          visitItems.push(<AccordionNavItem key={item.name} {...item} />)
          return null
        } else if (
          item.name === "displays/" ||
          item.name === "calendar-integrations/" ||
          item.name === "spaces/"
        ) {
          // TODO: Remove feature flag check when feature is complete
          if (
            item.name === "spaces/" &&
            !posthog.isFeatureEnabled("show-spaces")
          ) {
            return null
          }
          roomsItems.push(<AccordionNavItem key={item.name} {...item} />)
          return null
        } else {
          return <NavItem key={item.name} {...item} />
        }
      })
      .filter((item) => item !== null)

    return (
      <div className="mt-8 space-y-2">
        <div className="h-12">{home}</div>
        <Accordion
          title="Visits"
          icon={faPeopleArrows}
          locked={
            visitItems.length > 0 && canAccessVisitModule?.can ? false : true
          }
          loading={isLoading}
        >
          <>{visitItems}</>
        </Accordion>
        <Accordion title="Inductions" icon={faCertificate} locked={true} />
        <Accordion
          title="Rooms"
          icon={faPersonShelter}
          locked={
            roomsItems.length > 0 && canAccessRoomsModule?.can ? false : true
          }
          loading={isLoading}
        >
          <>{roomsItems}</>
        </Accordion>
        {items}
      </div>
    )
  }

  return (
    <div className="px-4 w-72">
      <div className="flex flex-col justify-between">
        <div className="flex flex-col">
          <div className="flex items-start justify-between">
            <div className="flex justify-center items-center h-20">
              <button
                className=""
                onClick={() => {
                  push("/")
                }}
              >
                <Logo className="h-6" />
              </button>
            </div>
          </div>

          <div
            onClick={() => {
              setDropdown(!showDropdown)
            }}
          >
            <WorkspacePicker
              showDropdown={showDropdown}
              setDropdown={setDropdown}
            />
          </div>

          {getNavItems(newMenuItems)}
        </div>
      </div>
    </div>
  )
}

const NavItem = ({ name, route, icon, label }: ITreeMenu) => {
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const { selectedKey } = useMenu()
  const isSelected = route === selectedKey

  return (
    <button
      onClick={() => {
        void Logger().log(LOG.CHANGE_PAGE, route)
        push(route)
      }}
      className={`px-2 flex items-center rounded-lg h-12 w-full space-x-2 ${
        isSelected
          ? "bg-white hover:bg-one-gray-50 shadow-sm"
          : "hover:bg-one-gray-200/25"
      }`}
    >
      <div
        className={`flex justify-center items-center w-6 h-6 ${
          isSelected ? "text-one-rose-500" : "text-one-gray-500"
        }`}
      >
        {icon}
      </div>
      <div className={`${isSelected ? "text-one-rose-500" : ""}`}>{label}</div>
    </button>
  )
}

const AccordionNavItem = ({ name, route, icon, label }: ITreeMenu) => {
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()
  const { selectedKey } = useMenu()
  const isSelected = route === selectedKey
  const dispatch = useDispatch()

  return (
    <button
      onClick={() => {
        // Clear device search filter
        if (route?.includes("devices") || route?.includes("displays")) {
          dispatch(setDeviceSearch(""))
        }
        void Logger().log(LOG.CHANGE_PAGE, route)
        push(route)
      }}
      className={`px-2 flex items-center h-10 space-x-2 transform rounded-xl hover:bg-one-gray-200/25 ${
        isSelected ? "text-one-rose-500" : "text-one-gray-600"
      }`}
    >
      <div className="ml-8 z-0">{label}</div>
    </button>
  )
}

// Accordion
type AccordionProps = {
  title: string
  children?: JSX.Element
  icon: IconDefinition
  locked?: boolean
  loading?: boolean
}

const Accordion = ({
  title,
  children,
  icon,
  locked = false,
  loading = false
}: AccordionProps) => {
  const [isActive, setIsActive] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const { useHistory } = useRouterContext()
  const { push }: { push: (arg0: string | undefined) => void } = useHistory()

  const toggleAccordion = () => {
    setIsActive(!isActive)
  }

  const name = title?.toLowerCase()

  return (
    <div className="">
      <button
        data-testid={name && !locked ? `menu-button-${name}` : undefined}
        onClick={
          locked
            ? () => {
                // TODO: Create pass info page
                push(`/${name}`)
              }
            : toggleAccordion
        }
        className={`px-2 flex items-center h-12 w-full text-left space-x-2 rounded-lg ${
          isActive
            ? "bg-white hover:bg-one-gray-50 shadow-sm"
            : "hover:bg-one-gray-200/25"
        }`}
      >
        <div
          className={`flex items-center justify-center w-6 h-6 ${
            isActive ? "text-one-rose-400" : ""
          }`}
        >
          {generateIcon(icon, undefined, isActive ? "" : "gray")}
        </div>

        <div
          className={`flex-grow truncate ${
            isActive ? "text-one-rose-500" : ""
          }`}
        >
          {title}
        </div>

        <div>
          {loading ? (
            <></>
          ) : locked ? (
            <LockClosedIcon className="text-one-purple-900 mr-1.5 h-3 w-3" />
          ) : isActive ? (
            <ChevronDownIcon className="text-one-gray-600 mr-1 h-4 w-4" />
          ) : (
            <ChevronRightIcon className="text-one-gray-600 mr-1 h-4 w-4" />
          )}
        </div>
      </button>

      {isActive && (
        <div ref={contentRef} className="mt-2 mb-4 flex flex-col">
          {children}
        </div>
      )}
    </div>
  )
}

type WorkspacePickerProps = {
  showDropdown: boolean
  setDropdown: (arg0: boolean) => void
}

export const WorkspacePicker = (props: WorkspacePickerProps) => {
  const { data: workspaceData, isLoading } = useCustom<[IWorkspace]>({
    url: "workspaces/",
    method: "get"
  })

  const dispatch = useAppDispatch()
  const selectedWorkspaceId = useAppSelector((state) => state.workspace)

  useEffect(() => {
    if (!isLoading && !selectedWorkspaceId) {
      const { id } = workspaceData?.data[0] || {}
      dispatch(setSelectedWorkspace(id))
    }
  }, [isLoading, selectedWorkspaceId])

  const currentWorkspace = workspaceData?.data?.at(
    workspaceData?.data.findIndex(
      (obj) => obj.id === parseInt(selectedWorkspaceId || "")
    )
  )

  const currentWorkspaceName = currentWorkspace?.name
  const currentOrganizationName = currentWorkspace?.organization?.name
  const workspaceCount = workspaceData?.data?.length ?? 0

  let css =
    "px-2 space-x-2 flex items-center w-full h-16 rounded-lg text-left text-sm bg-white cursor-default"
  if (workspaceCount > 1) {
    css += " cursor-pointer shadow hover:bg-one-gray-50"
  }

  return (
    <div className="relative z-auto">
      <button
        data-tooltip-id="workspace-picker-tooltip"
        data-tooltip-content={currentWorkspaceName}
        data-tooltip-place="right"
        className={css}
      >
        <div className="flex flex-shrink-0 items-center justify-center rounded-full font-medium uppercase bg-one-rose-200 w-8 h-8">
          {currentOrganizationName?.charAt(0)}
        </div>

        <div className="flex-grow overflow-hidden inline">
          <div className="truncate">{currentWorkspaceName}</div>
          <div className="truncate text-one-gray-400">
            {currentOrganizationName}
          </div>
        </div>

        {workspaceCount > 1 && (
          <div className="flex-shrink-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M16 28L9 21 10.41 19.59 16 25.17 21.59 19.59 23 21 16 28zM16 4L23 11 21.59 12.41 16 6.83 10.41 12.41 9 11 16 4z"></path>
            </svg>
          </div>
        )}
      </button>

      {workspaceCount > 1 && props.showDropdown && (
        <WorkspaceDropdown
          workspaces={workspaceData?.data}
          selectedWorkspaceId={selectedWorkspaceId}
        />
      )}

      <Tooltip id="workspace-picker-tooltip" style={{ zIndex: 99 }} />
    </div>
  )
}

const WorkspaceDropdown = ({
  workspaces,
  selectedWorkspaceId
}: {
  workspaces: [IWorkspace] | undefined
  selectedWorkspaceId: string | null
}) => {
  const dispatch = useAppDispatch()
  const workspaceId = selectedWorkspaceId && parseInt(selectedWorkspaceId)
  const queryClient = useQueryClient()

  return (
    <div
      style={{ animation: "scaleIn 0.125s" }}
      className="mt-1 p-1 absolute origin-top-left shadow rounded-lg bg-white w-72 z-10"
    >
      {workspaces?.map(({ name, id }, index) => (
        <button
          className="p-1 space-x-2 flex items-center w-full rounded-md text-left text-one-gray-600 hover:bg-one-gray-50"
          data-tooltip-id="workspace-picker-tooltip"
          data-tooltip-content={name}
          data-tooltip-place="right"
          key={index}
          onClick={() => {
            void Logger().log(LOG.PRESS_WORKSPACE_ITEM, `pressed ${id}`)
            // reset workspace settings query
            queryClient.removeQueries({
              queryKey: [QUERY_KEYS.WORKSPACE_SETTING]
            })
            dispatch(setSelectedWorkspace(id))
          }}
        >
          <div className="flex flex-shrink-0 items-center justify-center rounded-full font-medium uppercase bg-blue-200 w-8 h-8">
            {name.charAt(0)}
          </div>

          <div
            className={`flex-grow truncate ${
              workspaceId === id ? "font-medium text-black" : ""
            }`}
          >
            {name}
          </div>

          {workspaceId === id && (
            <div className="flex flex-shrink-0 justify-center items-center">
              <CheckIcon className="text-emerald-500 h-4 w-4" />
            </div>
          )}
        </button>
      ))}
    </div>
  )
}
