import { useTranslate } from "@pankod/refine-core"
import { InputSearch } from "./globals/InputSearch"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { IVisitVisitor, IVisitorInfo } from "interfaces"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { usePagination } from "hooks/usePagination"
import { INF_SCROLL_QUERY_KEY_TYPES } from "utilities/types"
import { capitalizeFirstLetter } from "utilities/string"
import { useScrollTrigger } from "hooks/useScrollTrigger"
import { faCircleUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const VisitorSearch = ({
  workspaceId,
  setVisitorId,
  setVisitorInfo,
  showNewVisitorForm,
  setShowNewVisitorForm,
  setCreateFirstName,
  setCreateLastName,
  search,
  setSearch,
  selectedVisitors,
  error,
  setError
}: {
  workspaceId: number
  setVisitorId: Dispatch<SetStateAction<number | undefined>>
  setVisitorInfo: Dispatch<SetStateAction<IVisitorInfo | undefined>>
  showNewVisitorForm: boolean
  setShowNewVisitorForm: Dispatch<SetStateAction<boolean>>
  setCreateFirstName: Dispatch<SetStateAction<string>>
  setCreateLastName: Dispatch<SetStateAction<string>>
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  selectedVisitors: IVisitVisitor[]
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
}) => {
  const translate = useTranslate()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [focused, setFocused] = useState(false)

  const scrollRef = useRef<HTMLDivElement>(null)

  const [visitorData, , isLoading, fetchNextPage, hasNextPage] =
    usePagination<IVisitorInfo>(
      "/visitors",
      "",
      lastName
        ? `workspace_id=${workspaceId}&first_name__like=${firstName}&last_name__like=${lastName}`
        : `workspace_id=${workspaceId}&first_name__like=${firstName}`,
      INF_SCROLL_QUERY_KEY_TYPES.VISITORS
    )

  useScrollTrigger(hasNextPage, scrollRef, fetchNextPage)

  useEffect(() => {
    showNewVisitorForm && setShowNewVisitorForm(false)

    if (!search) {
      setFirstName("")
      setLastName("")
    } else if (search.includes(" ")) {
      const nameArray = search.split(" ")
      setFirstName(nameArray[0] as string)
      setLastName(nameArray[1] as string)
    } else {
      setFirstName(search)
      setLastName("")
    }
  }, [search])

  useEffect(() => {
    if (focused) {
      setShowNewVisitorForm(false)
    }
  }, [focused])

  const selectVisitor = (visitor: IVisitorInfo) => {
    setVisitorInfo(visitor)
    setVisitorId(visitor.id)
    setError(false)
  }

  return (
    <div className="py-1">
      <label>{translate("addVisit.enterVisitorName")}</label>
      <div className="flex rounded-lg py-1">
        <InputSearch
          placeholder={translate("addVisit.searchVisitors")}
          search={search}
          setSearch={setSearch}
          css="w-full"
          error={error}
          onBlur={() => {
            selectedVisitors?.length === 0 ? setError(true) : setError(false)
          }}
          setFocused={setFocused}
          onEnter={() => {
            if (visitorData?.length === 0) {
              setCreateFirstName(capitalizeFirstLetter(firstName))
              setCreateLastName(capitalizeFirstLetter(lastName))
              setShowNewVisitorForm(true)
            }
          }}
        />
      </div>
      <div className="min-h-fit">
        {focused && (
          <div className="shadow-md rounded-md">
            <div
              ref={scrollRef}
              className="overflow-x-auto bg-white max-h-48 rounded-t-md"
            >
              {isLoading ? (
                <div className="px-1 pb-1">
                  <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
                    <Skeleton count={3} height={30} />
                  </SkeletonTheme>
                </div>
              ) : (
                <>
                  {visitorData?.length === 0 && !showNewVisitorForm && search && (
                    <div className="pl-5 py-1 flex items-center text-gray-400">
                      <p>
                        {`${translate("addVisit.noResultsFor")} '${search}'`}
                      </p>
                    </div>
                  )}
                  {!search && (
                    <div className="pl-5 py-1 flex items-center text-gray-400">
                      <p>{translate("addVisit.startByTypingAName")}</p>
                    </div>
                  )}
                  {visitorData?.length > 0 &&
                    search &&
                    visitorData.map((visitor: IVisitorInfo, index) => (
                      <div
                        key={index}
                        className="cursor-pointer pl-5 py-1 flex hover:bg-gray-100 items-center"
                        onClick={() => {
                          selectVisitor(visitor)
                          setSearch("")
                        }}
                      >
                        <p>{`${visitor.first_name} ${visitor.last_name}`}</p>
                        <p className="cursor-pointer whitespace-pre text-gray-500 text-xs text-center">
                          {` ${capitalizeFirstLetter(visitor.company ?? "")}`}
                        </p>
                      </div>
                    ))}
                </>
              )}
            </div>
            {!showNewVisitorForm && (
              <div
                className="cursor-pointer pl-5 py-1 flex hover:bg-gray-100 items-center border-t border-gray-300 rounded-b-md mb-2"
                onClick={() => {
                  setCreateFirstName(capitalizeFirstLetter(firstName))
                  setCreateLastName(capitalizeFirstLetter(lastName))
                  setShowNewVisitorForm(true)
                  setError(false)
                }}
              >
                <p className="text-blue-500">
                  {search ? (
                    <div>
                      <FontAwesomeIcon icon={faCircleUser} />{" "}
                      {translate("addVisit.addXAsNewVisitor", {
                        name: search
                      })}
                    </div>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faCircleUser} />{" "}
                      {translate("addVisit.addNewVisitor")}
                    </div>
                  )}
                </p>
              </div>
            )}
          </div>
        )}
        {!focused && !showNewVisitorForm && error && (
          <div className="pl-1 py-1 flex items-center text-red-600 text-sm">
            <p>{translate("addVisit.errorVisitor")}</p>
          </div>
        )}
      </div>
    </div>
  )
}
