import React from "react"

export interface SyCodeProps {
  readonly lines: readonly string[]
}

export function SyCode({ lines }: SyCodeProps): React.ReactElement {
  return (
    <div className="bg-gray-300 rounded-md px-2 py-1">
      <div className="text-gray-600 font-medium overflow-x-auto">
        {lines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    </div>
  )
}
