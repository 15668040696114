import { createSlice } from "@reduxjs/toolkit"

export const adHocVisitorPaginationSlice = createSlice({
  name: "adHocVisitorPagination",
  initialState: {
    currentPage: 1,
    totalPages: 1
  },
  reducers: {
    setCurrentPage: (state, action: { payload: number }) => {
      state.currentPage = action.payload
    },
    setTotalPages: (state, action: { payload: number }) => {
      state.totalPages = action.payload
    },
    clearPagination: (state) => {
      state.currentPage = 1
      state.totalPages = 1
    },
    previousPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage--
      }
    },
    nextPage: (state) => {
      if (state.currentPage < state.totalPages) {
        state.currentPage++
      }
    }
  }
})

export const {
  setCurrentPage,
  setTotalPages,
  clearPagination,
  previousPage,
  nextPage
} = adHocVisitorPaginationSlice.actions

export default adHocVisitorPaginationSlice.reducer
