import {
  faEnvelope,
  faPhone,
  faSignInAlt,
  faPrint,
  faEdit
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { VisitorState } from "components/VisitorState"
import { IVisitor } from "interfaces"
import { ContactDetailNotificationEnablement } from "modules/ContactDetails/ContactDetailNotificationEnablement"
import {
  contactDetailPrimaryByType,
  shouldSendNotification
} from "modules/ContactDetails/contactDetailPrimaryNotification"
import { contactDetailType } from "modules/ContactDetails/contactDetailType"
import { VisitorAvatar } from "modules/Visit/VisitorAvatar"
import {
  visitSourceByState,
  visitStartOrEndTimeByState
} from "modules/Visit/visitDateTime"
import { isEmptyString } from "utilities/string"
import { useTranslate } from "@pankod/refine-core"
import { useAppDispatch } from "reduxStore/store"
import { setIsPrintVisitBadgeOpen } from "reduxStore/reducers/visitPrintBadgeReducer"
import { useUpdateVisitor } from "hooks/useUpdateVisitorData"
import { useHasPermission } from "utilities/permissionSelector"
import { IS_PREVIEW_MODE } from "utilities/development"
import { UserIcon } from "@heroicons/react/24/outline"

interface VisitorContainerProps {
  original: any
  onEditVisitClick: (visitId: number) => void
}

export const VisitorContainer = (props: VisitorContainerProps) => {
  const { onEditVisitClick } = props

  const dispatch = useAppDispatch()
  const translate = useTranslate()
  const { handleVisitorUpdate } = useUpdateVisitor()
  const hasEditVisitPermissions = useHasPermission("Visit", "ReadWrite")

  const { id: visitId, visitor } = props.original as {
    id: number
    visitor: IVisitor
    start_time: string
    end_time: string
  }
  const visitorPrimaryContactDetailEmail = contactDetailPrimaryByType({
    contactDetails: props.original.visitor.details.contact_details,
    contactDetailType: contactDetailType.email
  })
  const visitorPrimaryContactDetailPhone = contactDetailPrimaryByType({
    contactDetails: props.original.visitor.details.contact_details,
    contactDetailType: contactDetailType.phone
  })
  const hostPrimaryContactDetailPhone = contactDetailPrimaryByType({
    contactDetails: props.original.host.details.contact_details,
    contactDetailType: contactDetailType.phone
  })
  const hostPrimaryContactDetailEmail = contactDetailPrimaryByType({
    contactDetails: props.original.host.details.contact_details,
    contactDetailType: contactDetailType.email
  })
  const hostHasSmsNotificationEnabled = shouldSendNotification({
    contactDetails: props.original.host.details.contact_details,
    contactDetailType: contactDetailType.phone
  })
  const hostHasEmailNotificationEnabled = shouldSendNotification({
    contactDetails: props.original.host.details.contact_details,
    contactDetailType: contactDetailType.email
  })

  return (
    <div className="flex items-start">
      <VisitorAvatar visitor={props.original?.visitor} />
      <div className="ml-4">
        <div className="text-xl font-medium text-gray-900 mb-4">
          {props.original?.visitor?.details?.first_name}{" "}
          {props.original?.visitor?.details?.last_name}
          <h3 className="text-lg font-normal text-gray-900 mb-4">
            {props.original?.visitor?.details?.company}
          </h3>
        </div>
        <div className="text-sm text-gray-500">
          {props.original?.visitor?.details !== undefined && (
            <div className="grid grid-cols-3 gap-36">
              <div className="flex flex-wrap flex-col">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  {translate("table.visits.information")}
                </h3>
                <span className="text-base text-gray-900 mb-2">
                  <VisitorState state={props.original?.visitor?.state?.name} />
                </span>
                <span className="text-base text-gray-900 mb-2">
                  {visitStartOrEndTimeByState(
                    props.original?.visitor?.state?.name as string,
                    props.original?.visitor as IVisitor,
                    props.original?.start_time as string,
                    props.original?.end_time as string
                  )}
                </span>
                <span className="text-base text-gray-900 mb-2">
                  {visitSourceByState(
                    props.original?.visitor?.state?.name as string,
                    props.original?.visitor as IVisitor
                  )}
                </span>
                {props.original?.name !== undefined &&
                  !isEmptyString(props.original?.name as string) && (
                    <div className="flex-wrap flex-col mb-4">
                      <h3 className="text-lg font-medium text-gray-900 mb-2">
                        {translate("table.visits.title")}
                      </h3>
                      <span className="text-base text-gray-900 mb-2">
                        {props.original?.name}
                      </span>
                    </div>
                  )}
                {props.original?.info !== undefined &&
                  !isEmptyString(props.original?.info as string) && (
                    <div className="flex flex-wrap flex-col">
                      <h3 className="text-lg font-medium text-gray-900 mb-2">
                        {translate("table.visits.extraInformation")}
                      </h3>
                      <span className="text-base text-gray-900 mb-2">
                        {props.original?.info}
                      </span>
                    </div>
                  )}
              </div>
              <div className="flex flex-wrap flex-col">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  {translate("table.visits.contact")}
                </h3>
                <span className="mb-2 text-base text-gray-900">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="lg"
                    className="text-systam-blue"
                  />{" "}
                  {visitorPrimaryContactDetailEmail ?? null}
                </span>
                <span className="mb-2 text-base text-gray-900">
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="lg"
                    className="text-systam-blue"
                  />{" "}
                  {visitorPrimaryContactDetailPhone ?? null}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* avatar - this will be a reusable component - single, group (with stacking dependending how many hosts in a group +n) */}
      <div className="flex-shrink-0 h-16 w-16">
        {props.original?.host?.details?.picture ? (
          <img
            className="shadow-lg h-16 w-16 rounded-full object-cover aspect-square"
            src={props.original?.host?.details?.picture}
            alt="Host looks"
          />
        ) : (
          <UserIcon className="h-14 w-14 text-systam-blue" />
        )}
      </div>
      {/* host card details - this will be a reusable component*/}
      <div className="ml-4">
        <div className="text-xl font-medium text-gray-900 mb-4">
          {props.original?.host?.details?.first_name}{" "}
          {props.original?.host?.details?.last_name}
          <h3 className="text-lg font-normal text-gray-900 mb-4">
            {props.original?.workspace?.organization?.name}
          </h3>
        </div>
        <div className="text-sm text-gray-500">
          {props.original?.host?.details?.contact_details?.length > 0 && (
            <div className="grid grid-cols-3 gap-36">
              <div className="flex flex-wrap flex-col">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  {translate("table.visits.hostNotifications")}
                </h3>
                <ContactDetailNotificationEnablement
                  notificationEnabled={shouldSendNotification({
                    contactDetails: props.original.host.details.contact_details,
                    contactDetailType: contactDetailType.phone
                  })}
                  contactDetailType={contactDetailType.phone}
                />{" "}
                <span className="text-base text-gray-900">
                  is{" "}
                  {hostHasSmsNotificationEnabled ? (
                    <span>enabled</span>
                  ) : (
                    <span>disabled</span>
                  )}{" "}
                  for notifications
                </span>
                <ContactDetailNotificationEnablement
                  notificationEnabled={shouldSendNotification({
                    contactDetails: props.original.host.details.contact_details,
                    contactDetailType: contactDetailType.email
                  })}
                  contactDetailType={contactDetailType.email}
                />{" "}
                <span className="text-base text-gray-900">
                  is{" "}
                  {hostHasEmailNotificationEnabled ? (
                    <span>enabled</span>
                  ) : (
                    <span>disabled</span>
                  )}{" "}
                  for notifications
                </span>
              </div>
              <div className="flex flex-wrap flex-col ml-4">
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  {translate("table.visits.contact")}
                </h3>
                <span className="text-base text-gray-900 mb-2">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="lg"
                    className="text-systam-blue"
                  />{" "}
                  {hostPrimaryContactDetailEmail}
                </span>
                <span className="text-base text-gray-900 mb-2">
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="lg"
                    className="text-systam-blue"
                  />{" "}
                  {hostPrimaryContactDetailPhone}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* actions component - dependenting on the visit state, refactor to use global button */}
      <div className="ml-4">
        <div className="text-lg text-gray-500">
          <div className="flex flex-wrap flex-col m-4">
            <button
              onClick={() =>
                handleVisitorUpdate(visitId, visitor?.visitor_id, {
                  state: "signed_in"
                })
              }
              className={`flex hover:bg-gray-200 bg-gray-100 py-1 px-3 rounded-md items-center mr-4 min-w-24 h-12 focus:outline-none ${
                visitor?.state?.name !== "expected" &&
                visitor?.state?.name !== "signed_out"
                  ? "!cursor-not-allowed"
                  : ""
              }`}
              disabled={
                visitor?.state?.name !== "expected" &&
                visitor?.state?.name !== "signed_out"
              }
            >
              <p className="pr-2 text-gray-600 font-medium">
                {translate("table.visits.signIn")}
              </p>
              <FontAwesomeIcon icon={faSignInAlt} size="lg" />
            </button>
          </div>
          <div className="flex flex-wrap flex-col m-4">
            <button
              onClick={() => dispatch(setIsPrintVisitBadgeOpen(true))}
              className="flex hover:bg-gray-200 bg-gray-100 py-1 px-3 rounded-md items-center mr-4 min-w-24 h-12 focus:outline-none"
            >
              <p className="pr-2 text-gray-600 font-medium">
                {translate("table.visits.printBadge")}
              </p>
              <FontAwesomeIcon icon={faPrint} size="lg" />
            </button>
          </div>
          {IS_PREVIEW_MODE && hasEditVisitPermissions && (
            <div className="flex flex-wrap flex-col m-4">
              <button
                className="flex hover:bg-gray-200 bg-gray-100 py-1 px-3 rounded-md items-center mr-4 min-w-24 h-12 focus:outline-none"
                onClick={() => {
                  onEditVisitClick(visitId)
                }}
              >
                <p className="pr-2 text-gray-600 font-medium">
                  {translate("table.visits.editVisit")}
                </p>
                <FontAwesomeIcon icon={faEdit} size="lg" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
