import { useCallback } from "react"
import { Listbox } from "@headlessui/react"
import i18next from "i18next"
import i18n from "utilities/i18n"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { Logger, LOG } from "utilities/logger"
import { useLanguage } from "hooks/useLanguage"
import { Language } from "icons/icons"

export const LanguageDropdown = () => {
  const supportedLanguages = i18n.languages
  const { selectedLanguage, saveSelectedLanguage } = useLanguage()

  const languageMapping = {
    en: "In English",
    fi: "Suomeksi",
    sv: "På svenska"
  }

  const handleChangeLanguage = (language: string) => {
    i18next
      .changeLanguage(language)
      .then(() => {
        saveSelectedLanguage(language)
      })
      .catch((error) => {
        void Logger().error(LOG.CHANGE_LANGUAGE, `${error}`)
      })
  }

  const handleLanguageChangeCallback = useCallback(handleChangeLanguage, [
    saveSelectedLanguage
  ])

  return (
    <div className="relative w-48">
      <Listbox value={selectedLanguage} onChange={handleLanguageChangeCallback}>
        <Listbox.Button className="relative w-full border border-solid border-gray-300 py-2 pl-3 pr-10 text-left bg-white rounded-3xl shadow-md cursor-pointer focus:outline-none">
          <span className="inline-flex items-center justify-between w-full">
            <span className="inline-flex items-center">
              <Language />
              <span className="ml-2">{languageMapping[selectedLanguage]}</span>
            </span>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="w-4 h-4 transition-transform duration-200 transform ui-open:rotate-180"
            />
          </span>
        </Listbox.Button>
        <Listbox.Options className="absolute w-full py-1 mt-1 bg-white rounded-3xl shadow-md">
          {supportedLanguages.map((language) => (
            <Listbox.Option
              key={language}
              value={language}
              className="cursor-pointer select-none relative py-2 pl-10 pr-4"
            >
              {({ selected }) => (
                <>
                  <span
                    className={`block truncate ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {languageMapping[language]}
                  </span>
                  {selected && (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <Language />
                    </span>
                  )}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
