import google from "assets/images/gmail.svg"
import outlook from "assets/images/outlook.svg"
import question_mark from "assets/images/question_mark.svg"

export const getResourceTypeIcon = (type?: string) => {
  let imgSrc = ""
  switch (type) {
    case "Azure": {
      imgSrc = outlook
      break
    }
    case "Google": {
      imgSrc = google
      break
    }
    default: {
      imgSrc = question_mark
      break
    }
  }
  return imgSrc
}
