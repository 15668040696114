import { useMutation } from "@tanstack/react-query"
import MessagingApiService from "services/MessagingApiService"

type IVisitor = {
  state: string
  first_name: string
  last_name: string
  email: string
  phone: string
  company: string
  visitorId?: number
}

export interface ISmsNotificationMessageRequest {
  userId?: string
  visitors?: IVisitor[]
  visitId?: number
  visitorName?: string
  visitorCompanyName?: string
  visitorPhone?: string
  organizationId?: number
  hostId?: number
  destination?: string
  userData?: string
  template?: string
}

const useSendSms = () => {
  return useMutation(
    ({ request }: { request: ISmsNotificationMessageRequest }) =>
      MessagingApiService.sendSms(request)
  )
}

export default useSendSms
