import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { Logger, LOG } from "./logger"

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "fi", "sv"],
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
    defaultNS: "common",
    lng: "en",
    fallbackLng: ["en", "fi", "sv"]
  })
  .catch((e) => {
    void Logger().error(LOG.I18N, `${e}`)
  })

export default i18n
