import { useTranslate } from "@pankod/refine-core"
import unlinkIcon from "assets/images/unlink-icon.svg"

type UnlinkButtonProps = {
  onClick: () => void
}

export const UnlinkButton: React.FC<UnlinkButtonProps> = ({ onClick }) => {
  const translate = useTranslate()

  return (
    <button
      className="ring-1 ring-offset-0 ring-one-gray-200 rounded-md w-full py-1 h-10"
      onClick={onClick}
    >
      <div className="flex flex-row justify-center">
        <img alt="unlink-icon h-4" src={unlinkIcon} className="mr-2" />
        <p className="font-medium">{translate("pages.spaces.unlink")}</p>
      </div>
    </button>
  )
}
