import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { useTranslate } from "@pankod/refine-core/"
import { generateIcon } from "resources"
import { LOG, Logger } from "utilities/logger"

export const PassInfo: React.FC = () => {
  const checkIcon = generateIcon(faCheck, undefined, "#2ab9ad")
  const translate = useTranslate()

  return (
    <div className="flex h-full">
      <div className="flex h-full flex-row pt-40 w-full ">
        <div className="flex flex-col w-full">
          <h1 className="text-4xl font-medium pb-10">
            {translate("pages.passInfo.title")}
          </h1>
          <p>{translate("pages.passInfo.description")}</p>
          <div className="flex flex-col gap-2 pt-4">
            <div className="flex flex-row gap-2 items-center">
              {checkIcon}
              <p>{translate("pages.passInfo.bulletList.inductions")}</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              {checkIcon}
              <p>{translate("pages.passInfo.bulletList.safety")}</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              {checkIcon}
              <p>{translate("pages.passInfo.bulletList.ndas")}</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              {checkIcon}
              <p>{translate("pages.passInfo.bulletList.onboarding")}</p>
            </div>
            <div className="flex flex-row gap-2 items-center">
              {checkIcon}
              <p>{translate("pages.passInfo.bulletList.andMore")}</p>
            </div>
          </div>
          <div className="flex flex-col gap-2 pt-10">
            <p className="font-medium pb-4">
              {translate("pages.passInfo.wantToGetMost")}
            </p>
            <a
              href="https://systam.fi/en/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-systam-blue w-80 px-8 py-2 text-white font-medium hover:bg-blue-900 focus:outline-none rounded-full text-center"
              onClick={() => {
                void Logger().log(LOG.PRESS_PASS_UPSELLING_CONTACT)
              }}
            >
              {translate("pages.passInfo.buttons.contactToUnlock")}
            </a>
            <a
              href="https://systam.fi/en/services/systam-pass/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-systam-blue w-80 px-8 py-2 text-white font-medium hover:bg-blue-900 focus:outline-none rounded-full text-center"
              onClick={() => {
                void Logger().log(LOG.PRESS_PASS_UPSELLING_READMORE)
              }}
            >
              {translate("pages.passInfo.buttons.readMore")}
            </a>
          </div>
        </div>
        <div className="w-full visible -2xl:hidden">
          <div className="ml-24 min-h-full bg-pass-art bg-no-repeat bg-contain bg-right"></div>
        </div>
      </div>
    </div>
  )
}
