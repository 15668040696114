import { useCustom, useImport, useTranslate } from "@pankod/refine-core"
import { useCallback, useEffect, useState } from "react"
import { LOG, Logger } from "utilities/logger"
import { IHostCSV, IHostPost, IWorkspace } from "interfaces"
import ErrorText from "components/formMessage"
import { useAppSelector } from "reduxStore/store"
import customToast from "utilities/toastHelper"
import { useQueryClient } from "@tanstack/react-query"
import { ClipLoader } from "react-spinners"
import { SyFileUpload } from "components/new/shared/input/SyFileUpload"
import { SelectWorkspace } from "../globals/SelectWorkspace"
import { OkCancelButtonGroup } from "components/new/shared/modal"
import { SyLabel } from "components/new/shared"

export interface ImportHostsCsvProps {
  readonly onSuccess: () => void
  readonly onClose: () => void
}

export const ImportHostsCsv = ({ onSuccess, onClose }: ImportHostsCsvProps) => {
  const translate = useTranslate()
  const [selectedWorkspace, setSelectedWorkspace] = useState<
    IWorkspace | undefined
  >(undefined)
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [fileMissing, setFileMissing] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const [itemsProcessed, setItemsProcessed] = useState(1)
  const [total, setTotal] = useState(0)
  const [processing, setProcessing] = useState(false)

  const workspaceId = useAppSelector((state) => state.workspace)

  const { data: workspaces } = useCustom<IWorkspace[]>({
    url: "/workspaces/",
    method: "get"
  })

  const { handleChange } = useImport({
    resourceName: `hosts`,
    paparseOptions: {
      skipEmptyLines: true
    },
    onProgress: ({ totalAmount, processedAmount }) => {
      setItemsProcessed(processedAmount)
      setTotal(totalAmount)
    },
    onFinish: ({ succeeded, errored }) => {
      if (succeeded.length === 0) {
        customToast.error(translate("notifications.hostImportFailed"))
      } else {
        customToast.success(
          translate("notifications.xHostsAdded", {
            amount: succeeded.length
          })
        )
        onSuccess()
        if (errored.length > 0) {
          customToast.error(
            translate("notifications.xHostImportFailed", {
              amount: errored.length
            })
          )
        }
      }

      void queryClient.resetQueries({
        queryKey: ["infScroll"]
      })
    },
    mapData: (hostFromCSV: IHostCSV): IHostPost => {
      const { Familyname, Firstname, Tel, Email } = hostFromCSV

      return {
        workspace_id: parseInt(workspaceId as string),
        language_id: 1,
        picture: "",
        first_name: Firstname,
        last_name: Familyname,
        email: [
          {
            primary: true,
            notifications: true,
            value: Email
          }
        ],
        phone: [
          {
            primary: true,
            notifications: true,
            value: Tel
          }
        ]
      }
    },
    batchSize: 1
  })

  useEffect(() => {
    const workspaceList = workspaces?.data ?? []
    if (selectedWorkspace || workspaceList.length === 0) {
      return
    }

    if (workspaceList.length === 1) {
      setSelectedWorkspace(workspaceList[0])
      return
    }

    const currentUserWorkspace = workspaceList.find(
      (w) => w.id.toFixed() === workspaceId
    )
    if (currentUserWorkspace) {
      setSelectedWorkspace(currentUserWorkspace)
    }
  }, [workspaceId, workspaces, selectedWorkspace, setSelectedWorkspace])

  const handleImport = useCallback(() => {
    async function handleImportInternal() {
      if (!selectedFile) {
        setFileMissing(true)
        return
      }

      setProcessing(true)
      try {
        await handleChange({
          file: selectedFile as Partial<File>
        })
        setSelectedFile(undefined)
        setFileMissing(false)
      } catch (e) {
        void Logger().error(LOG.IMPORT_CSV, `${e}`)
      } finally {
        setProcessing(false)
      }
    }
    void handleImportInternal()
  }, [selectedFile, setProcessing, setFileMissing, handleChange])

  const handleClose = useCallback(() => {
    setSelectedFile(undefined)
    setFileMissing(false)
    setProcessing(false)
    onClose()
  }, [onClose, setSelectedFile, setFileMissing, setProcessing])

  return (
    <div className="flex flex-col">
      <SelectWorkspace
        workspaces={workspaces?.data ?? []}
        selectedWorkspace={selectedWorkspace}
        setSelectedWorkspace={setSelectedWorkspace}
      />
      <div className="mt-3">
        <SyLabel>{translate("modal.importHostsInstructionsLabel")}</SyLabel>
        <p className="text-gray-500">
          {translate("modal.importHostsInstructions")}
        </p>
      </div>
      <div className="bg-gray-300 rounded-md px-2 py-1">
        <p className="text-gray-600 font-medium">
          Familyname;Firstname;Tel;Email
          <br />
          Doe;John;050123123;john.doe@mail.com
          <br />
          Doe;Jane;050123123;jane.doe@mail.com
        </p>
      </div>
      <div className="mt-5 mb-5">
        <SyFileUpload
          accept={[".csv"]}
          files={selectedFile ? [selectedFile] : undefined}
          onFileUploadChange={(files) => {
            if (!files || files.length === 0) {
              setFileMissing(true)
              return
            }
            setSelectedFile(files[0])
          }}
        />
        {fileMissing && ErrorText("Add csv file")}
      </div>

      <OkCancelButtonGroup
        okLabel={translate("buttons.importCsv")}
        onOkClick={handleImport}
        cancelLabel={translate("buttons.cancel")}
        onCancelClick={handleClose}
      />

      {processing && (
        <div className="flex justify-end mt-3">
          <div className="flex justify-center items-center">
            <ClipLoader
              color="#0C46CA"
              loading={true}
              size={25}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <p className="text-gray-500 pl-2">
              {translate("importingHosts") + `... ${itemsProcessed} / ${total}`}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
