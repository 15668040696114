import axios from "axios"
import { MESSAGING_API_POST_FIX_URL } from "services/MessagingApiService"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import i18n from "utilities/i18n"

enum NotificationKeys {
  SmsError = "notifications.smsError",
  EmailError = "notifications.emailError",
  CreateVisitError = "notifications.createVisitError",
  GenericError = "error.something_wrong",
  NotFoundError = "not_found"
}

type ErrorHandler = {
  url: string
  message: NotificationKeys
}

const errorHandlers: ErrorHandler[] = [
  { url: MESSAGING_API_POST_FIX_URL.SMS, message: NotificationKeys.SmsError },
  {
    url: MESSAGING_API_POST_FIX_URL.EMAIL,
    message: NotificationKeys.EmailError
  }
]

const workspaceErrorHandlers: ErrorHandler[] = [
  {
    url: `workspaces/%s/visitors/`,
    message: NotificationKeys.CreateVisitError
  },
  { url: `workspaces/%s/visits/`, message: NotificationKeys.CreateVisitError }
]

export const handleAxiosError = (
  error: unknown,
  selectedWorkspaceId: string | null
) => {
  if (!(error instanceof Error)) {
    customToast.error(i18n.t(NotificationKeys.GenericError))
    return false
  }

  if (!selectedWorkspaceId) {
    customToast.error(i18n.t(NotificationKeys.GenericError))
    return false
  }

  if (typeof error === "object" && error !== null && "stack" in error) {
    void Logger().error(LOG.CREATE_VISIT_ERROR, `${error.stack}`)
  }

  if (!axios.isAxiosError(error)) {
    customToast.error(i18n.t(NotificationKeys.GenericError))
    return false
  }

  const { responseURL } = error.request

  if (typeof responseURL !== "string") {
    customToast.error(i18n.t(NotificationKeys.GenericError))
    return false
  }

  const workspaceHandler = workspaceErrorHandlers.find(({ url }) =>
    responseURL.includes(url.replace("%s", selectedWorkspaceId))
  )

  if (workspaceHandler) {
    customToast.error(i18n.t(workspaceHandler.message))
    return true
  }

  const errorHandler = errorHandlers.find(({ url }) =>
    responseURL.includes(url)
  )
  if (errorHandler) {
    customToast.error(i18n.t(errorHandler.message))
    return true
  }

  customToast.error(i18n.t(NotificationKeys.GenericError))
  return true
}
