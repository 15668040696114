import { SyTypography } from "components/new/shared"

export interface FooterContentItemProps {
  readonly href: string
  readonly children: string
}

/**
 * Renders a footer content item with a link.
 *
 * @param href - The URL of the link.
 * @param children - The content to be displayed inside the link.
 * @returns The rendered footer content item.
 */
export const FooterContentItem = ({
  href,
  children
}: FooterContentItemProps): JSX.Element => {
  return (
    <a
      className="font-medium text-one-rose-400 hover:text-one-rose-500"
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <SyTypography>{children}</SyTypography>
    </a>
  )
}

/**
 * Renders a separator element for the footer content item.
 * @returns The separator element.
 */
export const FooterContentItemSeparator = (): JSX.Element => {
  return (
    <span
      className="inline-block w-1 h-1 bg-gray-600 rounded-full"
      aria-label="separator"
    ></span>
  )
}
