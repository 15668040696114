import React, { useCallback } from "react"
import { DateTime } from "luxon"
import { faCalendar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslate } from "@pankod/refine-core"
import { DateTimeInput } from "components/globals/DateTimeInput"
import { VisitTimePicker } from "modules/Visit/VisitTimePicker"

export interface VisitDateTimeData {
  readonly startDate: string
  readonly startTime: string
}

export interface VisitDateTimeInputProps {
  readonly dateTime: VisitDateTimeData
  readonly onDateTimeChange: (dateTime: VisitDateTimeData) => void
  readonly isDisabled?: boolean
}

export function VisitDateTimeInput({
  dateTime,
  onDateTimeChange,
  isDisabled
}: VisitDateTimeInputProps): React.ReactElement {
  const translate = useTranslate()

  const { startDate, startTime } = dateTime

  const minDate = DateTime.local().toISODate()

  const handleStartDateChange = useCallback(
    (date: string) => {
      onDateTimeChange({
        ...dateTime,
        startDate: date
      })
    },
    [dateTime, onDateTimeChange]
  )

  const handleStartTimeChange = useCallback(
    (time: string) => {
      onDateTimeChange({
        ...dateTime,
        startTime: time
      })
    },
    [dateTime, onDateTimeChange]
  )

  return (
    <div className="mt-0 bg-gray-50">
      <div className="px-4 pb-4 bg-white">
        <div className="mb-4">
          <FontAwesomeIcon
            size="lg"
            icon={faCalendar}
            className="text-gray-400 mr-2"
            style={{ width: "1.5rem" }}
          />
          <label>{translate("buttons.dateOfVisit")}</label>
        </div>
        <div className="flex flex-row space-x-4 mb-4">
          <div className="flex-shrink">
            <DateTimeInput
              title="Start date"
              type="date"
              value={startDate}
              min={minDate}
              onChange={handleStartDateChange}
              isDisabled={isDisabled ?? false}
            />
          </div>
          <div className="flex-shrink">
            <VisitTimePicker
              title="Start time"
              startDate={startDate}
              initialVisitTime={startTime}
              editMode={true}
              onChange={handleStartTimeChange}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
