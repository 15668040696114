export const ACCESS_TOKEN_KEY = "accessToken"
export const REFRESH_TOKEN_KEY = "refreshToken"
export const USERNAME_KEY = "username"
export const EXPIRES_KEY = "expires"
export const USER_TYPE_KEY = "type"
export const USER_ID_KEY = "userId"
export const PERMISSION_KEY = "permissions"
export const ROLE_KEY = "role"
export const SCOPE_ID = "SCOPE_ID"
export const SELECTED_WORKSPACE = "SELECTED_WORKSPACE"

export const HTTP_STATUS_OK = 200
export const HTTP_STATUS_NO_CONTENT = 204
export const HTTP_STATUS_UNAUTHORIZED = 401
export const HTTP_STATUS_FORBIDDEN = 403
export const HTTP_STATUS_NOT_FOUND = 404

export const DAY_IN_MS = 86400000
export const MONTH_START_FROM_0 = 1

export const PAGE_ONE = 1
export const PAGE_TWO = 2
export const PAGE_SIZE = 20
export const MAX_PAGE = 100

export const VISIT_TABLE_FILTER_YESTERDAY = "VISIT_TABLE_FILTER_YESTERDAY"
export const VISIT_TABLE_FILTER_TODAY = "VISIT_TABLE_FILTER_TODAY"
export const VISIT_TABLE_FILTER_TOMORROW = "VISIT_TABLE_FILTER_TOMORROW"
export const VISIT_TABLE_FILTER_CUSTOM_DATE = "VISIT_TABLE_FILTER_CUSTOM_DATE"
export const VISIT_TABLE_FILTER_CUSTOM_START = "VISIT_TABLE_FILTER_CUSTOM_START"
export const VISIT_TABLE_FILTER_CUSTOM_END = "VISIT_TABLE_FILTER_CUSTOM_END"

export const REFETCH_INTERVAL_IN_MS = 1000 * 60 * 5 // 5 minutes