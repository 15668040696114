import React, { useCallback } from "react"
import { SyModal } from "components/new/shared"
import { LOG, Logger } from "utilities/logger"
import { ImportUsersCsv } from "./ImportUsersCsv"
import { useTranslate } from "@pankod/refine-core"

export interface ImportUsersCsvModalProps {
  readonly open: boolean
  readonly onSuccess: () => void
  readonly onClose: () => void
}

export function ImportUsersCsvModal({
  open,
  onSuccess,
  onClose
}: ImportUsersCsvModalProps): React.ReactElement {
  const translate = useTranslate()

  const handleClose = useCallback(() => {
    void Logger().log(LOG.PRESS_CANCEL_IMPORT_USER_MODAL)
    onClose()
  }, [onClose])

  return (
    <SyModal
      open={open}
      onClose={handleClose}
      title={translate("users.importCsv.modalTitle")}
      size="2xl"
    >
      <ImportUsersCsv
        onSuccess={onSuccess}
        onClose={handleClose}
      />
    </SyModal>
  )
}
