import React, { useMemo } from "react"
import classNames from "classnames"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export type SyButtonVariant = "contained" | "outlined" | "text"
export type SyButtonColor = "primary" | "secondary" | "danger"

export interface SyButtonProps {
  readonly className?: string
  readonly variant?: SyButtonVariant
  readonly color?: SyButtonColor
  readonly fullRounded?: boolean
  readonly label: string
  readonly leftIcon?: IconDefinition
  readonly rightIcon?: IconDefinition
  readonly onClick?: () => void
}

export function SyButton({
  className,
  variant,
  color,
  fullRounded,
  label,
  leftIcon,
  rightIcon,
  onClick
}: SyButtonProps): React.ReactElement {
  const mainVariableClasses = useMemo(
    () => getMainClasses(variant, color, fullRounded, leftIcon, rightIcon),
    [variant, color, fullRounded, leftIcon, rightIcon]
  )

  const finalMainClasses = classNames(
    className,
    "h-12 font-medium text-base",
    mainVariableClasses
  )

  return (
    <button className={finalMainClasses} onClick={onClick}>
      {leftIcon && (
        <span className="mr-2">
          <FontAwesomeIcon icon={leftIcon} size="sm" />
        </span>
      )}
      <span>{label}</span>
      {rightIcon && (
        <span className="ml-2">
          <FontAwesomeIcon icon={rightIcon} size="sm" />
        </span>
      )}
    </button>
  )
}

function getMainClasses(
  variant: SyButtonVariant | undefined,
  color: SyButtonColor | undefined,
  fullRounded: boolean | undefined,
  leftIcon: IconDefinition | undefined,
  rightIcon: IconDefinition | undefined
): Readonly<Record<string, boolean>> {
  const variantFinal = variant ?? "contained"
  const colorFinal = color ?? "primary"

  return {
    "px-6": !leftIcon && !rightIcon,
    "px-4": !!leftIcon || !!rightIcon,
    "rounded-full": !!fullRounded,
    "rounded-lg": !fullRounded,
    "font-medium": variantFinal === "contained",
    "bg-sy-denim-800 text-white hover:bg-sy-denim-700":
      variantFinal === "contained" && colorFinal === "primary",
    "bg-sy-gray-100 text-sy-gray-600 hover:bg-sy-gray-200":
      variantFinal === "contained" && colorFinal === "secondary",
    "bg-sy-mandy-600 text-white hover:bg-sy-mandy-500":
      variantFinal === "contained" && colorFinal === "danger",
    "text-sy-denim-800 border border-sy-denim-800 hover:text-sy-denim-600 hover:border-sy-denim-600":
      variantFinal === "outlined" && colorFinal === "primary",
    "text-sy-gray-600 border border-sy-gray-100 hover:text-sy-gray-500 hover:border-sy-gray-200":
      variantFinal === "outlined" && colorFinal === "secondary",
    "text-sy-mandy-600 border border-sy-mandy-600 hover:text-sy-mandy-400 hover:border-sy-mandy-400":
      variantFinal === "outlined" && colorFinal === "danger",
    "text-sy-denim-800 hover:text-sy-denim-600":
      variantFinal === "text" && colorFinal === "primary",
    "text-sy-gray-600 hover:text-sy-gray-400":
      variantFinal === "text" && colorFinal === "secondary",
    "text-sy-mandy-600 hover:text-sy-mandy-400":
      variantFinal === "text" && colorFinal === "danger"
  }
}
