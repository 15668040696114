import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { useTranslate } from "@pankod/refine-core"
import {
  ICaledarIntegration,
  IDevice,
  IDeviceModel,
  IOrganization,
  IRoles,
  ISettingOption,
  IWorkspace
} from "interfaces"
import React, { Dispatch, SetStateAction, useMemo } from "react"
import { generateIcon } from "resources"

type SelectProps = {
  disabled?: boolean
  label: string
  placeholder: string
  id: string
  value?: string
  /** Error state */
  error?: boolean
  required?: boolean
  initialValue?: string
  allowShowAll?: boolean
  optionData?:
    | [IDevice]
    | IDevice[]
    | [IOrganization]
    | [IRoles]
    | [IWorkspace]
    | [IDeviceModel]
    | ISettingOption[]
    | [ICaledarIntegration]
  onChange?: Dispatch<SetStateAction<any>>
  onBlur?: () => void
}

export const Select: React.FC<SelectProps> = ({
  disabled,
  label,
  placeholder,
  id,
  value,
  error,
  required,
  initialValue,
  optionData,
  allowShowAll,
  onChange,
  onBlur
}) => {
  const requiredIcon = generateIcon(faCircle, "2xs", "orangered")
  const translate = useTranslate()

  const renderedOptions = useMemo(
    () =>
      optionData?.map((option) => (
        <option key={option.id} value={option.id} className="text-black">
          {option.name ?? option.username}
        </option>
      )),
    [optionData]
  )
  return (
    <div className="w-full">
      <label
        className={[
          "text-s pt-1 !text-neutral-800",
          error ? "text-red" : "text-neutral-800"
        ].join(" ")}
      >
        {label}
      </label>
      {required && (
        <div className="inline-block pl-1 scale-75">{requiredIcon}</div>
      )}
      <select
        disabled={disabled}
        className={[
          "flex w-full py-2 rounded-md pl-2 !border !border-gray-300",
          error ?? "!border !border-red !border-solid"
        ].join(" ")}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        <option value="" disabled hidden selected>
          {initialValue}
        </option>
        {allowShowAll && (
          <option value="0">{translate("buttons.showAll")}</option>
        )}
        {renderedOptions}
      </select>
    </div>
  )
}
