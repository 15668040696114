import React from "react"
import { createBrowserHistory } from "history"
import { LOG, Logger } from "utilities/logger"
import i18n from "utilities/i18n"

interface MyProps {
  children: JSX.Element
}

interface MyState {
  hasError: boolean
  message: string
  buttonMessage: string
}

export default class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props)
    this.state = {
      hasError: false,
      message: i18n.t("pages.error.something_wrong"),
      buttonMessage: i18n.t("pages.error.backHome")
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    void Logger().error(LOG.ERROR_BOUNDARY, `${error.stack}`)
  }

  render() {
    const { hasError, message, buttonMessage } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <div className="flex flex-1 flex-col items-center justify-center h-screen">
          <h1 className="text-gray-500">{message}</h1>
          <p
            className="cursor-pointer font-medium"
            onClick={() => {
              createBrowserHistory().replace("/")
              window.location.reload()
            }}
          >
            {buttonMessage}
          </p>
        </div>
      )
    }

    return children
  }
}
