import { createSlice } from "@reduxjs/toolkit"

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    isDrawerOpen: false,
    mode: "",
    selectedId: undefined
  },
  reducers: {
    setIsDrawerOpen: (state, { payload }) => {
      state.isDrawerOpen = payload
    },
    setMode: (state, { payload }) => {
      state.mode = payload
    },
    setSelectedResourceId: (state, { payload }) => {
      state.selectedId = payload
    }
  }
})

export const { setIsDrawerOpen, setMode, setSelectedResourceId } =
  drawerSlice.actions

export default drawerSlice.reducer
