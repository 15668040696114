import { Dispatch, SetStateAction, useEffect, useState } from "react"

export const useOutsideClick = (
  ref: React.RefObject<HTMLDivElement>,
  dropdownButtonRef: React.RefObject<HTMLButtonElement>
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [dropdown, setDropdown] = useState<boolean>(false)

  useEffect(() => {
    const handleClick = (event) => {
      // Check that target element is not the dropdown div
      if (
        ref.current &&
        !ref.current.contains(event.target as HTMLButtonElement) &&
        !dropdownButtonRef.current?.contains(event.target as HTMLButtonElement)
      ) {
        setDropdown(!dropdown)
      }
    }

    document.addEventListener("click", handleClick, true)

    return () => {
      document.removeEventListener("click", handleClick, true)
    }
  }, [dropdown, setDropdown])

  return [dropdown, setDropdown]
}
