import { useTranslate } from "@pankod/refine-core"
import { Dispatch, SetStateAction } from "react"
import {
  setFilterCustomDate,
  setFilterCustomDateEnd,
  setFilterCustomDateStart
} from "reduxStore/reducers/visitTableFilters"
import { useAppDispatch, useAppSelector } from "reduxStore/store"
import { startAndEndDateCrudFilter } from "utilities/date"

export const VisitTableDateFilterModal = ({
  showModal
}: {
  showModal: Dispatch<SetStateAction<boolean>>
}) => {
  const translate = useTranslate()
  const dispatch = useAppDispatch()

  const { customStartDatetime, customEndDatetime } = useAppSelector(
    (state) => state.visitTableFilters
  )

  return (
    <>
      <div className="absolute top-11 z-20 bg-white rounded-md border border-gray-200 rounded-lg p-4">
        <h3 className="font-medium self-center text-xl text-gray-800">
          {translate("visitTime")}
        </h3>
        <div className="flex mt-4">
          <div className="flex flex-col mr-4">
            <label className="mb-1 text-sm sm:text-sm tracking-wide text-gray-600">
              {translate("from")}:
            </label>
            <input
              className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-systam-blue"
              type="datetime-local"
              name="from-visit-dates"
              value={customStartDatetime || ""}
              onChange={(e) =>
                dispatch(setFilterCustomDateStart(e.target.value))
              }
            />
          </div>
          <div className="flex flex-col">
            <label className="mb-1 text-sm sm:text-sm tracking-wide text-gray-600">
              {translate("to")}:
            </label>
            <input
              className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-systam-blue"
              type="datetime-local"
              name="to-visit-dates"
              value={customEndDatetime || ""}
              onChange={(e) => dispatch(setFilterCustomDateEnd(e.target.value))}
            />
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <div className="flex w-full">
            <button
              onClick={() => {
                showModal(false)
              }}
              className="items-center justify-center focus:outline-none text-sm sm:text-base font-medium bg-gray-200 hover:bg-gray-300 rounded py-2 w-1/5 transition duration-150 ease-in border border-transparent text-gray-600 px-5 mr-2"
            >
              <span className="mr-2">{translate("buttons.cancel")}</span>
            </button>
            <button
              onClick={() => {
                dispatch(setFilterCustomDateStart(""))
                dispatch(setFilterCustomDateEnd(""))
                dispatch(setFilterCustomDate(""))
              }}
              className="focus:outline-none text-sm sm:text-base font-medium bg-white rounded py-2 w-1/5 transition duration-150 ease-in border border-transparent text-gray-600 px-5 mr-2"
            >
              {translate("buttons.clear")}
            </button>
            <button
              onClick={() => {
                dispatch(
                  setFilterCustomDate(
                    startAndEndDateCrudFilter(
                      customStartDatetime || "",
                      customEndDatetime || ""
                    )
                  )
                )
                showModal(false)
              }}
              className="items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-systam-blue hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
            >
              <span className="mr-2 uppercase">
                {translate("buttons.save")}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
