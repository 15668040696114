import { createSlice } from "@reduxjs/toolkit"

export const printVisitSlice = createSlice({
  name: "showPrint",
  initialState: false,
  reducers: {
    setIsPrintVisitBadgeOpen: (state, { payload }) => {
      return payload
    },
    hidePrint: () => false
  }
})

export const { setIsPrintVisitBadgeOpen, hidePrint } = printVisitSlice.actions

export default printVisitSlice.reducer
