import { useTranslate } from "@pankod/refine-core"
import { Modal } from "../../../components/Modal"
import customToast from "utilities/toastHelper"
import { useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utilities/dataProvider"
import { HTTP_STATUS_NO_CONTENT, HTTP_STATUS_OK } from "utilities/constants"

export const RemoveLinkModal: React.FC<any> = ({
  deviceLinkId,
  open,
  onClose
}: {
  deviceLinkId: number
  open: boolean
  onClose: () => void
}) => {
  const translate = useTranslate()
  const queryClient = useQueryClient()

  const removeLink = async (e: React.SyntheticEvent) => {
    const res = await axiosInstance.delete(`/devices/links/${deviceLinkId}`)
    if (
      res.status === HTTP_STATUS_OK ||
      res.status === HTTP_STATUS_NO_CONTENT
    ) {
      customToast.success(
        translate("notifications.deleteSuccess", {
          resource: translate("resources.deviceLink")
        })
      )
      onClose()
      void queryClient.resetQueries({
        queryKey: [`deviceLinks`]
      })
    } else {
      customToast.error(translate("notifications.deleteError"))
    }
  }

  return (
    <Modal
      title={translate("pages.devices.removeLink")}
      isOpen={open}
      onClose={onClose}
    >
      <div className="text-center text-l pt-6 px-6 ">
        {translate("pages.devices.areYouSureRemoveLink")}
      </div>
      <div className="flex flex-col">
        <div className="mt-6 ">
          <div className="flex justify-around">
            <button
              onClick={() => {
                onClose()
              }}
            >
              {translate("buttons.cancel")}
            </button>
            <button
              className="py-3 px-6 font-medium text-white bg-systam-blue hover:bg-systam-dark rounded-lg"
              onClick={(event) => void removeLink(event)}>
              {translate("buttons.ok")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
