import { UseModalFormReturnType } from "@pankod/refine-react-hook-form"
import { useDelete, useTranslate } from "@pankod/refine-core"
import { Modal } from "../../components/Modal"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { useQueryClient } from "@tanstack/react-query"

export const DeleteHost: React.FC<UseModalFormReturnType | any> = ({
  modal: { visible, close },
  selectedId
}: {
  modal: { visible: boolean; close: () => void }
  selectedId: number
}) => {
  const translate = useTranslate()
  const { mutate } = useDelete()

  const queryClient = useQueryClient()
  const hostId = selectedId

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    void Logger().log(LOG.DEL_HOST, `deleting ${hostId}`)
    mutate(
      {
        id: hostId,
        resource: "/hosts"
      },
      {
        onError: (error, variables, context) => {
          customToast.error(
            translate("notifications.deleteError", {
              resource: translate("resources.host")
            })
          )
          void Logger().error(LOG.DEL_HOST, `${error.stack}`)
        },
        onSuccess: (data, variables, context) => {
          customToast.success(
            translate("notifications.deleteSuccess", {
              resource: translate("resources.host")
            })
          )
          close()
        },
        onSettled() {
          void queryClient.resetQueries({
            queryKey: ["infScrollHosts"]
          })
        }
      }
    )
  }

  return (
    <Modal
      title={translate("pages.hosts.deleteHost")}
      isOpen={visible}
      onClose={close}
    >
      <div className="text-center text-l pt-6 px-6 ">
        {translate("pages.hosts.areYouSure")}
      </div>
      <div className="flex flex-col">
        <form className="mt-6 ">
          <div className="flex justify-around">
            <button
              onClick={() => {
                void Logger().log(LOG.PRESS_CANCEL_DEL_HOST_MODAL)
                close()
              }}
            >
              {translate("buttons.cancel")}
            </button>
            <button
              className="py-3 px-6 font-medium text-white bg-systam-blue hover:bg-systam-dark rounded-lg"
              onClick={(event) => void onSubmit(event)}>
              {translate("buttons.delete")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}
