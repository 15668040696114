import {
  useCustom,
  useQueryClient,
  useTranslate,
  useUpdate
} from "@pankod/refine-core"
import { IVisitorInfo, IVisitorUpdate } from "interfaces"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Input } from "components/globals/Input"
import { Button, ButtonStyle } from "components/globals/Button"
import Skeleton from "react-loading-skeleton"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validatePhone
} from "utilities/formValidator"
import ErrorText from "components/formMessage"
import { UserIcon } from "@heroicons/react/24/outline"

type EditVisitorProps = {
  selectedVisitorId: number
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}

export const EditVisitor = (props: EditVisitorProps) => {
  const { data } = useCustom<IVisitorInfo>({
    url: `/visitors/${props.selectedVisitorId}`,
    method: "get"
  })
  const queryClient = useQueryClient()

  const translate = useTranslate()
  const { mutate } = useUpdate<IVisitorUpdate>()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [picture, setPicture] = useState("")

  const [errors, setErrors] = useState<string[]>([])

  const handleInputChange = (e) => {
    const { id, value } = e.target
    if (id === "firstName") {
      setFirstName(value as string)
    } else if (id === "lastName") {
      setLastName(value as string)
    } else if (id === "company") {
      setCompany(value as string)
    } else if (id === "email") {
      setEmail(value as string)
    } else if (id === "phone") {
      setPhone(value as string)
    } else if (id === "picture") {
      setPicture(value as string)
    }
  }

  useEffect(() => {
    if (data) {
      setFirstName(data.data.first_name)
      setLastName(data.data.last_name)
      data.data.company && setCompany(data.data.company)
      if (data.data.contact_details && data.data.contact_details.length > 0) {
        for (let i = 0; i < data.data.contact_details.length; i++) {
          if (data.data.contact_details[i]?.type === "Email") {
            setEmail(data.data.contact_details[i]?.value || "")
          } else if (data.data.contact_details[i]?.type === "Phone") {
            setPhone(data.data.contact_details[i]?.value || "")
          }
        }
      }
      data.data.picture && setPicture(data.data.picture)
    }
  }, [data])

  /**
   * Validate fields
   * @returns True if no errors
   */
  function validate(): boolean {
    const validationErrors = []
    validationErrors["firstName"] = validateFirstName(firstName)
    validationErrors["lastName"] = validateLastName(lastName)
    if (email) {
      validationErrors["email"] = validateEmail(email)
    }
    if (phone) {
      validationErrors["phone"] = validatePhone(phone)
    }

    setErrors(validationErrors)
    return validationErrors["firstName"] ||
      validationErrors["lastName"] ||
      validationErrors["email"] ||
      validationErrors["phone"]
      ? false
      : true
  }

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    void Logger().log(LOG.EDIT_VISITOR)

    if (validate()) {
      mutate(
        {
          resource: `visitors`,
          id: props.selectedVisitorId,
          values: {
            email: email
              ? [
                  {
                    value: email
                  }
                ]
              : [],
            phone: phone
              ? [
                  {
                    value: phone
                  }
                ]
              : [],
            first_name: firstName,
            last_name: lastName,
            company: company
          }
        },
        {
          onError: (error, variables, context) => {
            customToast.error(
              translate("notifications.editError", {
                resource: translate("resources.visitor")
              })
            )
            void Logger().error(LOG.EDIT_VISITOR, `${error.stack}`)
          },
          onSuccess: (data, variables, context) => {
            customToast.success(
              translate("notifications.editSuccess", {
                resource: translate("resources.visitor")
              })
            )
          },
          onSettled(data, error, variables, context) {
            setTimeout(() => void queryClient.invalidateQueries(), 100)
          }
        }
      )
    }
  }

  return (
    <div>
      {data ? (
        <>
          <form className="form pt-10" onSubmit={onSubmit} autoComplete="off">
            <div className="flex flex-col gap-2 px-6">
              <div>
                <div className="flex flex-col gap-4 pb-6">
                  <div>
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-1 items-center justify-center">
                        {picture ? (
                          <img
                            alt="userpic"
                            width="96"
                            height="96"
                            className="rounded-full object-cover aspect-square"
                            src={picture}
                          ></img>
                        ) : (
                          <div>
                            <UserIcon className="h-14 w-14 text-systam-blue" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-center pt-6">
                      {data.data.workspace.name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between gap-4">
                <div className="flex flex-1">
                  <Input
                    label={translate("form.firstName")}
                    placeholder={translate("form.firstName")}
                    id="firstName"
                    type="text"
                    value={firstName}
                    required={true}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className="flex flex-1">
                  <Input
                    label={translate("form.lastName")}
                    placeholder={translate("form.lastName")}
                    id="lastName"
                    type="text"
                    value={lastName}
                    required={true}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between gap-4 -mt-1">
                <div className="flex flex-1">
                  {errors["firstName"] && ErrorText(errors["firstName"])}
                </div>
                <div className="flex flex-1">
                  {errors["lastName"] && ErrorText(errors["lastName"])}
                </div>
              </div>
              <div>
                <Input
                  label={translate("form.company")}
                  placeholder={translate("form.company")}
                  id="company"
                  type="text"
                  value={company}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div>
                <Input
                  label={translate("form.email")}
                  placeholder={translate("form.email")}
                  id="email"
                  type="text"
                  value={email}
                  onChange={(e) => handleInputChange(e)}
                />
                <div className="pt-1">
                  {errors["email"] && ErrorText(errors["email"])}
                </div>
              </div>
              <div>
                <Input
                  label={translate("form.phone")}
                  placeholder={translate("form.phone")}
                  id="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => handleInputChange(e)}
                />
                <div className="pt-1">
                  {errors["phone"] && ErrorText(errors["phone"])}
                </div>
              </div>
              <div className="py-6 flex justify-around">
                <Button
                  type="submit"
                  style={ButtonStyle.BluePrimary}
                  name={translate("buttons.save")}
                />
              </div>
            </div>
          </form>
        </>
      ) : (
        <Skeleton height={400} width={200} />
      )}
    </div>
  )
}
