import { useTranslate } from "@pankod/refine-core"
import { LOG, Logger } from "utilities/logger"

export const FallbackUpselling = () => {
  const translate = useTranslate()
  return (
    <div className="flex h-full">
      <div className="flex h-full flex-row w-full ">
        <div className="flex flex-col w-full">
          <h1 className="text-4xl font-medium pb-10">
            {translate("pages.passInfo.wantToGetMost")}
          </h1>
          <div className="flex flex-col gap-2 pt-10">
            <p className="font-medium pb-4">
              {translate("pages.passInfo.wantToGetMost")}
            </p>
            <a
              href="https://systam.fi/en/contact-us/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-systam-blue w-80 px-8 py-2 text-white font-medium hover:bg-blue-900 focus:outline-none rounded-full text-center"
              onClick={() => {
                void Logger().log(LOG.PRESS_PASS_UPSELLING_CONTACT)
              }}
            >
              {translate("pages.passInfo.buttons.contactToUnlock")}
            </a>
          </div>
        </div>
        <div className="w-full visible -2xl:hidden">
          <div className="ml-4 min-h-full bg-pass-art bg-no-repeat bg-contain"></div>
        </div>
      </div>
    </div>
  )
}
