import { LOG, Logger } from "utilities/logger"
import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"

type ModalPropsType = {
  isOpen: boolean
  onClose: () => void
  title?: string
  children: any
}

export const Modal: React.FC<ModalPropsType> = ({
  isOpen,
  onClose,
  title,
  children
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            onClick={() => {
              void Logger().log(LOG.PRESS_OUTSIDE_MODAL, title)
              onClose()
            }}
            className="fixed inset-0 bg-one-gray-950/50" ></div>
        </Transition.Child>

        <div className="fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-144 bg-white rounded-xl shadow">
              <div className="p-6 flex justify-between items-center">
                <h2 className="text-2xl font-kanit">
                  {title}
                </h2>

                <button
                  className="flex justify-center items-center h-8 w-8 bg-one-gray-100 hover:bg-one-gray-200 rounded-full"
                  onClick={() => {
                    void Logger().log(LOG.PRESS_OUTSIDE_MODAL, title)
                    onClose()
                  }}
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>

              <div className="p-6">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
