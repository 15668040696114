import { DAY_IN_MS, MONTH_START_FROM_0 } from "utilities/constants"

export const filterVisitsYesterday = (): string => {
  const today = new Date()
  const yesterday = new Date(Date.now() - DAY_IN_MS)

  const params = new URLSearchParams({
    start_time__gte: `${yesterday.getFullYear()}-${
      yesterday.getMonth() + MONTH_START_FROM_0
    }-${yesterday.getDate()}`,
    start_time__lt: `${today.getFullYear()}-${
      today.getMonth() + MONTH_START_FROM_0
    }-${today.getDate()}`
  })

  return params.toString()
}

export const filterVisitsToday = () => {
  const today = new Date()
  const tomorrow = new Date(Date.now() + DAY_IN_MS)

  const params = new URLSearchParams({
    start_time__gte: `${today.getFullYear()}-${
      today.getMonth() + MONTH_START_FROM_0
    }-${today.getDate()}`,
    start_time__lt: `${tomorrow.getFullYear()}-${
      tomorrow.getMonth() + MONTH_START_FROM_0
    }-${tomorrow.getDate()}`
  })

  return params.toString()
}

export const filterVisitsTomorrow = () => {
  const TOMORROW_IN_MS = Date.now() + DAY_IN_MS
  const tomorrow = new Date(TOMORROW_IN_MS)
  const dayAfterTomorrow = new Date(TOMORROW_IN_MS + DAY_IN_MS)

  const params = new URLSearchParams({
    start_time__gte: `${tomorrow.getFullYear()}-${
      tomorrow.getMonth() + MONTH_START_FROM_0
    }-${tomorrow.getDate()}`,
    start_time__lt: `${dayAfterTomorrow.getFullYear()}-${
      dayAfterTomorrow.getMonth() + MONTH_START_FROM_0
    }-${dayAfterTomorrow.getDate()}`
  })

  return params.toString()
}
