import i18n from "./i18n"

type ValidationResult = string | null

/**
 * Minimum length required for a password.
 */
export const MIN_PASSWORD_LENGTH = 8

/**
 * Validates a password.
 *
 * @param password - The password to be validated.
 * @returns The validation result. Returns null if the password is valid, otherwise returns an error message.
 */
export const validatePassword = (password: string): ValidationResult => {
  if (!password) {
    return i18n.t("form.validators.empty", { field: i18n.t("form.password") })
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    return i18n.t("form.validators.minLength", {
      field: i18n.t("form.password"),
      length: MIN_PASSWORD_LENGTH
    })
  }

  return null
}
