export const TableButton = ({
  active,
  onClick,
  children
}: {
  active?: string | null
  onClick: () => void
  children: JSX.Element
}) => {
  return (
    <button
      className={`flex items-center rounded-lg py-2 px-3 whitespace-nowrap text-lg ${
        active ? "bg-systam-blue text-white" : "bg-white border border-gray-200"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
