import { Dialog, Transition } from "@headlessui/react"
import { useDelete, useQueryClient, useTranslate } from "@pankod/refine-core"
import { Fragment, useCallback } from "react"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"

type VisitDeleteModalProps = {
  visitId?: number
  showDeleteModal: boolean
  setShowDeleteModal: (showDeleteModal: boolean) => void
}

export const VisitDeleteModal = (
  props: VisitDeleteModalProps
) => {
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const { mutate: deleteVisitMutation } = useDelete()

  const handleVisitRemove = useCallback(
    (visitId: number) => {
      deleteVisitMutation(
        {
          id: visitId,
          resource: `visits`
        },
        {
          onSuccess: (_data, _variables, _context) => {
            customToast.success(translate(`notifications.success`))
            props.setShowDeleteModal(false)
          },
          onError: (_error, _variables, _context) => {
            void Logger().error(LOG.REMOVE_VISIT, `${_error.stack}`)
          },
          onSettled(data, error, variables, context) {
            setTimeout(() => void queryClient.invalidateQueries(), 100)
          }
        }
      )
    },
    []
  )

  return (
    <Transition appear show={props.showDeleteModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => props.setShowDeleteModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-systam-dark bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {translate("notifications.areYouSureDeleteVisit")}
                </Dialog.Title>
                <div className="flex justify-center items-center space-x-4 mt-4">
                  <button
                    type="button"
                    className="py-2 px-3 text-sm font-medium text-gray-500 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 hover:text-gray-900 focus:z-10"
                    onClick={() => props.setShowDeleteModal(false)}
                  >
                    {translate("buttons.cancel")}
                  </button>
                  <button
                    type="button"
                    className="py-2 px-3 text-sm font-medium text-center text-white !bg-red-600 rounded-lg !hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300"
                    onClick={() => {
                      if (props.visitId && props.visitId > 0) {
                        handleVisitRemove(props.visitId)
                      }
                    }
                    }
                  >
                    {translate("buttons.ok")}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
