import dayjs from "dayjs"
import { IVisitor } from "interfaces"
import { DateTime } from "luxon"
import i18n from "utilities/i18n"
import { isEmptyString } from "utilities/string"

export const convertTime = (start_time: string, end_time: string) => {
  const start = DateTime.fromISO(start_time + "Z").setLocale(navigator.language)
  let result = start.toFormat("dd.MM.yyyy HH:mm")

  // If end_time is defined, convert it to a local time and append it to the result.
  if (end_time) {
    const end = DateTime.fromISO(end_time + "Z").setLocale(navigator.language)
    result += " > " + end.toFormat("HH:mm")
  }

  return result
}

export const getTimeSpanText = (
  customStartDatetime: string | null,
  customEndDatetime: string | null
) => {
  if (customEndDatetime && customStartDatetime) {
    const start = new Date(customStartDatetime)
    const end = new Date(customEndDatetime)
    const startMonth = start.toLocaleString("default", { month: "short" })
    const endMonth = end.toLocaleString("default", { month: "short" })
    const startDate = start.getDate()
    const endDate = end.getDate()
    return `${startMonth} ${startDate} - ${endMonth} ${endDate}`
  }
}

export const formatVisitDateTime = (
  datePart: string | null | undefined,
  timePart: string | null | undefined
): string | undefined => {
  if (!datePart || !timePart) {
    // Either date or time is missing
    return undefined
  }

  const dateTime = DateTime.fromISO(`${datePart}T${timePart}`, {
    zone: "utc"
  })

  if (!dateTime.isValid) {
    // Either date or time is not properly formatted
    return undefined
  }

  return dateTime.toISO() || undefined
}

export const visitStartTimeAsHour = (visitStartTime: string): string => {
  if (!isEmptyString(visitStartTime)) {
    return DateTime.fromISO(visitStartTime + "Z").toLocaleString(
      DateTime.TIME_24_SIMPLE
    ) // add Z to make it UTC
  }

  return ""
}

export const visitStartOrEndTimeByState = (
  visitState: string,
  visitor: IVisitor,
  startTime: string,
  endTime: string
): string => {
  switch (visitState) {
    case "signed_in":
      return `${i18n.t("table.visits.signedInAt")} ${visitStartTimeAsHour(
        visitor?.sign_in?.time as string
      )}`
    case "signed_out":
      return `${i18n.t("table.visits.signedOutAt")} ${visitStartTimeAsHour(
        visitor?.end_time
      )}`
    case "expected":
      return `${dayjs(startTime).format("DD.MM.YYYY HH:mm")}`
  }

  return ""
}

export const visitSourceByState = (
  visitState: string,
  visitor: IVisitor
): string => {
  switch (visitState) {
    case "signed_in":
      return `${i18n.t("table.visits.signedInVia", {
        application: visitor?.sign_in?.application
      })}`
    case "signed_out":
      return `${i18n.t("table.visits.signedOutVia", {
        application: visitor?.sign_out?.application
      })}`
  }

  return ""
}
