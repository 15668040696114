import { useTranslate } from "@pankod/refine-core"
import { IHost } from "interfaces"
import { Dispatch, SetStateAction } from "react"
import { isEmptyString } from "utilities/string"
import { UserIcon } from "@heroicons/react/24/outline"

export const HostCell = (
  host: IHost,
  setSelectedPicture: Dispatch<SetStateAction<string | undefined>>,
  setShowPictureModal: Dispatch<SetStateAction<boolean>>
) => {
  const translate = useTranslate()
  const { first_name, last_name, company, picture } = host?.details || {}

  let notFound = false

  if (first_name === undefined || last_name === undefined) {
    notFound = true
  }

  return (
    <div className="flex items-center">
      {notFound && (
        <div>
          <UserIcon className="h-8 w-8 text-systam-blue" />
        </div>
      )}
      {!notFound &&
        (isEmptyString(picture) ? (
          <UserIcon className="h-8 w-8 text-systam-blue" />
        ) : (
          <img
            alt="host person"
            width="40"
            height="40"
            className="rounded-full object-cover aspect-square"
            src={picture}
            onClick={() => {
              setSelectedPicture(host?.details?.picture)
              setShowPictureModal(true)
            }}
          />
        ))}
      <div className="pl-2">
        <p className={notFound ? "text-gray-500" : "font-medium truncate"}>
          {notFound
            ? translate("host_not_found")
            : `${first_name} ${last_name}`}
        </p>
        <p className="text-gray-500">{!notFound && company}</p>
      </div>
    </div>
  )
}
