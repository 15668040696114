import React from "react"

type LabeledCheckboxProps = {
  label?: string
  id: string
  checked?: boolean
  onChange?: (e) => void
}

export const LabeledCheckbox: React.FC<LabeledCheckboxProps> = ({
  label,
  id,
  checked,
  onChange
}) => {
  return (
    <div className="flex items-center justify-end">
      <label className="text-gray-900 mr-2" htmlFor={id}>
        {label}
      </label>
      <input
        checked={checked}
        className="w-4 h-4"
        type="checkbox"
        id={id}
        name={id}
        onChange={onChange}
      />
    </div>
  )
}
