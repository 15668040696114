import {
  faCheck,
  faCaretUp,
  faCaretDown,
  faFilter
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Listbox, Transition } from "@headlessui/react"
import { useTranslate } from "@pankod/refine-core"
import i18next from "i18next"
import { Fragment } from "react"
import { VisitState } from "utilities/types"

export const findVisitorStateFiltersByIds = (ids: number[]): (typeof VISITOR_STATE_FILTERS[number] | undefined)[] =>
  ids
    .map((id: number) => VISITOR_STATE_FILTERS.find(filter => filter.id === id))
    .filter((filter): filter is typeof VISITOR_STATE_FILTERS[number] => filter !== undefined)

export const VISITOR_STATE_FILTERS = [
  { id: VisitState.signedIn, name: i18next.t("filters.visitorState.signedIn") },
  { id: VisitState.signedOut, name: i18next.t("filters.visitorState.signedOut") },
  { id: VisitState.expected, name: i18next.t("filters.visitorState.expected") },
  { id: VisitState.removed, name: i18next.t("filters.visitorState.removed") }
]

export type VisitorStateFilter = (typeof VISITOR_STATE_FILTERS)[number]
export type VisitorStateFilterMultiple = [
  VisitorStateFilter,
  ...VisitorStateFilter[]
]

export type VisitFiltersProps = {
  selectedVisitorStateFilter: VisitorStateFilterMultiple
  setSelectedVisitorStateFilter: (
    visitorStateFilter: VisitorStateFilterMultiple
  ) => void
}

export const VisitorStateFilters = (props: VisitFiltersProps) => {
  const translate = useTranslate()
  const visitorStateLocalizationMap = new Map([
    [1, translate("filters.visitorState.expected")],
    [2, translate("filters.visitorState.signedIn")],
    [3, translate("filters.visitorState.signedOut")],
    [4, translate("filters.visitorState.removed")]
  ])

  return (
    <Listbox
      value={props.selectedVisitorStateFilter}
      onChange={props.setSelectedVisitorStateFilter}
      multiple
    >
      <div className="flex flex-grow">
        <div className="flex">
          <Listbox.Button className="grow w-28 bg-white rounded-lg border border-gray-200 cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            {({ open }) => (
              <p className="text-base block">
                <FontAwesomeIcon icon={faFilter} size="lg" className="mr-2" />
                {translate("filters.filter")}
                {open ? (
                  <FontAwesomeIcon
                    className="ml-4"
                    icon={faCaretUp}
                    size="lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    className="ml-4"
                    icon={faCaretDown}
                    size="lg"
                  />
                )}
              </p>
            )}
          </Listbox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mt-9 z-40 max-h-60 w-36 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {VISITOR_STATE_FILTERS.map((visitorState, visitorStateIndex) => (
              <Listbox.Option
                key={visitorStateIndex}
                className={({ selected }) =>
                  `relative select-none py-2 pl-10 pr-4 leading-tight transition duration-150 ease-in-out hover:bg-systam-blue hover:text-white cursor-pointer ${
                    selected ? "bg-white text-gray-900" : "text-gray-900"
                  }`
                }
                value={visitorState}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={`block ${
                        selected ? "font-medium" : "font-normal"
                      }`}
                    >
                      {visitorStateLocalizationMap.get(visitorState.id)}
                    </span>
                    {selected || active ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-systam-blue hover:text-white">
                        <FontAwesomeIcon
                          icon={faCheck}
                          size="sm"
                          className="hover:text-white"
                        />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
