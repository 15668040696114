import { useTranslate, useCustomMutation, useApiUrl } from "@pankod/refine-core"
import { Select } from "components/globals/Select"
import { IDeviceSetting } from "interfaces"
import { useEffect, useState } from "react"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"

interface ILanguageOption {
  id: string
  name: string
  locale: string
}

export const DefaultLanguageDropdown = ({
  deviceId,
  deviceSettingsData
}: {
  deviceId: number
  deviceSettingsData: IDeviceSetting[]
}) => {
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()

  const [currentOption, setOption] = useState<ILanguageOption>()
  const key = "DefaultLanguage"
  const apiUrl = useApiUrl()

  // TODO: In future get available languages for set workspace/organization/etc
  const settingOptions: ILanguageOption[] = [
    {
      id: "1",
      name: translate("pages.devices.workspaceDefault"),
      locale: ""
    },
    { id: "2", name: translate("languages.english"), locale: "en" },
    { id: "3", name: translate("languages.finnish"), locale: "fi" },
    { id: "4", name: translate("languages.norwegian"), locale: "no" }
  ]

  const selectedSetting = deviceSettingsData?.filter(
    (setting) => setting.setting_metadata.key === key
  )[0]

  useEffect(() => {
    const selectedOption = settingOptions.find(
      (option) => selectedSetting?.value === option.locale
    )
    if (selectedOption) return setOption(selectedOption)
  }, [selectedSetting])

  const handleChange = (e) => {
    const selectedOption = settingOptions.find(
      ({ id }) => e.target.value === id
    )

    updateMutation(
      {
        url: `${apiUrl}/devices/${deviceId}/settings/${key}`,
        method: "put",
        values: { value: selectedOption?.locale }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`pages.devices.settings.${key}`)
            })
          )
          setOption(selectedOption)
          void Logger().log(LOG.EDIT_SETTING, key)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`pages.devices.settings.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }

  return (
    <div className="mb-5 flex justify-between">
      <div>
        <p className="font-medium self-center">
          {translate(`pages.devices.settings.${key}`)}
        </p>
        <p className="text-gray-500 py-3">
          {translate(`pages.devices.settings.${key}Description`)}
        </p>
      </div>
      <div>
        <Select
          label={""}
          placeholder={"placeholderi"}
          id="settingOption"
          value={`${currentOption?.id}`}
          onChange={(e) => handleChange(e)}
          optionData={settingOptions}
        />
      </div>
    </div>
  )
}
