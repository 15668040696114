import {
  IResourceComponentsProps,
  useApiUrl,
  useCreate,
  useQuery,
  useQueryClient,
  useTranslate
} from "@pankod/refine-core"
import { useModalForm } from "@pankod/refine-react-hook-form"
import {
  useReactTable,
  ColumnDef as CDef,
  getCoreRowModel
} from "@tanstack/react-table"
import React from "react"
import { Table } from "components/table/table"
import { useLocalStorage } from "hooks/useLocalStorage"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"
import { axiosInstance } from "utilities/dataProvider"
import { DateTime } from "luxon"
import {
  INTEGRATION_TYPES,
  LOCAL_STORAGE_KEYS,
  QUERY_KEYS
} from "utilities/types"
import { ICaledarIntegration } from "interfaces"
import { useMsal } from "@azure/msal-react"
import { RedirectRequest } from "@azure/msal-browser"
import { Close, GoogleWorkspace, Microsoft365 } from "icons/icons"
import { DeleteIntegrationModal } from "./delete"
import { useGoogleCalendar } from "modules/Calendar/useGoogleCalendar"
import { useFeatureFlagEnabled } from "posthog-js/react"

export const CalendarIntegrationList: React.FC<IResourceComponentsProps> = (
  props
) => {
  const translate = useTranslate()
  const { instance } = useMsal()
  const { mutate } = useCreate()
  const queryClient = useQueryClient()

  const loginRedirect: RedirectRequest = {
    scopes: [
      "User.Read",
      "User.Readbasic.All",
      "Calendars.Read",
      "Calendars.Readwrite",
      "Calendars.Read.Shared",
      "Calendars.Readwrite.Shared"
    ],
    state: "a9f447e3-9d84-4ccb-97bb-531c6765b521",
    prompt: "select_account"
  }

  const handleSignIn = () => {
    instance
      .loginPopup(loginRedirect)
      .then((res) => {
        if (res.idToken) {
          mutate(
            {
              resource: `/calendars/activate`,
              values: {
                integration_type: INTEGRATION_TYPES.AZURE,
                username: res.account?.username,
                id_token: res.idToken
              }
            },
            {
              onError: (error) => {
                customToast.error(
                  translate("notifications.createError", {
                    resource: translate("resources.CalendarIntegration")
                  })
                )
                void Logger().error(LOG.CALENDAR_INTEGRATION, `${error.stack}`)
              },
              onSuccess: () => {
                customToast.success(
                  translate("notifications.createSuccess", {
                    resource: translate("resources.CalendarIntegration")
                  })
                )
              },
              onSettled() {
                setTimeout(
                  () =>
                    void queryClient.resetQueries({
                      queryKey: [QUERY_KEYS.CALENDAR_INTEGRATION]
                    }),
                  200
                )
              }
            }
          )
        } else {
          void Logger().error(LOG.CALENDAR_INTEGRATION, "Cannot find idToken")
        }
      })
      .catch((err) => {
        void Logger().error(LOG.CALENDAR_INTEGRATION, `${err}`)
      })
  }

  const handleGoogleLoginClick = useGoogleCalendar()

  /**
   * Delete integration modal
   */
  const deleteModalFormReturnValues = useModalForm()

  const {
    modal: { show: showDeleteModal }
  } = deleteModalFormReturnValues

  /**
   * Define table column headers
   */
  const columnsMemoized = React.useMemo<CDef<ICaledarIntegration, any>[]>(
    () => [
      {
        id: "integration",
        header: translate("table.integration.integration"),
        accessorKey: "integration",
        cell: ({ getValue }) => {
          const integrationType = getValue()?.type
          return <h1>{integrationType || "???"}</h1>
        }
      },
      {
        id: "name",
        header: translate("table.integration.name"),
        accessorKey: "username"
      },
      {
        id: "created",
        header: translate("table.integration.created"),
        accessorKey: "created",
        cell: ({ getValue }) => {
          const date = getValue() as string
          const dateFormated = DateTime.fromISO(date).toLocaleString(
            DateTime.DATETIME_SHORT
          )
          return <span>{dateFormated}</span>
        }
      },
      {
        id: "remove",
        header: translate("table.integration.remove"),
        accessorKey: "remove",
        cell: (props) => {
          return (
            <button
              onClick={() => {
                showDeleteModal(props.row.original.id)
              }}
            >
              <Close color="#DC2626" />
            </button>
          )
        }
      }
    ],
    []
  )

  const apiUrl = useApiUrl()

  const { data: integrations, isLoading } = useQuery<[ICaledarIntegration]>(
    [QUERY_KEYS.CALENDAR_INTEGRATION],
    async () => {
      const res = await axiosInstance.get(`${apiUrl}/calendars`)
      return res.data
    }
  )

  /**
   * Persist column visibility state in local storage
   */
  const [columnVisibility, setLocalStorage]: [any, (arg0: any) => void] =
    useLocalStorage(LOCAL_STORAGE_KEYS.CALENDER_PROVIDER, {})

  /**
   * Initialize react-table
   */
  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    onColumnVisibilityChange: (state) => {
      setLocalStorage(state)
    },
    initialState: {},
    state: {
      columnVisibility
    },
    data: integrations || [],
    columns: columnsMemoized,
    getCoreRowModel: getCoreRowModel(),
    enableHiding: true
  })

  const showGoogleWorkspaceSignIn = useFeatureFlagEnabled(
    "show-google-workspace-sign-in"
  )

  return (
    <>
      <DeleteIntegrationModal {...deleteModalFormReturnValues} />
      <Table
        header={translate("pages.calendarIntegrations.title")}
        page={undefined}
        getAllColumns={getAllColumns}
        getHeaderGroups={getHeaderGroups}
        getRowModel={getRowModel}
        isLoading={isLoading}
        leftButtons={[
          <>
            {showGoogleWorkspaceSignIn && (
              <button
                className="flex items-center"
                key={translate("buttons.addGoogleCalendarIntegration")}
                onClick={handleGoogleLoginClick}
              >
                <GoogleWorkspace />
              </button>
            )}
            <button
              key={translate("buttons.addMicrosoftCalendarIntegration")}
              onClick={handleSignIn}
            >
              <Microsoft365 />
            </button>
          </>
        ]}
      />
    </>
  )
}
