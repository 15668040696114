import { Dialog } from "@headlessui/react"
import { useTranslate } from "@pankod/refine-core"
import { IVisitor } from "interfaces"
import { DateTime } from "luxon"
import { forwardRef, useCallback, useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { setIsPrintVisitBadgeOpen } from "reduxStore/reducers/visitPrintBadgeReducer"
import { useAppDispatch } from "reduxStore/store"
import { isEmptyString } from "utilities/string"
import { HostNotify } from "./QuickNotificationModal"

type IVisitPrintBadgeModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  visitId?: number
  visitorId?: number
  visitorData?: IVisitor
  visitHost?: HostNotify | null
}

export const Badge = forwardRef<HTMLDivElement, IVisitPrintBadgeModalProps>(
  (props, ref) => {
    const getVisitHostFullName = (
      props: IVisitPrintBadgeModalProps
    ): string => {
      let fullName = ""

      if (props.visitHost?.host?.details) {
        const { first_name, last_name } = props.visitHost.host.details
        fullName = `${first_name || ""} ${last_name || ""}`.trim()
      }

      return fullName
    }

    return (
      <div
        className="w-80 h-50 relative overflow-hidden rounded shadow-lg font-normal text-sm bg-white"
        ref={ref}
        style={{
          width: "8.5cm",
          height: "5.4cm"
        }}
      >
        <div className="flex items-center justify-around mt-12">
          {isEmptyString(
            (props.visitorData?.details?.picture as string)
              ? (props.visitorData?.details?.picture as string)
              : ""
          ) ? (
            <img
              className="w-24 h-24 rounded-full"
              src="https://via.placeholder.com/100x100"
              alt="Visitor"
            />
          ) : (
            <img
              className="w-24 h-24 rounded-full"
              src="https://via.placeholder.com/100x100"
              alt="Visitor"
            />
          )}
          <div className="text-left text-black">
            <p className="text-lg font-semibold">
              {props.visitorData?.details?.first_name}{" "}
              {props.visitorData?.details?.last_name}
            </p>
            <p className="text-lg font-semibold">
              {props.visitorData?.details?.company}
            </p>
            <p className="text-base font-semibold">
              Host: {getVisitHostFullName(props)}
            </p>
          </div>
        </div>
        <div className="absolute top-2 right-2"></div>
        <div className="text-center m-8">
          <span className="text-black text-sm">
            {DateTime.local().toLocaleString(DateTime.DATE_FULL)}
          </span>
        </div>
      </div>
    )
  }
)

export const VisitPrintBadgeModal = (props: IVisitPrintBadgeModalProps) => {
  const translate = useTranslate()
  const dispatch = useAppDispatch()
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })
  const close = useCallback(() => {
    dispatch(setIsPrintVisitBadgeOpen(false))
  }, [dispatch])

  return (
    <Dialog
      as="div"
      className="relative z-50"
      open={props.open}
      onClose={() => false}
    >
      <div
        className="fixed inset-0 bg-systam-dark opacity-70"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-52">
        <Dialog.Panel className="w-144 transform bg-white overflow-hidden rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
          <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
            {translate("table.visits.printBadge")}
          </div>
          <div className="flex items-center justify-center">
            <Badge
              ref={componentRef}
              {...props}
              visitorData={props.visitorData}
            />
          </div>
          <div className="flex justify-between mt-6">
            <button
              onClick={() => {
                close()
              }}
              className="items-center justify-center focus:outline-none text-sm sm:text-base font-medium bg-gray-200 hover:bg-gray-300 rounded py-2 w-1/5 transition duration-150 ease-in border border-transparent text-gray-600 px-5 mr-2"
            >
              <span className="mr-2">{translate("buttons.cancel")}</span>
            </button>
            <button
              onClick={handlePrint}
              className="items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-systam-blue hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
            >
              <span className="mr-2 uppercase">
                {translate("table.visits.printBadge")}
              </span>
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
