import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import advancedFormat from "dayjs/plugin/advancedFormat"
import duration from "dayjs/plugin/duration"

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(duration)

export const isOverdueVisit = (expectedVisitDate: string) => {
  const now = dayjs().startOf("day") // start of current day
  const expectedVisitDayjs = dayjs(expectedVisitDate).startOf("day") // start of expected visit day
  const diff = expectedVisitDayjs.diff(now)

  return diff < 0 // true if expected visit day is before today
}

export const formatVisitDateTime = (expectedVisitDate: string) => {
  const now = dayjs()
  const expectedVisitDayjs = dayjs(expectedVisitDate)

  const diff = expectedVisitDayjs.diff(now)
  const diffDuration = dayjs.duration(diff)

  if (diff < 0) {
    return `Overdue visit`
  }

  // if the visit is within the next hour
  if (diffDuration.asMinutes() < 60) {
    return `In ${diffDuration.minutes()} mins`
  }
  // if the visit is within the next day
  else if (diffDuration.asHours() < 24) {
    return `In ${diffDuration.hours()} hours`
  }
  // if the visit is within the next week
  else if (diffDuration.asDays() < 7) {
    const days = Math.floor(diffDuration.asDays())
    return days > 1 ? `In ${days} days` : `In ${days} day`
  }
  // if the visit is within the next month
  else if (diffDuration.asWeeks() < 4) {
    const weeks = Math.floor(diffDuration.asWeeks())
    return weeks > 1 ? `In ${weeks} weeks` : `In ${weeks} week`
  }
  // if the visit is more than a month away
  else {
    const months = Math.floor(diffDuration.asMonths())
    return months > 1 ? `In ${months} months` : `In ${months} month`
  }
}
