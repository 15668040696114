import { ITreeMenu, usePermissions } from "@pankod/refine-core"
import { IPermissions } from "interfaces"
import { permissionType } from "./types"
import { useMemo } from "react"

export const visitPermissionEnabled = (
  permissionData: string | undefined
): boolean => {
  if (permissionData !== undefined) {
    const permissionList: IPermissions[] = JSON.parse(permissionData)
    return checkPermission(permissionList, permissionType.visit)
  }
  return false
}

export const findPermissionPair = (
  permArr: permissionType[] | undefined,
  backendPerm: string | undefined
) => {
  if (Array.isArray(permArr)) {
    return permArr.some((perm) => {
      if (perm === permissionType.explicit_deny) return false
      if (perm === backendPerm) return true
      // if resource has a faked allow permission, then return true
      if (perm === permissionType.explicit_allow) return true
      return false
    })
  }
  return false
}

export const findAllowSettingPermission = (permData?: string): boolean => {
  if (permData !== undefined) {
    const permissions: IPermissions[] = JSON.parse(permData)

    return permissions.some((perm) => {
      const permType = perm?.permission.type
      if (
        permType === permissionType.all ||
        permType === permissionType.setting ||
        permType === permissionType.workspace
      ) {
        return true
      }
      return false
    })
  }
  return false
}

export function getPermittedMenuItems(
  permissions: string | undefined,
  resourceItems: ITreeMenu[]
): ITreeMenu[] {
  let generatedResourcelist: ITreeMenu[] = []

  if (permissions !== undefined) {
    const permissionList: IPermissions[] = JSON.parse(permissions)

    for (const resourceItem of resourceItems) {
      const resourcePermArr: [] | undefined = resourceItem.options?.permissions

      for (const {
        permission: { type }
      } of permissionList) {
        if (type === permissionType.all) return resourceItems

        if (findPermissionPair(resourcePermArr, type)) {
          generatedResourcelist = [...generatedResourcelist, resourceItem]
          break
        }
      }
    }
  }
  return generatedResourcelist
}

export const checkPermission = (
  permissionList: IPermissions[],
  permissionType: string
): boolean => {
  return permissionList?.some((el) => el.permission.type === "All") ||
    permissionList?.some((el) => el.permission.type === permissionType)
    ? true
    : false
}

export const toList = (permissions: string): IPermissions[] =>
  JSON.parse(permissions)

export type PermissionType = Exclude<
  `${permissionType}`,
  "explicit_allow" | "explicit_deny"
>
export type PermissionValue = "All" | "Read" | "ReadWrite"
export type PermissionName = `${PermissionType}.${PermissionValue}`

export function useHasPermission(
  type: PermissionType,
  value: PermissionValue
): boolean {
  const { data: permissionsData } = usePermissions<string>()

  return useMemo(
    () =>
      permissionsData
        ? hasPermission(parsePermissions(permissionsData), type, value)
        : false,
    [permissionsData]
  )
}

export function parsePermissions(permissions: string): readonly IPermissions[] {
  return JSON.parse(permissions)
}

export function hasPermission(
  permissions: readonly IPermissions[],
  type: PermissionType,
  value: PermissionValue
): boolean {
  const permissionsSet = new Set(permissions.map((p) => p.permission.name))

  switch (value) {
    case "All": {
      return permissionsSet.has("All.All") || permissionsSet.has(`${type}.All`)
    }
    case "Read": {
      return (
        permissionsSet.has("All.All") ||
        permissionsSet.has("All.Read") ||
        permissionsSet.has("All.ReadWrite") ||
        permissionsSet.has(`${type}.All`) ||
        permissionsSet.has(`${type}.Read`) ||
        permissionsSet.has(`${type}.ReadWrite`)
      )
    }
    case "ReadWrite": {
      return (
        permissionsSet.has("All.All") ||
        permissionsSet.has("All.ReadWrite") ||
        permissionsSet.has(`${type}.All`) ||
        permissionsSet.has(`${type}.ReadWrite`)
      )
    }
    default: {
      throw new Error(`Unknown permission type: ${type}`)
    }
  }
}
