import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { useTranslate } from "@pankod/refine-core"

export const ContactDetailWorkspaceNotificationEnablement = ({
  workspaceNotificationDisabled: workspaceNotificationEnabled
}: {
  workspaceNotificationDisabled: boolean
}): JSX.Element => {
  const translate = useTranslate()

  const disabled = () => {
    return (
      <div className="flex items-center p-4 rounded-lg bg-gray-100 mt-4">
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="text-rose-500"
          beat
          beatFade
        />
        <p className="ml-2 font-medium text-rose-500">
          {translate("pages.hosts.workspaceDisabledNotification")}
        </p>
      </div>
    )
  }

  return <div>{workspaceNotificationEnabled ? disabled() : null}</div>
}
