import { Row } from "@pankod/refine-react-table"
import { useList } from "@pankod/refine-core"
import { IVisitor } from "interfaces"
import { AdHocVisitorInitialsAvatar } from "modules/Visit/AdHocVisitorAvatar"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { VisitorContainer } from "./VisitorContainer"
import React, { useCallback, useMemo, useState } from "react"
import { VisitGroupIndicator } from "./VisitGroupIndicator"
import { Tab } from "@headlessui/react"
import { faUsers, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputSearch } from "components/globals/InputSearch"

interface TableExpandableRowProps {
  row: Row<any>
  colSpan: number
  onEditClick?: (row: Row<any>) => void
}

interface SingleVisitProps {
  original: any
  onEditVisitClick: () => void
}

const SingleVisit = React.memo(
  ({ original, onEditVisitClick }: SingleVisitProps) => {
    return (
      <VisitorContainer
        original={original}
        onEditVisitClick={onEditVisitClick}
      />
    )
  }
)

const GroupVisit = ({ original }: { original: any }) => {
  const [search, setSearch] = useState<string>("")

  const {
    data: visitorsData,
    isLoading: isVisitorsDataLoading,
    error
  } = useList<IVisitor>({
    resource: `visits/${original.id}/visitors`,
    queryOptions: {
      enabled: !!(original && original.id)
    }
  })

  const expectedVisitors =
    visitorsData?.data.filter((visitor) => visitor.state.name === "expected") ||
    []
  const signedInVisitors =
    visitorsData?.data.filter(
      (visitor) => visitor.state.name === "signed_in"
    ) || []
  const signedOutVisitors =
    visitorsData?.data.filter(
      (visitor) => visitor.state.name === "signed_out"
    ) || []

  if (error) {
    return <div>There was an error loading the visitors data.</div>
  }

  const filteredVisitorsData = visitorsData?.data.filter(
    (visitor) =>
      visitor.details &&
      (visitor.details.first_name
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
        visitor.details.last_name?.toLowerCase().includes(search.toLowerCase()))
  )

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ")
  }

  return (
    <div>
      {isVisitorsDataLoading ? (
        <SkeletonTheme baseColor="#f4f4f4" highlightColor="#fcfcfc">
          <Skeleton count={original.visitors_count} height={40} />
        </SkeletonTheme>
      ) : (
        <Tab.Group>
          <Tab.List className="flex justify-center space-x-1 p-1 w-full ml-2">
            <div className="flex w-full -mb-px items-center gap-4 font-medium">
              <Tab
                className={({ selected }) =>
                  classNames(
                    "text-blue-700 items-center inline-flex cursor-pointer gap-2 px-1 py-3 hover:text-primary-700 relative",
                    selected
                      ? 'text-blue-600 after:border-blue-600 after:border-b-2 after:inset-x-0 after:absolute after:bottom-0 after:content-[""]'
                      : "text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  )
                }
              >
                <FontAwesomeIcon icon={faUsers} className="mr-1" /> Group visit
              </Tab>
              <div className="border-r border-gray-300 h-6 mx-2 my-auto" />
              <Tab
                className={({ selected }) =>
                  classNames(
                    "text-blue-700 flex items-center line-flex cursor-pointer gap-2 px-1 py-3 hover:text-primary-700 relative",
                    selected
                      ? 'text-blue-600 after:border-blue-600 after:border-b-2 after:inset-x-0 after:absolute after:bottom-0 after:content-[""]'
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  )
                }
              >
                <FontAwesomeIcon icon={faUser} className="mr-1" /> Visitors in
                group
              </Tab>
            </div>
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel className={classNames("bg-white p-3")}>
              <VisitGroupIndicator
                startTime={original.start_time}
                endTime={original.end_time}
                visitorsCount={original.visitors_count}
                expectedVisitors={expectedVisitors.length}
                signedInVisitors={signedInVisitors.length}
                signedOutVisitors={signedOutVisitors.length}
              />
            </Tab.Panel>
            <Tab.Panel className={classNames("bg-white p-3")}>
              <div className="mb-4">
                <InputSearch
                  placeholder="Search visitor in group visit"
                  search={search}
                  setSearch={setSearch}
                  css="max-w-144 w-full"
                />
              </div>
              {filteredVisitorsData && filteredVisitorsData.length > 0 ? (
                filteredVisitorsData.map((visitor) => {
                  const fullName = `${visitor.details?.first_name} ${visitor?.details?.last_name}`
                  return (
                    <AdHocVisitorInitialsAvatar
                      key={visitor.id}
                      visitId={original.id}
                      initials={visitor?.details?.first_name}
                      fullName={fullName
                        .split(new RegExp(`(${search})`, "gi"))
                        .map((part, i) =>
                          part.toLowerCase() === search.toLowerCase() ? (
                            <span key={i} className="bg-yellow-200">
                              {part}
                            </span>
                          ) : (
                            part
                          )
                        )}
                      visitor={visitor}
                    />
                  )
                })
              ) : (
                <div>No results found</div>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  )
}

export const VisitExpandableRow = ({
  row,
  onEditClick
}: TableExpandableRowProps) => {
  const { original } = row
  const isAGroupVisit = original.visitors_count > 1

  const handleEditClick = useCallback(() => {
    onEditClick?.(row)
  }, [onEditClick, row])

  const singleVisit = useMemo(
    () => (
      <SingleVisit original={original} onEditVisitClick={handleEditClick} />
    ),
    [original, handleEditClick]
  )
  const groupVisit = useMemo(
    () => <GroupVisit original={original} />,
    [original]
  )

  return (
    <div className="p-4 bg-gray-50 border-gray-100 rounded-b-md">
      <div className="flex flex-col h-full overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-md">
        <div className="min-w-full divide-y divide-gray-300">
          <div className="divide-y divide-gray-200 bg-white">
            <div className="px-2 py-2 whitespace-nowrap">
              {!isAGroupVisit ? singleVisit : groupVisit}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
