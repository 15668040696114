import {
  useCustomMutation,
  usePermissions,
  useTranslate
} from "@pankod/refine-core"
import { ISettingEntity } from "interfaces"
import { useCallback, useEffect, useState } from "react"
import { SELECTED_WORKSPACE } from "utilities/constants"
import { LOG, Logger } from "utilities/logger"
import customToast from "utilities/toastHelper"

export const SettingTextArea = ({
  settingsData: {
    value,
    endpoint,
    setting_metadata: { description, key }
  }
}: {
  settingsData: ISettingEntity
}) => {
  const translate = useTranslate()
  const { mutate: updateMutation } = useCustomMutation()
  const { data: permissionsData } = usePermissions<string>()
  const canEdit =
    permissionsData?.includes("All.All") ||
    permissionsData?.includes("Workspace.All")
  const [realValue, setRealValue] = useState(value)
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setRealValue(value)
    setLocalValue(value)
  }, [value])

  useEffect(() => {
    setLocalValue(realValue)
  }, [realValue])

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setLocalValue(event.target.value)
    },
    [setLocalValue]
  )

  const handleBlur = useCallback(() => {
    if (realValue === localValue) return

    const workspace = localStorage.getItem(SELECTED_WORKSPACE) || ""

    updateMutation(
      {
        url: endpoint.replace("{id}", workspace),
        method: "put",
        values: { value: localValue }
      },
      {
        onSuccess: (_data, _variables, _context) => {
          setRealValue(`${_data.data?.value}`)
          customToast.success(
            translate("notifications.editSuccess", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().log(LOG.EDIT_SETTING, key)
        },
        onError: (_error) => {
          customToast.error(
            translate("notifications.editError", {
              resource: translate(`resources.${key}`)
            })
          )
          void Logger().error(LOG.EDIT_SETTING, `${key}: ${_error}`)
        }
      }
    )
  }, [realValue, localValue, endpoint, key, translate, updateMutation])

  return (
    <div className="w-full mb-5">
      <h1 className="self-center text-md font-medium">{translate(`pages.settings.${key}`)}</h1>
      <p className="text-gray-500 py-3 text-sm whitespace-pre-wrap">{translate(description)}</p>
      <textarea
        disabled={!canEdit}
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
        cols={60}
        rows={10}
        className="border border-gray-300 text-black rounded-md"
      />
    </div>
  )
}
