import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"

type INotification = {
  id: string
  timestamp: string
  message: string
  dismissed: boolean
}

let nextNotificationId = 0

const NOTIFICATIONS_CACHE_KEY = "notifications"

const getPersistedNotifications = (): Array<INotification> =>
  JSON.parse(localStorage.getItem("notifications") || "[]", (_key, value) => {
    return value
  }) as Array<INotification>

const persistNotifications = (notifications: Array<INotification>) => {
  localStorage.setItem(NOTIFICATIONS_CACHE_KEY, JSON.stringify(notifications))
}

const notificationsSlice = createSlice({
  name: NOTIFICATIONS_CACHE_KEY,
  initialState: getPersistedNotifications(),
  reducers: {
    addNotification: {
      reducer: (state, action: { payload: INotification }) => {
        const notifications = getPersistedNotifications()
        notifications?.push(action.payload)
        const eightHoursAgo = dayjs().subtract(8, "hour").toISOString()
        const filteredNotifications = notifications.filter(
          (notification) => notification.timestamp > eightHoursAgo
        )
        persistNotifications(filteredNotifications)
        state.push(action.payload)
      },
      prepare: ({ id, timestamp, message, dismissed, type, options = {} }) => {
        return {
          payload: {
            id: nextNotificationId++,
            timestamp: timestamp || new Date().toISOString(),
            message,
            type: type || "Systam One",
            dismissed: false,
            ...options
          }
        }
      }
    },
    removeNotification: (state, action) => {
      const index = state.findIndex(
        (notification) => notification.id === action.payload
      )
      if (index !== -1) {
        state.splice(index, 1)
        const notifications = getPersistedNotifications()
        notifications.splice(index, 1)
        persistNotifications(notifications)
      }
    },
    markAllNotificationsAsRead: (state) => {
      state.forEach((notification) => {
        notification.dismissed = true
      })
      const notifications = getPersistedNotifications()
      notifications.forEach((notification) => {
        notification.dismissed = true
      })
      persistNotifications(notifications)
    },
    markNotificationAsRead: (state, action) => {
      const notificationToDismiss = state.find(
        (notification) => notification.id === action.payload
      )
      if (notificationToDismiss && !notificationToDismiss.dismissed) {
        notificationToDismiss.dismissed = true
        const notifications = getPersistedNotifications()
        const notificationToDismissIndex = notifications.find(
          (notification) => notification.id === action.payload
        )
        if (notificationToDismissIndex) {
          notificationToDismissIndex.dismissed = true
          persistNotifications(notifications)
        }
      }
    },
    markNotificationAsUnread: (state, action) => {
      const notificationToDismiss = state.find(
        (notification) => notification.id === action.payload
      )
      if (notificationToDismiss && notificationToDismiss.dismissed) {
        notificationToDismiss.dismissed = false
        const notifications = getPersistedNotifications()
        const notificationToDismissIndex = notifications.find(
          (notification) => notification.id === action.payload
        )
        if (notificationToDismissIndex) {
          notificationToDismissIndex.dismissed = false
          persistNotifications(notifications)
        }
      }
    },
    markAllNotificationsAsUnread: (state) => {
      state.forEach((notification) => {
        notification.dismissed = false
        const notifications = getPersistedNotifications()
        notifications.forEach((notification) => {
          notification.dismissed = false
        })
        persistNotifications(notifications)
      })
    }
  }
})

export const {
  addNotification,
  removeNotification,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  markNotificationAsUnread,
  markAllNotificationsAsUnread
} = notificationsSlice.actions

export default notificationsSlice.reducer
