import { useCustom, useTranslate } from "@pankod/refine-core"
import { IOrganization } from "interfaces"
import { Select } from "components/globals/Select"

export const OrganizationDropdown = ({
  organizationId,
  handleInputChange
}: {
  organizationId: string
  handleInputChange: (e) => void
}) => {
  const translate = useTranslate()

  const { data: organizationData } = useCustom<[IOrganization]>({
    url: "organizations/", // TODO: remove '/'-symbol after backend is updated
    method: "get"
  })

  return (
    <Select
      label={translate("pages.users.organization")}
      placeholder={translate("pages.users.organization")}
      id="organizationId"
      value={organizationId}
      onChange={(e) => handleInputChange(e)}
      initialValue={translate("pages.users.selectOrganization")}
      optionData={organizationData?.data}
      required={true}
    />
  )
}
