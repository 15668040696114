import { Tag } from "./Tag"

// TODO: Move to interfaces file. Fix conflicting interfaces with same name
type IVisitor = {
  state: string
  first_name: string
  last_name: string
  email: string
  phone: string
  company: string
  visitorId?: number
}

export const VisitorTag = ({
  index,
  visitor
}: {
  index: number
  visitor: IVisitor
}) => {
  return (
    <Tag index={index} css="bg-blue-100 border-blue-200" visitor={visitor} />
  )
}
