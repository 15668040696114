import React, { useCallback } from "react"
import { SyModal } from "components/new/shared"
import { LOG, Logger } from "utilities/logger"
import { ImportHostsCsv } from "./ImportHostsCsv"
import { useTranslate } from "@pankod/refine-core"

export interface ImportHostsCsvModalProps {
  readonly open: boolean
  readonly onSuccess: () => void
  readonly onClose: () => void
}

export function ImportHostsCsvModal({
  open,
  onSuccess,
  onClose
}: ImportHostsCsvModalProps): React.ReactElement {
  const translate = useTranslate()

  const handleClose = useCallback(() => {
    void Logger().log(LOG.PRESS_CANCEL_IMPORT_HOST_MODAL)
    onClose()
  }, [onClose])

  return (
    <SyModal
      open={open}
      onClose={handleClose}
      title={translate("modal.importHosts")}
      size="lg"
    >
      <ImportHostsCsv
        onSuccess={onSuccess}
        onClose={handleClose}
      />
    </SyModal>
  )
}
